<template>
  <div v-if="index%2===0" class="discount-card-item">
      <div class="discount-card-top">
          <img v-lazy="item.mainGraph" alt=""><!-- :src="mainGraph" -->
      </div>
      <div class="discount-card-center" :title="item.commodityName">
          <span class="overflow">{{item.commodityName}}</span>
      </div>
<!--      <div class="sperate-horizontal-line" v-if="hasLine"></div>-->
  </div>
  <div v-else class="discount-card-item">
    <div class="discount-card-center" :title="item.commodityName">
      <span class="overflow">{{item.commodityName}}</span>
    </div>
    <div class="discount-card-top">
      <img v-lazy="item.mainGraph" alt=""><!-- :src="mainGraph" -->
    </div>
<!--    <div class="sperate-horizontal-line" v-if="hasLine"></div>-->
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    hasLine: {
      type: Boolean,
      default: true
    },
    noBackground: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
.discount-card-item {
    //min-width: 191px;
    max-width: 242px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .discount-card-top {
        width: 162px;
        height: 162px;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .discount-card-center {
      width:225px;
      height:30px;
      .overflow{
        width:100%;
        padding:0 10px;
      }
      overflow:hidden;
        padding: 31px 10px 13px 10px;
        font-size: 13px;
        color: #333;
        span{
          // width: 90%;
          // margin-left: 5%;
          line-height: 20px;
        }
    }
    .discount-card-bottom {
        width: 153px;
        height: 30px;
        background: url("../../assets/discountDiff.png");
        display: flex;
        align-items: center;
        position: relative;
        .half {
            width: 50%;
            &.left {
                font-size: 14px;
                color: #FFFFFF;
            }
            &.delete {
                font-size:12px;
                color: #999999;
                text-decoration:line-through;
            }
        }
        &.no-background {
            background: transparent;
            margin-top: -15px;
            justify-content: center;
            .half {
                width: unset;
                text-align: center;
                &.left {
                    color: #F30012;
                    padding-right: 5px;
                }
            }
        }
    }
    .sperate-horizontal-line {
        position: absolute;
        width: 1px;
        height: 152px;
        background: linear-gradient(#ffffff, #E1E1E1, #ffffff);
        right: 0;
        top: 36px;
    }
}
</style>
