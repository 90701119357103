import request from '@/router/axios'

export const ajaxList = (pageNo, pageSize, params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/myInvoiceList',
    method: 'get',
    params: {
      ...params,
      pageNo,
      pageSize
    }
  })
}
