<template>
  <div class="special-card-item-de" :style="{'background-color': backgroundColor}">
      <div class="special-card-top">
          <img :src="item.mainGraph" alt="">
      </div>
      <div class="special-card-center" :title="item.commodityName">
          <span class="overflow">{{item.commodityName}}</span>
      </div>
      <div class="special-card-bottom">
          <div class="half left">

              <span>￥{{(item.salePrice).toFixed(2)}}</span>
          </div>
          <div class="half delete">
              <span>￥{{(item.referencePrice).toFixed(2)}}</span>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    /* name: {
      type: String,
      default: ''
    },
    originPrice: {
      type: Number,
      default: 0.00
    },
    specialPrice: {
      type: Number,
      default: 0.00
    },
    mainGraph: {
      type: String
    }, */
    backgroundColor: {
      type: String,
      default: 'transparent'
    }
  }
}
</script>
<style scoped>
.display_flex{
  width:100%;
}
</style>

<style lang="scss">
.special-card-item-de {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .special-card-top {
        img {
            display: block;
            width: 162px;
            height: 162px;
        }

    }
  .special-card-none-top{
    margin:58px 0 22px 0;
  }
    .special-card-center {
      text-align: left;
      width:100%;
      height:45px;
      .overflow{
        height:16px;
      }
      overflow:hidden;
        font-size: 14px;
        color: #111111;
        // margin-top: 50px;
        span{
          position: relative;
          top: 20px;
          width: 90%;
          margin-left: 5%;
          line-height: 20px;
        }
    }
    .special-card-bottom {
        width: 90%;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content:left;
        position: relative;
        .half {
            &.left {
                color: #F30012;
                font-size: 14px;
                margin-right: 9px;
            }
            &.delete {
                font-size:12px;
                color: #999999;
                text-decoration:line-through;
            }
        }
    }
}
</style>
