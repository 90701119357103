<template>
  <div class="swipper-floor">
    <div class="left" @mouseleave="mouseLeave">
      <div v-if="headerStatus != null" class="toast_block">
        <div v-for="item01 in twoList" :key="item01.id" style="padding-left: 0" class="display_flex toast_content">
          <div class="title" @click="searchGoodsCategory(item01.treeCode,item01.treeName)">
            {{ item01.treeName }}
            <img src="@/assets/arrow.png" alt="arrow" />
          </div>
          <div class="display_flex title_two">
            <p class="list" v-for="items in item01.itemCatList" :key="items.id">
              <span @click="searchGoodsCategory(items.treeCode,items.treeName)">{{ items.treeName }}</span>
            </p>
          </div>
        </div>
      </div>
      <div @mouseover="mouseOver(idx, item)" :class="['left-item-select', headerStatus == idx ? 'add_bg' : 'no_bg',{ 'active': headerStatus == idx && headerStatus != null }]"
           v-for="(item, idx) in categoryList" :key="item.treeCode" @click="searchGoodsCategory(item.treeCode,item.treeName)">
        <div class="title">
          <span style="font-weight: 600">{{ item.treeName }}</span>
        </div>
        <div class="arrow">
          <div><img src="@/assets/arrow.png" alt="arrow" /></div>
        </div>
      </div>
    </div>
    <div class="center">
      <el-carousel height="100%" :interval="centerInterval" :autoplay="centerAutoplay">
        <el-carousel-item v-for="(arr, index) in carouselList.advertList" :key="index">
          <div style="width: 100%; height: 100%;" class="img-carousel-item">
            <div class="dianWo" @click="openUrl(arr.link, index, '03')">
              <img style="width: 100%;height: 100%;" id="img1" :src="arr.img" alt="" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="right">
      <el-carousel ref="carousel1" height="100%" style="margin-bottom: 5px" arrow="never" indicator-position="none" :autoplay="false">
        <el-carousel-item v-for="(arr, idx) in rightCarouselList.rightList1" :key="arr.id">
          <div style="width: 100%; height: 100%;" class="img-carousel-item">
            <div @click="openUrl(arr.link, idx, '04', 't')">
              <img :src="arr.img" alt="" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <el-carousel height="100%" @change="changeCarousel" style="margin-bottom: 5px" :autoplay="rightAutoplay" indicator-position="none" :interval="rightInterval">
        <el-carousel-item v-for="(arr, idx) in rightCarouselList.rightList2" :key="arr.id">
          <div style="width: 100%; height: 100%;" class="img-carousel-item">
            <div @click="openUrl(arr.link, idx,  '04', 'm')">
              <img :src="arr.img" alt="" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <el-carousel ref="carousel3" height="100%" arrow="never" indicator-position="none" :autoplay="false">
        <el-carousel-item v-for="(arr, idx) in rightCarouselList.rightList3" :key="arr.id">
          <div style="width: 100%; height: 100%;" class="img-carousel-item">
            <div @click="openUrl(arr.link, idx, '04', 'b')">
              <img :src="arr.img" alt="" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { hits } from '@/api/hits'
export default {
  props: {
    categoryList: {
      type: Array
    },
    rightCarouselList: {
      type: Object
    },
    carouselList: {
      type: Object
    },
    rightIntervalTmp: {
    },
    centerIntervalTmp: {
    }
  },
  data () {
    return {
      headerStatus: null,
      label: null,
      twoList: [],
      rightInterval: 3000,
      centerInterval: 3000,
      rightAutoplay: false,
      centerAutoplay: false
    }
  },
  created () {
  },
  mounted () {
    // attribute2
    // var that = this
    // setTimeout(function () {
    //   that.rightAutoplay = true
    //   that.centerAutoplay = true
    // }, 1000)
  },
  watch: {
    rightIntervalTmp: {
      handler (val) {
        this.rightAutoplay = false
        if (val) {
          this.rightInterval = parseFloat(val)
          this.rightAutoplay = true
        }
      }
    },
    centerIntervalTmp: {
      handler (val) {
        this.centerAutoplay = false
        if (val) {
          this.centerInterval = parseFloat(val)
          this.centerAutoplay = true
        }
      }
    }
  },
  methods: {
    mouseOver (idx, item) {
      this.headerStatus = idx
      this.twoList = item.itemCatList
    },
    mouseLeave () {
      this.headerStatus = null
    },
    searchGoodsCategory (code, name) {
      hits({
        type: '02',
        clickPostion: '02',
        busId: '02-' + code,
        busTitle: '分类-' + name,
        sourceSection: '03'
      })
      this.$router.push({
        // 调转到商品搜索页面
        path: '/search',
        query: {
          commodityClass: code,
          extName: name,
          flag: '01'
        }
      })
    },
    changeCarousel (newI, oldI) {
      // console.log('changeCarousel', new Date(), newI, oldI, this.rightInterval, this.centerInterval)
      if (newI > oldI) {
        if (newI === this.rightCarouselList.rightList2.length - 1 && oldI === 0) {
          this.$refs.carousel1.prev()
          this.$refs.carousel3.prev()
        } else {
          this.$refs.carousel1.next()
          this.$refs.carousel3.next()
        }
      }
      if (oldI > newI) {
        if (newI === 0 && oldI === this.rightCarouselList.rightList2.length - 1) {
          this.$refs.carousel1.next()
          this.$refs.carousel3.next()
        } else {
          this.$refs.carousel1.prev()
          this.$refs.carousel3.prev()
        }
      }
    },
    openUrl (link, idx, clickPostion, type) {
      var busId = ''
      var busTitle = ''
      var tmp
      if (clickPostion === '03') {
        var idxTmp = this.carouselList.advertList.length - idx
        if (idxTmp > 9) {
          busId = '03' + (idxTmp)
        } else {
          busId = '030' + (idxTmp)
        }
        busTitle = '大轮播' + (idx + 1)
      } else if (clickPostion === '04' && type === 't') {
        tmp = 1 + (idx * 3)
        if (tmp > 9) {
          busId = '04' + tmp
        } else {
          busId = '040' + tmp
        }
        busTitle = '小轮播-上' + (idx + 1)
      } else if (clickPostion === '04' && type === 'm') {
        tmp = ((idx * 3) + 2)
        if (tmp > 9) {
          busId = '04' + tmp
        } else {
          busId = '040' + tmp
        }
        busTitle = '小轮播-中' + (idx + 1)
      } else if (clickPostion === '04' && type === 'b') {
        tmp = (idx + 1) * 3
        if (tmp > 9) {
          busId = '04' + tmp
        } else {
          busId = '040' + tmp
        }
        busTitle = '小轮播-下' + (idx + 1)
      }
      hits({
        type: '02',
        clickPostion: clickPostion,
        busId: busId,
        busTitle: busTitle,
        sourceSection: '01'
      })
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-carousel--horizontal{
  overflow-y: hidden;
}
.swipper-floor {
  width: 100%;
  display: flex;
  height: 460px;
  .left {
    // width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding: 0 20px;
    background-color: #fff;
    color: #333333;
    position: relative;
    .toast_block {
      box-sizing: border-box;
      background: #fff;
      position: absolute;
      height: 460px;
      width: 1026px;
      /*width: 836px;*/
      z-index: 999;
      left: 200px;
      // border-left: 1px solid #f5f5f5;
      padding-top: 20px;
      overflow: auto;
      border: 1px solid #f7f7f7;
      background-color: #fff;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
      transition: top 0.25s ease;
      .toast_content {
        align-items: flex-start;
        box-sizing: border-box;
        padding: 5px 30px;
        .title {
          margin-right: 10px;
          min-width: 80px;
          text-align: right;
          font-weight: 700;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          cursor: pointer;
          img {
            margin-left: 10px;
            margin-bottom: 3px;
            vertical-align: middle;
          }
        }
        .title:hover,
        .list:hover {
          color: #c81623;
        }
        .title_two {
          flex-wrap: wrap;
          line-height: 20px;
        }
        .list {
          cursor: pointer;
          padding: 0 10px;
          font-size: 12px;
        }
      }
    }
    .left-item-select {
      width: 180px;
      padding: 0 16px;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      &:hover,
      &.active {
        color: #c81623;
      }
      .arrow {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .add_bg {
      background: #f5f5f5;
      box-sizing: border-box;
    }
    .no_bg {
      background: #fff;
      // box-sizing: border-box;
      .title {
        margin-left: 0;
      }
    }
  }
  .center {
    flex: 1;
    height: 100%;
    margin-left: 5px;
    .dianWo{
      height: 100%;
      cursor: pointer;
    }
  }
  .right {
    margin-left: 5px;
    width: 180px;
    display: flex;
    flex-direction: column;
    img{
      width: 180px;
      height: 150px;
    }
  }
}
</style>
