<template>
   <div class="discount-floor">
       <div class="left" v-if="hotObj && hotObj.advertPostionVo">
           <img :src="hotObj.advertPostionVo.img" alt="discount" @click="clickTotal('11', '11', '竖左活动2')">
       </div>

       <div class="right" v-if="hotObj && hotObj.goodsList">
         <el-carousel height="100%" :interval="centerInterval" :autoplay="centerAutoplay"  arrow="always">
           <el-carousel-item v-for="(item1, index) in [1,2]" :key="index">
               <div id="wrap-scroll" class="wrap-scroll" v-if="item1 ==1">
                   <div v-for="(item, index) in hotObj.goodsList.slice(0,4)" :key="item.id + '' + index + 'q'"
                        @click="goodsDetail(item.id, item.commodityName, '05', index)">
                     <discount-card :item="item"/>
                   </div>
               </div>
             <div id="wrap-scroll2" class="wrap-scroll" v-if="item1 ==2 & hotObj.goodsList.length < 5">
               <div v-for="(item, index) in hotObj.goodsList.slice(0,4)" :key="item.id + '' + index + 'q'"
                    @click="goodsDetail(item.id, item.commodityName, '05', index)">
                 <discount-card :item="item"/>
               </div>
             </div>
             <div id="wrap-scroll3" class="wrap-scroll" v-if="item1 ==2 & hotObj.goodsList.length > 4">
                 <div v-for="(item, index) in hotObj.goodsList.slice(4,8)" :key="item.id + '' + index + 'q'"
                      @click="goodsDetail(item.id, item.commodityName, '05', index)">
                   <discount-card :item="item"/>
                 </div>
               </div>
           </el-carousel-item>
         </el-carousel>
       <!--  <div class="arrow-img-left" @mouseover="mouseOver(hotObj, hotObj)" :class="['left-item-select', headerStatus == hotObj ? 'add_bg' : 'no_bg',{ 'active': headerStatus == hotObj && headerStatus != null }]">
           <img src="@/assets/rightScroll.png" alt="">
         </div>-->
         <!--  <div class="wrap-scroll">
             <div v-for="(item, idx) in hotObj.goodsList"
                  :key="item.id"
                  @click="goodsDetail(item.id, item.commodityName, '11', idx)">
                 <discount-card :item="item"/>
             </div>
           </div>-->
        <!-- <div class="arrow-img-right" @mouseover="mouseOver(hotObj, hotObj)" :class="['left-item-select', headerStatus == hotObj ? 'add_bg' : 'no_bg',{ 'active': headerStatus == hotObj && headerStatus != null }]">
           <img src="@/assets/leftScroll.png" alt="" >
         </div>-->
       </div>
   </div>
</template>

<script>
import discountCard from '@/components/discountCard'
// import moveBar from '@/components/moveBar'
import { hits } from '@/api/hits'
export default {
  components: {
    discountCard
  },
  props: {
    hotObj: {
      type: Object
    }
  },
  data () {
    return {
      headerStatus: null,
      label: null,
      rightInterval: 3000,
      centerInterval: 3000,
      rightAutoplay: false,
      centerAutoplay: false
    }
  },
  methods: {
    mouseOver (idx, item) {
      debugger
      this.headerStatus = idx
      this.twoList = item.goodsList
    },
    mouseLeave () {
      this.headerStatus = null
    },
    goodsDetail (id, commodityName, clickPosition, idx) {
      hits({
        type: '02',
        clickPostion: clickPosition,
        busId: '110' + (this.hotObj.goodsList - idx),
        busTitle: '中部产品' + (idx + 1),
        sourceSection: '01'
      })
      hits({
        type: '01',
        goodsId: id,
        goodsName: commodityName,
        sourcePosition: clickPosition,
        sourceSection: '01'
      })
      var blan = this.$router.resolve({ // 调转到商品详情页面
        path: '/detail',
        query: {
          id: id,
          commodityName: commodityName,
          url: '/home'
        }
      })
      window.open(blan.href, '_blank')
    },
    clickTotal (clickPosition, busId, busTitle) {
      hits({
        type: '02',
        clickPostion: clickPosition,
        busId: busId,
        busTitle: busTitle,
        sourceSection: '01'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.discount-floor:hover {
  cursor: pointer;
}
.discount-floor {
  background: #f90;
    width: 100%;
    display: flex;
    height: 260px;
    background-color: white;
    margin-top: 20px;
    .left {
      padding-left: 20px;
        width: 200px;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .right {
        flex: 1;
        overflow-x: auto;
        padding-left:20px;
        position: relative;
        .wrap-scroll {
            display: flex;
            height: 100%;
            width: 100%;
            overflow:hidden;
            overflow-x: auto;
        }
        .arrow-img-left {
          position: absolute;
          z-index: 999;
          top: 105px;
          left: 0;
        }
        .arrow-img-right {
          position: absolute;
          z-index: 999;
          top: 105px;
          right: 0;
        }
    }
}
</style>
<style>
  .discount-floor  .el-carousel__arrow {
    background: #5d6670;
  }
</style>
