<template>
  <div class="box display_column">
    <div class="header all_width display_flex bg_fff"><img src="../../assets/login_logo.png" alt="" /></div>
    <div class="content display_column">
      <div class="all_width">
<!--        <img class="all_width" src="../../assets/login_bg.jpg" alt="" />-->
        <div v-if="status == 0" class="login_box bg_fff">
          <div class="com_width">
            <div class="wel">
              <el-steps :active="0" align-center  finish-status="success">
                <el-step title="填写账号信息"></el-step>
                <el-step title="填写注册信息"></el-step>
                <el-step title="注册成功"></el-step>
              </el-steps>
            </div>
            <div class="display_flex">
              <div class="form_title"><span style="color: red;">*</span>手机号：</div>
              <input type="text" maxlength="11" v-model="form.phone" placeholder="手机号" />
            </div>
            <label class="display_flex space_between label_view">
              <div class="display_flex">
                <div class="form_title"><span style="color: red;">*</span>验证码：</div>
                <input type="text" maxlength="4" v-model="form.phoneCode" placeholder="验证码" />
              </div>
              <el-button type="primary" @click="sendMessage" v-if="show">获取验证码</el-button>
              <el-button type="primary" v-if="!show" @click="errorMessage(count)">{{ count }}s后重新获取</el-button>
            </label>
            <label class="display_flex">
              <div class="form_title"><span style="color: red;">*</span>用户名：</div>
              <input class="margin_top" type="text" maxlength="18" v-model="form.userCode" placeholder="用户名" />
            </label>
            <label class="display_flex">
              <div class="form_title"><span style="color: red;">*</span>设置密码：</div>
              <input class="margin_top" type="password" maxlength="16" v-model="form.password" placeholder="设置密码" />
            </label>
            <div class="margin_top display_flex cursor">
              <img @click="checked = !checked" class="checkout_img" v-if="!checked" src="../../assets/checkout.png">
              <img @click="checked = !checked" class="checkout_img" v-else src="../../assets/cheacout_a.png">
              <span>我已阅读并同意</span>
              <span @click="showToast = true" style="color: red;">《犀牛 MALL 企业用户注册协议》</span>
            </div>
            <div class="btn" @click="nextStep">下一步</div>
          </div>
        </div>
        <!-- <div v-if="status == 2" class="login_box bg_fff"> -->
        <!--   <div class="com_width_item"> -->
        <!--     <span style="color: red;font-size: 28px;">注册成功！</span> -->
        <!--   </div> -->
        <!-- </div> -->
        <div v-if="status == 1" class="login_box bg_fff">
          <div class="com_width">
            <div class="wel">
              <el-steps :active="1" align-center  finish-status="success">
                <el-step title="填写账号信息"></el-step>
                <el-step title="填写注册信息"></el-step>
                <el-step title="注册成功"></el-step>
              </el-steps>
            </div>
            <!--<label  class="display_flex">
              <div class="form_title"><span style="color: red;">*</span>注册类型：</div>
              <el-select v-model="form.registerType" placeholder="注册类型">
                <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </label>-->
            <label class="display_flex">
              <div class="form_title"><span style="color: red;">*</span>公司名称：</div>
              <input class="margin_top" style="width: 80%;" type="text" v-model="form.customerName" placeholder="公司名称" />
            </label>
            <label class="display_flex">
              <div class="form_title">影院名称：</div>
              <input class="margin_top" style="width: 80%;" type="text" v-model="form.cinemaName" placeholder="影院名称" />
            </label>
            <!--<label class="display_flex" v-if="form.registerType === '1'">
              <div class="form_title"><span style="color: red;">*</span>公司名称：</div>
              <input class="margin_top" style="width: 80%;" type="text" v-model="form.customerName" placeholder="公司名称" />
            </label>-->
            <label class="display_flex">
              <div class="form_title"><span style="color: red;">*</span>统一社会信用代码(税号)：</div>
              <input class="margin_top" style="width: 50%;" type="text" v-model="form.taxpayerNo" placeholder="统一社会信用代码" />
            </label>
            <!--<label class="display_flex" v-if="form.registerType === '0'">
              <div class="form_title">专资办编码：</div>
              <input class="margin_top" style="width: 75%;" type="text" v-model="form.specialOfficeCode" placeholder="专资办编码" />
            </label>
            <label class="display_flex" v-if="form.registerType === '0'">
              <div class="form_title">所属院线：</div>
              <input class="margin_top" type="text" v-model="form.theatreChainName" placeholder="所属院线" @click="openWindow" />
            </label>-->
            <!--<label class="margin_top display_flex" v-if="form.registerType === '0'">
              <div class="form_title">所在省市：</div>
              <div class="item-div">
                <el-cascader
                  ref="cascaderAddr"
                  size="large"
                  width="100%"
                  :options="options"
                  v-model="areasList"
                  @change="handleChange"
                >
                </el-cascader>
              </div>
            </label>-->
            <!--<label class="display_flex space_between label_view" v-if="isShowInvitationCode">
              <div class="form_title display_flex">邀请码：</div>
              <input class="margin_top" style="width: 80%;" type="text" maxlength="8" v-model="form.mobileCode" placeholder="邀请码" />
            </label>-->
            <div class="btn" @click="loginCheck" v-loading.fullscreen.lock="fullscreenLoading">注册</div>
            <!-- <div class="btn" @click="status = 0">重新填写填写账号信息</div> -->
          </div>
        </div>
        <div v-if="status == 2" class="login_box bg_fff">
          <div class="com_width">
            <div class="wel">
              <el-steps :active="2" align-center  finish-status="success">
                <el-step title="填写账号信息"></el-step>
                <el-step title="填写注册信息"></el-step>
                <el-step title="注册成功"></el-step>
              </el-steps>
            </div>
            <div>
              <span style="color: red;font-size: 22px;">注册成功！</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer"></div>
    <div class="toast" v-if="showToast" @click="showToast = false">
      <div @click.stop="showToast = true" class="all_width">
        <div class="title">犀牛Mall企业用户注册协议</div>
        <div class="info">
          <p>1. 犀牛 Mall 网（ 网址 www.xiniumall.com）、犀牛 Mall 微信小程序及犀牛 Mall APP
          （下称“网站及 APP”）的运营方，在网站及 APP 上销售影院维护、经营所需的产品
          （下称“商品”），提供软件及/或硬件设备的租赁、远程数据服务及其他后续技术支
          持服务（租赁服务、远程数据服务及其他后续技术支持服务，以下统称“支持服
          务”）；</p><br>
          <p>2. 注册用户同意通过犀牛 Mall 提供的网站及 APP 采购犀牛 Mall 所售商品及/或犀牛
          Mall 提供的支持服务。</p><br>
          <p>因此，双方本着平等、自愿、诚信的原则，经过友善协商，达成如下一致意见：</p><br>
          <p>1、服务内容</p><br>
          <p style="line-height: 1.5;margin-left: 20px;">1.1 犀牛 Mall 通过其运营的网站及 APP 提供商品及/或支持服务。 <br>
          1.2 注册用户同意通过在网站及 APP 下订单的模式采购犀牛 Mall 提供的商品及/或支持服
          务。</p><br>
          <p>2、平台服务及规范</p><br>
          <p style="line-height: 1.5;margin-left: 20px;">2.1 注册用户通过账号登录犀牛 Mall 的网站及 APP、提交采购商品及/或支持服务的订
          单、支付订单价款、申请退换货、要求售后服务等。<br>
          2.2 注册用户选择商品及/或支持服务时，应在提交订单前仔细阅读并确认所购买商品及/ 或支持服务的品名、价格、数量、型号、规格、尺寸或支持服务的时间、内容、限制
          性要求，以及商品及/或支持服务的交付方式、付款方式、退换货政策、售后服务等
          重要事项。<br>
          2.3 注册用户应在提交订单前填写真实、准确、完整的收货信息，包括但不限于收货人、
          收货地址、联系电话等。<br>
          2.4 对于注册用户通过其账号在网站及 APP 提交的订单，自注册用户完成支付之时起，
          视为针对此次订单交易的合同在双方之间成立并发生法律效力。订单及订单中的商品
          及/或支持服务页面包含的订单履行和售后服务等信息，包括但不限于：商品及/或支
          持服务的品名、价格、数量、型号、规格、尺寸或时间、内容、限制性要求，以及交
          付方式、使用周期、付款方式、退换货政策、售后服务、收货信息，与本协议一起，
          视为双方针对此次订单交易的完整合同约定。<br>
          2.5 尽管犀牛 Mall 做出最大努力，但由于市场变化及等难以控制因素的影响，若注册用
          户在网站及 APP 下订单的商品及/或支持服务出现缺货、价格标示错误等情况，犀牛
          Mall 将及时联系注册用户，取消订单并退还注册用户已支付的相应款项。<br>
          2.6 注册用户在网站及 APP 购买的商品及/或支持服务由第三方配送的，第三方系统或单
          据记录的签收时间为商品及/或支持服务交付时间。除犀牛 Mall 有重大过错外，在商
          品及/或支持服务配送过程中产生的毁损、丢失或因延迟配送等引发的损失，犀牛
          Mall 不对注册用户承担任何法律责任，但犀牛 Mall 应协助注册用户向责任方追偿。<br>
          2.7 除非犀牛 Mall 另行声明，犀牛 Mall 的网站及 APP 展示的商品、软件、程序、数
          据、网页设计及其他信息（包括文字、表格、图标、图片、音频、视频、页面设计
          等）的所有权利（包括著作权、商标权、专利权、商业秘密等其他相关权利）均归犀 牛 Mall 及/或其关联公司所有。未经犀牛 Mall 及/或其关联公司书面许可，注册用户
          不得擅自以任何形式使用上述内容和信息。<br>
          2.8 注册用户不得对犀牛 Mall 的网站及 APP 进行编译、反编译、反向工程，或以其他任
          何方式尝试发现源代码，或实施其他破坏网站安全、数据安全的行为。<br>
          2.9 犀牛 Mall 在网站及 APP 上提供具体的操作及使用规范说明。</p><br>
          <p>3、账号管理</p><br>
          <p style="line-height: 1.5;margin-left: 20px;">3.1 在签订本协议之时，若注册用户已有可在犀牛 Mall 运营的自助网站（网址
          self.cineappo.com）和易掌影院微信小程序使用的账号，则可直接使用该账号及密
          码登陆犀牛 Mall 的网站及 APP。<br>
          3.2 在签订本协议之时，若注册用户没有可在犀牛 Mall 运营的自助网站（网址
          self.cineappo.com）和易掌影院微信小程序使用的账号，则自本协议生效之日起 10
          个工作日内，犀牛 Mall 应向注册用户提供犀牛 Mall 为注册用户预设的账号与初始密
          码。<br>
          3.3 注册用户应在收到犀牛 Mall 依据本协议第 3.2 条提供的账号与初始密码后 10 个工作
          日内，登陆犀牛 Mall 的网站及 APP，重新设定密码，以激活账号。<br>
          3.4 本协议第 3.1 条和第 3.2 条所述的账号均适用于：犀牛 Mall 运营的自助网站（网址
          self.cineappo.com）、易掌影院微信小程序和犀牛 Mall 及其关联公司未来开通运营
          的其他网络平台（前述自助网站、微信小程序、其他网络平台统称“其他平台”）。
          其他平台的服务协议的内容与签署方式，以犀牛 Mall 在各其他平台对应发布的服务
          协议和相关规则为准。<br>
          3.5 注册用户账号仅限注册用户使用。未经犀牛 Mall 同意，注册用户不得直接或间接授
          权任何第三方以任何形式使用其账号。<br>
          3.6 注册用户应设专人妥善保管、使用、维护其账号及密码，并做好账号及密码的保密和
          交接工作。注册用户确认，由该账号在犀牛 Mall 的网站及 APP 提交的订单、与犀牛
          Mall 客服人员的沟通信息，均视为注册用户的真实意思表示，因此产生的法律后果均
          由注册用户承担。<br>
          3.7 若注册用户账号发生密码泄露或被第三方盗取、注册用户工作人员违规使用等情形
          的，注册用户应在其知晓该情形后立即书面通知犀牛 Mall，犀牛 Mall 将协助注册用
          户对其账号进行冻结或修改密码等处理。<br>
          3.8 因注册用户管理账号和密码不善导致的损失或其他后果，犀牛 Mall 不承担任何责
          任。<br>
          3.9 若注册用户自收到犀牛 Mall 提供的账号后 2 个月内未激活其账号，或连续 12 个月未
          使用其账号的，犀牛 Mall 出于安全考虑可回收注册用户账号。若注册用户后续需继
          续使用该账号，可联系犀牛 Mall 协商处理。</p><br>
          <p>4、隐私政策及保密条款</p><br>
          <p style="line-height: 1.5;margin-left: 20px;">4.1 注册用户同意，犀牛 Mall 有权收集和使用注册用户在使用网站及 APP 的过程中提供
          和产生的数据信息。<br>
          4.2 犀牛 Mall 在履行本协议过程中提供给注册用户的技术资料、信息、计算机软件等，
          注册用户应采取措施予以保密，注册用户不得允许任何除注册用户工作人员之外的其
          他第三方使用或向任何第三方转让、交换、公开或泄漏犀牛 Mall 提供的技术资料、
          信息、计算机软件等。如注册用户（包括注册用户工作人员）违反上述规定致使犀牛
          Mall 遭受损失的，注册用户应负法律责任，并赔偿犀牛 Mall 由此遭受的损失。<br>
          4.3 不论本协议是否终止，本协议第 4 条持续有效，不因本协议的终止（无论以何种原
          因）而终止。</p><br>
          <p>5、违约条款</p><br>
          <p style="line-height: 1.5;margin-left: 20px;">5.1 双方有权依据合理判断对违反有关法律法规或本协议规定的行为采取适当的法律行
          动，并依据法律法规保存有关信息向有关部门报告等。<br>
          5.2 若本协议一方因违反本协议或具体订单的约定，导致或产生第三方主张的任何索赔、
          要求或损失，违约方应当独立承担责任；另一方因此遭受损失的，也应由违约方一并
          赔偿。</p><br>
          <p>6、争议解决</p><br>
          <p style="line-height: 1.5;margin-left: 20px;">6.1 本协议适用中华人民共和国大陆地区法律。<br>
          6.2 因本协议产生的或与之相关的任何争议，双方均应首先通过友好协商方式予以解决。
          如果在一方向其他方发出要求协商解决的书面通知后 30 日之内争议仍然得不到解决
          的，则任何一方均可将有关争议提交中国国际经济贸易仲裁委员会（“CIETAC”）
          根据申请时有效的仲裁规则，在北京进行仲裁。仲裁应由三名仲裁员进行。其中，一
          名仲裁员应由申请方指定，另外一名仲裁员由被申请方指定，首席仲裁员由 CIETAC
          根据其时适用的仲裁规则进行指定。仲裁裁决为终局的，对双方均有约束力，任一方
          均可向有管辖权的法院申请执行该等裁决。<br>
          6.3 在相关争议的仲裁期间，除争议事项外，双方仍应当善意继续履行其在本协议项下的
          其他各项义务。除仲裁裁决另有规定外，因仲裁所产生的成本和费用由败诉方承担。</p><br>
          <p>7、其他</p><br>
          <p style="line-height: 1.5;margin-left: 20px;">7.1 本协议有效期为壹年。若双方在协议到期 30 日前均未书面提出异议，则本协议有效
          期自动顺延，直至双方一致决定终止本协议为止。<br>
          7.2 注册用户同意，对于本协议的任何变更、补充或修改，犀牛 Mall 可以通过在犀牛
          Mall 的网站及 APP 设置弹窗提醒的方式将补充协议通知注册用户，注册用户点击同
          意补充协议的，视为双方已签署该补充协议。补充协议与本协议具有同等法律效力。<br>
          7.3 本协议存续期间，如发生政治、经济、金融、法律、卫生、通讯故障或其它方面政策
          的重大变故，或发生无法预见、无法预防、无法避免和无法控制的事件，或犀牛 Mall
          尽善意管理的情况下因常规或紧急的设备与系统维护、设备与系统故障、网络信息与
          数据安全等因素，以致不能履行或不能如期履行订单的情形，双方可进行协商。协商
          不成的，双方可终止本协议，任何一方均不承担违约责任。<br>
          7.4 本协议自注册用户点击【我已阅读并同意《犀牛 MALL 企业用户注册协议》】之日起
          生效。</p>
          <button class="toast_but" style="margin-top: 20px;margin-left: 45%;width: 60px;height: 30px;background-color: #ef922e;color: #eee;border: 0;border-radius: 5px;font-size: 17px;" @click.stop="cancelToast">关闭</button>
          <button class="toast_but" style="margin-top: 20px;margin-left: 20px;width: 60px;height: 30px;background-color: #ef922e;color: #eee;border: 0;border-radius: 5px;font-size: 17px" @click.stop="confirmToast">同意</button>
        </div>
      </div>
    </div>
    <!-- 弹出层table -->
    <el-dialog title="院线名称列表" width="30%" :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="dialogTableVisible">
      <el-table :data="customerInfoList">
        <el-table-column property="label" label="院线名称" width="200"></el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="200">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
       <el-pagination
         background
         @size-change="handleSizeChange"
         @current-change="handleCurrentChange"
         layout="prev, pager, next"
         :total="totalNum"
         :page-size="pageSizes"
         :current-page.sync="currentPage1">
       </el-pagination>
    </el-dialog>
    <div style="height: 70px;line-height: 25px;">
      <a style="text-decoration:none;color: #666;" href=" https://uc.cineappo.com/image/aboutWe.jpg">关于我们</a>
      <span style="color: #666;">&nbsp;&nbsp;丨&nbsp;&nbsp;</span>
      <a style="text-decoration:none;color: #666;" href="https://uc.cineappo.com/image/linkWe.jpg">联系我们</a>
      <span style="color: #666;">&nbsp;&nbsp;丨&nbsp;&nbsp;</span>
      <span class="span_yqlj" @click="centerDialogVisible = true">友情链接</span>

      <p style="text-align: center;color: #666;">Copyright©2021</p>
      <span style="color: #666;">
        版权所有：中影光峰激光影院技术（北京）有限公司 <a href="https://beian.miit.gov.cn" class="link" target="_blank">京ICP备15058245号-3</a>
      </span>
    </div>
    <el-dialog
      title="友情链接"
      :visible.sync="centerDialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      style="text-align: center;line-height: 20px;">
      <a style="text-decoration:none;color: #114cc6;" target="_blank" href="http://www.cineappo.com/">官方网站：中影光峰</a><br>
      <a style="text-decoration:none;color: #114CC6;" target="_blank" href="#">微信服务号：中影光峰</a><br>
      <a style="text-decoration:none;color: #114CC6;" target="_blank" href="http://v.qq.com/s/videoplus/694329818">腾讯视频号：中影光峰</a><br>
      <a style="text-decoration:none;color: #114CC6;" target="_blank" href="https://weibo.com/6502234675/profile?rightmod=1&wvr=6&mod=personnumber">微博：@中影光峰_CINEAPPO</a><br>
      <a style="text-decoration:none;color: #114CC6;" target="_blank" href="#">抖音：中影光峰</a><br>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { accountIsExist, tycCinemaInfo, checkSendMessage, sendMessage, getVoId, findCustomerBatchList, registerAccount, customerIsExist, sendInvitationCode, checkInvitationCode, sendLoginMessage } from '@/api/companyRegister/companyRegister'
import { regionData } from 'element-china-area-data'

export default {
  data () {
    return {
      // 分页组件参数
      pam: {
        pageNo: 1,
        pageSize: 10
      },
      pageInfo: {
        total: 0,
        pageSize: 10,
        nowPage: 1
      },
      start: 0,
      totalNum: 0,
      pageSizes: 10,
      currentPage1: 1,
      // 校验统一信用代码次数标志
      checkFlag: 0,
      // 友情链接弹出
      centerDialogVisible: false,
      // 页面加载
      fullscreenLoading: false,
      // 客户列表弹出层
      gridData: [],
      // 弹出层控制
      dialogTableVisible: false,
      // 短信计数器
      show: true,
      count: '',
      timer: null,
      // 省市区
      options: regionData,
      // 注册类型
      typeList: [
        {
          value: '0',
          label: '影院'
        },
        {
          value: '1',
          label: '一般公司'
        }
      ],
      customerInfoList: [
        // {
        //   value: '0',
        //   label: '犀牛影院'
        // },
        // {
        //   value: '1',
        //   label: '时代广场'
        // }
      ],
      showToast: false,
      checked: false,
      status: 0,
      form: {
        // 租户ID
        tenantId: '000000',
        // 用户名
        userCode: '',
        // 手机号
        phone: '',
        // 验证码
        phoneCode: '',
        // 邀请码
        mobileCode: '',
        // 密码
        password: '',
        // 账户类型
        type: 'password',
        // 验证码的值
        code: '',
        // 公司名称 111
        customerName: '',
        // 影院名称
        cinemaName: '',
        // 统一社会信用代码
        taxpayerNo: '',
        // 专资办编码
        specialOfficeCode: '',
        // 所属院线
        theatreChainId: '',
        // 所属院线名
        theatreChainName: '',
        // 数据来源系统名称
        systemSource: 'MALL',
        // 注册类型
        registerType: '0',
        // 验证码的索引
        key: '',
        // 预加载白色背景
        image: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
      },
      areasList: [
        // {
        //   value: '0',
        //   label: '北京'
        // },
        // {
        //   value: '1',
        //   label: '上海'
        // }
      ],
      areasStr: '',
      // 省市
      areas: '',
      // 省
      province: '',
      // 市
      city: '',
      // 是否展示邀请码
      isShowInvitationCode: false,
      // 是否检验邀请码
      isCheckInvitationCode: false,
      // 注册过的名
      isCheckInvitation: '',
      companyName: '',
      firstContactTelphone: '',
      // customerInfo: {
      //   customerName: this.form.customerName,
      //   systemSource: 'MALL',
      //   taxpayerNo: this.form.taxpayerNo,
      //   specialOfficeCode: this.form.specialOfficeCode, // 非必传
      //   theatreChainId: this.form.theatreChainId, // 非必传
      //   province: this.areas, // 非必传
      //   proviceName: this.province, // 非必传
      //   city: this.city // 非必传
      // },
      // 短信唯一id
      voId: ''
    }
  },
  mounted () {
    this.onLoad()
  },
  methods: {
    onLoad () {
      this.getVoId()
      this.findCustomerBatchList()
    },
    // 呼出弹窗接口
    openWindow () {
      this.dialogTableVisible = true
      // 点击如果列表为空重新调接口刷新数据
      if (!this.customerInfoList) {
        this.findCustomerBatchList()
      }
    },
    // 取消弹窗
    cancelToast () {
      this.showToast = false
    },
    // 同意协议
    confirmToast () {
      this.checked = true
      this.showToast = false
    },
    // 表格点击事件
    handleClick (row) {
      // console.log(row, 'handleClick')
      // 关闭弹出层
      this.dialogTableVisible = false
      // 赋值
      this.form.theatreChainId = row.key
      this.form.theatreChainName = row.label
    },
    // 弹出层分页组件方法1 TODO 未确认页码和页面大小是哪个方法
    handleSizeChange (page) {
      this.pam.pageNo = page
      this.pam.pageSize = page
      // 获取记录
      // this.$parent.onLoad(this.pam)
      if (this.start !== 0 && this.start !== 1) {
        this.start = this.pageInfo.pageSize * this.pageInfo.nowPage - this.pageInfo.pageSize
      }
      var obj = {
        start: this.start,
        limit: this.pageInfo.pageSize,
        isTheatreChain: '1'
      }
      this.findCustomerBatchList(obj)
    },
    // 弹出层分页组件方法2
    handleCurrentChange (page) {
      // this.pam.pageNo = page
      this.pageInfo.nowPage = page
      this.start = page
    },
    // 省市区联动
    handleChange (e) {
      // debugger
      // 省编码
      this.areas = e[0]
      var list = this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels
      this.areasList = e
      // 省市名称
      this.province = list[0]
      if (list[0].indexOf('市')) {
        this.city = list[0]
      } else {
        // 市名称
        this.city = list[1]
      }
      // var areaName = this.areasList
      // // this.areasList = ''
      // this.areasStr = ''
      // for (let i = 0; i < areaName.length; i++) {
      //   if (i !== areaName.length - 1) {
      //     this.areasStr += areaName[i] + ''
      //   } else {
      //     this.areasStr += areaName[i]
      //   }
      // }
    },
    // 60s倒计时点击提醒
    errorMessage (count) {
      if (this.count != null) {
        this.$message({
          message: '请读秒结束后再重新获取！',
          type: 'warning'
        })
      }
    },
    // 批量获取客户信息
    findCustomerBatchList () {
      const par = {}
      par.start = 0
      par.limit = 999999
      findCustomerBatchList(par).then(res => {
        // debugger
        // 总数
        this.totalNum = res.data.data.total
        this.customerInfoList = res.data.data.result.map(item => {
          return {
            label: item.customerName,
            value: item.id,
            key: item.customerInfo.theatreChainId
          }
        })
        // console.log(res, 'findCustomerBatchList')
      }).catch(e => {
        console.log(e)
      })
    },
    // 发送短信
    sendMessage () {
      // 判断手机号是否填写
      if (this.form.phone != null && this.form.phone !== '') {
        // 先校验手机号是否已注册
        // const param = {}
        // param.userCode = this.form.phone
        accountIsExist(this.form.phone).then(res => {
          // console.log(res, 'accountIsExist')
          if (res.data) {
            if (res.data.data && res.data.data.code === '-1') {
              this.$message({
                message: '手机号：' + this.form.phone + '已被注册，请重新填写！',
                type: 'warning'
              })
              return false
            }
            const par = {}
            par.phone = this.form.phone
            par.voId = this.voId
            sendMessage(par).then(res => {
              // 短信计时
              var TIME_COUNT = 60
              if (!this.timer) {
                this.count = TIME_COUNT
                this.show = false
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--
                  } else {
                    this.show = true
                    clearInterval(this.timer)
                    this.timer = null
                  }
                }, 1000)
              }
              // console.log(res, 'sendMessage')
            }).catch(e => {
              // console.log(e.message)
              if (e.message === '验证码发送错误') {
                this.$message({
                  message: '您已超出上限，每个手机每天最多发送10条短信！',
                  type: 'warning'
                })
              }
              return false
            })
            return false
          }
          // console.log(res, 'nishuone')
        }).catch(e => {
          // console.log(e)
        })
      } else {
        this.$message({
          message: '请先填写手机号！',
          type: 'warning'
        })
      }
    },
    // 获取短信唯一id
    getVoId () {
      getVoId().then(res => {
        // console.log(res.data.data, 'getVoId')
        this.voId = res.data.data
      }).catch(e => {
        // console.log(e)
      })
    },
    // 下一步
    nextStep () {
      // 放开校验直接下一步
      /* var a = 1
      if (a === 1) {
        this.status = 1
        return false
      } */
      // 如果勾选协议
      if (this.checked) {
        // 判断数据是否填写完整
        if (this.form.phone && this.form.phoneCode && this.form.userCode && this.form.password) {
          // 验证账号是否存在
          accountIsExist(this.form.userCode).then(res => {
            // console.log(res.data.code, '你说呢')
            // 0 账号不存在，可以注册
            if (res.data.code === 0) {
              // 校验验证码
              const par = {}
              par.voId = this.voId
              par.phone = this.form.phone
              par.code = this.form.phoneCode
              checkSendMessage(par).then(res => {
                // console.log(res, 'checkSendMessage')
                // 验证通过
                if (res.data.data === true && this.checked === true) {
                  // 跳转页面
                  this.status = 1
                } else {
                  this.$message({
                    message: '验证码有误，请重新输入!',
                    type: 'error'
                  })
                  return false
                }
              }).catch(e => {
                // console.log(e)
              })
              // this.status = 1
            } else if (res.data.code === -1) {
              this.$message({
                message: '该账号已存在,请换个账号重试！',
                type: 'warning'
              })
              return false
            }
          }).catch(e => {
            // console.log(e)
          })
          // 验证验证码
          // 切换页面
          //
        } else {
          this.$message({
            message: '请把数据填写完整！',
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: '请点击勾选注册协议！',
          type: 'warning'
        })
      }
    },
    //
    change (e) {
      console.log(e)
    },
    // 登录校验
    loginCheck () {
      this.fullscreenLoading = true
      // 先校验统一信用代码
      if (this.form.taxpayerNo && this.form.customerName) {
        const parInfo = {}
        parInfo.customerName = this.form.customerName
        parInfo.taxpayerNo = this.form.taxpayerNo
        tycCinemaInfo(parInfo).then(res => {
          // console.log(res, 'tycCinemaInfo')
          debugger
          if (res.data.data != null && res.data.data.code === 0) {
            this.newLogin()
          } else {
            this.checkFlag += 1
            this.fullscreenLoading = false
            // 验证超过3次
            if (this.checkFlag >= 3) {
              this.$message({
                message: '3次审核未通过,自动放弃注册！',
                type: 'warning'
              })
              this.fullscreenLoading = false
              this.$router.push('/home')
              return
            }
            // 返回注册页
            this.$confirm('非常抱歉!您填写的企业信息未通过系统审核, 您可点击"继续注册"重新填写信息(3次审核未通过自动放弃)', '提示', {
              confirmButtonText: '继续注册',
              cancelButtonText: '放弃',
              type: 'warning',
              closeOnClickModal: false,
              closeOnPressEscape: false
            }).then(() => {
              this.fullscreenLoading = false
              return false
            }).catch(() => {
              this.fullscreenLoading = false
              this.$router.push('/home')
            })
          }
        }).catch(e => {
          this.fullscreenLoading = false
          this.$message({
            message: '信息有误，请重新输入！',
            type: 'error',
            duration: 2000
          })
        })
      } else {
        this.fullscreenLoading = false
        this.$message({
          message: '请把信息填写完整！',
          type: 'warning',
          duration: 2000
        })
      }
    },
    newLogin () {
      // 开启加载
      this.fullscreenLoading = true
      if (this.form.cinemaName) {
        this.form.registerType = '0'
      } else {
        this.form.registerType = '1'
      }
      // 校验必填项
      if (this.form.customerName && this.form.taxpayerNo) {
        // 校验客户是否存在
        const param = {}
        param.customerName = this.form.customerName
        customerIsExist(param).then(res => {
          let results = {}
          const par = {
            userCode: this.form.userCode,
            password: this.form.password,
            phone: this.form.phone,
            properties: '1',
            systemSource: 'MALL',
            registerType: this.form.registerType, // 非必传
            customerInfo: {
              customerName: this.form.customerName,
              cinemaName: this.form.cinemaName, // 非必传
              systemSource: 'MALL',
              taxpayerNo: this.form.taxpayerNo,
              specialOfficeCode: this.form.specialOfficeCode, // 非必传
              theatreChainId: this.form.theatreChainId, // 非必传
              province: this.areas, // 非必传
              proviceName: this.province, // 非必传
              city: this.city // 非必传
            }
          }
          if (res.data.data.code === '0') {
            // 可以注册
            this.submitLogin(par)
          } else if (res.data.data.code === '-1') { // 表示客户已存在 需要发送短信
            if (res.data.data.result != null) {
              results = JSON.parse(res.data.data.result)
              this.companyName = results.customerName
              this.firstContactTelphone = results.firstContactTelphone
              console.log(results, 'results')
            }
            this.$confirm('您注册的公司（影院）已存在，您可以通过激光充值账号直接登录,如需新增犀牛商城账号,请点击注册', '提示', {
              confirmButtonText: '注册',
              cancelButtonText: '取消',
              type: 'warning',
              closeOnClickModal: false,
              closeOnPressEscape: false
            }).then(() => {
              // 发送短信
              if (this.firstContactTelphone) {
                console.log('发送短信:', this.firstContactTelphone)
                this.SendSms()
              }
              // 进行注册
              this.submitLogin(par)
            }).catch(() => {
              this.fullscreenLoading = false
              this.$router.push('/home')
              return false
            })
          }
        })
      } else {
        this.$message({
          message: '请把数据填写完整！',
          type: 'warning'
        })
        this.fullscreenLoading = false
      }
    },
    // 提交注册
    submitLogin (pam) {
      registerAccount(pam).then(res => {
        this.fullscreenLoading = false
        // console.log(res, 'registerAccount')
        if (res.data.data.code === '0') {
          this.$confirm('注册成功', '提示', {
            confirmButtonText: '去登录',
            showCancelButton: false,
            type: 'warning',
            closeOnClickModal: false,
            closeOnPressEscape: false
          }).then(() => {
            // this.status = 2
            this.$router.push('/home')
            return false
          }).catch(() => {
            this.fullscreenLoading = false
            this.$router.push('/home')
          })
        } else if (res.data.data.code === '-1') {
          this.$message({
            message: '注册失败!',
            type: 'error',
            duration: 2000
          })
          return false
        }
        return false
      }).catch(e => {
        this.fullscreenLoading = false
        this.$message({
          message: '注册失败!',
          type: 'error',
          duration: 2000
        })
        return false
        // console.log(e)
      })
    },
    // 发送短信
    SendSms () {
      sendLoginMessage({ phone: this.firstContactTelphone, sendPhone: this.form.phone, compname: this.form.customerName }).then(res => {
        console.log('短信发送结果', res.data.data)
      }).catch(e => {
        console.log('短信发送失败', e)
      })
    },
    // 注册
    login () { // 111
      // 开启加载
      this.fullscreenLoading = true
      // 校验邀请码
      if (this.isCheckInvitation === this.form.customerName && !this.isCheckInvitationCode) {
        // 校验邀请码
        if (this.form.mobileCode) {
          let start = true
          const params = {}
          params.invitationCode = this.form.mobileCode
          params.phone = this.firstContactTelphone
          params.voId = this.voId
          checkInvitationCode(params).then(res => {
            if (res.data.data) {
              this.isCheckInvitationCode = true
              if (this.isCheckInvitation === this.form.customerName && this.isCheckInvitationCode) {
                // 经过校验邀请码 直接提交 无需校验
                const part = {
                  userCode: this.form.userCode,
                  password: this.form.password,
                  phone: this.form.phone,
                  properties: '1',
                  systemSource: 'MALL',
                  code: this.form.mobileCode,
                  registerType: this.form.registerType === '影院' ? '0' : '1', // 非必传
                  customerInfo: {
                    customerName: this.form.customerName,
                    cinemaName: this.form.cinemaName,
                    systemSource: 'MALL',
                    taxpayerNo: this.form.taxpayerNo,
                    specialOfficeCode: this.form.specialOfficeCode, // 非必传
                    theatreChainId: this.form.theatreChainId, // 非必传
                    province: this.areas, // 非必传
                    proviceName: this.province, // 非必传
                    city: this.city // 非必传
                  }
                }
                // 是否已经过校验邀请码
                registerAccount(part).then(res => {
                  debugger
                  // 关闭加载
                  this.fullscreenLoading = false
                  // console.log(res, 'registerAccount')
                  if (res.data.data.code === '0') {
                    this.$confirm('注册成功', '提示', {
                      confirmButtonText: '去登录',
                      showCancelButton: false,
                      type: 'warning',
                      closeOnClickModal: false,
                      closeOnPressEscape: false
                    }).then(() => {
                      // this.status = 2
                      this.$router.push('/home')
                      return false
                    }).catch(() => {
                      this.fullscreenLoading = false
                      this.$router.push('/home')
                    })
                  } else if (res.data.data.code === '-1') {
                    this.$message({
                      message: res.data.data.message,
                      type: 'error',
                      duration: 2000
                    })
                    return false
                  }
                  return false
                }).catch(e => {
                  this.fullscreenLoading = false
                  this.$message({
                    message: '注册失败!',
                    type: 'error',
                    duration: 2000
                  })
                  return false
                  // console.log(e)
                })
                return false
              }
            } else {
              start = false
              this.$message({
                message: '邀请码有误或已失效，请重新填写！',
                type: 'error',
                duration: 2000
              })
              this.fullscreenLoading = false
              return false
            }
          })
          if (!start) {
            return false
          }
        } else {
          this.$message({
            message: '请填写邀请码！',
            type: 'error',
            duration: 2000
          })
          this.fullscreenLoading = false
          return false
        }
      } else {
        // 校验表数据
        if (this.form.registerType && this.form.customerName && this.form.taxpayerNo) {
          // 校验客户是否存在
          const param = {}
          param.customerName = this.form.customerName
          customerIsExist(param).then(res => {
            // debugger
            // console.log(res, 'customerIsExist')
            let results = {}
            if (res.data.data.code === '0') {
              // 可以注册
              const par = {
                userCode: this.form.userCode,
                password: this.form.password,
                phone: this.form.phone,
                properties: '1',
                systemSource: 'MALL',
                registerType: this.form.registerType === '影院' ? '0' : '1', // 非必传
                customerInfo: {
                  customerName: this.form.customerName,
                  cinemaName: this.form.cinemaName, // 非必传
                  systemSource: 'MALL',
                  taxpayerNo: this.form.taxpayerNo,
                  specialOfficeCode: this.form.specialOfficeCode, // 非必传
                  theatreChainId: this.form.theatreChainId, // 非必传
                  province: this.areas, // 非必传
                  proviceName: this.province, // 非必传
                  city: this.city // 非必传
                }
              }
              // 是否已经过校验邀请码
              registerAccount(par).then(res => {
                this.fullscreenLoading = false
                // console.log(res, 'registerAccount')
                if (res.data.data.code === '0') {
                  this.$confirm('注册成功', '提示', {
                    confirmButtonText: '去登录',
                    showCancelButton: false,
                    type: 'warning',
                    closeOnClickModal: false,
                    closeOnPressEscape: false
                  }).then(() => {
                    // this.status = 2
                    this.$router.push('/home')
                    return false
                  }).catch(() => {
                    this.fullscreenLoading = false
                    this.$router.push('/home')
                  })
                } else if (res.data.data.code === '-1') {
                  this.$message({
                    message: '注册失败!',
                    type: 'error',
                    duration: 2000
                  })
                  return false
                }
                return false
              }).catch(e => {
                this.fullscreenLoading = false
                this.$message({
                  message: '注册失败!',
                  type: 'error',
                  duration: 2000
                })
                return false
                // console.log(e)
              })
            } else if (res.data.data.code === '-1') { // 表示客户已存在 需要发送邀请码
              // debugger
              if (res.data.data.result != null) {
                results = JSON.parse(res.data.data.result)
                this.companyName = results.customerName
                this.firstContactTelphone = results.firstContactTelphone
                console.log(results, 'results')
              }
              this.$confirm('您注册的公司（影院）已存在，需要邀请码才能注册，请您点击【获取邀请码】', '提示', {
                confirmButtonText: '获取邀请码',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal: false,
                closeOnPressEscape: false
              }).then(() => {
                this.fullscreenLoading = false
                // 如果联系人存在
                if (this.firstContactTelphone != null && this.companyName != null) {
                  // 获取邀请码
                  const pars = {}
                  // if (this.firstContactTelphone !== '' && this.firstContactTelphone != null) {
                  //   pars.mobilecode = this.firstContactTelphone
                  // } else {
                  //   this.$message({
                  //     message: '联系人手机号不存在！',
                  //     type: 'error',
                  //     duration: 2000
                  //   })
                  // }
                  pars.mobilecode = this.form.phone // 注册手机号
                  pars.phone = this.firstContactTelphone // 第一联系人
                  pars.voId = this.voId
                  pars.compname = this.companyName
                  sendInvitationCode(pars).then(res => {
                    // debugger
                    // console.log(res, 'sendInvitationCode')
                    this.fullscreenLoading = false
                    const tel = results.firstContactTelphone
                    if (res.data.data === '发送成功') {
                      // this.$message({
                      //   message: '邀请码已经发送至手机号为' + tel + '的公司联系人，本邀请码30分钟有效，请尽快联系获取。',
                      //   type: 'success',
                      //   duration: 2000
                      // })
                      this.$confirm('邀请码已经发送至手机号为' + tel + '的公司联系人，本邀请码30分钟有效，请尽快联系获取。', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'success',
                        closeOnClickModal: false,
                        closeOnPressEscape: false
                      }).then(() => {
                        this.fullscreenLoading = false
                        return false
                      }).catch(() => {
                        this.fullscreenLoading = false
                        return false
                      })
                      this.isCheckInvitation = this.companyName
                      this.isShowInvitationCode = true
                    } else {
                      this.$message({
                        message: res.data.data,
                        type: 'error'
                      })
                    }
                    return false
                  }).catch(e => {
                    this.fullscreenLoading = false
                    // console.log(e)
                  })
                } else {
                  this.$confirm('由于无法联系上' + this.companyName + '的联系人，无法获取注册邀请码，如需注册请联系客服400-870-9700-3', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                    closeOnPressEscape: false
                  }).then(() => {
                    this.fullscreenLoading = false
                    return false
                  }).catch(() => {
                    this.fullscreenLoading = false
                    return false
                  })
                }
              }).catch(() => {
                this.fullscreenLoading = false
                return false
              })
            } else if (res.data.data.code === '500') { // 提示错误信息
              this.$message({
                message: res.data.data.message,
                type: 'error'
              })
            }
          }).catch(e => {
            this.fullscreenLoading = false
            // console.log(e)
          })
        } else {
          this.$message({
            message: '请把数据填写完整！',
            type: 'warning'
          })
          this.fullscreenLoading = false
        }
      }
      // this.$store.dispatch('Login', this.form).then(res => {
      //   this.$router.push('/home')
      // })
    },
    goPerson () {
      this.$router.push('/personRegister')
    }
  }
}
</script>
<style lang="scss" scoped>
.span_yqlj:hover {
  cursor: pointer;
}
.span_yqlj {
  color: #666;
}
.el-cascader {
  width: 100%;
}
.toast_but:hover {
  cursor: pointer;
  background-color: white;
  color: #ef922e;
}
.form_title{
  min-width:74px;
  text-align: left;
}
.com_width_item {
  width: 92%;
  margin-left: 4%;
  height: 400px;
}
.item-div {
  //width: 342px;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 10px;
}
  .box {
    .toast {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .5);
      z-index: 999;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      .all_width {
        background: #fff;
        margin: 80px 0;
        border-radius: 8px;
        overflow: auto;
        height: 80%;
        .title {
          padding: 30px 0;
          font-size: 26px;
        }
        .info {
          width: 100%;
          text-align: left;
          padding: 0 30px;
          font-size: 20px;
          p{
            line-height: 21px;
          }
        }
      }
    }

    .margin_top {
      margin-top: 10px;
    }

    .header {
      height: 100px;
      justify-content: flex-start;

      img {
        width: 188px;
        height: 43px;
      }
    }

    .content {
      width: 100%;
      //background: #f83c46;

      .checkout_img {
        width: 20px !important;
        height: 20px !important;
        margin-right: 5px;
        margin-bottom: 2px;
      }

      .all_width {
        position: relative;

        img {
           height: 558px;
        }

        .login_box {
          border-radius: 6px;
          width: 405px;
          background: #ffffff;
          //position: absolute;
          z-index: 999;
          top: 49px;
          box-sizing: border-box;
          right: 0;
          margin-left: 35%;
          padding: 40px 20px;
          .label_view {
            input {
              width: 60%;
              margin-top: 10px !important;
            }
            .el-button {
              height: 46px;
              margin-top: 10px;
            }
          }

          .wel {
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            text-align: left;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
            margin-bottom: 30px;
          }

          input {
            box-sizing: border-box;
            width: 100%;
            display: block;
            height: 48px;
            background: #ffffff;
            border: 1px solid #e5e5e5;
            border-radius: 4px;
            padding-left: 10px;
          }

          .el-select {
            width: 100%;
          }

          .btn {
            width: 100%;
            height: 48px;
            line-height: 48px;
            text-align: center;
            background: #ef922e;
            border-radius: 4px;
            color: #fff;
            margin-top: 20px;
            cursor: pointer;
          }

          .bot_div {
            margin-top: 30px;
            color: #333;
            font-size: 15px;

            span {
              width: 14px;
              height: 14px;
              border: 1px solid #ef922e;
              border-radius: 3px;
              // flex:1;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
.link {
  color: #7C7C7C;
  text-decoration: none;
}
.link:hover {
}
</style>
