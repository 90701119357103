<template>
  <div class="couponItem">
      <div class="coupon-img" :class="{'disabled': disabled}" style="position: relative">
        <img v-if="toExpire && !disabled" :src="require('../../../../assets/quan.png')" class="toExpire">
        <span class="coupon-img-title">￥{{bean.faceValue}}</span>
        <span style="z-index: 2" v-if="bean.overNYuanUse!==0 && allowance === false">满{{bean.overNYuanUse}}元可用</span>
        <span style="z-index: 2" v-if="bean.overNYuanUse===0">无门槛</span>
      </div>
      <div class="coupon-tips mg-top-20">
        <p style="cursor: pointer" v-if="bean.typesOfGoodsAvailable !== {} && bean.typesOfGoodsAvailable" :title="bean.typesOfGoodsAvailable">限品类：{{bean.typesOfGoodsAvailable.length>12?(bean.typesOfGoodsAvailable.substring(0,12)+'  ...'):bean.typesOfGoodsAvailable}}</p>
        <p style="cursor: pointer" v-if="bean.availableProductRange !== {} && bean.availableProductRange" :title="bean.availableProductRange">限商品：{{bean.availableProductRange.length>12?(bean.availableProductRange.substring(0,12)+'  ...'):bean.availableProductRange}}</p>
        <span v-if="(bean.typesOfGoodsAvailable !== {} && !bean.typesOfGoodsAvailable) && (bean.availableProductRange !== {} && !bean.availableProductRange)">全场通用</span>
      </div>
      <div class="coupon-tips mg-top-10">
          <span>有效期至：{{bean.specifyValidityPeriod}}</span>
      </div>
      <div class="btn" :class="{'disabled': disabled}">
          <span style="cursor: pointer" v-if="!disabled" @click="goSearch(bean.typesOfGoodsAvailableId, bean.availableProductRangeId)">立即使用</span>
          <span v-else>已过期</span>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    bean: {
      type: Object,
      default: function () {
        return {}
      }
    },
    allowance: Boolean
  },
  computed: {
    toExpire: function () {
      const isShow = this.bean.specifyValidityPeriod.replace('-', '/')
      return (new Date(isShow).getTime() - new Date().getTime()) / (24 * 3600 * 1000) < 3
    }
  },
  methods: {
    goSearch (v1, v2) {
      if (v1) {
        this.$router.push({
          path: '/search',
          query: {
            commodityClass: JSON.parse(v1).join(','),
            flag: '01'
          }
        })
      } else if (v2) {
        debugger
        this.$store.commit('SET_SEARCH_KEYWORD', '')
        // this.$store.commit('SET_SEARCH_KEYWORD', v2.replace(/"/g, '').replace(/,/g, ''))
        this.$store.commit('SET_SEARCH_GOODS_ID', JSON.parse(v2))
        this.$router.push({ path: 'search', query: { isTokenizer: '01' } })
      } else {
        // this.$store.commit('SET_SEARCH_KEYWORD', '酷薯')
        this.$store.commit('SET_SEARCH_GOODS_ID', '')
        // this.$router.push({ path: 'search' })
        this.$router.push({
          // 调转到商品搜索页面
          path: '/search',
          query: {
            commodityClass: 1005,
            extName: '影院卖品',
            flag: '01'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.couponItem {
  margin:5px;
  float:left;
  width: 205px;
  height: 270px;
  border: 1px solid #e1e1e1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .toExpire {
    position: absolute;
    left: -11px;
    top: -15px;
    width: 51px;
    height: 51px;
    z-index: 1;
    background: linear-gradient(0deg, rgb(215, 215, 215), rgb(255, 255, 255));
  }
  .coupon-tips {
      color: #999999;
      font-size: 12px;
  }
  .btn {
      width: 130px;
        height: 36px;
        border: 1px solid #ef922e;
        border-radius: 2px;
        color: #ef922e;
        text-align: center;
        line-height: 36px;
        margin-top: 28px;
        &.disabled {
            border: none;
            background-color:#F5F5F5;
            color: #999999;
        }
  }
}
.coupon-img {
  height: 69px;
  width: 137px;
  background: url("../../../../assets/coupon.png");
  &.disabled {
    background: url("../../../../assets/coupon2.png");
    background-size: 137px 69px;
  }
  /*&.toExpire {*/
  /*  background: url("");*/
  /*  background-size: 137px 69px;*/
  /*}*/
  background-size: 137px 69px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  color: #f5f5f5;
  .coupon-img-title {
    font-size: 16px;
    z-index: 2;
  }
}
</style>
