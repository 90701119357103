<template>
  <div class="detail-dis">
    <div class="content">
      <div v-if="goosRecommendedation.length > 0" class="title tab_title display_flex">
        <p class="add_bg">相关推荐</p>
      </div>
      <div v-if="goosRecommendedation.length > 0" class="reocommend display_flex">
        <div v-for="item in goosRecommendedation.slice(0, 8)" :key="item.id" @click="goodsDetail(item.id, item.commodityName)">
          <smallCard v-if="item.id!=goodsDetId" :item="item">
          </smallCard>
        </div>
      </div>
      <div class="title tab_title display_flex">
        <p @click="tabFun(1)" :class="tabSta == 1 ?'add_bg':''">商品介绍</p>
        <p @click="tabFun(2)" :class="tabSta == 2 ?'add_bg':''">规格参数</p>
        <p @click="tabFun(3)" :class="tabSta == 3 ?'add_bg':''">售后保障</p>
      </div>
      <div class="img-and-recommend">
        <div class="img">
          <div class="ql-container ql-snow">
            <div class="ql-editor" data-gramm="false" contenteditable="true">
              <div v-html="html" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import smallCard from './smallCard'
import { hits } from '@/api/hits/index'
export default {
  data () {
    return {
      tabSta: 1
    }
  },
  methods: {
    tabFun (sta) {
      this.tabSta = sta
    },
    goodsDetail (id, commodityName) {
      hits({
        type: '01',
        goodsId: id,
        goodsName: commodityName,
        sourceSection: '06'
      })
      var blan = this.$router.resolve({ // 调转到商品搜索页面
        path: '/detail',
        query: {
          id: id,
          keys: '相关推荐',
          commodityName: commodityName
        }
      })
      window.open(blan.href, '_blank')
    }
  },
  props: {
    goosRecommendedation: {
      type: Array
    },
    infoPic: {
      type: Array
    },
    goodsInfoBelowDetails: {
      type: Object
    },
    goodsDetId: {
      type: String
    }
  },
  computed: {
    html () {
      if (this.tabSta === 1) {
        return this.goodsInfoBelowDetails.detailedDescription
      } else if (this.tabSta === 2) {
        return this.goodsInfoBelowDetails.specificationsDetails
      } else {
        return this.goodsInfoBelowDetails.saleEnsure
      }
    }
  },
  components: {
    smallCard
    // goodsSynopsis
  }
}
</script>

<style lang="scss" scoped>
.ql-container.ql-snow {
  border: 0px solid #ccc;
}
.reocommend:hover {
  cursor: pointer;
}
.goodsSynopsis2 {
  width: 1216px;
  // border-bottom:2px solid #d4dde4;
}
.detail-dis {
  width: 100%;
  display: flex;
  justify-content: center;
  .content {
    width: 1226px;
    padding-top: 60px;
    text-align: left;
    overflow: visible;
    .tab_title {
      background: #ccc;
      border-bottom: 1px solid #ef922e;
      p {
        cursor: pointer;
        padding: 10px 20px;
      }
      .add_bg {
        background: #ef922e;
        color: #fff;
      }
    }
    .title {
      color: #333333;
      font-size: 16px;
    }
    .img-and-recommend {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      width: 1450px;
      .img {
        width: 1216px;
        position: relative;
        .item {
          width: 100%;
          margin-bottom: 15px;
          img {
            display: block;
            width: 100%;
          }
        }
        img {
          width: 100%;
          margin-bottom: 15px;
          display: block;
        }
      }
      .ql-video {
        width: 1226px;
      }
      .reocommend {
        width: 200px;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 9px 16px 0px rgba(181, 181, 181, 0.06);
        .close {
          height: 46px;
          background: #f7f7f7;
          border-bottom: 1px solid #e1e1e1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 15px;
          box-sizing: border-box;
          .text {
            font-size: 16px;
            color: #333333;
          }
        }
      }
    }
  }
}
.small-card .sperate-line {
  width: 2px;
}
</style>
