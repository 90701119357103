<template>
  <notifyList :totalNum="totalNum" :queryNotReadCount="queryNotReadCount" :notifyInfoList="notifyInfoList"/>
</template>

<script>
import notifyList from './components/notifyList'
import { queryMsgList, queryNotReadCount } from '@/api/personCenter/notify/notify'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['cartList', 'currentCustom'])
  },
  components: {
    notifyList
  },
  data () {
    return {
      totalNum: 0,
      notifyInfoList: [],
      breadCrumbs: [{
        label: '首页'
      }, {
        label: '个人中心'
      }],
      queryNotReadCount: 0
    }
  },
  methods: {
    onLoad (params) {
      queryMsgList(params).then(res => {
        this.notifyInfoList = res.data.data.data
        this.totalNum = res.data.data.count
      })
      queryNotReadCount(params.userId).then(res => {
        this.queryNotReadCount = res.data.data
        this.$store.commit('SET_QUERY_READ_COUNT', this.queryNotReadCount)
      })
    }
  },
  mounted () {
    var params = {
      pageNo: 1,
      pageSize: 15,
      userId: this.currentCustom.customerId,
      readStatus: '',
      dateNumber: ''
    }
    this.onLoad(params)
  }
}
</script>

<style lang="scss" scoped>
</style>
