<template>
  <div class="orderList">
    <div class="title">
      <span>我的订单</span>
    </div>
    <div class="div-1">
      <div class="left">
        <div :class="[{ 'all' : (idx == '01')},{ 'get': (idx != '01')}]">
          <span @click="orderStatusList()" class="orderStatus_stl">全部订单</span>
        </div>
        <div :class="[{ 'all' : (idx == '02')},{ 'get': (idx != '02')}]">
          <span @click="orderStatusList('99','paystateNo03')" class="orderStatus_stl">待付款</span>
        </div>
        <div :class="[{ 'not-get-all' : (idx == '04')},{ 'not-get': (idx != '04')}]">
          <span @click="orderStatusList('044')" class="orderStatus_stl">待收货</span>
          <!--<div class="top">
            <span v-if="myWaitForReceivingCount <= 99">{{ myWaitForReceivingCount }}</span><span v-if="myWaitForReceivingCount > 99">99+</span>
           &lt;!&ndash; <span>{{ myWaitForReceivingCount }}+</span>&ndash;&gt;
          </div>-->
        </div>
        <div :class="[{ 'all' : (idx == '05')},{ 'get': (idx != '05')}]">
          <span @click="orderStatusList('05','paystateNo03')" class="orderStatus_stl">已完成</span>
        </div>
        <div class="sperate"></div>
        <div :class="[{ 'all' : (idx == '06')},{ 'delete': (idx != '06')}]">
          <span @click="orderStatusList('06')" class="orderStatus_stl">订单回收站</span>
        </div>
      </div>
      <div class="right">
        <el-input v-model="searchParams.searchKey" placeholder="商品名称/订单号">
          <el-button slot="append" icon="el-icon-search" @click="orderStatusList()"></el-button>
        </el-input>
      </div>
    </div>
    <div class="div-2">
      <div class="son-1">
        <div class="son-1-1">
          <el-dropdown>
            <span class="el-dropdown-link" style="margin-left: 20px">
              {{ value1 }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in dingList" :key="item.pam">
                <a @click="dateSerch(item.pam,item.riqi)">{{ item.riqi }}</a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="son-3">
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ value2 }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in statusList" :key="item.para">
              <a @click="orderStatusList2(item.para,item.zhuangtai)">{{ item.zhuangtai }}</a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div v-for="(item,index) in orderInforList" :key="index" class="mg-top-1">
      <tableStatus color="orange" :orderName="orderName" :amount="amount" @onLoad="onLoad007" :orderNo="item.orderNo" :id="item.id" :receivingName="item.receivingName" :orderStatus="item.orderStatus" :orderTime="item.orderTime" :orderTotalSum="item.orderTotalSum" :searchParams="searchParams" :sourceSystem="item.sourceSystem" :isIntegral="item.isIntegral"></tableStatus>
      <simpleTable v-for="items in item.goodsVoList" :key="items.id" :goodsName="items.goodsName" :commoditySubTitle="items.specificationName" :actualAmount="items.actualAmount" :specificationDrawing="items.specificationDrawing" :num="items.num" :goodsId="items.goodsInfoId" color="orange"></simpleTable>
      <div v-for="itemInvoice in item.invoiceVoList" :key="itemInvoice.id">
        <div>
          <tableStatus :orderNo="itemInvoice.invoiceNumber" :orderName="orderFaName" @onLoad="onLoad007" :id="itemInvoice.id" :receivingName="itemInvoice.customerName" :orderStatus="itemInvoice.signFor" :orderTime="itemInvoice.deliveryTime" :orderTotalSum="orderTotalSum" :logisticsOrderNo="itemInvoice.logisticsOrderNo" :flag="1" :searchParams="searchParams"></tableStatus>
          <simpleTable v-for="itemInvoiceDetail in itemInvoice.invoiceDetailVoList" :key="itemInvoiceDetail.id" :goodsName="itemInvoiceDetail.goodsName" :commoditySubTitle="itemInvoiceDetail.specificationName" :actualAmount="itemInvoiceDetail.actualAmount" :specificationDrawing="itemInvoiceDetail.specificationDrawing" :num="itemInvoiceDetail.num" :goodsId="itemInvoiceDetail.goodsInfoId"></simpleTable>
        </div>
      </div>
    </div>
    <div class="block12">
      <!--<el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-size="this.pageSizes"
        layout="total, prev, pager, next, jumper"
        :total="this.totalNum">
      </el-pagination>-->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout='prev, pager, next'
        :total='this.totalNum'
        :page-size="this.pageSizes"
        :current-page.sync="currentPage1">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import tableStatus from '@/components/tableStatus'
import simpleTable from '@/components/simpleTable'
export default {
  props: {
    orderInforList: {
      type: Array
    },
    totalNum: {
      type: Number
    },
    myWaitForReceivingCount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      pageSizes: 15,
      currentPage1: 1,
      idx: '02',
      orderName: '订单号',
      orderFaName: '发货单号',
      orderTotalSum: 0,
      amount: '金额:',
      searchParams: {
        orderStatus: '',
        paystateNo03: '03',
        payStatus: '',
        payStatus2: '03',
        orderFlagStatus: '',
        searchKey: '',
        dateNumer: 0,
        pageNo: 0,
        pageSize: 15
      },
      value1: '全部订单',
      value2: '全部状态',
      statusList: [],
      zhuangList: [
        {
          zhuangtai: '全部状态',
          para: ''
        }, {
          zhuangtai: '待付款',
          para: '01'
        }, {
          zhuangtai: '待发货',
          para: '02'
        }, {
          zhuangtai: '部分发货',
          para: '03'
        }, {
          zhuangtai: '全部发货',
          para: '04'
        }, {
          zhuangtai: '已完成',
          para: '05'
        }, {
          zhuangtai: '已取消',
          para: '06'
        }
      ],
      dingList: [
        {
          riqi: '全部订单',
          pam: 0
        }, {
          riqi: '近一周订单',
          pam: 7
        }, {
          riqi: '近一月订单',
          pam: 30
        }, {
          riqi: '近半年订单',
          pam: 183
        }, {
          riqi: '近一年订单',
          pam: 365
        }
      ]
    }
  },
  created () {
    this.value2 = '待付款'
    this.statusList = [{ zhuangtai: '待付款', para: '01' }, { zhuangtai: '部分付款', para: '02' }]
    //  this.statusList = this.zhuangList
  },
  methods: {
    onLoad007 (params) {
      this.$emit('onLoad', params)
    },
    // 最大条数
    handleSizeChange (val) {
      this.searchParams.pageNo = 1
      this.searchParams.pageSize = val
      // 获取记录
      this.$parent.onLoad(this.searchParams)
    },
    handleCurrentChange (val) {
      // console.log('当前页' + val)

      this.searchParams.pageNo = val - 1

      this.$parent.onLoad(this.searchParams)
    },
    dateSerch (pam, pamName) {
      this.searchParams.dateNumer = pam
      this.value1 = pamName
      this.$parent.onLoad(this.searchParams)
    },
    orderStatusList (orderStatus, paystate) { // 调用上级方法
      debugger
      this.searchParams.pageNo = 0
      this.currentPage1 = 1
      if (paystate !== undefined && paystate != null && paystate.length > 0) {
        this.value2 = '待付款'
        this.searchParams.paystateNo03 = '03'
        this.searchParams.payStatus2 = '03'
        this.statusList = [{ zhuangtai: '待付款', para: '01' }, { zhuangtai: '部分付款', para: '02' }]
        this.searchParams.orderStatus = null
        this.searchParams.orderFlagStatus = null
      }
      if (orderStatus != null && orderStatus.length > 0) {
        if (orderStatus === '99') {
          this.idx = '02'
          this.searchParams.orderStatus = null
        } else if (orderStatus === '044') {
          this.value2 = '待收货'
          this.searchParams.orderFlagStatus = '044'
          this.statusList = [{ zhuangtai: '部分发货', para: '03' }, { zhuangtai: '全部发货', para: '04' }]
          this.searchParams.paystateNo03 = null
          this.searchParams.payStatus2 = null
          this.searchParams.orderStatus = null
          this.idx = '04'
        } else if (orderStatus === '05') {
          this.value2 = '已完成'
          this.searchParams.orderStatus = orderStatus
          this.idx = orderStatus
          this.searchParams.paystateNo03 = null
          this.searchParams.payStatus2 = null
          this.searchParams.orderFlagStatus = null
          this.statusList = []
        } else {
          this.value2 = '已取消'
          this.searchParams.orderStatus = orderStatus
          this.idx = orderStatus
          this.searchParams.paystateNo03 = null
          this.searchParams.payStatus2 = null
          this.searchParams.orderFlagStatus = null
          this.statusList = []
        }
      } else {
        this.idx = '01'
        this.searchParams.orderStatus = null
        this.searchParams.payStatus = null
        this.searchParams.paystateNo03 = null
        this.searchParams.payStatus2 = null
        this.searchParams.orderFlagStatus = null
        this.searchParams.dateNumer = 0
        this.value1 = '全部订单'
        this.value2 = '全部状态'
        this.statusList = this.zhuangList
      }
      this.$parent.onLoad(this.searchParams)
    },
    orderStatusList2 (orderStatus, orderStatusName) { // 调用上级方法
      this.value2 = orderStatusName
      this.searchParams.pageNo = 0
      this.currentPage1 = 1
      if (this.searchParams.payStatus2 !== '03') {
        if (orderStatus != null && orderStatus.length > 0) {
          this.searchParams.orderStatus = orderStatus
          this.searchParams.payStatus = null
          // this.idx = orderStatus
          this.value2 = orderStatusName
        } else {
          // this.idx = '01'
          this.searchParams.orderStatus = null
        }
      } else {
        this.searchParams.payStatus = orderStatus
        this.searchParams.paystateNo03 = null
        this.searchParams.orderStatus = null
      }
      this.$parent.onLoad(this.searchParams)
    }
  },
  components: {
    tableStatus,
    simpleTable
  }
}
</script>

<style lang="scss" scoped>
/**/
.el-dropdown-link {
  cursor: pointer;
  color: #111111;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #111111;
  font-size: 14px;
  margin-bottom: 20px;
}
/**/
.orderStatus_stl:hover {
  cursor: pointer;
  color: #ef922e;
}
.mg-top-1 {
  margin-bottom: 15px;
}
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 110px 40px;
  .title {
    color: #333333;
    font-size: 24px;
  }
  .div-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 27px;
    .left {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 16px;
      .all {
        color: #ef922e;
        margin-right: 40px;
      }
      .get {
        margin-right: 40px;
      }
      .not-get {
        margin-right: 40px;
        position: relative;
        .top {
          background: #ef922e;
          border-radius: 3px;
          font-size: 10px;
          color: #ffffff;
          padding: 0 1px;
          position: absolute;
          right: -10px;
          top: -12px;
        }
      }
      .not-get-all {
        color: #ef922e;
        margin-right: 40px;
        position: relative;
        .top {
          background: #ef922e;
          border-radius: 3px;
          font-size: 10px;
          color: #ffffff;
          padding: 0 1px;
          position: absolute;
          right: -10px;
          top: -12px;
        }
      }
      .sperate {
        width: 1px;
        height: 19px;
        background-color: #dcd9d9;
        margin-right: 40px;
      }
      .delete {
        color: #999999;
        font-size: 14px;
      }
    }
    .right {
      width: 260px;
      height: 32px;
    }
  }
  .div-2 {
    margin-top: 20px;
    height: 40px;
    background: #f5f5f5;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #333333;
    font-size: 14px;
    .son-1 {
      display: flex;
      align-items: center;
      flex: 1;
      .son-1-1 {
        display: flex;
        align-items: center;
        margin-left: 20px;
        .son-1-1-2 {
          padding-left: 10px;
          height: 17px;
        }
      }
      .son-1-2 {
        margin-left: 190px;
      }
    }
    .son-2 {
      width: 160px;
    }
    .son-3 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      .son-3-2 {
        padding-left: 10px;
        height: 17px;
      }
    }
  }
}
</style>

<style lang="scss">
.block12 {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  position: static;
}
.orderList {
  position: relative;
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
</style>
