import { render, staticRenderFns } from "./detailCardToCom.vue?vue&type=template&id=9d376c36&scoped=true&"
import script from "./detailCardToCom.vue?vue&type=script&lang=js&"
export * from "./detailCardToCom.vue?vue&type=script&lang=js&"
import style0 from "./detailCardToCom.vue?vue&type=style&index=0&id=9d376c36&lang=scss&scoped=true&"
import style1 from "./detailCardToCom.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d376c36",
  null
  
)

export default component.exports