import request from '@/router/axios'

/**
 * 获取首页顶部广告信息
 * @returns {AxiosPromise}
 */
export const showHomeTopAdvert = () => {
  return request({
    url: '/cineplex/homePage/showHomeTopAdvert',
    method: 'post'
  })
}

/**
 * 获取首页轮播图
 * @returns {AxiosPromise}
 */
export const showSwipe = () => {
  return request({
    url: '/cineplex/homePage/showSwipe',
    method: 'post'
  })
}

/**
 * 获取首页中部广告图
 * @returns {AxiosPromise}
 */
export const showMiddleAdvert = () => {
  return request({
    url: '/cineplex/homePage/showMiddleAdvert',
    method: 'post'
  })
}

/**
 * 获取购物车广告图
 * @returns {AxiosPromise}
 */
export const showShopAdvert = () => {
  return request({
    url: '/cineplex/homePage/showShopAdvert',
    method: 'post'
  })
}
