<template>
  <div class="orderList">
    <div class="title">
      <div class="left">
        <span>收货地址</span>
      </div>
      <div>
      <div class="right" @click="showModal4">
        <span>+ 新增收货地址</span>
      </div>
        <el-dialog title="新增收货地址" @close="closeSelf" :visible.sync="visible4">
          <addressForm :ruleForm="ruleForm" :edit="false" @closeModal4="closeModal4"/>
        </el-dialog>
      </div>
    </div>
    <div class="list">
      <addressCard :addressMes="addr" :index="index" :addressList="addr" v-for="(addr, index) in addressMes" :key="index"></addressCard>
    </div>
  </div>
</template>
<script>
import addressCard from '@/components/addressCard'
import { doInsertAddress, doDeleteAddress, doUpdateAddress, findAddress } from '@/api/personCenter/addressInfo/addressL'
import addressForm from '@/views/personCenter/sendAdress/components/addressForm'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['currentCustom'])
  },
  components: {
    addressForm,
    addressCard
  },
  data () {
    return {
      visible4: false,
      ruleForm: {},
      addressMes: []
    }
  },
  methods: {
    showModal4 () {
      this.visible4 = true
    },
    closeModal4 (flag) {
      this.ruleForm = {}
      this.visible4 = false
      if (flag) this.onLoad()
    },
    closeSelf () {
      this.ruleForm = {}
    },
    add (params) {
      params.customerId = this.currentCustom.customerId
      params.customerName = this.currentCustom.customerName
      doInsertAddress(params).then(res => {
        this.addressMes = res.data.data
        for (var i = 0; i < this.addressMes.length; i++) {
          if (this.addressMes[i].attribute1 != null && this.addressMes[i].attribute1.length > 0) {
            this.addressMes[i].attribute1 = JSON.parse(this.addressMes[i].attribute1)
          }
        }
      })
    },
    upd (addressId) {
      doUpdateAddress(addressId).then(res => {
        this.addressMes = res.data.data
        for (var i = 0; i < this.addressMes.length; i++) {
          if (this.addressMes[i].attribute1 != null && this.addressMes[i].attribute1.length > 0) {
            this.addressMes[i].attribute1 = JSON.parse(this.addressMes[i].attribute1)
          }
        }
      })
    },
    del (i) {
      var params = this.addressMes[i].id
      doDeleteAddress(params).then(res => {
        this.addressMes.splice(i, 1)
      })
    },
    setDefault (i) { // 设置默认
      for (var a = 0; a < this.addressMes.length; a++) {
        if (a === i) {
          this.addressMes[a].isDefault = '01'
        } else {
          this.addressMes[a].isDefault = '02'
        }
      }
    },
    onLoad () {
      findAddress(this.currentCustom.customerId).then(res => {
        this.addressMes = res.data.data
        for (var i = 0; i < this.addressMes.length; i++) {
          if (this.addressMes[i].attribute1 != null && this.addressMes[i].attribute1.length > 0) {
            this.addressMes[i].attribute1 = JSON.parse(this.addressMes[i].attribute1)
          }
        }
      })
    }
  },
  created () {
    this.onLoad()
  }
}
</script>

<style lang="scss" scoped>
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 38px 40px;
  .title {
    color: #333333;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      line-height: 42px;
    }
    .right {
      width: 148px;
      height: 36px;
      border: 1px solid gainsboro;
      border-radius: 2px;
      line-height: 36px;
      text-align: center;
      color: #ef922e;
      font-size: 14px;
    }
    .right:hover{
      cursor: pointer;
      border: 1px solid #ef922e;
    }
  }

}
</style>
