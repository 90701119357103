<template>
  <div class="order-list">
    <div class="title">
      <span>商品列表</span>
    </div>
    <div class="table">
      <el-table header-row-class-name="center-text" :data="orderGoodsVoList" style="width: 100%">
        <el-table-column prop="name" label="商品信息" width="350px">
            <template slot-scope="scope">
                <pro-info :name="scope.row.goodsName"
                          :mainGraph="scope.row.specificationDrawing"
                          :commoditySubTitle="scope.row.commoditySubTitle"
                          :id="scope.row.goodsInfoId"
                ></pro-info>
            </template>
        </el-table-column>
        <el-table-column prop="num" label="数量">
        </el-table-column>
        <el-table-column prop="specificationName" label="规格名称"> </el-table-column>
        <el-table-column prop="unitPrice" label="价格"> </el-table-column>
        <el-table-column prop="translateNum" label="已发货数量"> </el-table-column>
        <el-table-column prop="discountAmount" label="折扣金额"> </el-table-column>
        <el-table-column prop="actualAmount" label="实付金额"> </el-table-column>
        <el-table-column prop="translateMoney" label="已开票金额">
            <template slot-scope="scope">
                <span style="text-decoration: underline;">{{scope.row.price2}}</span>
            </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 影厅start -->
    <div class="title" v-if="cinemaInfoList != null && cinemaInfoList.length > 0">
      <span>影厅列表</span>
    </div>
    <div class="table" v-if="cinemaInfoList != null && cinemaInfoList.length > 0">
      <el-table header-row-class-name="center-text" :data="cinemaInfoList" style="width: 100%">
        <!-- <el-table-column prop="name" label="商品信息" width="350px">
          <template slot-scope="scope">
            <pro-info :name="scope.row.goodsName"
                      :mainGraph="scope.row.specificationDrawing"
                      :commoditySubTitle="scope.row.commoditySubTitle"
                      :id="scope.row.goodsInfoId"
            ></pro-info>
          </template>
        </el-table-column> -->
        <el-table-column prop="cinemaName" label="影厅名称"> </el-table-column>
        <el-table-column prop="customerName" label="客户名称"> </el-table-column>
      </el-table>
    </div>
    <!-- 影厅end -->
  </div>
</template>

<script>
import proInfo from '@/components/proInfo'
export default {
  props: {
    orderGoodsVoList: {
      type: Array
    },
    cinemaInfoList: {
      type: Array
    }
  },
  components: {
    proInfo
  },
  data () {
    return {
      tableData: [
        {
          number: 'x1',
          name: 'DP4K-36BLP- 2',
          number2: '1',
          index: '000122434311111',
          price3: '¥54680.00',
          price: '¥54680.00',
          price1: '¥54680.00',
          price2: '¥54680.00'
        },
        {
          number: 'x1',
          name: 'DP4K-36BLP- 2',
          number2: '1',
          index: '000122434311111',
          price3: '¥54680.00',
          price: '¥54680.00',
          price1: '¥54680.00',
          price2: '¥54680.00'
        }, {
          number: 'x1',
          name: 'DP4K-36BLP- 2',
          number2: '1',
          index: '000122434311111',
          price3: '¥54680.00',
          price: '¥54680.00',
          price1: '¥54680.00',
          price2: '¥54680.00'
        }, {
          number: 'x1',
          name: 'DP4K-36BLP- 2',
          number2: '1',
          index: '000122434311111',
          price3: '¥54680.00',
          price: '¥54680.00',
          price1: '¥54680.00',
          price2: '¥54680.00'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.order-list {
  margin-top: 20px;
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  background: white;
  .title {
    color: #333333;
    font-size: 16px;
    text-align: left;
  }
  .table {
      margin-top: 10px;
  }
}
</style>
