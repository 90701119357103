import request from '@/router/axios'

export const ajaxList = (params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/myCouponList',
    method: 'get',
    params: {
      ...params
    }
  })
}
