import request from '@/router/axios'

export const grCode = (param) => {
  return request({
    url: '/cineplex/homePageOther/sendMessage',
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    params: {
      phone: param.phone,
      voId: param.voId
    }
  })
}
export const verifyCode = (param) => {
  return request({
    url: '/cineplex/homePageOther/checkSendMessage',
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    params: {
      phone: param.phone,
      code: param.code,
      voId: param.voId
    }
  })
}
// 验证账号是否存在
export const accountIsExist = (params) => {
  return request({
    url: '/cineplex/homePageOther/accountIsExist',
    method: 'get',
    params: {
      userCode: params
    }
  })
}
// 账户注册
export const registerAccount = (params) => {
  return request({
    url: '/cineplex/homePageOther/registerAccount',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: params
  })
}
export const getVoId = () => {
  return request({
    url: '/cineplex/homePageOther/getVoId',
    method: 'GET'
  })
}
