<template>
  <orderList :orderInforList="orderInforList" @onLoad="onLoad" :myWaitForReceivingCount="myWaitForReceivingCount" :totalNum="totalNum"/>
</template>

<script>
import orderList from './components/orderList'
import { orderAjaxList, myWaitForReceivingCount } from '@/api/order/orderInfo/index'
export default {
  components: {
    orderList
  },
  mounted () {
    var par = {
      paystateNo03: '03',
      pageNo: 0,
      pageSize: 15,
      dateNumer: 0
    }
    this.onLoad(par)
  },
  data () {
    return {
      status: '',
      totalNum: 0,
      totalPage: 0,
      orderInforList: [],
      myWaitForReceivingCount: 0,
      breadCrumbs: [{
        label: '首页'
      }, {
        label: '个人中心'
      }]
    }
  },
  methods: {
    onLoad (params) { // 初始化加载推荐,商品详情,商品图片
      orderAjaxList(params).then(res => {
        this.orderInforList = res.data.data.mainVoList
        /* for (var i = 0; i < this.orderInforList.length; i++) {
          this.orderInforList[i].orderTotalSum = parseFloat(res.data.data.mainVoList[i].orderTotalSum).toFixed(2)
        } */
        this.totalNum = res.data.data.totalNum
      })
      myWaitForReceivingCount().then(res => {
        this.myWaitForReceivingCount = res.data.data.stayReceNum
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.myOwnOrder {
    width: 100%;
}
</style>
