<template>
  <div class="successContent">
    <div class="part-1">
      <div class="qr">
        <img src="@/assets/qr.png" style="width:87px" alt="" />
      </div>
      <div class="info" v-if="order.amountDue === 0">
        <p class="text-1">提交订单成功！ <!--<textarea id="input">这是幕后黑手</textarea><img class="copyOrderNo" src="@/assets/copy.png" @click="copyText"/>--></p><!--<button class="copyOrderNo" @click="copyText">复制</button>-->
      </div>
      <div class="info" v-else>
        <p class="text-1" v-if="order.isMonthlyPayment === '02'">提交订单成功，您的付款截止日为{{order.prepaymentDate.slice(0,10)}}，为了不影响您后续下单，请在约定日期内付款！ </p>
        <p class="text-1" v-else>提交订单成功，请尽快付款！ <!--<textarea id="input">这是幕后黑手</textarea><img class="copyOrderNo" src="@/assets/copy.png" @click="copyText"/>--></p><!--<button class="copyOrderNo" @click="copyText">复制</button>-->
        <p class="text-2" v-if="order.isMonthlyPayment !== '02'">
          <span>请您尽快完成支付，否则订单会自动取消</span>
          <!-- <span>请您在</span>
          <span style="color: #f30012">23时53分01秒</span>
          <span>内完成支付，否则订单会自动取消</span> -->
        </p>
      </div>
    </div>
    <div class="sperate"></div>
    <div class="part-3">
      <span class="h1">付款信息：</span><textarea id="input">这是幕后黑手</textarea><img class="copyOrderNo" src="@/assets/copy.png" @click="copyText"/> <br>
      <span class="text-1 span_block" id="orderNo">
        订单编号：{{ order.supplier.orderNo }} <br>
        付款金额：{{ order.amountDue }}元 <br>
        收款单位：{{ order.supplier.supplierName }} <br>
        开户行：{{ order.supplier.bankOfDeposit }} <br>
        银行账号：{{ order.supplier.accountNumber }}
      </span>
      <!--<span class="span_block">收款单位：{{ order.supplier.supplierName }}</span>
      <span class="span_block">开户行：{{ order.supplier.bankOfDeposit }}</span>
      <span class="span_block">银行账号：{{ order.supplier.accountNumber }}</span>
      <span class="span_block">订单编号：{{ order.supplier.orderNo }}</span>-->
    </div>
    <div class="sperate"></div>
    <!-- 电子协议 -->
    <div class="orderList" v-if="isSave === true">
      <div class="title tit">
        <div class="left">
          <span>注意，您尚未签订电子商务合作协议</span>
        </div>
      </div>
      <div class="content">
        <div>
          尊敬的客户，您的第一笔订单需要和我方签订电子商务合作协议。
        </div>
        <!-- <div class="two">
          您可以任选以下方式进行签订：<a @click="vimAgreement()" class="href">浏览协议范本</a>
        </div>
        <div class="xuan indent">
          1.联系 <a @click="contactCustomer()" class="href">在线客服</a> &nbsp; 进行商务合作协议的签订。
        </div>
        <div class="xuan indent">
          2.麻烦留下你的 <a @click="showModal4()" class="href">姓名和联系方式</a> &nbsp; 客服会在24小时内与您联系，
        </div> -->
        <div class="two">
          您可以选择以下方式进行签订：
        </div>
        <div class="xuan indent">
          1、<a @click="vimAgreement()" href="#" class="href1">下载电子商务合作协议</a>，请仔细阅读并填写甲方信息及盖章后，并回寄。
        </div>
        <div class="addressG">收件地址：北京市西城区新德街20号东配楼三层中影光峰</div>
        <div class="addressG">收件联系人：犀牛Mall商务</div>
        <div class="addressG">联系电话：158 0149 2494</div>
        <div class="xuan indent">
          2、联系 <a @click="kefu()" class="href">在线客服</a> &nbsp; 进行商务合作协议的签订；
        </div>
        <div class="xuan indent">
          3、麻烦留下你的 <a @click="showModal4()" class="href">姓名和联系方式</a> &nbsp; 客服会在24小时内与您联系。
        </div>
        <el-dialog title="协议用户信息" :visible.sync="visible4" width="510px">
          <!---->
          <template>
            <div class="full-width">
              <template>
                <div class="get-addrrss" style="height: 210px">
                  <div class="form-div">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
                      <el-form-item label="姓名:" width="60px" prop="fullName">
                        <div class="item-div">
                          <el-input v-model="ruleForm.fullName" maxlength="50"></el-input>
                        </div>
                      </el-form-item>
                      <el-form-item label="手机号:" prop="phone">
                        <div class="item-div">
                          <el-input v-model="ruleForm.phone" type="tel" maxlength="11"></el-input>
                        </div>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="modal-btn2 active mg-auto" @click="addAddress()">
                    <span>提交</span>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </el-dialog>
        <div class="xuan">
          请您保持电话畅通，以便我们能联系您，为您处理签订协议事宜。
        </div>
      </div>
    </div>
    <div class="sperate" style="margin-top: 80px;"></div>
    <div class="part-2">
      <p>{{ order.address.receivingAddr }} 收货人：{{ order.address.receivingName }} {{ order.address.receivingPhone }} {{order.address.receivingFixedTel}}</p>
      <p>
        商品名称：
        <span v-for="item in order.goodsList" :key="item.id">
          <div style="margin-left: 40px;margin-top: 5px">
            <img :src="item.specificationDrawing" class="img" style="vertical-align: middle;" width="40px" height="40px" @click="goDetail(item.goodsInfoId, item.goodsName)"> {{ item.goodsName }} -{{item.specificationName}} X {{item.number}}
          </div>
        </span>
      </p>
    </div>
    <div class="sperate"></div>
    <!--<div class="part-3">
      <p class="h1">付款信息：</p>
      <p>收款单位：{{ order.supplier.supplierName }}</p>
      <p>开户行：{{ order.supplier.bankOfDeposit }}</p>
      <p>银行账号：{{ order.supplier.accountNumber }}</p>
      <p>订单编号：{{ order.supplier.orderNo }}</p>
    </div>
    <div class="sperate"></div>-->
    <div class="part-4">
<!--      <div class="btn" @click="exportPic">-->
      <div class="btn" @click="downloadExcel">
        <span>下载采购订单</span>
      </div>
      <div class="table-part" id="canvasPic">
        <div class="header">
          <span>中影光峰采购订单</span>
        </div>
        <div class="title">
          <div>
            <span>收货地址：{{ order.address.receivingAddr }} {{ order.address.receivingName }} {{ order.address.receivingPhone }} {{order.address.receivingFixedTel}}</span>
          </div>
          <div>
            <span>下单时间：{{ order.orderTime }}</span>
          </div>
        </div>
        <div class="table">
          <el-table :data="order.goodsList" border  header-row-class-name="gray-bg">
            <el-table-column type="index" label="序号" :index="indexMethod" min-width="150px">
            </el-table-column>
            <el-table-column prop="goodsName" label="商品名称" min-width="200px">
            </el-table-column>
            <el-table-column prop="specificationName" label="规格" min-width="200px">
            </el-table-column>
            <el-table-column prop="number" label="数量" min-width="150px">
            </el-table-column>
            <el-table-column prop="salePrice" label="单价" min-width="200px"> </el-table-column>
            <el-table-column prop="Subtotal" label="金额" min-width="200px"> </el-table-column>
          </el-table>
        </div>
        <div class="table" v-if="order.cinemaList && order.cinemaList.length > 0">
          <el-table :data="order.cinemaList" border  header-row-class-name="gray-bg">
            <el-table-column type="index" label="序号" :index="indexMethod" min-width="150px">
            </el-table-column>
            <el-table-column prop="cinemaName" label="影厅名称" min-width="200px">
            </el-table-column>
            <el-table-column prop="customerName" label="客户名称" min-width="200px">
            </el-table-column>
          </el-table>
        </div>
        <div class="summy">
          <div class="money">
            <span>订单金额：<span class="red-color">{{ order.totalAmont }}元</span></span>
            <span>折扣金额：<span class="red-color">{{ order.preferentialAmount }}元 </span></span>
          </div>
          <div class="operate">
            <span>操作员：{{ order.creator }}</span>
          </div>
        </div>
        <div class="sperate dotted"></div>
        <div class="china-money">
          <span>实际付款金额：</span>
          <span class="red-color">{{ order.amountDue }}元</span>
          <span>（{{ order.CapAmountDue }}）</span>
        </div>
        <div class="supply">
          <span>采购单位：{{ order.customerName }}</span>
        </div>
        <div class="supply">
          <span>供应商：{{ order.supplier.supplierName }}</span>
        </div>
        <div class="componey">
          <div class="left">
            <p>收款单位：{{ order.supplier.supplierName }}</p>
            <p>开户行：{{ order.supplier.bankOfDeposit }}</p>
            <p>银行账号：{{ order.supplier.accountNumber }}</p>
            <p>订单编号：{{ order.supplier.orderNo }}</p>
          </div>
          <div class="right">
            <span style="color: red">备注：为了不影响您的发货，请支付款项时，务必备注订单编号</span>
          </div>
        </div>
        <span>{{trst}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderDetal } from './orderDetal'
// import { exportTemplateExcel } from '@/api/order/orderInfo/index'
import { findInfo, getElectronicCommerceAgreement, saveStayUser } from '@/views/myCar/components/goodsCar.js'
import html2canvas from 'html2canvas'
import { baseUrl } from '@/config/env'
import { mapGetters } from 'vuex'
// import sha1 from 'js-sha1'
// import { guid } from '@/util/utils'
export default {
  computed: {
    ...mapGetters(['cartList', 'currentCustom', 'userInfo'])
  },
  data () {
    return {
      trst: '',
      agreementUrl: '',
      isSave: false,
      ruleForm: {
        fullName: '',
        phone: '',
        yesNo: '02'
      },
      rules: {
        fullName: [
          { required: true, message: '请填写姓名', trigger: 'change' }
        ],
        phone: [
          { required: true, pattern: /^1[0-9]{10}$/, message: '格式有误', trigger: 'change' }
        ]
      },
      customer_info: {},
      visible4: false,
      order: {
        address: {
          receivingAddr: '',
          receivingName: '',
          receivingPhone: '',
          receivingFixedTel: ''
        },
        goodsList: [
          {
            goodsName: '',
            specificationName: '',
            salePrice: 0.00,
            number: 0,
            Subtotal: 0.00
          }
        ],
        totalAmont: 0.00,
        preferentialAmount: 0,
        amountDue: 0,
        CapAmountDue: '零',
        creator: '',
        customerName: '',
        supplier: {
          supplierName: '',
          bankOfDeposit: '',
          accountNumber: '',
          orderNo: '',
          specificationDrawing: ''
        },
        orderTime: ''
      }
    }
  },
  methods: {
    //
    copyText () {
      var text = document.getElementById('orderNo').innerText
      var input = document.getElementById('input')
      input.value = text // 修改文本框的内容
      input.select() // 选中文本
      document.execCommand('copy') // 执行浏览器复制命令
      this.$notify({
        // title: '警告',
        message: '复制成功',
        // type: 'warning',
        duration: 500
      })
    },
    //
    addAddress () { // 添加
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          saveStayUser(this.ruleForm).then(res => {
            this.cancelModal4()
          })
        }
      })
      // this.AddressList.push(addressFrom)
    },
    showModal4 () {
      this.handleDialogClose()
      this.visible4 = true
    },
    cancelModal4 () {
      this.visible4 = false
    },
    handleDialogClose () {
      this.ruleForm = {
        fullName: '',
        phone: '',
        yesNo: '02'
      }
      // this.$refs.ruleForm.resetFields()
      this.cancelModal4()
    },
    // 查询协议范本
    vimAgreement () {
      if (this.agreementUrl === null || this.agreementUrl.length <= 0) {
        this.$notify({
          title: '提示',
          message: '协议未找到,请联系客服咨询详情!',
          type: 'warning',
          duration: 2000
        })
      } else {
        window.open(this.agreementUrl)
      }
    },
    randomn (n) {
      if (n > 21) return null
      return parseInt((Math.random() + 1) * Math.pow(10, n - 1))
    },
    kefu () {
      // var url = 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626&signature=true&randomn=' + guid()
      // this.$store.commit('SET_IFRAME_SRC', url)
      var mpurl = 'https://work.weixin.qq.com/kfid/kfc51fdfa81129546b4'
      window.open(mpurl, '_blank')
      // this.customer_info.nonce = this.randomn(6)
      // this.customer_info.timestamp = new Date().getTime()
      // var name = this.userInfo.operatorName
      // if (name) {
      //   this.customer_info.web_token = name
      //   var signStr = `nonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&e9402bfd15f27fba780d906ce451e3c4`
      //   this.customer_info.signature = sha1(signStr).toUpperCase()
      //   url = url + `&nonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&signature=${this.customer_info.signature}&c_name=${encodeURIComponent(name)}&c_desc=kk`
      // }
      // this.openOne(url, 'detail')
    },
    // openOne (url, name) {
    //   var win = window.open('', name)
    //   try {
    //     if (win.location.href === 'about:blank') {
    //       //  窗口不存在
    //       win = window.open(url, name)
    //     } else {
    //       win.close()
    //       //  窗口以已经存在了
    //       //  win.focus()
    //       window.open(url, name)
    //     }
    //   } catch (e) {
    //     //  win.focus()
    //     win.close()
    //     window.open(url, name)
    //   }
    // },
    indexMethod (index) {
      return index + 1
    },
    goDetail (id, goodsName) {
      var blan = this.$router.resolve({ // 调整到详情界面
        path: '/detail',
        query: {
          id: id,
          commodityName: goodsName
        }
      })
      window.open(blan.href, '_blank')
    },
    onload () {
      // 获取url带过来的参数
      var orderNo = this.$route.query.orderNo
      // 查询订单
      getOrderDetal(orderNo).then(res => {
        this.order = res.data.data
      })
      // 查询是否签约
      var customerId = this.currentCustom.customerId
      if (customerId !== null && customerId !== undefined) {
        findInfo(customerId).then(res => {
          var cust = res.data.data
          if (cust.signingStatus !== null && cust.signingStatus === '02') {
            this.isSave = true
            getElectronicCommerceAgreement().then(res => {
              this.agreementUrl = res.data.data
            })
          }
        })
      }
      // 查询范本
    },
    // 下载excel结算单
    downloadExcel () {
      var mpurl = `${baseUrl}/cineplex/homePageGoods/exportTemplateExcel?orderNo=${this.$route.query.orderNo}&token=${this.$store.getters.token}&ticket=${this.$store.getters.userInfo.ticket}`
      window.open(mpurl, '_blank')
    },
    //
    exportPic () {
      event.preventDefault()
      var canvas2 = document.createElement('canvas')
      var _canvas = document.getElementById('canvasPic')
      var w = parseInt(window.getComputedStyle(_canvas).width)
      var h = parseInt(window.getComputedStyle(_canvas).height)
      // 放大canvas画布，然后放在较小的容器内，像素会升高
      var bye = window.screen.width // 屏幕分辨率的宽
      if (bye >= 1400 && bye <= 1600) {
        canvas2.width = w * 2.5
        canvas2.height = h * 2.5
      } else {
        canvas2.width = w * 2
        canvas2.height = h * 2
      }
      canvas2.style.width = w + 'px'
      canvas2.style.height = h + 'px'
      var context = canvas2.getContext('2d')
      context.scale(2, 2)
      html2canvas(document.getElementById('canvasPic'), {
        canvas: canvas2
      }).then(function (canvas) {
        var link = document.createElement('a')
        link.href = canvas.toDataURL('image/jpeg', 2) // 获取生成的图片的url
        link.setAttribute('download', '中影光峰采购单.png') // 利用了a标签的download 来下载 canvas图片
        link.style.display = 'none' // 将图片隐藏起来
        document.body.appendChild(link) // 插入到其中
        link.click()
      })
    }
  },
  created () {
    this.onload()
    /* this.trst =
    '网页可见区域宽：' + document.body.clientWidth +
    '网页可见区域高：' + document.body.clientHeight +
    '网页可见区域宽：' + document.body.offsetWidth + '(包括边线和滚动条的宽)' +
    '网页可见区域高：' + document.body.offsetHeight + '(包括边线的宽)' +
    '网页正文全文宽：' + document.body.scrollWidth +
    '网页正文全文高：' + document.body.scrollHeight +
    '网页被卷去的高：' + document.body.scrollTop +
    '网页被卷去的左：' + document.body.scrollLeft +
    '网页正文部分上：' + window.screenTop +
    '网页正文部分左：' + window.screenLeft +
    '屏幕分辨率的高：' + window.screen.height +
    '屏幕分辨率的宽：' + window.screen.width +
    '屏幕可用工作区高度：' + window.screen.availHeight +
    '屏幕可用工作区宽度：' + window.screen.availWidth
    // '屏幕设置 window.screen.colorDepth 位彩色
    // '屏幕设置 window.screen.deviceXDPI 像素/英寸 */
  }
}
</script>

<style lang="scss" scoped>
  .tit {
   padding-bottom: 20px;
   margin-top: -20px;
  }
  .span_block {
    display: block;
  }
.el-table-body{
  width:100%;
}
.el-table-head{
  width:100%;
}
.href1 {
  color: #0066cc;
  //margin-left: 30px;
  text-decoration: none;
}
.href1:hover {
  color: #ef922e;
}
#input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
}
#orderNo {
  display: inline;
}
.copyOrderNo:hover {
  cursor: pointer;
}
.img {
}
.img:hover {
  cursor: pointer;
}
.successContent {
  .red-color {
    color: #f30012;
  }
  margin-top: 20px;
  width: 100%;
  padding: 30px;
  background-color: white;
  text-align: left;
  .part-1 {
    display: flex;
    align-items: center;
    .qr {
      width: 100px;
      height: 100px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .info {
      color: #333333;
      margin-left: 30px;
      .text-1 {
        font-size: 20px;
        margin-bottom: 19px;
      }
      .text-2 {
        font-size: 14px;
      }
    }
  }
  .sperate {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
    margin-top: 30px;
    &.dotted {
      height: 0;
      border-bottom: 1px dotted #c1c1c1;
    }
  }
  .part-2 {
    color: #333333;
    font-size: 14px;
    line-height: 30px;
    margin-top: 30px;
  }
  .part-3 {
    color: #333333;
    font-size: 14px;
    line-height: 30px;
    margin-top: 30px;
    .h1 {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .part-4 {
    margin-top: 30px;
    .btn {
      width: 187px;
      height: 42px;
      border: 1px solid #ef922e;
      border-radius: 2px;
      color: #ef922e;
      line-height: 42px;
      text-align: center;
    }
    .btn:hover {
      cursor: pointer;
    }
    .table-part {
      width: 1166px;
      border: 1px dotted #c1c1c1;
      margin-top: 30px;
      padding: 61px 82px;
      box-sizing: border-box;
      .header {
        color: #333333;
        font-size: 34px;
        text-align: center;
        margin-bottom: 30px;
      }
      .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: #333333;
        font-size: 14px;
        margin-bottom: 14px;
      }

      .summy {
        display: flex;
        justify-content: space-between;
        color: #333333;
        margin-top: 13px;
        font-size: 14px;
        .money span {
          padding-right: 60px;
        }
      }
      .china-money {
        color: #000000;
        font-size: 20px;
        margin-top: 30px;
      }
      .supply {
        color: #333333;
        font-size: 14px;
        margin-top: 18px;
        span {
          padding-right: 60px;
        }
      }
      .componey {
        margin-top: 38px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 14px;
        .left {
          color: #000000;
          line-height: 24px;
        }
        .right {
          color: #999999;
        }
      }
    }
  }
}
  .addressG {
    margin-left: 48px;
    margin-top: 20px;
  }
//电子协议
.xuan {
  margin-top: 20px;
  &.indent {
    text-indent: 2em;
  }
}
.href {
  color: #0066cc;
}
.content {
  margin-top: 20px;
  // margin-left: 40px;
}
.item-div {
  width: 342px;
  position: relative;
}
.orderList {
  margin-top: 30px;
  text-align: left;
  width: 1166px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 38px 0px;
  height: 300px;
  box-sizing: border-box;
  .title {
    color: #333333;
    font-size: 24px;
  }
  .list-form {
    margin-top: 35px;
    color: #333333;
    font-size: 14px;
  }
  .form-div {
    margin-top: 30px;
    margin-left: -10px;
  }
  .button-ope {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .btn {
      width: 123px;
      height: 40px;
      border: 1px solid #e1e1e1;
      border-radius: 2px;
      line-height: 40px;
      text-align: center;
      color: #666666;
      font-size: 14px;
      &.active {
        background-color: #ef922e;
        color: white;
        margin-right: 15px;
      }
    }
  }
  .left {
    color: red;
  }
}
.mg-auto {
  margin: 30px auto;
}
.two {
  margin-top: 4px;
}
</style>
