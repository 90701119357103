<template>
<!--  公用名称-->
  <div class="addressCard">
    <div class="name" style="margin-bottom: 10px">
      <div class="text">
        <span>{{bean.invoiceTitle}}</span>
      </div>
      <div class="icon" v-if="isDefault">
        <img src="@/assets/mr.png" alt="" />
      </div>
    </div>
    <div class="infoBox" v-if="(customerCategory === '0' || customerCategory === '1') && customerCategory != null">
<!--   企业判断条件    v-if="(customerCategory === '0' || customerCategory === '1') && customerCategory != null" -->
      <div class="name">
        <div class="text">
          <span>【开票信息】</span>
        </div>
      </div>
      <div class="h2">
        <span>是否一般纳税人：</span>
        <span>{{ bean.isPayTaxes==='0'?'是':'否' }}</span>
      </div>
      <div class="h2">
        <span>发票类型：</span>
        <span>{{bean.attribute1}}</span>
      </div>
      <div class="h2">
        <span>开票业务类型：</span>
        <span>{{ bean.invoiceTitleType==='02'?'企业':'个人' }}</span>
      </div>
      <div class="h2">
        <span>开票名称：</span>
        <span>{{ bean.invoiceTitle }}</span>
      </div>
      <div class="h2">
        <span>纳税人识别号：</span>
        <span>{{ bean.taxpayerIdNumber }}</span>
      </div>
      <div class="h2">
        <span>开户行名称：</span>
        <span>{{ bean.bankOfDeposit }}</span>
      </div>
      <div class="h2">
        <span>开户行账号：</span>
        <span>{{ bean.accountNumber }}</span>
      </div>
      <div class="h2">
        <span>开票地址：</span>
        <span>{{ bean.registeredAddress }}</span>
      </div>
      <div class="h2">
        <span>电话：</span>
        <span>{{ bean.registeredTel }}</span>
      </div>
      <div class="h2">
        <span>邮箱：</span>
        <span>{{ bean.email }}</span>
      </div>
    </div>
<!--    个人发票-->
    <div class="infoBox" v-if="customerCategory === '2' && customerCategory != null">
      <div class="name">
        <div class="text">
          <span>【开票信息】</span>
        </div>
      </div>
      <div class="h2">
        <span>是否一般纳税人：</span>
        <span>{{ bean.isPayTaxes==='0'?'是':'否' }}</span>
      </div>
      <div class="h2">
        <span>发票类型：</span>
        <span>{{bean.attribute1}}</span>
      </div>
      <div class="h2">
        <span>开票业务类型：</span>
        <span>{{ bean.invoiceTitleType==='01'?'企业':'个人' }}</span>
      </div>
      <div class="h2">
        <span>开票名称：</span>
        <span>{{ bean.invoiceTitle }}</span>
      </div>
      <div class="h2">
        <span>纳税人识别号：</span>
        <span>{{ bean.taxpayerIdNumber }}</span>
      </div>
      <div class="h2">
        <span>开户行名称：</span>
        <span>{{ bean.bankOfDeposit }}</span>
      </div>
      <div class="h2">
        <span>开户行账号：</span>
        <span>{{ bean.accountNumber }}</span>
      </div>
      <div class="h2">
        <span>开票地址：</span>
        <span>{{ bean.registeredAddress }}</span>
      </div>
      <div class="h2">
        <span>电话：</span>
        <span>{{ bean.registeredTel }}</span>
      </div>
      <div class="h2">
        <span>邮箱：</span>
        <span>{{ bean.email }}</span>
      </div>
    </div>
<!--    公用地址信息-->
    <div class="infoBox" style="margin-left: 2px">
      <div class="name">
        <div class="text">
          <span>【发票邮寄地址信息】</span>
        </div>
      </div>
      <div class="h2">
        <span>邮寄省：</span>
        <span>{{ handleCode(bean.invoiceProvince) }}</span>
      </div>
      <div class="h2">
        <span>邮寄市：</span>
        <span>{{ handleCode(bean.invoiceProvince,bean.invoiceCity) }}</span>
      </div>
      <div class="h2">
        <span>邮寄地址：</span>
        <span>{{bean.toAddress}}</span>
      </div>
      <div class="h2">
        <span>联系人：</span>
        <span>{{bean.invoiceLinkMan}}</span>
      </div>
      <div class="h2">
        <span>联系人手机：</span>
        <span>{{ bean.telephone }}</span>
      </div>
    </div>
    <div class="h2" style="margin-top: 2%;color: #ef922e" v-if="false">
      <span>Tip: 更多信息请点击框内右下角查看</span>
    </div>
    <div class="h2 operate" v-if="false">
      <!--<span v-if="!isDefault" style="margin-right: 15px">设为默认</span>-->
      <span @click="showModal4(bean.id)" style="font-size: 16px"><a>查看</a></span>
    </div>
    <el-dialog title="编辑发票信息" :visible.sync="visible4">
      <div class="full-width">
        <invoiceFrom :ruleForm="beanT" :edit="edit" @closeModal4="closeModal4"/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import invoiceFrom from './invoiceFrom'
import { findInvoiceDetail, getCustomerCategoryById } from '@/api/personCenter/customerInvoice'
import province from '@/api/json/city.json'
import { mapGetters } from 'vuex'
export default {
  props: {
    isDefault: Boolean,
    bean: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  components: {
    invoiceFrom
  },
  mounted () {
    this.getCustomerCategoryById()
  },
  methods: {
    // 获取客户类别
    getCustomerCategoryById () {
      getCustomerCategoryById(this.userInfo.customerId).then(res => {
        this.customerCategory = res.data.data
      }).catch(e => {
        // console.log(e)
      })
    },
    showModal4 (id) {
      this.edit = false
      this.visible4 = true
      findInvoiceDetail(id).then((res) => {
        this.beanT = res.data.data
      })
    },
    closeModal4 (flag) {
      this.visible4 = false
      if (flag) this.$emit('ajaxList')
    },
    handleCode (invoiceProvince, invoiceCity) {
      if (invoiceProvince !== '' && invoiceProvince !== undefined && invoiceProvince !== null) {
        for (var item of province.provinces) {
          if (invoiceProvince === item.code) {
            if (invoiceCity) {
              for (let i = 0; i < item.cities.length; i++) {
                if (invoiceCity === item.cities[i].code) {
                  return item.cities[i].name
                }
              }
            } else {
              return item.name
            }
          }
        }
      }
    }
  },
  data () {
    return {
      visible4: false,
      beanT: {},
      edit: false,
      // 客户类别
      customerCategory: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.full-width {
  border: 1px solid #E1E1E1;
}
.addressCard {
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  padding: 20px;
  margin-top: 20px;
  .name {
    display: flex;
    align-items: center;
    .text {
      color: #333333;
      font-size: 16px;
      margin-right: 10px;
      font-weight: bold;
    }
  }
  .h2 {
    color: #666666;
    font-size: 14px;
    margin-top: 15px;
    &.operate {
        text-align: right;
        color: #ef922e;
    }
  }
  .infoBox {
    display: inline-block;
    width: 49%;
    vertical-align: top;
  }
}
</style>
