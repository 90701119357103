<template>
  <div class="detail-card">
    <div class="content">
      <div class="left">
        <div class="large-img">
          <vue-photo-zoom-pro :url="shipin.productVideoPic" v-if="shipin.type == 1"></vue-photo-zoom-pro>
          <video :src="shipin.productVideoPic" v-if="shipin.type == 2" autoplay="autoplay" controls="controls" type="video/mp4" muted="true" class="large-video" alt=""></video>
        </div>
        <div class="img_box display_flex">
          <!-- <img class="icon" src="../../../assets/rightScroll.png" alt="" @click="lastPic(index)"> -->
          <div class="small-img" ref="ulScroll1">
            <div :class="[{ 'item active' : (idx == i)},{ 'item': (idx != i)}]" v-for="(item, idx) in infoPic" :key="idx" @click="qurtyPic(idx)">
              <img :src="item.productVideoPic" v-if="item.type==1" alt="">
              <video :src="item.productVideoPic" v-if="item.type==2" style="width:100px;height:80px" alt=""></video>
            </div>
          </div>
          <!-- <img class="icon" src="../../../assets/leftScroll.png" alt="" @click="nextPic(index)"> -->
        </div>
      </div>
      <div class="right">
        <div class="display_flex">
          <div class="title">{{ goodsDet.commodityName }}</div>
          <div class="sub-title" @click="kefu()">
            <img src="@/assets/msg.png" alt="联系客服">
          </div>
        </div>
        <div class="sellingPoints">
          <!--<span>1080P精致影院！900ANSI家用高亮！上线钜惠推出</span>-->
          <!-- <span>{{ goodsDet.commoditySubTitle }}</span>-->
          <div class="downPrice">{{ goodsDet.sellingPoints }}</div>
          <img :src="goodsDet.sellingPointsPic" v-if="goodsDet.sellingPointsPic!=undefined&&goodsDet.sellingPointsPic!=''" style="width: 747px; height: 33px;" alt="">
        </div>
        <div class="price">
          <div class="price-cheap">
            <div class="price-1">
              <span>市场价:</span>
            </div>
            <div class="price-2">
              <!-- <span>¥94680.00</span>-->
              <span>￥{{ referenceInitPrice }}</span>
            </div>
            <div class="price-3">
              <span>￥{{ saleInitPrice }}</span>
            </div>
          </div>
          <div v-if="coupons!=''&&coupons!=undefined" class="price-coupon">
            <div class="price-1">
              <span>优惠券:</span>
            </div>
            <div class="coupon">
              <div v-for="item in coupons.slice(0,4)" :key="item.id">
                <coupon :couponType="item.couponType" :faceValue="item.faceValue" :overNYuanUse="item.overNYuanUse" class="mg-lf-12">
                </coupon>
              </div>
            </div>
          </div>
        </div>
        <div class="weight">
          <span></span>
          <span class="large"> </span>
        </div>
        <div class="version">
          <span>选择规格</span>
        </div>
        <div class="select" v-if="goodsSpecificationsList.length > 1">
          <p> {{ goodsSpecificationsList[0].specTatle[0] }} :</p>
          <div class="display_flex" v-for="(goodsSpecifications,index) in goodsSpecificationsList" :key="index">
            <!--<p> {{ goodsSpecifications.specTatle[0] }} :</p>-->
            <div @click="qurty(index,i)" :class="[{ 'version-btn active display_flex' : (res[index] == i)},{ 'version-btn display_flex' : (res[index] != i && att.specVal !== '0')},{ 'version-btn change-background display_flex' : (res[index] != i && att.specVal === '0')}]" v-for="(att,i) in goodsSpecifications.specArr" :key="i">
              <img v-if="index === 0" style="width: 50px;height:100%;margin-right: 10px;" :src="att.specVal" alt="">
              <div v-if="index === 0 ">{{att.specKey}}</div><!-- || index === goodsSpecificationsList.length-1 -->
              <div v-if="index !== 0 ">{{att.specKey}}</div><!-- && index !== goodsSpecificationsList.length-1 -->
            </div>
          </div>
          <!-- <div v-for="specification in specifications" :key="specification.id">
                  <div @click="qurty(specification.id)" :class="[{ 'version-btn active' : (specification.attribute1 == true)},{ 'version-btn': (specification.attribute1 == false)}]"   :id="specification.id">
                   {{ specification.specificationHeadJson }}
                  </div>
                </div> -->
        </div>
        <div class="select" v-if="goodsSpecificationsList.length === 1">
          <p> {{ goodsSpecificationsList[0].specTatle[0] }} :</p>
          <div class="display_flex" v-for="(goodsSpecifications,index) in goodsSpecificationsList" :key="index">
            <div @click="qurty(index,i)" :class="[{ 'version-btn active display_flex' : (res[index] == i)},{ 'version-btn display_flex' : (res[index] != i && att.specStock !== '0')},{ 'version-btn change-background display_flex' : (res[index] != i && att.specStock === '0')}]" v-for="(att,i) in goodsSpecifications.specArr" :key="i">
              <img v-if="index === 0" style="width: 50px;height:100%;margin-right: 10px;" :src="att.specVal" alt="">
              <div v-if="index === 0 ">{{att.specKey}}</div><!-- || index === goodsSpecificationsList.length-1 -->
              <div v-if="index !== 0 ">{{att.specKey}}</div><!-- && index !== goodsSpecificationsList.length-1 -->
            </div>
          </div>
        </div>
        <div class="buy">
          <div class="input-number" @click="calculateAmount()">
            <el-input-number controls-position="right" v-model="goods.number" :min="goodsDet.minBuyNum ? goodsDet.minBuyNum : 1"></el-input-number>
          </div>
          <div class="buy-btn car-fisrt" @click="putInMyCar()">
            <!-- :class="[{'buy-btn car-fisrt nokclicked' : goodsDet.isVirtual == '01' },{'buy-btn car-fisrt nokclicked' : goodsDet.isVirtual != '01'}]" -->
            <span>加入购物车</span>
          </div>
          <div class="buy-btn buy-immiate" @click="purchaseBuy()">
            <span>立即购买</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="成功加入购物车" style="font-size: 30px;" :visible.sync="dialogVisible" width="500px">
      <div class="a1">
        <span>您可以<a class="a2" style="color: blue;text-decoration: none;" target="_blank" @click="goMycar">去购物车结算</a></span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import coupon from '@/components/coupon'
import { mergeShoppingCart, goodsSpecifications, getSpecificationsPrice } from '@/api/goods/goodsInfo/index'
import { findId, shouCartNum, getDictVal } from '@/views/myCar/components/goodsCar.js'
// import sha1 from 'js-sha1'
import { mapGetters } from 'vuex'
import { guid } from '@/util/utils'
import store from '@/store'
// (function (a, h, c, b, f, g) {
//   a.UdeskApiObject = f
//   a[f] = a[f] || function () {
//     (a[f].d = a[f].d || []).push(arguments)
//   }
//   g = h.createElement(c)
//   g.async = 1
//   g.src = b
//   c = h.getElementsByTagName(c)[0]
//   c.parentNode.insertBefore(g, c)
// // eslint-disable-next-line func-call-spacing
// })
// // eslint-disable-next-line no-unexpected-multiline
// (window, document, 'script', 'https://cineappo.s4.udesk.cn/im_client/js/udeskApi.js', 'ud')
// var nonce = parseInt((Math.random() + 1) * Math.pow(10, 6 - 1))
// var timestamp = new Date().getTime()
// var webToken = '0001004'
// var signStr = `nonce=${nonce}&timestamp=${timestamp}&web_token=${webToken}&e9402bfd15f27fba780d906ce451e3c4`
// var signature = sha1(signStr).toUpperCase()
// // eslint-disable-next-line no-undef
// ud(
//   {
//     code: '8hg3ajb',
//     link: 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626',
//     customer: {
//       c_name: webToken,
//       nonce: nonce,
//       signature: signature,
//       timestamp: timestamp,
//       web_token: webToken
//     },
//     product: {
//       title: 'Apple iPhone 7',
//       url: 'http://item.jd.com/3133829.html?cu=true&utm_source…erm=9457752645_0_11333d2bdbd545f1839f020ae9b27f14',
//       image: 'http://img14.360buyimg.com/n1/s450x450_jfs/t3157/63/1645131029/112074/f4f79169/57d0d44dN8cddf5c5.jpg',
//       价格: '￥6189.00',
//       促销价: '￥6188.00'
//     }
//   }
// )
// eslint-disable-next-line no-undef
export default {
  components: {
    coupon
  },
  props: {
    specifications: {
      type: Array
    },
    infoPic: {
      type: Array
    },
    coupons: {
      type: Array
    },
    goodsDet: {
      type: Object
    },
    goodsId: {
      type: String
    }
  },
  watch: {
    infoPic: function (newVal, oldVal) {
      this.shipin.productVideoPic = newVal[0].productVideoPic
      this.shipin.type = newVal[0].type
    }
  },
  computed: {
    ...mapGetters(['goodsCarNum', 'userInfo'])
  },
  data () {
    return {
      dialogVisible: false,
      index: 0,
      referenceInitPrice: 0,
      saleInitPrice: 0,
      i: '0',
      submitCart: {
        orderNo: '',
        submitList: []
      },
      customer_info: {},
      res: [0, 0],
      shipin: {
        productVideoPic: '',
        type: ''
      },
      specPam: [],
      goodsSpecificationsList: [],
      goods: {
        goodsId: this.goodsId,
        commodityName: this.goodsDet.commodityName,
        commoditySubTitle: this.goodsDet.commoditySubTitle,
        mainGraph: '',
        specId: '',
        specName: '',
        salePrice: '',
        number: 1,
        goodsInfoStatus: '',
        Subtotal: 0
      }
    }
  },
  methods: {
    goMycar () {
      this.$router.push({ // 调整到购物车界面
        name: 'myCar'
      })
    },
    randomn (n) {
      if (n > 21) return null
      return parseInt((Math.random() + 1) * Math.pow(10, n - 1))
    },
    kefu () {
      //  var randomNum = Math.round(Math.random() * 10) + '&randomNum=' + randomNum
      var url = 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626&product_title=' + encodeURIComponent(this.goodsDet.commodityName) + '&product_url=' + encodeURIComponent(window.location.href) + '&product_image=' + encodeURIComponent(this.goodsDet.mainGraph) + '&product_send=true' + '&product_价格=' + encodeURIComponent('￥' + this.referenceInitPrice) + '&product_促销价=' + encodeURIComponent('￥' + this.saleInitPrice)
      url += '&signature=true&randomn=' + guid()
      this.$store.commit('SET_IFRAME_SRC', url)
      // this.customer_info.nonce = this.randomn(6)
      // this.customer_info.timestamp = new Date().getTime()
      // var name = this.userInfo.operatorName
      // if (name) {
      //   this.customer_info.web_token = name
      //   var signStr = `nonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&e9402bfd15f27fba780d906ce451e3c4`
      //   this.customer_info.signature = sha1(signStr).toUpperCase()
      //   url = url + `&nonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&signature=${this.customer_info.signature}&c_name=${encodeURIComponent(name)}&c_desc=kk`
      // }
      //  this.$store.commit('SET_IFRAME_SRC', url)
      //  window.open(url)
      //  this.openOne(url, 'detail')
    },
    // openOne (url, name) {
    //   var win = window.open('', name)
    //   try {
    //     if (win.location.href === 'about:blank') {
    //       //  窗口不存在
    //       win = window.open(url, name)
    //     } else {
    //       win.close()
    //       //  窗口以已经存在了
    //       //  win.focus()
    //       window.open(url, name)
    //     }
    //   } catch (e) {
    //     //  win.focus()
    //     win.close()
    //     window.open(url, name)
    //   }
    // },
    // 下一张图片
    nextPic (a) {
      a = a + 1
      this.index = a
      this.shipin.productVideoPic = this.infoPic[a].productVideoPic
      this.shipin.type = this.infoPic[a].type
      this.$refs.ulScroll1.scrollLeft = this.$refs.ulScroll1.scrollLeft + 100 // 滚动条移动
      this.i = a
    },
    // 上一张图片
    lastPic (a) {
      a = a - 1
      this.index = a
      this.shipin.productVideoPic = this.infoPic[a].productVideoPic
      this.shipin.type = this.infoPic[a].type
      this.$refs.ulScroll1.scrollLeft = this.$refs.ulScroll1.scrollLeft - 100 // 滚动条移动
      this.i = a
    },
    // 加入购物车
    putInMyCar () {
      this.dialogVisible = true
      if (this.goods.specId === '' || this.res[this.res.length] === 9999) {
        this.$notify({
          title: '警告',
          message: '请选择有效规格参数',
          type: 'warning',
          duration: 2000
        })
        return false
      }
      mergeShoppingCart(this.goods).then(res => {
        shouCartNum().then(res => {
          this.$store.commit('SET_CART_NUM', res.data.data)
        })
      })
    },
    // 立即购买
    purchaseBuy () {
      if (this.goods.specId === '' || this.res[this.res.length] === 9999) {
        this.$notify({
          title: '警告',
          message: '请选择有效规格参数',
          type: 'warning',
          duration: 2000
        })
        return false
      }
      if (this.goodsDet.minBuyNum && this.goods.number < this.goodsDet.minBuyNum) {
        // 您购买的数量小于此商品的最小购买量：10
        this.$notify({
          title: '警告',
          message: '您购买的数量小于此商品的最小购买量：' + this.goodsDet.minBuyNum,
          type: 'warning',
          duration: 2000
        })
        return false
      }
      // 校验是否需要组合规格购买
      var pam = {
        submitList: [this.goods]
      }
      getDictVal(pam).then(res => {
        debugger
        if (res.data.code === 0 && res.data.data.code === '1') {
          // 支付
          findId().then(res => {
            this.submitCart.orderNo = res.data.data
            this.submitCart.submitList.push(this.goods)
            this.$store.commit('SET_CART_LIST', this.submitCart)
            this.$router.push({ // 调整到详情界面
              name: 'pay'
            })
          })
        } else if (res.data.code === 0 && res.data.data.code === '3') {
          this.$notify({
            title: '警告',
            message: '您购买的GDC服务器需硬件和软件配套下单,请加入购物车后一起结算!',
            type: 'warning',
            duration: 2000
          })
          return false
        }
      })
    },
    // 计算金额
    calculateAmount () {
      this.goods.Subtotal = (this.goods.number * this.goods.salePrice).toFixed(2)
    },
    // 选择规格
    qurty (a, i) {
      // 判断是否只有一行
      if (this.goodsSpecificationsList.length === 1) {
        var sppc = this.goodsSpecificationsList[a].specArr
        if (sppc[i].specStock !== '0') {
          // 页面动态切换
          this.$set(this.res, a, i)
        } else {
          return false
        }
      }
      // 判断是否是最后一行
      if (a === this.goodsSpecificationsList.length - 1) {
        var spp = this.goodsSpecificationsList[a].specArr
        if (spp[i].specVal !== '0') {
          // 页面动态切换
          this.$set(this.res, a, i)
        } else {
          return false
        }
      } else {
        // 页面动态切换
        this.$set(this.res, a, i)
      }
      // 初始化查询 下列规格参数
      var params = ''
      // 当点击第一行规格 只取第一行
      if (a === 0) {
        params = this.goodsSpecificationsList[0].specArr[i].specKey
      } else if (a === this.goodsSpecificationsList.length - 1) {
        // 当点击最后一行时 只查询规格价格
        this.packagePamas()
        return true
      } else {
        // 否则遍历点击行数 从数组中获取选择的规格头
        for (var j = 0; j <= a; j++) {
          if (j === a) {
            // 最后一行时 不加,
            params += this.goodsSpecificationsList[j].specArr[this.res[j]].specKey// .key
          } else {
            // 第一行时 需要获取key 因为第一行有图片
            params += this.goodsSpecificationsList[j].specArr[this.res[j]].specKey + ','
          }
        }
      }
      // 查询规格
      this.qurtySpecificationsByParams(params, a)
    },
    // 根据商品规格的第一列查询下面的动态数据
    qurtySpecificationsByParams (params, b) {
      // 商品规格
      var customerId = store.getters.currentCustom.customerId
      // debugger
      goodsSpecifications(this.goodsId, params, customerId).then(res => {
        // 处理规格tou
        this.goodsSpecificationsList = res.data.data.res

        for (var a = 0; a < this.goodsSpecificationsList.length; a++) {
          // 处理规格头
          var icatp = this.goodsSpecificationsList[a].specTatle
          var attp = icatp.split(',')
          this.goodsSpecificationsList[a].specTatle = attp
        }
        // 处理规格数组

        for (var j = 0; j < this.goodsSpecificationsList.length; j++) {
          if (j > b) {
            if (j === this.goodsSpecificationsList.length - 1) {
              var sprca = this.goodsSpecificationsList[j].specArr
              var lsp = 0
              for (var l = 0; l < sprca.length; l++) {
                if (sprca[l].specVal !== '0') {
                  lsp++
                  this.$set(this.res, j, l)
                  break
                }
              }
              if (lsp === 0) {
                this.$set(this.res, j, 9999)
              }
            } else {
              this.$set(this.res, j, 0)
            }
          }
        }
        this.packagePamas()
      })
    },
    // 规格封装参数
    packagePamas () {
      for (var b = 0; b < this.goodsSpecificationsList.length; b++) {
        // if (b === 0) {
        var name = this.goodsSpecificationsList[b].specTatle[0]
        var id = this.goodsSpecificationsList[b].specTatle[1]
        var asp = this.res[b]
        if (asp === 9999) {
          // 库存不足 不继续查询
          this.goods.specId = ''
          return false
        }
        var value = this.goodsSpecificationsList[b].specArr[this.res[b]].specKey
        var pam = {
          name: name,
          id: id,
          value: value
        }
        this.specPam[b] = pam
      }
      this.qurtyPrice(JSON.stringify(this.specPam))
    },
    // 查询规格价格
    qurtyPrice (params) {
      var customerId = store.getters.currentCustom.customerId
      // debugger
      getSpecificationsPrice(this.goodsId, params, customerId).then(res => {
        this.referenceInitPrice = res.data.data.referencePrice.toFixed(2)
        this.saleInitPrice = res.data.data.salePrice.toFixed(2)
        this.shipin.productVideoPic = res.data.data.specDrawing
        this.shipin.type = 1
        // 传参专用: 规格id 规格名字等信息
        this.goods.specId = res.data.data.id
        this.goods.specName = res.data.data.specificationHeadJson
        this.goods.salePrice = res.data.data.salePrice.toFixed(2)
        this.goods.mainGraph = res.data.data.specDrawing
        this.goods.goodsId = this.goodsId
        this.goods.commodityName = this.goodsDet.commodityName
        this.goods.Subtotal = (this.goods.number * this.goods.salePrice).toFixed(2)
        this.goods.commoditySubTitle = res.data.data.specificationHeadJson
      })
    },
    // 查询图片
    qurtyPic (a) {
      this.index = a
      this.shipin.productVideoPic = this.infoPic[a].productVideoPic
      this.shipin.type = this.infoPic[a].type
      this.i = a
    },
    onload () {
      // 商品规格
      var customerId = store.getters.currentCustom.customerId
      debugger
      goodsSpecifications(this.goodsId, '', customerId).then(res => {
        this.goodsSpecificationsList = res.data.data.res
        this.goods.goodsId = this.goodsId
        this.goods.specId = res.data.data.childsVo.id
        this.goods.specName = res.data.data.childsVo.specificationHeadJson
        this.goods.salePrice = res.data.data.childsVo.salePrice.toFixed(2)
        this.referenceInitPrice = res.data.data.childsVo.referencePrice.toFixed(2)
        this.saleInitPrice = res.data.data.childsVo.salePrice.toFixed(2)
        this.goods.mainGraph = res.data.data.childsVo.specDrawing
        this.goods.commodityName = this.goodsDet.commodityName
        this.goods.number = 1
        this.goods.Subtotal = (1 * res.data.data.childsVo.salePrice).toFixed(2)
        this.goods.commoditySubTitle = res.data.data.childsVo.specificationHeadJson

        for (var a = 0; a < this.goodsSpecificationsList.length; a++) {
          // 处理规格头
          var icatp = this.goodsSpecificationsList[a].specTatle
          var attp = icatp.split(',')
          this.goodsSpecificationsList[a].specTatle = attp
        }
        for (var j = 0; j < this.goodsSpecificationsList.length; j++) {
          if (j === this.goodsSpecificationsList.length - 1) {
            var sprca = this.goodsSpecificationsList[j].specArr
            var lsp = 0
            for (var l = 0; l < sprca.length; l++) {
              if (sprca[l].specVal !== '0') {
                this.res[j] = l
                lsp++
                break
              }
            }
            if (lsp === 0) {
              this.$set(this.res, j, 9999)
            }
          } else {
            this.res[j] = 0
          }
        }
      })
    }
  },
  mounted () {
    this.onload()
  }
}
</script>

<style lang="scss" scoped>
//
.a1 {
  height: 50px;
  margin-top: 50px;
  font-size: 16px;
  .a2:hover {
    color: #ef922e;
  }
}
/deep/ .el-dialog{
  .el-dialog__header {
    margin-left: 165px;
    font-size: 40px;
  }
}
//
.nokclicked {
  pointer-events: none;
}
.photo-zoom-pro {
  width: 430px;
  height: 430px;
}
.nokclicked:hover {
  cursor: not-allowed;
}
.detail-card {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  .content {
    width: 1226px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .left {
      width: 430px;
      .large-img {
        width: 430px;
        height: 430px;
        border: 1px solid #e1e1e1;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .large-video {
        width: 430px;
        height: 100%;
        background: #2c2c2c;
        border: 1px solid #e1e1e1;
      }
      .small-img:hover {
        cursor: pointer;
      }
      .img_box {
        width: 430px;
      }
      .icon {
        width: 50px;
        height: 80px;
      }
      .icon:hover {
        cursor: pointer;
      }
      .small-img {
        width: 100%;
        overflow-y: hidden;
        // dingwei
        margin-top: 10px;
        text-align: left;
        // display: flex;
        // justify-content:space-between;
        // align-items: center;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        .item {
          display: inline-block;
          width: 90px;
          height: 90px;
          margin-right: 7px;
          margin-bottom: 7px;
          position: relative;
          border: 1px solid #e1e1e1;
          &.active {
            border: 1px solid #ef922e;
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .right {
      width: 747px;
      text-align: left;
      .title {
        font: 700 16px Arial, 'microsoft yahei';
        color: #666;
        padding-top: 10px;
        line-height: 28px;
        margin-bottom: 5px;
      }
      .sub-title {
        font-size: 14px;
        color: #8c939d;
        padding: 5px 0 0 10px;
        cursor: pointer;
      }
      .sellingPoints {
        font-size: 14px;
        color: #f30012;
        // margin-top: 18px;
        .downPrice {
          padding-bottom: 10px;
        }
      }
      .price {
        width: 747px;
        // height: 160px;
        box-sizing: border-box;
        background: #f5f5f5;
        margin-top: 20px;
        padding: 28px 21px 21px 21px;
        .price-cheap {
          display: flex;
          align-items: center;
          .price-1 {
            font-size: 14px;
            color: #333333;
          }
          .price-2 {
            font-size: 16px;
            color: #999999;
            text-decoration: line-through;
            padding-left: 25px;
          }
          .price-3 {
            font-size: 26px;
            color: #f30012;
            padding-left: 14px;
          }
        }
        .price-coupon {
          display: flex;
          .price-1 {
            font-size: 14px;
            color: #333333;
            padding-top: 25px;
          }
          .coupon {
            height: 69px;
            display: flex;
            margin-left: 18px;
            margin-top: 20px;
            .mg-lf-12 {
              margin-left: 12px;
            }
          }
        }
      }
      .weight {
        margin-top: 25px;
        font-size: 14px;
        margin-left: 21px;
        color: #999999;
        .large {
          color: #333333;
        }
      }
      .version {
        margin-top: 18px;
        font-size: 14px;
        color: #999999;
        margin-left: 21px;
      }
      .select {
        // display: flex;
        // flex-wrap: wrap;
        margin-top: 16px;
        // display: grid;
        margin-left: 21px;
        // grid-template-columns: 185px 185px 185px;
        // grid-template-rows: 37px 37px;
        // grid-column-gap: 14px;
        // grid-row-gap: 14px;
        .display_flex {
          padding: 10px 0;
          flex-wrap: wrap;
          p {
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
        .version-btn {
          // width: 185px
          padding: 0 20px 0 0;
          margin-bottom: 10px;
          margin-right: 15px;
          // margin-top: 10px;
          // min-width:185px;
          height: 37px;
          border: 1px solid #e1e1e1;
          color: #333333;
          text-align: center;
          line-height: 37px;
          font-size: 14px;
          text-indent: 10px;
          &.active {
            border: 1px solid #ef922e;
            color: #ef922e;
          }
          &.disabled {
            background-color: #f5f5f5;
          }
        }
        .version-btn:hover {
          cursor: pointer;
        }
      }
      .buy {
        margin-top: 30px;
        display: flex;
        margin-left: 21px;
        .input-number {
          width: 180px;
          height: 60px;
        }
        .buy-btn {
          width: 288px;
          height: 60px;
          border: 1px solid #e1e1e1;
          font-size: 20px;
          text-align: center;
          color: #666666;
          line-height: 60px;
          margin-left: 20px;
          &.buy-immiate {
            background-color: #ef922e;
            border: none;
            color: #ffffff;
          }
        }
        .buy-btn:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.change-background {
  background-color: #dddddd;
}
</style>

<style lang="scss">
.detail-card {
  .el-input__inner {
    height: 60px;
    line-height: 60px;
  }
  .el-input-number.is-controls-right .el-input-number__decrease,
  .el-input-number.is-controls-right .el-input-number__increase {
    height: 30px;
    line-height: 30px;
  }
}
</style>
