<template>
  <div class="content-wrap display_column">
    <div class="header display_column">
      <div class="img_box"><img src="../../assets/logo.png" alt="" /></div>
    </div>
    <div class="content-inner display_column">
      <p class="info">登录错误，请联系客服。<br/>客服电话：400-870-9700<br/>客服二维码：请扫下面客服二维码</p>
      <div class="img_box_weixin">
        <img src="../../assets/zhu.jpg" alt="">
        <img src="../../assets/403_weixin.jpg" alt="">
      </div>
    </div>
    <div @click="logout" style="    border-color: #409EFF;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin-top: 10px;
    -webkit-transition: .1s;
    transition: .1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    width: 225px;
    border-radius: 4px;">返回</div>
  </div>
</template>

<script>
import { casLogOutUrl } from '@/config/env'
export default {
  data () {
    return {
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('LogOut').then(() => {
        window.location.href = casLogOutUrl
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-wrap {
  background: #f5f5f5;
  padding-bottom: 200px;
  width: 100%;
  margin-top: 14px;
  .header {
    width: 100%;
    height: 100px;
    background: #fff;
    .img_box {
      width: 1226px;
      height: 100px;
      display: flex;
      img {
        width: 135px;
        height: 60px;
      }
    }
  }
  .content-inner {
    width: 1226px;
    .info {
      font-size: 17px;
      padding: 50px 0;
      margin-top: 30px;
      line-height: 30px;
    }
    .btn {
      width: 170px;
      height: 42px;
      line-height: 42px;
      background: #fff;
      border: 1px solid #ef922e;
      border-radius: 21px;
      color: #ef922e;
    }
    .img_box_weixin{
      // margin-top: 130px;
      display:flex;
      img{
        width:100px;
        height: 100px;
        margin: 0 10px;
      }
    }
  }
}
</style>
