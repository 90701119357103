
<template>
  <div class="small-card">
      <div class="img">
        <img v-lazy="item.mainGraph" alt=""><!-- :src="mainGraph" -->
      </div>
      <div class="name" :title="item.commodityName">
          <span class="overflow_two">{{ item.commodityName }}</span>
      </div>
      <div class="price">
          <span>￥{{(item.salePrice).toFixed(2)}}</span>
      </div>
      <div class="sperate-line"></div>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object
    }
    // commodityName: {
    //   type: String,
    //   default: ''
    // },
    // salePrice: {
    //   type: Number,
    //   default: 0.00
    // },
    // mainGraph: {
    //   type: String,
    //   default: ''
    // }
  }
}
</script>

<style lang="scss" scoped>
.small-card {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    position: relative;
    //
    width: 140px;
    //
    padding: 30px 18.5px;
    .img {
        width: 136px;
        height: 136px;
        margin-top: 29px;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .name {
      width: 100%;
      height:40px;
        color: #111111;
        font-size: 14px;
        margin-top: 19px;
      span {
        display:inline-block;
        width: 100%;
        margin-left: 5%;
        line-height: 20px;
      }
    }
    .price {
        margin-top: 9px;
        margin-bottom: 30px;
        font-size: 14px;
        color: #F30012;
    }
}
.sperate-line {
        position: absolute;
        width: 1px;
        height:100px;
        background: linear-gradient(90deg,#f4f4f4, #E1E1E1, #f4f4f4);
            right: 0;
    bottom: 160px;
    }
</style>
