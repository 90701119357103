<template>
  <div class="successContent">
    <div class="part-4">
      <div class="btn" @click="downloadExcel">
        <span>下载采购订单</span>
      </div>
      <div class="table-part" id="canvasPic">
        <!-- <div style="text-align: right;"> <p class="text-1">订单号：{{ order.supplier.orderNo }}</p></div> -->
        <div class="header">
          <span>中影光峰采购订单</span>
        </div>
        <div class="title">
          <div>
            <span>收货地址：{{ order.address.receivingAddr }} {{ order.address.receivingName }} {{ order.address.receivingPhone }} {{order.address.receivingFixedTel}}</span>
          </div>
          <div>
            <span>下单时间：{{ order.orderTime }}</span>
          </div>
        </div>
        <!-- <div class="table">
          <el-table :data="order.goodsList" border style="width: 100%" header-row-class-name="gray-bg">
            <el-table-column type="index" label="序号" :index="indexMethod" width="128">
            </el-table-column>
            <el-table-column prop="goodsName" label="商品名称" width="360">
            </el-table-column>
            <el-table-column prop="number" label="数量">
            </el-table-column>
            <el-table-column prop="salePrice" label="单价"> </el-table-column>
            <el-table-column prop="Subtotal" label="金额"> </el-table-column>
          </el-table>
        </div> -->
        <div class="table">
          <el-table :data="order.goodsList" border style="width: 100%"  header-row-class-name="gray-bg">
            <el-table-column type="index" label="序号" :index="indexMethod" min-width="150px">
            </el-table-column>
            <el-table-column prop="goodsName" label="商品名称" min-width="200px">
            </el-table-column>
            <el-table-column prop="specificationName" label="规格" min-width="200px">
            </el-table-column>
            <el-table-column prop="number" label="数量" min-width="150px">
            </el-table-column>
            <el-table-column prop="salePrice" label="单价" min-width="200px"> </el-table-column>
            <el-table-column prop="Subtotal" label="金额" min-width="200px"> </el-table-column>
          </el-table>
        </div>
        <div class="table" v-if="order.cinemaList != null && order.cinemaList.length > 0">
          <el-table :data="order.cinemaList" border style="width: 100%"  header-row-class-name="gray-bg">
            <el-table-column type="index" label="序号" :index="indexMethod" min-width="150px">
            </el-table-column>
            <el-table-column prop="cinemaName" label="影厅名称" min-width="200px">
            </el-table-column>
            <el-table-column prop="customerName" label="客户名称" min-width="200px">
            </el-table-column>
          </el-table>
        </div>
        <div class="summy">
            <div class="money">
                <span>订单金额：<span class="red-color">{{ order.totalAmont }}元</span></span>
                <span>折扣金额：<span class="red-color">{{ order.preferentialAmount }}元 </span></span>
            </div>
            <div class="operate">
                <span>操作员：{{ order.creator }}</span>
            </div>
        </div>
        <div class="sperate dotted"></div>
        <div class="china-money">
            <span>实际付款金额：</span>
            <span class="red-color">{{ order.amountDue }}元</span>
            <span>（{{ order.CapAmountDue }}）</span>
        </div>
        <div class="supply">
            <span>采购单位：{{ order.customerName }}</span>
        </div>
        <div class="supply">
          <span>供应商：{{ order.supplier.supplierName }}</span>
        </div>
        <div class="componey">
            <div class="left">
                <p>收款单位：{{ order.supplier.supplierName }}</p>
                <p>开户行：{{ order.supplier.bankOfDeposit }}</p>
                <p>银行账号：{{ order.supplier.accountNumber }}</p>
                <p>订单编号：{{ order.supplier.orderNo }}</p>
            </div>
            <div class="right">
                <span style="color: red">备注：为了不影响您的发货，请支付款项时，务必备注订单编号</span>
            </div>
        </div>
        <span>{{trst}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderDetal } from './orderDetal'
import html2canvas from 'html2canvas'
import { baseUrl } from '@/config/env'
export default {
  methods: {
    // 下载excel结算单
    downloadExcel () {
      var mpurl = `${baseUrl}/cineplex/homePageGoods/exportTemplateExcel?orderNo=${this.$route.query.orderNo}&token=${this.$store.getters.token}&ticket=${this.$store.getters.userInfo.ticket}`
      window.open(mpurl, '_blank')
    },
    indexMethod (index) {
      return index + 1
    },
    goDetail (id) {
      var blan = this.$router.resolve({ // 调整到详情界面
        path: '/detail',
        query: {
          id: id
        }
      })
      window.open(blan.href, '_blank')
    },
    onload () {
      // 获取url带过来的参数
      var orderNo = this.$route.query.orderNo
      // 查询订单
      getOrderDetal(orderNo).then(res => {
        this.order = res.data.data
        this.order.totalAmont = res.data.data.totalAmont.toFixed(2)
        this.order.preferentialAmount = res.data.data.preferentialAmount.toFixed(2)
        this.order.amountDue = res.data.data.amountDue.toFixed(2)
      })
    },
    exportPic () {
      event.preventDefault()
      var canvas2 = document.createElement('canvas')
      var _canvas = document.getElementById('canvasPic')
      var w = parseInt(window.getComputedStyle(_canvas).width)
      var h = parseInt(window.getComputedStyle(_canvas).height)
      // 放大canvas画布，然后放在较小的容器内，像素会升高
      var bye = window.screen.width // 屏幕分辨率的宽
      if (bye >= 1400 && bye <= 1600) {
        canvas2.width = w * 2.5
        canvas2.height = h * 2.5
      } else {
        canvas2.width = w * 2
        canvas2.height = h * 2
      }
      canvas2.style.width = w + 'px'
      canvas2.style.height = h + 'px'
      var context = canvas2.getContext('2d')
      context.scale(2, 2)
      html2canvas(document.getElementById('canvasPic'), {
        canvas: canvas2
      }).then(function (canvas) {
        var link = document.createElement('a')
        link.href = canvas.toDataURL('image/jpeg', 2) // 获取生成的图片的url
        link.setAttribute('download', '中影光峰采购单.png') // 利用了a标签的download 来下载 canvas图片
        link.style.display = 'none' // 将图片隐藏起来
        document.body.appendChild(link) // 插入到其中
        link.click()
      })
    }
  },
  data () {
    return {
      trst: '',
      order: {
        address: {
          receivingAddr: '',
          receivingName: '',
          receivingPhone: ''
        },
        goodsList: [
          {
            goodsName: '',
            salePrice: 0.00,
            number: 0,
            Subtotal: 0.00
          }
        ],
        totalAmont: 0.00,
        preferentialAmount: 0,
        amountDue: 0,
        CapAmountDue: '零',
        creator: '',
        customerName: '',
        supplier: {
          supplierName: '',
          bankOfDeposit: '',
          accountNumber: '',
          orderNo: '',
          specificationDrawing: ''
        },
        orderTime: ''
      }
    }
  },
  created () {
    this.onload()
    /* this.trst =
    '网页可见区域宽：' + document.body.clientWidth +
    '网页可见区域高：' + document.body.clientHeight +
    '网页可见区域宽：' + document.body.offsetWidth + '(包括边线和滚动条的宽)' +
    '网页可见区域高：' + document.body.offsetHeight + '(包括边线的宽)' +
    '网页正文全文宽：' + document.body.scrollWidth +
    '网页正文全文高：' + document.body.scrollHeight +
    '网页被卷去的高：' + document.body.scrollTop +
    '网页被卷去的左：' + document.body.scrollLeft +
    '网页正文部分上：' + window.screenTop +
    '网页正文部分左：' + window.screenLeft +
    '屏幕分辨率的高：' + window.screen.height +
    '屏幕分辨率的宽：' + window.screen.width +
    '屏幕可用工作区高度：' + window.screen.availHeight +
    '屏幕可用工作区宽度：' + window.screen.availWidth
    // '屏幕设置 window.screen.colorDepth 位彩色
    // '屏幕设置 window.screen.deviceXDPI 像素/英寸 */
  }
}
</script>

<style lang="scss" scoped>
  .img {

  }
  .img:hover {
    cursor: pointer;
  }
.successContent {
    .red-color {
        color: #F30012;
    }
  margin-top: 20px;
  width: 100%;
  padding: 30px;
  background-color: white;
  text-align: left;
  .part-1 {
    display: flex;
    align-items: center;
    .qr {
      width: 100px;
      height: 100px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .info {
      color: #333333;
      margin-left: 30px;
      .text-1 {
        font-size: 40px;
        margin-bottom: 19px;
      }
      .text-2 {
        font-size: 14px;
      }
    }
  }
  .sperate {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
    margin-top: 30px;
    &.dotted {
        height: 0;
        border-bottom: 1px dotted #C1C1C1;
    }
  }
  .part-2 {
    color: #333333;
    font-size: 14px;
    line-height: 30px;
    margin-top: 30px;
  }
  .part-3 {
    color: #333333;
    font-size: 14px;
    line-height: 30px;
    margin-top: 30px;
    .h1 {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .part-4 {
    margin-top: 30px;
    .btn {
      width: 187px;
      height: 42px;
      border: 1px solid #ef922e;
      border-radius: 2px;
      color: #ef922e;
      line-height: 42px;
      text-align: center;
    }
    .btn:hover {
      cursor: pointer;
    }
    .table-part {
      width: 1166px;
      border: 1px dotted #c1c1c1;
      margin-top: 30px;
      padding: 61px 82px;
      box-sizing: border-box;
      .header {
          color: #333333;
          font-size: 34px;
          text-align: center;
          margin-bottom: 30px;
      }
      .title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          color: #333333;
          font-size: 14px;
          margin-bottom: 14px;
      }

      .summy {
          display: flex;
          justify-content: space-between;
          color: #333333;
          margin-top: 13px;
          font-size: 14px;
          .money span {
              padding-right: 60px;
          }
      }
      .china-money {
            color: #000000;
            font-size: 20px;
            margin-top: 30px;
      }
      .supply {
          color: #333333;
          font-size: 14px;
          margin-top: 18px;
          span {
              padding-right: 60px;
          }
      }
      .componey {
          margin-top: 38px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
            font-size: 14px;
          .left {
              color: #000000;
            line-height: 24px;
          }
          .right {
              color: #999999
          }
      }
    }
  }
}
</style>
