<template>
  <div class="onlySearchPart">
      <div class="content">
          <div class="img">
              <img src="@/assets/logo1.png" style="cursor: pointer" alt="" @click="goHome">
          </div>
          <div>
              <searchInput></searchInput>
          </div>
      </div>
  </div>
</template>

<script>
import searchInput from '@/components/searchInput'
export default {
  methods: {
    goHome () {
      this.$router.push({ // 调转到商品搜索页面
        path: '/home'
        /* query: {
          id: this.id
        } */
      })
    }
  },
  components: {
    searchInput
  }
}
</script>

<style lang="scss" scoped>
.onlySearchPart {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ef922e;
    .content {
        width: 1226px;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img {
          display: flex;
          align-items:center;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .img:hover {
          cursor: pointer;
        }
    }
}
</style>
