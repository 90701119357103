<template>
  <div class="special-card-item" :style="{'background-color': backgroundColor}">
    <div class="special-card-top">
      <img v-lazy="item.mainGraph" alt=""> <!-- :src="mainGraph" -->
    </div>
    <div class="special-card-center" style="min-height:39px;" :title="item.commodityName">
      <span class="overflow_two">{{ item.commodityName }}</span>
    </div>
    <div style="width: 80%;font-size:12px;min-height:22px;" class="special-card-tips overflow">
      <span>{{ item.sellingPoints }}</span>
    </div>
    <div class="special-card-bottom">
      <div class="half left">
        <span>￥{{(item.salePrice).toFixed(2)}}</span>
      </div>
      <div class="half delete">
        <span>￥{{(item.referencePrice).toFixed(2)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    /* name: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: ''
    },
    salePrice: {
      type: Number,
      default: 0.00
    },
    referencePrice: {
      type: Number,
      default: 0.00
    },
    mainGraph: {
      type: String,
      default: ''
    }, */
    backgroundColor: {
      type: String,
      default: '#ffffff'
    }
  }
}
</script>

<style lang="scss">
.special-card-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .special-card-top {
    padding-top: 30px;
    img {
      display: block;
      width: 209px;
      height: 209px;
    }
  }
  .special-card-center {
    text-align:left;
    width:100%;
    display:flex;
    flex-wrap:wrap;
    height: 40px;
    font-size: 13px;
    color: #333;
    margin-top: 53px;
    overflow: hidden;
    span {
      // width: 80%;
      // margin-left: 10%;
      padding: 0 20px;
      line-height: 20px;
    }
  }
  .special-card-tips {
    margin-left:-9px;
    text-align:left;
    color: #999999;
    padding-top: 10px;
  }
  .special-card-bottom {
    width: 90%;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;
    .half {
      margin-left:5px;
      &.left {
        color: #f30012;
        font-size: 14px;
        margin-right: 9px;
      }
      &.delete {
        font-size: 12px;
        color: #999999;
        text-decoration: line-through;
      }
    }
  }
}
</style>
