import request from '@/router/axios'

export const invoiceDetail = (params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/invoiceDetail',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const invoiceSubmit = (row) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/invoiceSubmit',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: row
  })
}

export const getCustomerCategoryById = (id) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/getCustomerCategoryById',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    params: {
      id
    }
  })
}
