<template>
  <div class="move-bar-floor" id="move-bar-floor" @mouseover="destroyTransformFn" @mouseleave="transformFn">
    <div class="left" v-if="moveBarObj && moveBarObj.advertPostionVo">
      <img :src="moveBarObj.advertPostionVo.img" alt="move" @click="clickTotal('05', '05', '竖左活动1')">
    </div>
    <div class="right" v-if="moveBarObj.goodsList && moveBarObj.goodsList.length > 0">
      <div id="wrap-scroll" class="wrap-scroll">
        <div v-for="(item, index) in moveBarObj.goodsList" :key="item.id + '' + index + 'q'"
             @click="goodsDetail(item.id, item.commodityName, '05', index)">
          <move-bar :item="item" :index="index"/>
        </div>
        <div v-for="(item, index) in moveBarObj.goodsList" :key="item.id + '' + index + 'w'"
             @click="goodsDetail(item.id, item.commodityName, '05', index)">
          <move-bar :item="item" :index="index"/>
        </div>
      </div>
      <div class="scroll-bar" id="scroll-bar">
        <span id="scroll-points" class="scroll-points"  @mousedown="dragMouseDown($event)"/>
      </div>
    </div>
  </div>
</template>

<script>
import moveBar from '@/components/moveBar'
import { hits } from '@/api/hits'
export default {
  components: {
    moveBar
  },
  props: {
    moveBarObj: {
      type: Object
    }
  },
  data () {
    return {
      transformObj: '',
      clientOffset: 0,
      clientRatio: 1,
      dragOffset: 0,
      isDown: false,
      dragEl: '',
      x: 0
    }
  },
  watch: {
    moveBarObj: {
      handler: function (arr) {
        this.transformFn()
        this.clientRatio = arr.goodsList.length * 242 / 877
      },
      deep: true
    }
  },
  methods: {
    goodsDetail (id, commodityName, clickPosition, index) {
      var busId = ''
      var idxTmp = this.moveBarObj.goodsList.length - index
      if (idxTmp >= 9) {
        busId = '05' + (idxTmp)
      } else {
        busId = '050' + (idxTmp)
      }
      var busTitle = '滚动产品' + (index + 1)
      hits({
        type: '02',
        clickPostion: clickPosition,
        busId: busId,
        busTitle: busTitle,
        sourceSection: '01'
      })
      hits({
        type: '01',
        goodsId: id,
        goodsName: commodityName,
        sourcePosition: clickPosition,
        sourceSection: '01'
      })
      var blan = this.$router.resolve({ // 调转到商品详情页面
        path: '/detail',
        query: {
          id: id,
          commodityName: commodityName,
          url: '/home'
        }
      })
      window.open(blan.href, '_blank')
    },
    clickTotal (clickPosition, busId, busTitle) {
      hits({
        type: '02',
        clickPostion: clickPosition,
        busId: busId,
        busTitle: busTitle,
        sourceSection: '01'
      })
    },
    transformFn () {
      if (!this.transformObj && !this.isDown && this.moveBarObj &&
        this.moveBarObj.goodsList && this.moveBarObj.goodsList.length > 0) {
        var that = this
        this.transformObj = setInterval(function () {
          var e = document.getElementById('wrap-scroll')
          if (that.clientOffset <= -1 * that.moveBarObj.goodsList.length * 242) {
            // clearInterval(that.transformObj)
            that.clientOffset = 0
          }
          that.clientOffset -= 0.6
          e.style.transform = 'translate3d(' + that.clientOffset + 'px, 0px, 0px)'
        }, 20)
      }
    },
    destroyTransformFn () {
      // 计算滚动条偏移量
      this.dragOffset = -this.clientOffset / this.clientRatio
      this.dragEl = document.getElementById('scroll-points')
      this.dragEl.style.transform = 'translate3d(' + this.dragOffset + 'px, 0px, 0px)'
      clearInterval(this.transformObj)
      this.transformObj = ''
    },
    dragMouseDown (e) {
      // 获取x坐标和y坐标
      this.x = e.clientX
      // 开关打开
      this.isDown = true
      // 设置样式
      var that = this
      this.dragEl = document.getElementById('scroll-points')
      var scrollBar = document.getElementById('scroll-bar')
      scrollBar.style.opacity = 1
      scrollBar.style.visibility = 'visible'
      scrollBar.classList.remove('strengthen')

      document.onmousemove = function (e) {
        if (!that.isDown) {
          return
        }
        const nx = e.clientX
        let nl = nx - that.x + that.dragOffset
        if (nl <= 0) {
          nl = 0
        }
        if (nl >= 877) {
          nl = 877
        }
        var tmp = document.getElementById('wrap-scroll')
        that.clientOffset = -that.clientRatio * nl
        tmp.style.transform = 'translate3d(' + that.clientOffset + 'px, 0px, 0px)'
        that.dragEl.style.transform = 'translate3d(' + nl + 'px, 0px, 0px)'
        return false
      }

      document.onmouseup = function (e) {
        that.isDown = false
        scrollBar.style.opacity = 0
        scrollBar.style.visibility = 'hidden'
        scrollBar.classList.add('strengthen')
        // this.dragEl.style.cursor = 'default'
        document.onmousemove = null
        document.onmouseup = null
        var moveBar = document.getElementById('move-bar-floor')
        var eventX = e.clientX
        var eventY = e.clientY
        var px1 = moveBar.offsetLeft
        var py1 = moveBar.offsetTop
        var px2 = moveBar.offsetLeft + moveBar.offsetWidth
        var py2 = moveBar.offsetTop + moveBar.offsetHeight
        if (eventX < px1 || eventX > px2 || eventY < py1 || eventY > py2) {
          that.transformFn()
        }
        return false
      }

      return false
    }
  },
  beforeDestroy () {
    clearInterval(this.transformObj)
    this.transformObj = ''
  }
}
</script>
<style lang="scss" scoped>
  .move-bar-floor:hover {
    cursor: pointer;
  }
  .move-bar-floor {
    width: 100%;
    display: flex;
    height: 280px;
    background-color: white;
    margin-top: 20px;
    .left {
      padding-left: 20px;
      width: 200px;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .right {
      flex: 1;
      overflow-x: hidden;
      padding-left: 20px;
      position: relative;
      .wrap-scroll {
        display: flex;
        height: 100%;
        width: 100%;
        /*// transform: none translate3d(0px, 0px, 0px) translate3d(-20px, 0px, 0px);*/
        /*// transition: all 6s;*/
      }
      .scroll-bar {
        position: absolute;
        left: 15px;
        bottom: 6px;
        width: calc(100% - 30px);
        height: 3px;
        border-radius: 3px;
        background-color: #f3f3f3;
        -webkit-transition: opacity .3s ease;
        transition: opacity .3s ease;
        opacity: 0;
        visibility: hidden;
        /*opacity: 1;*/
        /*visibility: visible;*/
        .scroll-points{
          width: 99px;
          /*width: 33px;*/
          height: 9px;
          border-radius: 4px;
          background-color: #d8d8d8;
          position: absolute;
          top: 50%;
          left: 0px;
          margin-top: -4.5px;
        }
      }
      .scroll-bar.strengthen {
        opacity: 0 !important;
        visibility: hidden !important;
      }
    }
    .right:hover {
      /*overflow-x: auto;*/
      .scroll-bar {
        opacity: 1 !important;
        visibility: visible !important;
      }
      .wrap-scroll {
        transform: translate3d(0px, 0px, 0px);
      }
    }
  }
</style>
