import request from '@/router/axios'

export const search = (row) => {
  row.sourceType = '01'
  return request({
    url: '/cineplex/homePage/search',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: row
  })
}

export const searchCondition = (row) => {
  return request({
    url: '/cineplex/homePage/searchCondition',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: row
  })
}

export const completionSearch = (params) => {
  return request({
    url: '/cineplex/homePage/completionSearch',
    method: 'get',
    params: {
      ...params
    }
  })
}
