<template>
  <div>
    <componeyInfoCrad :customerCompany="customerCompany" @onLoad="onLoad"/>
  </div>
</template>

<script>
import componeyInfoCrad from './components/componeyInfoCrad'
import { getAssetslightsource } from '@/api/personCenter/addressInfo/company'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['currentCustom'])
  },
  components: {
    componeyInfoCrad
  },
  data () {
    return {
      breadCrumbs: [{
        label: '首页'
      }, {
        label: '个人中心'
      }],
      customerCompany: []
    }
  },
  methods: {
    onLoad () {
      getAssetslightsource(this.currentCustom.customerId).then(res => {
        this.customerCompany = res.data.data
      })
    }
  },
  created () {
    this.onLoad()
  }
}
</script>

<style lang="scss" scoped>
  .myOwnOrder {
    width: 100%;
  }
</style>
