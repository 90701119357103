<template>
  <div class="get-addrrss">
    <div class="form-div">
      <el-form
        v-if="ruleForm.invoiceType === '04'"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="128px">
        <el-form-item label="抬头类型:" prop="invoiceTitleType">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceTitleType" placeholder="抬头类型">
              <el-option label="企业" value="02"/>
            </el-select>
          </div>
        </el-form-item>
<!--        <el-form-item label="发票内容:" prop="invoiceContent">-->
<!--          <div class="invoice-content item-div" style="cursor: pointer">-->
<!--            <div class="btn" :class="{ active: active }" @click="switchActive('商品明细', true)">-->
<!--              <span>商品明细</span>-->
<!--            </div>-->
<!--            <div class="btn" style="margin-left: 15px" :class="{ active: !active }" @click="switchActive('商品类别', false)">-->
<!--              <span>商品类别</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-form-item>-->
        <el-form-item label="是否为一般纳税人:" prop="isPayTaxes">
          <el-select v-model="ruleForm.isPayTaxes" disabled placeholder="是否为一般纳税人">
            <el-option label="否" value="1"/>
            <el-option label="是" value="0"/>
          </el-select>
        </el-form-item>
        <el-form-item label="所需发票种类:" prop="invoiceType">
          <div class="item-div" v-if="this.invoiceTypeTemp === '04'">
            <el-select v-model="ruleForm.invoiceType" placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
              <el-option label="增值税专用发票" value="04"/>
            </el-select>
          </div>
          <div class="item-div" v-if="this.invoiceTypeTemp === '03'">
            <el-select v-model="ruleForm.invoiceType" placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
              <el-option label="纸质普票" value="03"/>
            </el-select>
          </div>
          <div class="item-div" v-if="this.invoiceTypeTemp === '01'">
            <el-select v-model="ruleForm.invoiceType" placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="开票名称:" prop="invoiceTitle">
          <div class="item-div">
            <el-input v-model="ruleForm.invoiceTitle" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="纳税人识别号:" prop="taxpayerIdNumber">
          <div class="item-div">
            <el-input v-model="ruleForm.taxpayerIdNumber"/>
          </div>
        </el-form-item>
        <el-form-item label="开户行名称:" prop="bankOfDeposit">
          <div class="item-div">
            <el-input v-model="ruleForm.bankOfDeposit"/>
          </div>
        </el-form-item>
        <el-form-item label="开户行账号:" prop="accountNumber">
          <div class="item-div">
            <el-input v-model="ruleForm.accountNumber"/>
          </div>
        </el-form-item>
        <el-form-item label="开票地址:" prop="registeredAddress">
          <div class="item-div">
            <el-input v-model="ruleForm.registeredAddress"/>
          </div>
        </el-form-item>
        <el-form-item label="电话:" prop="registeredTel">
          <div class="item-div">
            <el-input v-model="ruleForm.registeredTel"/>
          </div>
        </el-form-item>
        <el-form-item label="邮箱:" prop="email" >
          <div class="item-div">
            <el-input v-model="ruleForm.email"/>
          </div>
        </el-form-item>
        <el-form-item label="邮寄省:" prop="invoiceProvince">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceProvince" placeholder="请选择" @change="provinceChanged">
              <el-option
                :key="''"
                :label="'全部'"
                :value="''">
              </el-option>
              <el-option
                v-for="item in provinces"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="邮寄市:" prop="invoiceCity">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceCity"
                       :loading="loadingCity"
                       placeholder="请选择">
              <el-option
                :key="''"
                :label="'全部'"
                :value="''">
              </el-option>
              <el-option
                v-for="item in cities"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="邮寄地址:" prop="toAddress">
          <div class="item-div">
            <el-input v-model="ruleForm.toAddress"/>
          </div>
        </el-form-item>
        <el-form-item label="联系人:" prop="invoiceLinkMan">
          <div class="item-div">
            <el-input v-model="ruleForm.invoiceLinkMan"/>
          </div>
        </el-form-item>
        <el-form-item label="联系人手机:" prop="telephone">
          <div class="item-div">
            <el-input v-model="ruleForm.telephone"/>
          </div>
        </el-form-item>
      </el-form>

      <el-form
        :model="ruleForm"
        v-if="ruleForm.invoiceType === '03'"
        :rules="rules1"
        ref="ruleForm"
        label-width="128px">
        <el-form-item label="抬头类型:" prop="invoiceTitleType">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceTitleType" placeholder="抬头类型">
              <el-option label="企业" value="02"/>
            </el-select>
          </div>
        </el-form-item>
<!--        <el-form-item label="发票内容:" prop="invoiceContent">-->
<!--          <div class="invoice-content item-div" style="cursor: pointer">-->
<!--            <div class="btn" :class="{ active: active }" @click="switchActive('商品明细', true)">-->
<!--              <span>商品明细</span>-->
<!--            </div>-->
<!--            <div class="btn" style="margin-left: 15px" :class="{ active: !active }" @click="switchActive('商品类别', false)">-->
<!--              <span>商品类别</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-form-item>-->
        <el-form-item label="是否为一般纳税人:" prop="isPayTaxes">
          <el-select v-model="ruleForm.isPayTaxes" disabled placeholder="是否为一般纳税人">
            <el-option label="否" value="1"/>
            <el-option label="是" value="0"/>
          </el-select>
        </el-form-item>
        <el-form-item label="所需发票种类:" prop="invoiceType">
          <div class="item-div" v-if="this.invoiceTypeTemp === '04'">
            <el-select v-model="ruleForm.invoiceType" placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
              <el-option label="增值税专用发票" value="04"/>
            </el-select>
          </div>
          <div class="item-div" v-if="this.invoiceTypeTemp === '03'">
            <el-select v-model="ruleForm.invoiceType" placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
              <el-option label="纸质普票" value="03"/>
            </el-select>
          </div>
          <div class="item-div" v-if="this.invoiceTypeTemp === '01'">
            <el-select v-model="ruleForm.invoiceType" placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="开票名称:" prop="invoiceTitle">
          <div class="item-div">
            <el-input v-model="ruleForm.invoiceTitle" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="纳税人识别号:" prop="taxpayerIdNumber">
          <div class="item-div">
            <el-input v-model="ruleForm.taxpayerIdNumber"/>
          </div>
        </el-form-item>
        <el-form-item label="开户行名称:" prop="bankOfDeposit">
          <div class="item-div">
            <el-input v-model="ruleForm.bankOfDeposit"/>
          </div>
        </el-form-item>
        <el-form-item label="开户行账号:" prop="accountNumber">
          <div class="item-div">
            <el-input v-model="ruleForm.accountNumber"/>
          </div>
        </el-form-item>
        <el-form-item label="开票地址:" prop="registeredAddress">
          <div class="item-div">
            <el-input v-model="ruleForm.registeredAddress"/>
          </div>
        </el-form-item>
        <el-form-item label="电话:" prop="registeredTel">
          <div class="item-div">
            <el-input v-model="ruleForm.registeredTel"/>
          </div>
        </el-form-item>
        <el-form-item label="邮箱:" prop="email" >
          <div class="item-div">
            <el-input v-model="ruleForm.email"/>
          </div>
        </el-form-item>
        <el-form-item label="邮寄省:" prop="invoiceProvince">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceProvince" placeholder="请选择" @change="provinceChanged">
              <el-option
                :key="''"
                :label="'全部'"
                :value="''">
              </el-option>
              <el-option
                v-for="item in provinces"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="邮寄市:" prop="invoiceCity">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceCity"
                       :loading="loadingCity"
                       placeholder="请选择">
              <el-option
                :key="''"
                :label="'全部'"
                :value="''">
              </el-option>
              <el-option
                v-for="item in cities"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="邮寄地址:" prop="toAddress">
          <div class="item-div">
            <el-input v-model="ruleForm.toAddress"/>
          </div>
        </el-form-item>
        <el-form-item label="联系人:" prop="invoiceLinkMan">
          <div class="item-div">
            <el-input v-model="ruleForm.invoiceLinkMan"/>
          </div>
        </el-form-item>
        <el-form-item label="联系人手机:" prop="telephone">
          <div class="item-div">
            <el-input v-model="ruleForm.telephone"/>
          </div>
        </el-form-item>
      </el-form>

      <el-form
        :model="ruleForm"
        v-if="ruleForm.invoiceType === '01'"
        :rules="rules2"
        ref="ruleForm"
        label-width="128px">
        <el-form-item label="抬头类型:" prop="invoiceTitleType">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceTitleType" placeholder="抬头类型">
              <el-option label="企业" value="02"/>
            </el-select>
          </div>
        </el-form-item>
<!--        <el-form-item label="发票内容:" prop="invoiceContent">-->
<!--          <div class="invoice-content item-div" style="cursor: pointer">-->
<!--            <div class="btn" :class="{ active: active }" @click="switchActive('商品明细', true)">-->
<!--              <span>商品明细</span>-->
<!--            </div>-->
<!--            <div class="btn" style="margin-left: 15px" :class="{ active: !active }" @click="switchActive('商品类别', false)">-->
<!--              <span>商品类别</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-form-item>-->
        <el-form-item label="是否为一般纳税人:" prop="isPayTaxes">
          <el-select v-model="ruleForm.isPayTaxes" disabled placeholder="是否为一般纳税人">
            <el-option label="否" value="1"/>
            <el-option label="是" value="0"/>
          </el-select>
        </el-form-item>
        <el-form-item label="所需发票种类:" prop="invoiceType">
          <div class="item-div" v-if="this.invoiceTypeTemp === '04'">
            <el-select v-model="ruleForm.invoiceType" placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
              <el-option label="增值税专用发票" value="04"/>
            </el-select>
          </div>
          <div class="item-div" v-if="this.invoiceTypeTemp === '03'">
            <el-select v-model="ruleForm.invoiceType" placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
              <el-option label="纸质普票" value="03"/>
            </el-select>
          </div>
          <div class="item-div" v-if="this.invoiceTypeTemp === '01'">
            <el-select v-model="ruleForm.invoiceType" placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="开票名称:" prop="invoiceTitle">
          <div class="item-div">
            <el-input v-model="ruleForm.invoiceTitle" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="纳税人识别号:" prop="taxpayerIdNumber">
          <div class="item-div">
            <el-input v-model="ruleForm.taxpayerIdNumber"/>
          </div>
        </el-form-item>
        <el-form-item label="开户行名称:" prop="bankOfDeposit">
          <div class="item-div">
            <el-input v-model="ruleForm.bankOfDeposit"/>
          </div>
        </el-form-item>
        <el-form-item label="开户行账号:" prop="accountNumber">
          <div class="item-div">
            <el-input v-model="ruleForm.accountNumber"/>
          </div>
        </el-form-item>
        <el-form-item label="开票地址:" prop="registeredAddress">
          <div class="item-div">
            <el-input v-model="ruleForm.registeredAddress"/>
          </div>
        </el-form-item>
        <el-form-item label="电话:" prop="registeredTel">
          <div class="item-div">
            <el-input v-model="ruleForm.registeredTel"/>
          </div>
        </el-form-item>
        <el-form-item label="邮箱:" prop="email" >
          <div class="item-div">
            <el-input v-model="ruleForm.email"/>
          </div>
        </el-form-item>
        <el-form-item label="邮寄省:" prop="invoiceProvince">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceProvince" placeholder="请选择" @change="provinceChanged">
              <el-option
                :key="''"
                :label="'全部'"
                :value="''">
              </el-option>
              <el-option
                v-for="item in provinces"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="邮寄市:" prop="invoiceCity">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceCity"
                       :loading="loadingCity"
                       placeholder="请选择">
              <el-option
                :key="''"
                :label="'全部'"
                :value="''">
              </el-option>
              <el-option
                v-for="item in cities"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="邮寄地址:" prop="toAddress">
          <div class="item-div">
            <el-input v-model="ruleForm.toAddress"/>
          </div>
        </el-form-item>
        <el-form-item label="联系人:" prop="invoiceLinkMan">
          <div class="item-div">
            <el-input v-model="ruleForm.invoiceLinkMan"/>
          </div>
        </el-form-item>
        <el-form-item label="联系人手机:" prop="telephone">
          <div class="item-div">
            <el-input v-model="ruleForm.telephone"/>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="modal-btn2 active mg-auto" v-if="edit" @click="addSubmit" style="cursor: pointer">
      <span>保存发票信息</span>
    </div>
  </div>
</template>

<script>
import { add, update } from '@/api/personCenter/customerInvoice'
import provice from '@/api/json/city.json'
import { mapGetters } from 'vuex'
export default {
  props: {
    edit: Boolean,
    ruleForm: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['currentCustom'])
  },
  watch: {
    'ruleForm.invoiceType': function (value) {
      if (this.init) {
        this.init = false
        this.invoiceTypeTemp = value
      }
    },
    'ruleForm.invoiceProvince': function (value) {
      this.provinceChanged(value)
    }
  },
  created () {
    this.invoiceTypeTemp = this.ruleForm.invoiceType
    this.provinces = provice.provinces
  },
  methods: {
    provinceChanged (value) {
      this.ruleForm.invoiceCity = ''
      if (value !== '') {
        this.loadingCity = true
        for (var item of this.provinces) {
          if (item.code === value) {
            this.cities = item.cities
            if (this.cities && this.cities.length > 0) {
              this.ruleForm.invoiceCity = this.cities[0].code
            }
            this.loadingCity = false
            break
          }
        }
      } else {
        this.cities = []
      }
    },
    switchActive (text, active) {
      this.ruleForm.invoiceContent = text
      this.active = active
    },
    addSubmit () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.edit) {
            this.ruleForm.invoiceContent = '商品明细'
            update(this.ruleForm).then(() => {
              // this.$message({ type: 'success', message: '操作成功!' })
              this.$emit('closeModal4', true)
            }, () => {
              this.$notify({
                title: '错误',
                message: '操作失败',
                type: 'error',
                duration: 2000
              })
            })
          } else {
            this.ruleForm.invoiceContent = '商品明细'
            this.ruleForm.customerId = this.currentCustom.customerId
            this.ruleForm.customerName = this.currentCustom.customerName
            add(this.ruleForm).then(() => {
              // this.$message({ type: 'success', message: '操作成功!' })
              this.$emit('closeModal4', true)
            }, () => {
              this.$notify({
                title: '错误',
                message: '操作失败',
                type: 'error',
                duration: 2000
              })
            })
          }
        } else {
          return false
        }
      })
    }
  },
  mounted () {
    this.active = (this.ruleForm.invoiceContent === '商品明细')
  },
  data () {
    return {
      init: true,
      invoiceTypeTemp: '01',
      active: true,
      provinces: [],
      cities: [],
      loadingCity: false,
      rules: {
        invoiceType: [
          { required: true, message: '请选择所需发票种类', trigger: 'change' }
        ],
        invoiceTitleType: [
          { required: true, message: '请选择抬头类型', trigger: 'change' }
        ],
        invoiceTitle: [
          { required: true, message: '请输入开票名称', trigger: 'change' }
        ],
        taxpayerIdNumber: [
          { required: true, message: '请输入纳税人识别号', trigger: 'change' }
        ],
        bankOfDeposit: [
          { required: true, message: '请输入开户行名称', trigger: 'change' }
        ],
        accountNumber: [
          { required: true, pattern: /^[1-9][0-9]{8,30}$/, message: '格式不正确,最少8位,最多30位', trigger: 'change' }
        ],
        registeredAddress: [
          { required: true, message: '请输入开票地址', trigger: 'change' }
        ],
        // registeredTel: [
        //   { required: true, pattern: /^1[0-9]{10}$/, message: '格式不正确', trigger: 'change' }
        // ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'change' }
        ],
        invoiceProvince: [
          { required: true, message: '请选择邮寄省', trigger: 'change' }
        ],
        invoiceCity: [
          { required: true, message: '请选择邮寄市', trigger: 'change' }
        ],
        toAddress: [
          { required: true, message: '请输入地址', trigger: 'change' }
        ],
        invoiceLinkMan: [
          { required: true, message: '请输入联系人', trigger: 'change' }
        ],
        telephone: [
          { required: true, message: '请输入联系人手机', trigger: 'change' }
        ]
      },
      rules1: {
        invoiceType: [
          { required: true, message: '请选择所需发票种类', trigger: 'change' }
        ],
        invoiceTitleType: [
          { required: true, message: '请选择抬头类型', trigger: 'change' }
        ],
        invoiceTitle: [
          { required: true, message: '请输入开票名称', trigger: 'change' }
        ],
        taxpayerIdNumber: [
          { required: true, message: '请输入纳税人识别号', trigger: 'change' }
        ],
        bankOfDeposit: [
          { required: false, message: '请输入开户行名称', trigger: 'change' }
        ],
        accountNumber: [
          { required: false, pattern: /^[1-9][0-9]{8,30}$/, message: '格式不正确,最少8位,最多30位', trigger: 'change' }
        ],
        registeredAddress: [
          { required: false, message: '请输入开票地址', trigger: 'change' }
        ],
        // registeredTel: [
        //   { required: false, pattern: /^1[0-9]{10}$/, message: '格式不正确', trigger: 'change' }
        // ],
        email: [
          { required: false, message: '请输入邮箱', trigger: 'change' }
        ],
        invoiceProvince: [
          { required: true, message: '请选择邮寄省', trigger: 'change' }
        ],
        invoiceCity: [
          { required: true, message: '请选择邮寄市', trigger: 'change' }
        ],
        toAddress: [
          { required: true, message: '请输入地址', trigger: 'change' }
        ],
        invoiceLinkMan: [
          { required: true, message: '请输入联系人', trigger: 'change' }
        ],
        telephone: [
          { required: true, message: '请输入联系人手机', trigger: 'change' }
        ]
      },
      rules2: {
        invoiceType: [
          { required: true, message: '请选择所需发票种类', trigger: 'change' }
        ],
        invoiceTitleType: [
          { required: true, message: '请选择抬头类型', trigger: 'change' }
        ],
        invoiceTitle: [
          { required: true, message: '请输入开票名称', trigger: 'change' }
        ],
        taxpayerIdNumber: [
          { required: true, message: '请输入纳税人识别号', trigger: 'change' }
        ],
        bankOfDeposit: [
          { required: false, message: '请输入开户行名称', trigger: 'change' }
        ],
        accountNumber: [
          { required: false, pattern: /^[1-9][0-9]{8,30}$/, message: '格式不正确,最少8位,最多30位', trigger: 'change' }
        ],
        registeredAddress: [
          { required: false, message: '请输入开票地址', trigger: 'change' }
        ],
        // registeredTel: [
        //   { required: false, pattern: /^1[0-9]{10}$/, message: '格式不正确', trigger: 'change' }
        // ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'change' }
        ],
        invoiceProvince: [
          { required: true, message: '请选择邮寄省', trigger: 'change' }
        ],
        invoiceCity: [
          { required: true, message: '请选择邮寄市', trigger: 'change' }
        ],
        toAddress: [
          { required: true, message: '请输入地址', trigger: 'change' }
        ],
        invoiceLinkMan: [
          { required: true, message: '请输入联系人', trigger: 'change' }
        ],
        telephone: [
          { required: true, message: '请输入联系人手机', trigger: 'change' }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item__label{
  width: 115px;
}
.get-addrrss {
  padding: 40px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-div {
    margin-left: 80px;
}
.item-div {
  width: 342px;
  position: relative;
  display: flex;
}
.mg-auto {
    margin: 30px auto;
}
.invoice-content {
  display: flex;
  align-items: center;
  .btn {
    width: 166px;
    height: 40px;
    border: 1px solid #E1E1E1;
    border-radius: 2px;
    line-height: 40px;
    text-align: center;
    color: #333333;
    font-size: 14px;
    &.active {
      border-color: #ef922e;
      color: #ef922e;
    }
  }
}
</style>
