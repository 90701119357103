<template>
  <div class="detail">
    <!-- 搜索层 -->
    <search-part></search-part>
    <bread-crumbs :breadcrumbList="breadCrumbs"></bread-crumbs>

    <detailCard v-if="toGo === '1'" :goodsDet="goodsDet" :specifications="specifications" :infoPic="infoPic" :coupons="coupons" :goodsId="this.$route.query.id"></detailCard>
    <detailCardToCom v-if="toGo === '2'" :goodsDet="goodsDet" :specifications="specifications" :infoPic="infoPic" :coupons="coupons" :goodsId="this.$route.query.id"></detailCardToCom>
    <detailCardToComFilm v-if="toGo === '3'" :goodsDet="goodsDet" :specifications="specifications" :infoPic="infoPic" :coupons="coupons" :goodsId="this.$route.query.id"></detailCardToComFilm>
    <detailCardToFilm v-if="toGo === '4'" :goodsDet="goodsDet" :specifications="specifications" :infoPic="infoPic" :coupons="coupons" :goodsId="this.$route.query.id"></detailCardToFilm>
    <detailDis :goosRecommendedation="goosRecommendedation" :infoPic="infoPic" :goodsDetId="goodsDet.id" :goodsInfoBelowDetails="goodsInfoBelowDetails"></detailDis>
  </div>
</template>
<script>
import searchPart from '@/components/searchPart'
import breadCrumbs from '@/components/breadCrumbs'
import detailCard from './components/detailCard'
import detailCardToCom from './components/detailCardToCom'
import detailCardToComFilm from './components/detailCardToComFilm'
import detailCardToFilm from './components/detailCardToFilm'
import detailDis from './components/detailDis'
import { goosRecommendedationAjaxList, goodsInfoDetail, goodsInfoBelowDetail, goodsCoupons } from '@/api/goods/goodsInfo/index'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['detailId', 'currentCustom'])
  },
  components: {
    searchPart,
    breadCrumbs,
    detailDis,
    detailCard,
    detailCardToCom,
    detailCardToComFilm,
    detailCardToFilm
  },
  data () {
    return {
      goodsInfoBelowDetails: {},
      goodsInfo: {
        goodsLabel: [],
        goodsCategory: [],
        cearchKey: []
      },
      toGo: '1',
      goosRecommendedation: [
        // {
        //   id: 'id',
        //   commodityName: '',
        //   salePrice: 0.00,
        //   mainGraph: ''
        // }
      ],
      res: [],
      labelPam: [
        {
          id: 'id',
          labelName: '',
          labelColor: ''
        }
      ],
      id: '',
      infoPic: [],
      coupons: [],
      goodsAttrJson: [],
      goodsDet:
        {
          id: 'id',
          commodityName: '',
          salePrice: 0.00,
          mainGraph: '',
          photoAlbum: '',
          attribute1: '',
          referencePrice: 0.00,
          productLabel: '',
          keys: '',
          type: 1,
          commodityClass: ''
        },
      specifications: [],
      breadCrumbs: [{
        label: '首页',
        url: '/home'
      },
      {
        label: this.$route.query.commodityName
      }]
    }
  },
  methods: {
    goodsDetail (id) {
      this.$router.push({ // 调转到商品搜索页面
        path: '/detail',
        query: {
          id: id
        }
      })
    },
    onLoad () { // 初始化加载推荐,商品详情,商品图片
      document.title = `犀牛MALL-${this.$route.query.commodityName}-详情`
      this.$store.commit('SET_BREADCRUMB', this.breadCrumbs)
      this.id = this.$route.query.id
      var customerId = this.currentCustom.customerId
      goodsInfoDetail(this.id, customerId).then(res => {
        res.data.data.infoVo.type = 1
        this.goodsDet = res.data.data.infoVo
        // 价格格式化
        this.goodsDet.salePrice = parseFloat(res.data.data.infoVo.salePrice).toFixed(2)
        this.goodsDet.referencePrice = parseFloat(res.data.data.infoVo.referencePrice).toFixed(2)
        // 优惠券
        // this.coupons = res.data.data.coupons
        this.infoPic = res.data.data.infoPics
        this.specifications = res.data.data.specifications
        // 商品详情和规格信息
        // 商品规格信息
        // this.goodsAttrJson = res.data.data.goodsAttrJson
        // 商品类别
        this.getRecommendedation(this.goodsDet.commodityClass, this.goodsDet.relationKeys)
        this.clieckGo()
      })
      // 商品规格参数展示
      goodsInfoBelowDetail(this.id).then(res => {
        this.goodsInfoBelowDetails = res.data.data
      })
      // 商品优惠券
      goodsCoupons(this.id, customerId).then(res => {
        this.coupons = res.data.data
      })
    },
    getRecommendedation (commodityClass, relationKeys) {
      this.goodsInfo.goodsCategory = JSON.parse(commodityClass)
      this.goodsInfo.relationKey = JSON.parse(relationKeys)
      goosRecommendedationAjaxList(this.goodsInfo).then(res => {
        this.goosRecommendedation = res.data.data
      })
    },
    // 校验 普通商品详情页  组合商品详情页 还有影厅类商品
    clieckGo () {
      // 组合商品
      debugger
      if (this.goodsDet.isCompose === '01') {
        // if (this.goodsDet.isAuth === null || this.goodsDet.isAuth === undefined || this.goodsDet.isAuth === '' || this.goodsDet.isAuth === '03') {
        if (this.goodsDet.isAuthProduct === '01' || this.goodsDet.isAuthService === '01' || this.goodsDet.isAuth === '01') {
          this.toGo = '3'
        } else {
          // 非组合商品 影厅类卖品
          this.toGo = '2'
        }
      } else {
        // 非组合商品 普通商品
        // if (this.goodsDet.isAuth === null || this.goodsDet.isAuth === undefined || this.goodsDet.isAuth === '' || this.goodsDet.isAuth === '03') {
        if (this.goodsDet.isAuthProduct === '01' || this.goodsDet.isAuthService === '01' || this.goodsDet.isAuth === '01') {
          this.toGo = '4'
        } else {
          // 非组合商品 影厅类卖品
          this.toGo = '1'
        }
      }
      console.log(this.toGo + '页面分录')
    }
  },
  mounted () {
    this.onLoad()
  }
}
</script>

<style lang="scss" scoped>
.detail {
  width: 100%;
  overflow: hidden;
}
</style>
