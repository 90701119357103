<template>
  <div class="box display_column">
    <div class="header all_width display_flex bg_fff"><img src="../../assets/login_logo.png" alt="" /></div>
    <div class="content display_column">
      <div class="all_width">
        <img class="all_width" src="../../assets/login_bg.jpg" alt="" />
        <div class="login_box bg_fff">
          <div class="com_width">
            <div class="wel">欢迎登录</div>
            <label>
              <input type="text" v-model="form.username" placeholder="用户名/手机号" />
            </label>
            <label>
              <input type="password" v-model="form.password" placeholder="密码" />
            </label>
            <div class="btn" @click="login">登录</div>
            <div class="bot_div display_flex space_between">
              <p class="display_flex"><span></span>记住我</p>
              <p>忘记密码</p>
            </div>
            <div class="bot_div display_flex space_between">
<!--              <p @click="goPerson" class="cursor">个人注册</p>-->
              <p @click="goCompany" class="cursor">企业注册</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        //  租户ID
        tenantId: '000000',
        //  用户名
        username: '',
        //  密码
        password: '',
        //  账户类型
        type: 'password',
        //  验证码的值
        code: '',
        //  验证码的索引
        key: '',
        //  预加载白色背景
        image: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
      }
    }
  },
  methods: {
    login () {
      this.$store.dispatch('Login', this.form).then(res => {
        this.$router.push('/home')
      })
    },
    goPerson () {
      this.$router.push('/personRegister')
    },
    goCompany () {
      this.$router.push('/companyRegister')
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  .header {
    height: 100px;
    justify-content: flex-start;
    img {
      width: 188px;
      height: 43px;
    }
  }
  .content {
    width: 100%;

    background: #f83c46;
    .all_width {
      img {
        height: 558px;
      }
      position: relative;
      .login_box {
        border-radius: 6px;
        width: 405px;
        height: 464px;
        background: #ffffff;
        position: absolute;
        z-index: 999;
        right: 0;
        top: 49px;
        box-sizing: border-box;
        padding: 47px 5px 100px 5px;
        .wel {
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
          text-align: left;
          border-bottom: 1px solid #eeeeee;
          padding-bottom: 10px;
          margin-bottom: 30px;
        }
        input {
          box-sizing: border-box;
          width: 100%;
          display: block;
          margin-top: 30px;
          height: 48px;
          background: #ffffff;
          border: 1px solid #e5e5e5;
          border-radius: 4px;
          padding-left: 20px;
        }
        .btn {
          width: 100%;
          height: 48px;
          line-height: 48px;
          text-align: center;
          background: #ef922e;
          border-radius: 4px;
          color: #fff;
          margin-top: 30px;
          cursor: pointer;
        }
        .bot_div {
          margin-top: 30px;
          color: #333;
          font-size: 15px;
          span{
            width: 14px;
            height: 14px;
            border: 1px solid #ef922e;
            border-radius: 3px;
            // flex:1;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
