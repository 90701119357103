<template>
  <div class="user">
      <!-- <span>{{userInfo.operatorName}} ｜ <a @click="goOrder">我的订单</a></span> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['breadcrumbList', 'userInfo', 'customInfos', 'currentCustom'])
  },
  methods: {
    goOrder () {
      this.$router.push({ // 调转到商品搜索页面
        path: '/myOwnOrder'
        // query: {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user {
    display: flex;
    color: #333333;
    font-size: 14px;
}
</style>
