import request from '@/router/axios'

export const getCinemas = (customerId) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/getCinemas',
    method: 'POST',
    params: {
      customerId
    }
  })
}

export const findInvoiceDetail = (id) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/findInvoiceDetail',
    method: 'GET',
    params: {
      id
    }
  })
}

export const remove = (id) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/doDeleteInvoice',
    method: 'post',
    params: {
      id
    }
  })
}

export const add = (row) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/doInsertInvoice',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: row
  })
}

export const editCustomAndCinemaInfo = (row) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/editCustomAndCinemaInfo',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/doUpdateInvoice',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: row
  })
}

export const findOneGoodsInfoId = () => {
  return request({
    url: '/cineplex/homePagePersonalCenter/findOneGoodsInfoId',
    method: 'post',
    headers: { 'Content-Type': 'application/json' }
  })
}

export const findCinemaByType = (type) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/findCinemaByType',
    method: 'post',
    params: {
      type
    }
  })
}
