<template>
<!-- 定位2 -->
  <div class="special-card-item" :style="{'background-color': backgroundColor}">
      <div class="special-card-top" @click="goodsDetail(item.id, item.commodityName)">
          <img v-lazy="item.mainGraph" alt=""> <!-- :src="mainGraph" -->
      </div>
      <div class="special-card-center" :title="item.commodityName">
          <span class="overflow_two">{{ item.commodityName }}</span>
      </div>
      <div style="width: 80%;font-size:12px;" class="special-card-tips overflow">
          <span>{{ item.sellingPoints }}</span>
      </div>
      <div class="special-card-bottom">
          <div class="half left">
              <span>￥{{(item.salePrice).toFixed(2)}}</span>
          </div>
          <div class="half delete">
              <span>￥{{(item.referencePrice).toFixed(2)}}</span>
          </div>
      </div>
    <div class="buy-card-add">
                <!--<div class="item left">
                     <div class="icon">
                        <img src="@/assets/car.png" alt="">
                    </div>
                    <div class="text">
                        <span>加入购物车</span>
                    </div>
                </div>-->
                <div class="item right" @click="addGoodsCard">
                    <!-- <div>
                        <span>立即购买</span>
                    </div> -->
                  <div class="icon">
                    <img src="@/assets/car.png" alt="">
                  </div>
                  <div class="text" style="margin-left: 4px">
                    <span>加入购物车</span>
                  </div>
                </div>
    </div>
  </div>
</template>
<script>
import { mergeGoodsCart } from '@/views/myCar/components/goodsCar.js'
import { hits } from '@/api/hits'
export default {
  props: {
    item: {
      type: Object
    },
    /* name: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: ''
    },
    salePrice: {
      type: Number,
      default: 0.00
    },
    referencePrice: {
      type: Number,
      default: 0.00
    },
    mainGraph: {
      type: String,
      default: ''
    }, */
    backgroundColor: {
      type: String,
      default: '#ffffff'
    }
  },
  methods: {
    addGoodsCard () {
      mergeGoodsCart(this.item.id).then(res => {
        this.$emit('upGoodsCar')
        this.backToTop()
      })
    },
    goodsDetail (id, commodityName) {
      hits({
        type: '01',
        goodsId: id,
        goodsName: commodityName,
        sourceSection: '04'
      })
      var blan = this.$router.resolve({ // 调整到详情界面
        path: '/detail',
        query: {
          id: id,
          commodityName: commodityName,
          url: '/myCar',
          keys: '猜你喜欢'
        }
      })
      window.open(blan.href, '_blank')
    },
    backToTop () {
      this.$nextTick(() => {
        // debugger
        var top = this.$parent.$parent.$parent.$parent.$parent.$el.scrollTop
        const timeTop = setInterval(() => {
          this.$parent.$parent.$parent.$parent.$parent.$el.scrollTop = top -= 50
          if (top <= 0) {
            clearInterval(timeTop)
          }
        }, 10)
      })
    }
  }
}
</script>

<style lang="scss">
  .buy-card-add {
    width:45%;
    margin-top: 10px;
    position: relative;
    height: 28px;
    display: flex;
    align-items:center;
    justify-content:center;
    border: 1px solid #E1E1E1;
    margin-left: 120px;
    .item {
      cursor: pointer;
      width:100%;
      display: flex;
      justify-content:center;
      align-items: center;
      font-size: 15px;
      height: 100%;
      &.left {
        border-right: 1px solid #E1E1E1;
        color: #666666;
        .icon {
          margin-right: 8px;
          height: 17px;
        }
      }
      &.right {
        color: #ef922e;
      }
    }
  }
.special-card-item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .special-card-top {
      padding-top: 30px;
        img {
            display: block;
            width: 209px;
            height: 209px !important;
        }
    }
    .special-card-center {
      width:100%;
      height:40px;
      word-wrap:break-word;
      // white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
      overflow: hidden;
        font-size: 13px;
        color: #333;
        margin-top: 30px;
        span{
          display:inline-block;
          height:100%;
          width: 80%;
          line-height: 20px;
        }
    }
    .special-card-tips {
      color: #999999;
      padding-top: 10px;
    }
    .special-card-bottom {
        width: 90%;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content:center;
        position: relative;
        .half {
            &.left {
                color: #F30012;
                font-size: 14px;
                margin-right: 9px;
            }
            &.delete {
                font-size:12px;
                color: #999999;
                text-decoration:line-through;
            }
        }
    }
}
</style>
