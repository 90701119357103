<template>
  <prove/>
</template>

<script>
import prove from './components/prove'
export default {
  components: {
    prove
  },
  data () {
    return {
      breadCrumbs: [{
        label: '首页'
      }, {
        label: '个人中心'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
