<template>
  <div class="search-content">
    <div class="search-inner" style="position:relative;">
      <div class="search-left" style="cursor: pointer;position:relative;" @click="$router.push({ path: '/home' })">
        <img src="@/assets/login_logo.png" alt="logo">
      </div>
      <!--<div class="search-car link" style="position:absolute;top:65px;" @click="showJgcz()">
        <div class="icon">
          <img style="width: 189px;" src="@/assets/jgcz.jpg" alt="car">
        </div>
      </div>-->
      <div class="search-center">
        <div class="search-input">
          <div class="search-top">
            <searchInput @search="search"/>
            <div class="search-car link" @click="showAudit()">
              <div class="icon">
                <img src="@/assets/car.png" alt="car">
              </div>
              <div class="text">
                <span>我的购物车</span>
              </div>
              <div class="number">
                <div class="top" v-if="goodsNum !== 0">
                  <!--                  <span>{{goodsNum}}+</span>-->
                  <span v-if="goodsNum <= 99">{{ goodsNum }}</span><span v-if="goodsNum > 99">99+</span>
                </div>
              </div>
            </div>
            <div class="search-car link" @click="showJgcz()">
              <div class="icon">
                <img src="@/assets/img/jgcz.png" alt="jgcz">
              </div>
              <div class="text">
                <span>激光充值</span>
              </div>
              <!--<div class="number">
                <div class="top" v-if="goodsNum !== 0">
                  &lt;!&ndash;                  <span>{{goodsNum}}+</span>&ndash;&gt;
                  <span v-if="goodsNum <= 99">{{ goodsNum }}</span><span v-if="goodsNum > 99">99+</span>
                </div>
              </div>-->
            </div>
          </div>
          <div class="search-tips">
            <span style="white-space:pre"><a @click="keywordSearchY('休闲食品')">休闲食品</a> | <a @click="keywordSearchY('3D')">3D</a> | <a @click="keywordSearchY('放映机')">放映机</a> | <a @click="keywordSearchY('滤网')">滤网</a></span>
          </div>
        </div>
        <div class="search-ad">
          <!--<div v-for="(item,index) in labelVos" :key="index">
                  <div :class="[{ 'search-ad-span special' :false},{ 'search-ad-span': false}]" :style="{ color: item.labelColor }" style="cursor: pointer;padding:0 20px 10px 0;font-weight: 700;font-size: 16px" @click="keywordSearch(item.id)">{{ item.labelName }}</div>
                </div>-->
          <div class="showLabel" v-for="(item,index) in labelVosList" :key="index">
            <span v-if="lunarKnotId || item.labelName !== '合同商品'" :style="{ color: item.labelColor }" @click="keywordSearch(item.id, item.labelName)">{{ item.labelName }}</span>
          </div>
          <!-- <div class="search-ad-span special">秒杀</div>
                  <div class="search-ad-span special">中影会员</div>
                  <div class="search-ad-span">新人活动</div>
                  <div class="search-ad-span">品牌福利</div>
                  <div class="search-ad-span">优惠购</div> -->
        </div>
      </div>
      <!--<div class="search-right" style="margin-right:20px;">
        <div class="search-right-top">
          <img src="@/assets/zhu.jpg" style="width:87px;display:inline-block;margin-right:20px;" alt="logo">
          <img src="@/assets/403_weixin.jpg" style="width:87px;display:inline-block" alt="logo">
        </div>
        <div class="search-right-bottom" style="margin-top:-2px;">
          <span>如需注册,请扫上面客服二维码咨询</span>
        </div>
      </div>-->
      <div class="search-right">
        <div class="search-right-top">
          <img src="@/assets/qr.png" style="width:87px" alt="logo">
        </div>
        <div class="search-right-bottom">
          <span>扫码，领福利</span>
        </div>
        <div class="position_absolute">
          <div style="margin-top: 10px;">
            <div class="search-right-top">
              <img src="@/assets/img/logo.jpg" width="450px" alt="logo">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchInput from '@/components/searchInput'
import { shouCartNum, checkIsLunarKnot } from '@/views/myCar/components/goodsCar.js'
import { mapGetters } from 'vuex'
import { jgczUrl } from '@/config/env'
export default {
  computed: {
    ...mapGetters(['goodsCarNum', 'cartList', 'currentCustom'])
  },
  components: {
    searchInput
  },
  props: {
    labelVosList: Array
  },
  watch: {
    goodsCarNum (newNum) {
      this.goodsNum = newNum
    }
  },
  data () {
    return {
      keyword: '',
      lunarKnotId: '',
      goodsNum: 0
    }
  },
  methods: {
    showAudit () {
      this.$router.push({ // 调整到详情界面
        path: '/myCar'
        // query: {id: row.id}
      })
    },
    showJgcz () {
      var mpurl = jgczUrl
      // var mpurl = 'https://uc.cineappo.com/cas/login?service=https://recharge.cineappo.com/'// 生产 'https://self.cineappo.com'
      // var mpurl = 'http://139.219.0.117:6050/cas/login?service=http://218.241.209.227:8087/'// 测试 'https://self.cineappo.com'
      window.open(mpurl, '_blank')
    },
    keywordSearch (id, name) {
      this.$router.push({ // 调转到商品搜索页面
        path: '/search',
        query: {
          productLabel: id,
          extName: name,
          lunarKnotId: this.lunarKnotId,
          flag: '01'
        }
      })
    },
    search (keyword) {
      this.$emit('search', keyword)
    },
    keywordSearchY (keyword) {
      // if (!keyword) {
      //   keyword = '酷薯'
      // }
      this.$store.commit('SET_SEARCH_KEYWORD', keyword)
      // this.$store.commit('SET_SEARCH_GOODS_ID', '')
      // if (this.$route.path === '/search') {
      //   this.$router.go(0)
      // } else {
      //   this.$router.push({ path: 'search' })
      // }
      this.$router.push({
        // 调转到商品搜索页面
        path: '/search',
        query: {
          commodityClass: 1005,
          extName: '影院卖品',
          flag: '01'
        }
      })
    },
    onLoad () {
      shouCartNum().then(res => {
        this.goodsNum = res.data.data
      })
      checkIsLunarKnot(this.currentCustom.customerId).then(res => {
        if (res.data.data.id) {
          this.lunarKnotId = res.data.data.id
        }
      })
    }
  },
  created () {
    if (this.currentCustom.customerId !== undefined && this.currentCustom.customerId !== null && this.currentCustom.customerId.length > 0) {
      this.onLoad()
    }
  }
}
</script>

<style lang="scss" scoped>
  .search-content {
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    background-color: white;
    .search-right{
      position: relative;
      &:hover {
          .position_absolute {
            display: block;
          }
        }
      .position_absolute{
        display: none;
        background: #fff;
        z-index:999;
        position: absolute;
        top: 103px;
        // padding: 0 10px;
        border-radius: 6px;
        left: -115px;
        /*right: -62%;*/
      }
    }

    .search-inner {
      width: 1226px;
      padding: 20px 0 13px 0;
      display: flex;
      align-items: flex-start;

      .search-left {
        display: flex;
        align-items: center;

        img {
        }
      }

      .search-center {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 16px;

        .search-input {
          .search-top {
            display: flex;
            align-items: center;

            .search-car {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 155px;
              height: 40px;
              background: #ffffff;
              border: 1px solid #e1e1e1;
              margin-left: 20px;

              .text {
                padding-left: 7px;
                padding-right: 2px;
                color: #ef922e;
              }

              .number {
                height: 90%;

                .top {
                  background: #ef922e;
                  border-radius: 3px;
                  font-size: 10px;
                  color: #ffffff;
                  padding: 0 1px;
                }
              }
            }
          }
        }

        .search-tips {
          font-size: 12px;
          color: #999999;
          text-align: left;
          margin-top: 9px;
          /*a {*/
          /*  float: left;*/
          /*  white-space: nowrap;*/
          /*}*/
          a:hover {
            color: #ef922e;
          }
        }

        .search-ad {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-top: 20px;
          // margin-left: -35px;
          //font-size: 14px;
          // justify-content: space-between;
          .search-ad-span {
            color: #333;
            // margin:0 10px 10px 0;
            &.special {
              color: #e1251b;
            }
          }

          .showLabel {
            // margin-top: -30px;
            font-size: 16px;
            padding-right: 20px;

            span {
              font-weight: 400;
            }

            span:hover {
              cursor: pointer;
              color: #ef922e;
            }
          }
        }
      }

      .search-right-top {
        img {
          display: block;
          box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.14);
        }
      }

      .search-right-bottom {
        color: #666666;
        font-size: 12px;
        text-align: center;
        padding-top: 4px;
      }
    }
  }

  .link:hover,
  .server-table i.el-tooltip:hover {
    cursor: pointer;
    color: #409eff;
  }
</style>
