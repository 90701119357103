<template>
  <div class="orderList" v-loading="isShowLoding">
    <div class="title">
      <span>发票信息</span>
    </div>
    <div class="sub-title">
      <span :class="{h1: true, orange: lastInvoiceInfoStat === '0'}"><a class="span1" @click="jump('0','invoiceApply')">开票申请</a></span>
      <span :class="{h2: true, orange: lastInvoiceInfoStat === '01'}"><a class="span1" @click="jump('01','')">申请中</a></span>
      <span :class="{h2: true, orange: lastInvoiceInfoStat === '04'}"><a class="span1" @click="jump('04','')">异常申请</a></span>
      <span :class="{h2: true, orange: lastInvoiceInfoStat === '02'}"><a class="span1" @click="jump('02','')">已开票</a></span>
      <span :class="{h2: true, orange: lastInvoiceInfoStat === '03'}"><a class="span1" @click="jump('03','')">全部发票</a></span>
      <span :class="{h1: true, orange: lastInvoiceInfoStat === '5'}"><a class="span1" @click="jump('5','customerInvoice')">开票账号管理</a></span>
    </div>
    <div class="table mg-top-20">
      <el-table :data="tableData" ref="table" v-loading="loading" :empty-text="emptyText" style="width: 100%" header-row-class-name="gray-bg">
        <el-table-column type="index" label="序号" :index="indexMethod" width="50"/>
        <el-table-column prop="invoiceType" label="发票类型" width="125"> </el-table-column>
        <el-table-column prop="invoiceAmt" label="发票金额"> </el-table-column>
        <el-table-column prop="accept" label="接收地址" width="150"> </el-table-column>
        <el-table-column prop="exDate1" label="开票时间" width="160"> </el-table-column>
        <el-table-column prop="invoiceTime" label="申请时间" width="160"> </el-table-column>
        <el-table-column prop="view" label="操作" width="160">
          <template v-slot="scope">
            <span v-if="scope.row.invoiceStatus === '已开具'" class="view-span" @click="dowLodnRow(scope.row)"><a>下载</a></span>
            <span v-if="scope.row.invoiceStatus === '已开具'" class="view-span" @click="downfile(scope.row)"><a>批量下载</a></span>
            <span v-if="scope.row.invoiceStatus !== '已开具'" class="view-span" @click="detail(scope.row.id)"><a>查看</a></span>
            <span v-if="scope.row.invoiceStatus === '已邮寄' && scope.row.logisticsOrderNo" class="view-span" @click="showModal3(scope.row.logisticsOrderNo)"><a>物流路由</a></span>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceStatus" label="状态"> </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :layout="pageInfo.layout"
        :total="pageInfo.total"
        :page-size="pageInfo.pageSize"
        :current-page.sync="pageInfo.pageNo">
      </el-pagination>
    </div>
    <el-dialog
      title="发票附件下载"
      :visible.sync="isShowInvoice"
      width="30%"> <!-- :before-close="handleClose" -->
      <el-table :data="invoiceTable">
        <el-table-column property="url" label="电子发票地址" width="450">
          <template slot-scope="scope">
            <span>{{scope.row}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <span class="view-span" v-show="scope.row.includes('type=0') || scope.row.includes('fileSno')" @click="lookPic(scope.row)"><a>下载pdf</a></span>
            <span class="view-span" v-show="scope.row.includes('type=1')" @click="lookPic(scope.row)"><a>下载ofd</a></span>
            <span class="view-span" v-show="scope.row.includes('type=3')" @click="lookPic(scope.row)"><a>下载xml</a></span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
    <el-button @click="isShowInvoice = false">关 闭</el-button>
    <!--<el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
  </span>
    </el-dialog>
    <el-dialog title="物流跟踪" :visible.sync="visible3"  :close-on-click-modal="false" :modal-append-to-body='false' :append-to-body='true'>
      <template>
        <div class="wlgz">
          <div class="text">
            <!-- <p>您的订单预计2020-11-20送达您手中</p>-->
          </div>
          <div class="timeline-wrap">
            <timeline>
              <timeline-item
                v-for="logistics in logisticsRoutingVos"
                :key="logistics.id"
                :timestamp="logistics.routingNodeOccurrenceTime"
                :timestamp-position="logistics.timestampPosition"
                :color="logistics.color"
                :size='20'
                :icon="logistics.icon"
                :image="require('@/assets/ship1.png')"
              >
                <div slot="content">
                  <timelineItemTemp :content="logistics.routingNodeDesc" :title="logistics.routingNodeDesc" />
                </div>
              </timeline-item>
            </timeline>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ajaxList } from '@/api/personCenter/invoiceInfo'
import { getLogisticsList } from '@/api/order/orderInfo/index'
import timeline from '@/components/timeline/timeline'
import timelineItem from '@/components/timeline/timelineItem'
import timelineItemTemp from '@/components/timeline/timelineItemTemp'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['currentStat', 'lastInvoiceInfoStat', 'currentCustom'])
  },
  components: {
    timeline,
    timelineItem,
    timelineItemTemp
  },
  methods: {
    downfile (row) {
      this.isShowLoding = true
      var blogTitle = 'invoiceFiles.zip' // 下载后压缩包的命名
      const zip = new JSZip()
      var promises = []
      var cache = {}
      var arrImg = []
      if (row.multipleAttachments) {
        var files = row.multipleAttachments.split(',')
        if (files && files.length > 1) {
          files.forEach(item => {
            var arrName = item.split('=')
            arrImg.push({
              path: item, // 文件链接
              name: arrName[arrName.length - 1] + '.pdf' // 文件名称
            })
          })
        } else {
          if (row.multipleAttachments.includes('fileSno')) {
            var arrName = row.multipleAttachments.split('=')
            arrImg.push({
              path: row.multipleAttachments, // 文件链接
              name: arrName[arrName.length - 1] + '.pdf' // 文件名称
            })
          } else if (row.multipleAttachments.includes('type=0')) {
            var arrNameArr = row.multipleAttachments.split('?')
            var fileNameArr = arrNameArr[0].split('/')
            arrImg.push({
              path: row.multipleAttachments, // 文件链接
              name: fileNameArr[fileNameArr.length - 1] + '.pdf' // 文件名称
            })
          }
        }
      }
      if (row.multipleAttachmentsOfd) {
        var fileName = row.multipleAttachmentsOfd.split('?')
        var fileNameAr = fileName[0].split('/')
        arrImg.push({
          path: row.multipleAttachmentsOfd, // 文件链接
          name: fileNameAr[fileNameAr.length - 1] + '.ofd' // 文件名称
        })
      }
      if (row.multipleAttachmentsXml) {
        var name = row.multipleAttachmentsXml.split('?')
        var fileNameA = name[0].split('/')
        arrImg.push({
          path: row.multipleAttachmentsXml, // 文件链接
          name: fileNameA[fileNameA.length - 1] + '.zip' // 文件名称
        })
      }
      arrImg.forEach(item => {
        const promise = this.getImgArrayBuffer(item.path).then(data => {
          // 下载文件, 并存成ArrayBuffer对象(blob)
          zip.file(item.name, data, { binary: true }) // 逐个添加文件
          cache[item.name] = data
        })
        promises.push(promise)
      })
      Promise.all(promises)
        .then(bin => {
          zip
            .generateAsync({
              type: 'blob'
            })
            .then(content => {
              // 生成二进制流
              saveAs(content, blogTitle) // 利用file-saver保存文件  自定义文件名
              this.isShowLoding = false
            })
        })
        .catch(res => {
          // alert("文件压缩失败");
          this.isShowLoding = false
          this.$message({
            showClose: true,
            message: '文件压缩失败!',
            type: 'error',
            ccuiCustom: true,
            duration: 0
          })
        })
    },
    async getFilenameFromUrl (url) {
      return new Promise((resolve, reject) => {
        const urlObj = new URL(url)
        // 获取路径组件并分割以获取文件名
        const pathComponents = urlObj.pathname.split('/')
        // 获取最后一个组件作为文件名（可能需要处理特殊情况，例如以斜杠结束的路径）
        debugger
        resolve(pathComponents[pathComponents.length - 1])
      })
    },
    getImgArrayBuffer (url) {
      return new Promise((resolve, reject) => {
        // 通过请求获取文件blob格式
        var xmlhttp = new XMLHttpRequest()
        xmlhttp.open('GET', url, true)
        xmlhttp.responseType = 'blob'
        xmlhttp.headers = {
          'content-type': 'application/json;charset=utf-8'
        }
        xmlhttp.onload = function () {
          if (this.status === 200) {
            resolve(this.response)
          } else {
            reject(this.status)
          }
        }
        xmlhttp.send()
      })
    },
    dowLodnRow (row) {
      this.invoiceTable = []
      if (row.multipleAttachments && row.multipleAttachments.split(',').length > 1) {
        this.invoiceTable = row.multipleAttachments.split(',')
        this.isShowInvoice = true
      } else if (row.multipleAttachmentsOfd && row.multipleAttachmentsXml) {
        this.invoiceTable.push(row.multipleAttachments)
        this.invoiceTable.push(row.multipleAttachmentsOfd)
        this.invoiceTable.push(row.multipleAttachmentsXml)
        this.isShowInvoice = true
      } else {
        this.lookPic(row.multipleAttachments)
      }
    },
    lookPic (url) {
      if (url && url.split(',').length > 1) {
        this.invoiceTable = url.split(',')
        this.isShowInvoice = true
      } else {
        window.open(url, '_blank')
      }
    },
    indexMethod (index) {
      return index + 1
    },
    jump (stat, url) {
      if (stat !== '0' && stat !== '5') {
        this.pageInfo.pageNo = 1
        this.$store.commit('SET_LAST_INVOICE_INFO_STAT', stat)
      }
      this.$store.commit('SET_CURRENT_STAT', stat)
      this.params.type = stat
      if (url) {
        this.$router.push({ name: url, params: { type: stat } })
      }
    },
    handleSizeChange (val) {
      this.pageInfo.pageNo = 1
      this.pageInfo.pageSize = val
      // 获取记录
      this.ajaxList(this.pageInfo, this.params)
    },
    handleCurrentChange (val) {
      this.pageInfo.pageNo = val
      // 获取记录
      this.ajaxList(this.pageInfo, this.params)
    },
    ajaxList (pageInfo, params) {
      // this.loading = true
      // var load = this.$loading()
      ajaxList(pageInfo.pageNo, pageInfo.pageSize, Object.assign({ customerId: this.currentCustom.customerId, customerName: this.currentCustom.customerName }, params)).then((res) => {
        // this.loading = false
        // load.close()
        this.tableData = res.data.data
        this.pageInfo.total = res.data.count
      })
    },
    // 查询物流信息
    showModal3 (logisticsNo) {
      if (logisticsNo === null || logisticsNo === undefined || logisticsNo === '' || logisticsNo === ' ') {
        this.$notify({
          title: '物流提示',
          message: '暂时没有物流信息',
          type: 'warning',
          duration: 3000
        })
      } else {
        getLogisticsList(logisticsNo).then(res => {
          this.logisticsRoutingVos = res.data.data
        })
        this.visible3 = true
      }
    },
    detail (id) {
      this.$router.push({ name: 'invoiceOperate', params: { id: id, editShow: true } })
    }
  },
  watch: {
    'params.type': function (value) {
      if (['01', '02', '03', '04'].includes(value)) {
        this.ajaxList(this.pageInfo, this.params)
      }
    }
  },
  created () {
    if (this.$route.params.type) {
      if (this.$route.params.type !== '0' && this.$route.params.type !== '5') {
        this.$store.commit('SET_LAST_INVOICE_INFO_STAT', this.$route.params.type)
      }
      this.$store.commit('SET_CURRENT_STAT', this.$route.params.type)
      this.params.type = this.$route.params.type
    } else {
      this.params.type = this.lastInvoiceInfoStat
    }
    this.ajaxList(this.pageInfo, this.params)
  },
  data () {
    return {
      emptyText: '空空如也,赶紧去消费吧',
      loading: false,
      invoiceTable: [],
      isShowInvoice: false,
      isShowLoding: false,
      visible3: false,
      pageInfo: {
        layout: 'prev, pager, next',
        total: 0,
        pageSize: 5,
        pageNo: 0
      },
      logisticsRoutingVos: [{
        size: '20',
        timestampPosition: 'left',
        routingNodeOccurrenceTime: '',
        image: require('@/assets/ship1.png'),
        routingNodeDesc: '',
        routingNodeAddress: ''
      }
      ],
      params: { type: '01' },
      tableData: []
    }
  }
}
</script>

<style lang="scss" scoped>
.span1:hover {
  color: #ef922e;
  cursor: pointer;
}
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 110px 40px;
  .title {
    color: #333333;
    font-size: 24px;
  }
  .sub-title {
    margin-top: 30px;
    span {
      margin-right: 20px;
    }
    .h1 {
      color: #333333;
      font-size: 16px;
    }
    .h2 {
      font-size: 14px;
      color: #333333;
    }
    .orange {
      color: #ef922e;
    }
  }
  .page {
    margin-top: 35px;
    display: flex;
    justify-content: center;
  }
}
</style>
<style lang="scss">
.orderList .view-span {
  padding-right: 10px;
  color: #ef922e;
  text-decoration: underline;
}
</style>
