<template>
  <div class="orderList">
    <div class="title">
      <div class="left">
        <!--        <span>我的优惠券</span>-->
        <span class="changeCoupon" :class="{ juan: fullMinus === true }" @click="changeCoupon(1)">满减券</span><span class="changeCoupon" :class="{ juan: allowance === true }" @click="changeCoupon(2)" style="margin-left: 30px;">代金券</span>
      </div>
    </div>
    <div class="list" v-if="fullMinus">
      <span class="span1" :class="{ juan: stat === '01' }" @click="jumpFullMinus('01')"><a>可用满减券 </a></span>
      <span class="span1" :class="{ juan: stat === '02' }" @click="jumpFullMinus('02')"><a>最新优惠</a></span>
      <span class="span1" :class="{ juan: stat === '03' }" @click="jumpFullMinus('03')"><a>即将过期</a></span>
      <span class="span1" :class="{ juan: stat === '04' }" @click="jumpFullMinus('04')"><a>已过期</a></span>
    </div>
    <div class="list" v-if="allowance">
      <span class="span1" :class="{ juan: stat === '01' }" @click="jumpAllowance('01')"><a>可用代金券 </a></span>
      <span class="span1" :class="{ juan: stat === '02' }" @click="jumpAllowance('02')"><a>最新优惠</a></span>
      <span class="span1" :class="{ juan: stat === '03' }" @click="jumpAllowance('03')"><a>即将过期</a></span>
      <span class="span1" :class="{ juan: stat === '04' }" @click="jumpAllowance('04')"><a>已过期</a></span>
    </div>
    <div v-for="(v,index) in data" :key="index">
      <div class="card-list mg-top-20" style="margin-top: 14px;">
        <couponItem v-for="(y,yIndex) in v.children" :key="yIndex" :allowance="allowance" :bean="y" :disabled="'01'===y.isExpire || stat === '04'"/>
      </div>
    </div>
  </div>
</template>

<script>
import couponItem from './couponItem'
import { ajaxList } from '@/api/personCenter/mycoupon'
import { mapGetters } from 'vuex'
export default {
  components: {
    couponItem
  },
  computed: {
    ...mapGetters(['currentCustom'])
  },
  data () {
    return {
      stat: '01',
      data: [],
      couponType: '01',
      // 满减
      fullMinus: true,
      // 代金
      allowance: false
    }
  },
  created () {
    this.ajaxList()
  },
  methods: {
    // 满减券
    jumpFullMinus (stat) {
      this.stat = stat
      this.couponType = '01'
      this.ajaxList()
    },
    // 代金券
    jumpAllowance (stat) {
      this.stat = stat
      this.couponType = '02'
      this.ajaxList()
    },
    ajaxList () {
      this.data = []
      ajaxList({ type: this.stat, customerId: this.currentCustom.customerId, customerName: this.currentCustom.customerName, couponType: this.couponType }).then((res) => {
        this.data = res.data.data
      })
    },
    changeCoupon (index) {
      this.data = []
      if (index === 1) {
        this.jumpFullMinus('01')
        this.allowance = false
        this.fullMinus = true
      } else {
        this.jumpAllowance('01')
        this.allowance = true
        this.fullMinus = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.span1:hover {
  cursor: pointer;
  color: #ef922e;
}
.changeCoupon:hover {
  cursor: pointer;
  color: #ef922e;
}
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 38px 40px;
  //height: 593px;
  box-sizing: border-box;
  .title {
    color: #333333;
    font-size: 24px;
  }
  .juan {
    color: #ef922e
  }
  .list {
    margin-top: 35px;
    color: #333333;
    font-size: 14px;
    span {
      margin-right: 25px;
    }
  }
  .card-list {
    // display: flex;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(4, 218px);
    grid-column-gap: 14px;
    // grid-row-gap: 14px;
  }
}
</style>
