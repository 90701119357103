<template>
  <div class="orderList" v-if="msgDetail === '01'">
    <div class="title">
      <div class="left">
        <span>消息通知</span>
      </div>
      <div class="div-1">
        <div class="left">
          <div :class="[{ 'all' : (idx == '01')},{ 'get': (idx != '01')}]">
            <span @click="notifyStatusList('')" class="orderStatus_stl">全部消息</span>
          </div>
          <div style="position:relative;" :class="[{ 'all' : (idx == '02')},{ 'get': (idx != '02')}]">
            <span @click="notifyStatusList('03')" class="orderStatus_stl">未读消息</span>
            <div style="position:absolute;right:-17px;top:-16px;" v-if="queryNotReadCount !== 0" class="top">
              <span style="user-select: none;display:inline-block;padding:1px 2px; background:#ef922e;border-radius:3px;font-size:10px;color: white;"><span v-if="queryNotReadCount <= 99">{{ queryNotReadCount }}</span><span v-if="queryNotReadCount > 99">99+</span></span>
            </div>
          </div>
          <div :class="[{ 'not-get-all' : (idx == '03')},{ 'not-get': (idx != '03')}]">
            <span @click="notifyStatusList('02')" class="orderStatus_stl">已读消息</span>
          </div>
        </div>
      </div>
      <div class="div-2">
        <div class="son-1">
          <div class="son-1-1">
            <el-dropdown>
            <span class="el-dropdown-link" style="margin-left: 20px">
              {{ value1 }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in dateList" :key="item.pam">
                  <a @click="dateSearch(item.pam,item.rate)">{{ item.rate }}</a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="tableList" v-for="item in notifyInfoList" :key="item.id"><!-- v-for="item in notifyInfoList" :key="item" -->
      <div class="tableItem">
        <div class="tableLeft">
          <img src="@/assets/3.png">
        </div>
        <div class="tableInfo">
          <div class="infoTop">
            <span class="infoTopSpan" title='嘿嘿'><!--峰狂惠聚High购节！-->{{ item.msgTitle }}</span>
          </div>
          <div class="infoBot">
            <span class="infoBotSpan">
              <!--HIGH购物即享超优惠！HIGH购物即享超优惠！HIGH购物即享超优惠！HIGH购物即享超优惠！HIGH购物即享超优惠！HIGH购物即享超优惠！HIGH购物即享超优惠！HIGH购物即享超优惠！HIGH购物即享超优惠！HIGH购物即享超优惠！-->
              {{ item.msgContent }}
            </span>
          </div>
        </div>
        <div class="tableRight">
          <span class="tableRightSpan"><!--2021-01-07 18:30-->{{ item.sendTime }}</span>
          <a class="tableRightA" @click="jumpDetail(item)">「查看详情」</a>
        </div>
      </div>
    </div>
    <!--<div class="mg-top-1 block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-size="this.pageSizes"
        layout="total, prev, pager, next, jumper"
        :total="this.totalNum">
      </el-pagination>
    </div>-->
    <div class="page">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="this.totalNum"
        :page-size="this.pageSizes"
        :current-page.sync="currentPage1">
      </el-pagination>
    </div>
  </div>
  <notifyDetail :idx="idx" :msgInfo="msgInfo" @upUsemd="upUsemd" :msgDetail="msgDetail" v-else-if="msgDetail === '02'"/>
</template>
<script>
import { mapGetters } from 'vuex'
import NotifyDetail from '@/views/personCenter/notify/components/notifyDetail'
import { updateMsgInfo } from '@/api/personCenter/notify/notify'
export default {
  computed: {
    ...mapGetters(['currentCustom'])
  },
  components: {
    NotifyDetail
  },
  props: {
    queryNotReadCount: {
      type: Number,
      default: 0
    },
    notifyInfoList: {
      type: Array
    },
    totalNum: {
      type: Number
    }
  },
  data () {
    return {
      pam: {
        pageNo: 1,
        pageSize: 15,
        userId: '',
        readStatus: '',
        dateNumber: ''
      },
      idx: '01',
      pageSizes: 15,
      currentPage1: 1,
      value1: '全部消息',
      msgInfo: {},
      dateList: [
        {
          rate: '全部消息',
          pam: 0
        },
        {
          rate: '近一周消息',
          pam: 6
        },
        {
          rate: '近一个月消息',
          pam: 30
        }
      ],
      msgDetail: '01'
    }
  },
  methods: {
    upUsemd (i) {
      this.msgDetail = i
    },
    jumpDetail (pam) {
      // window.open('', '_self')
      /* var blan = this.$router.push({ // 调整到详情界面
        path: '/notify'
         query: {
           id: id
        }
      })
      // window.open(blan.href, '_self') */
      var userId = this.currentCustom.customerId
      updateMsgInfo(pam, userId).then(res => {
        this.msgDetail = '02'
        this.msgInfo = res.data.data
      })
    },
    handleSizeChange (page) {
      this.pam.pageNo = page
      this.pam.pageSize = page
      // 获取记录
      this.$parent.onLoad(this.pam)
    },
    handleCurrentChange (page) {
      this.pam.pageNo = page
      this.pam.userId = this.currentCustom.customerId
      this.$parent.onLoad(this.pam)
    },
    /* handleCurrentChange (page) {
      this.pam.pageNo = page
      this.pam.userId = this.currentCustom.customerId
      this.$parent.onLoad(this.pam)
      console.log('当前页' + this.pam.pageNo)
    }, */
    dateSearch (pams, pamName) {
      this.value1 = pamName
      this.pam.userId = this.currentCustom.customerId
      // alert(pams)
      this.pam.dateNumber = this.geshji(pams)
      this.$parent.onLoad(this.pam)
    },
    notifyStatusList (readStatus) { // 调用上级方法
      switch (readStatus) {
        case '': // '01'全部消息 '02'未读消息 '03'已读消息
          this.idx = '01'
          this.currentPage1 = 1
          this.pam.pageNo = 1
          break
        case '02':
          this.idx = '03'
          this.currentPage1 = 1
          this.pam.pageNo = 1
          break
        case '03':
          this.idx = '02'
          this.currentPage1 = 1
          this.pam.pageNo = 1
          break
        default:
          this.idx = '01'
          this.currentPage1 = 1
          this.pam.pageNo = 1
          break
      }
      /* //逻辑不对 多条件 最好用switch case
      if (readStatus !== '') {
        if (readStatus === '03') {
          this.idx = '02'
        } else {
          this.idx = readStatus
        }
      } else {
        this.idx = '01'
      } */
      // 7天
      /* var tragetTime = new Date()
      tragetTime.setDate(tragetTime.getDate() - this.dateList.pam)
      var st = tragetTime.getFullYear() + '-' + (tragetTime.getMonth() > 9 ? (tragetTime.getMonth() + 1) : '0' + (tragetTime.getMonth() + 1)) + '-' + (tragetTime.getDate() > 9 ? (tragetTime.getDate()) : '0' + (tragetTime.getDate()))
      debugger */
      this.pam.userId = this.currentCustom.customerId
      this.pam.readStatus = readStatus
      // this.pam.dateNumber = st
      this.$parent.onLoad(this.pam)
    },
    // 格式化日期 为 年-月-日
    geshji (i) {
      if (i != null && i !== '' && i > 0) {
        var tragetTime = new Date()
        tragetTime.setDate(tragetTime.getDate() - i)
        var st = tragetTime.getFullYear() + '-' + (tragetTime.getMonth() > 9 ? (tragetTime.getMonth() + 1) : '0' + (tragetTime.getMonth() + 1)) + '-' + (tragetTime.getDate() > 9 ? (tragetTime.getDate()) : '0' + (tragetTime.getDate()))
        return st
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/**/
.page {
  margin-top: 35px;
  display: flex;
  justify-content: center;
}
.el-dropdown-link {
  cursor: pointer;
  color: #111111;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #111111;
  font-size: 14px;
  margin-bottom: 20px;
}
/**/
.orderStatus_stl:hover {
  cursor: pointer;
  color: #ef922e;
}
.mg-top-1 {
  margin-bottom: 15px;
}
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 110px 40px;
  .tableList{
    width:100%;
    .tableItem{
      width:100%;
      height: 50px;
      border-bottom:3px solid #eee;
      display:flex;
      margin: 25px 0;
      .tableLeft{
        width:50px;
        height:50px;
        //background:pink;
      }
      .tableInfo{
        flex:1;
        //background:lightblue;
        display:flex;
        flex-direction: column;
        .infoTop,.infoBot{
          //border:1px solid #eee;
          flex:1;
          .infoTopSpan {
            line-height: 25px;
            font-weight: 600;
            font-size:15px;
          }
          .infoBotSpan {
            display: inline-block;
            width: 680px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #666666;
            font-size: 14px;
          }
        }
      }
      .tableRight{
        width:150px;
        height:100%;
        //background:#eee;
        .tableRightSpan {
          display: inline-block;
          margin: 5px 0 0 10px;
        }
        .tableRightA {
          display: inline-block;
          color: #ef922e;
          margin: 12px 0 0 35px;
          text-decoration: none;
        }
      }
    }
  }
  .title {
    color: #333333;
    font-size: 24px;
  }
  .div-1 {
    display: flex;
    justify-content: space-between;
    align-items:center;
    margin-top: 27px;
    .left {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 16px;
      .all {
        color: #ef922e;
        margin-right: 40px;
      }
      .get {
        margin-right: 40px;
      }
      .not-get {
        margin-right: 40px;
        position: relative;
        .top {
          background: #ef922e;
          border-radius: 3px;
          font-size: 10px;
          color: #ffffff;
          padding: 0 1px;
          position: absolute;
          right: -20px;
          top: -12px;
        }
      }
      .not-get-all {
        color: #ef922e;
        margin-right: 40px;
        position: relative;
        .top {
          background: #ef922e;
          border-radius: 3px;
          font-size: 10px;
          color: #ffffff;
          padding: 0 1px;
          position: absolute;
          right: -20px;
          top: -12px;
        }
      }
      .sperate {
        width: 1px;
        height: 19px;
        background-color: #dcd9d9;
        margin-right: 40px;
      }
      .delete {
        color: #999999;
        font-size: 14px;
      }
    }
    .right {
      width: 260px;
      height: 32px;
    }
  }
  .div-2 {
    margin-top: 20px;
    height: 40px;
    background: #F5F5F5;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #333333;
    font-size: 14px;
    .son-1 {
      display: flex;
      align-items: center;
      flex: 1;
      .son-1-1 {
        display: flex;
        align-items: center;
        margin-left: 20px;
        .son-1-1-2 {
          padding-left: 10px;
          height: 17px;
        }
      }
      .son-1-2 {
        margin-left: 190px;
      }
    }
    .son-2 {
      width: 160px;
    }
    .son-3 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      .son-3-2 {
        padding-left: 10px;
        height: 17px;
      }
    }
  }
}
</style>

<style lang="scss">
.block {
  position: absolute;
  //float: right;
  //margin-right: 50px;
}
.orderList {
  position: relative;
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
</style>
