<template>
  <div class="contentArea">
    <div v-if="GoodsCart.goodsList.length > 0">
      <tableCar :tableData="GoodsCart.goodsList"></tableCar>
      <operateArea :num="GoodsCart.num" :totalAmont="goodsTaotal.totalAmont" :number="goodsTaotal.number"></operateArea>
    </div>
    <div v-else class="no_shop">
      <img src="../../../assets/none_car.png" alt="" />
      <div class="none">
        <p>您的购物车还是空的！</p>
        <div @click="go_home">去选购</div>
      </div>
    </div>
    <guessLike @upGoodsCar="onLoad"></guessLike>
  </div>
</template>

<script>
import tableCar from './tableCar'
import operateArea from './operateArea'
import guessLike from './guessLike'
import { mapGetters } from 'vuex'
import store from '@/store'
import { shouShoppingCart, delShoppingCart, reduceShoppingCart, reduceShoppingCart2, findId, checkGoodsOnLunarKnot, getDictVal } from '@/views/myCar/components/goodsCar.js'
export default {
  computed: {
    ...mapGetters(['currentCustom'])
  },
  components: {
    tableCar,
    guessLike,
    operateArea
  },
  data () {
    return {
      GoodsCart: { goodsList: [] },
      submitCart: {
        orderNo: '',
        submitList: []
      },
      /* GoodsCart: {
        id: '',
        customerId: '',
        num: 3,
        goodsList: [
          {
            goodsId: '',
            specId: '',
            commodityName: '华为P30',
            commoditySubTitle: '高贵水晶蓝,天使之恋,64G',
            mainGraph: 'https://ask-fd.zol-img.com.cn/t_s800x800/g5/M00/08/0B/ChMkJlwSBkyIBPAVAADV2YizTgsAAtw4QFLaH4AANXx999.jpg',
            salePrice: 3500.76,
            number: 1,
            Subtotal: 3500.76,
            goodsInfoStatus: '01'
          },
          {
            goodsId: '',
            specId: '',
            commodityName: 'iphone12',
            commoditySubTitle: '土豪金极限版,128G',
            mainGraph: 'http://5b0988e595225.cdn.sohucs.com/images/20191212/5d278a43615c45799197a021b7b89c6d.jpeg',
            salePrice: 12000,
            number: 2,
            Subtotal: 24000,
            goodsInfoStatus: '02'
          }
        ]
      }, */
      goodsTaotal: {
        totalAmont: 0.00,
        number: 0
      }
    }
  },
  methods: {
    go_home () {
      this.$router.push({
        path: '/home'
      })
    },
    asd (tableList) {
      this.submitCart.submitList = tableList
      // eslint-disable-next-line no-unused-vars
      var totalAmont = 0.00
      // eslint-disable-next-line no-unused-vars
      var num2 = 0
      tableList.forEach(ele => {
        totalAmont = totalAmont + parseFloat(ele.Subtotal)
        num2 = num2 + parseInt(ele.number)
      })
      this.goodsTaotal.totalAmont = parseFloat(totalAmont).toFixed(2)
      this.goodsTaotal.number = parseInt(num2)
    },
    // 添加或减少数量
    reduceShoppingCart (index) {
      var catMap = this.GoodsCart.goodsList[index]
      catMap.Subtotal = (catMap.salePrice * catMap.number).toFixed(2)
      this.GoodsCart.goodsList[index] = catMap
      this.upCardNum()
      /* if (code === 0) { // +
        this.upda(catMap.specId, 1, 0)
      } else if (code === 1) { // -
        this.upda(catMap.specId, 1, 1)
      } */
      this.upda2()
    },
    upCardNum () {
      var GoodsNumb = 0
      var Goods = this.GoodsCart.goodsList
      Goods.forEach(ele => {
        if (ele.goodsInfoStatus !== '02') {
          GoodsNumb = GoodsNumb + parseFloat(ele.number)
        }
      })
      this.GoodsCart.num = GoodsNumb
    },
    del (i) {
      var params = this.GoodsCart.goodsList[i].specId
      delShoppingCart(params).then(res => {
        this.GoodsCart.goodsList.splice(i, 1)
        this.upCardNum()
      })
    },
    upda (goodsId, num, code) {
      reduceShoppingCart(goodsId, num, code).then(res => {
        this.GoodsCart = res.data.data
        var dataArr = this.GoodsCart.goodsList
        for (var i = 0; i < dataArr.length; i++) {
          this.GoodsCart.goodsList[i].copyNum = this.GoodsCart.goodsList[i].number
          this.GoodsCart.goodsList[i].operate = ''
        }
      })
    },
    upda2 () {
      reduceShoppingCart2(this.GoodsCart).then(res => {
      })
    },
    onLoad () {
      var customerId = store.getters.currentCustom.customerId
      shouShoppingCart(customerId).then(res => {
        this.GoodsCart = res.data.data
        var dataArr = this.GoodsCart.goodsList
        for (var i = 0; i < dataArr.length; i++) {
          this.GoodsCart.goodsList[i].copyNum = this.GoodsCart.goodsList[i].number
          this.GoodsCart.goodsList[i].operate = ''
          this.GoodsCart.goodsList[i].salePrice = parseFloat(dataArr[i].salePrice).toFixed(2)
          this.GoodsCart.goodsList[i].Subtotal = parseFloat(dataArr[i].Subtotal).toFixed(2)
        }
      })
    },
    submitJieSuan () {
      debugger
      if (this.submitCart.submitList.length > 0) {
        var subMinBuyNum = []
        for (var o = 0; o < this.submitCart.submitList.length; o++) {
          var a = 0
          for (var j = 0; j < subMinBuyNum.length; j++) {
            if (subMinBuyNum[j].goodsId === this.submitCart.submitList[o].goodsId) {
              a++
              subMinBuyNum[j].number = subMinBuyNum[j].number + this.submitCart.submitList[o].number
              break
            }
          }
          if (a === 0) {
            var item = {
              goodsId: this.submitCart.submitList[o].goodsId,
              minBuyNum: this.submitCart.submitList[o].minBuyNum,
              number: this.submitCart.submitList[o].number,
              commodityName: this.submitCart.submitList[o].commodityName
            }
            subMinBuyNum.push(item)
          }
        }
        for (var i = 0; i < subMinBuyNum.length; i++) {
          if (subMinBuyNum[i].minBuyNum && subMinBuyNum[i].number < subMinBuyNum[i].minBuyNum) {
            this.$notify({
              title: '警告',
              message: '非常抱歉，您选择的商品' + subMinBuyNum[i].commodityName + '，最小购买数量为' + subMinBuyNum[i].minBuyNum + '，请加购',
              type: 'warning',
              duration: 2000
            })
            return false
          }
        }
        var params = {
          json: this.submitCart,
          customerId: this.currentCustom.customerId
        }
        // 先校验规格是否需要组合购买
        getDictVal(this.submitCart).then(res => {
          if (res.data.code === 0 && res.data.data.code === '1') {
            checkGoodsOnLunarKnot(params).then(res => {
              debugger
              var code = res.data.data.code
              var isMonthlyPayment = res.data.data.isMonthlyPayment
              if (code) {
                /* this.$notify({
                  title: '警告',
                  message: res.data.data.msg,
                  type: 'warning',
                  duration: 2000
                }) */
                this.$alert(res.data.data.msg, '提示')
                return false
              } else {
                if (isMonthlyPayment && isMonthlyPayment === '1') {
                  this.submitCart.isMonthlyPayment = isMonthlyPayment
                } else {
                  this.submitCart.isMonthlyPayment = '0'
                }
                findId().then(res => {
                  this.submitCart.orderNo = res.data.data
                  this.$store.commit('SET_CART_LIST', this.submitCart)
                  this.$router.push({ // 调整到详情界面
                    name: 'pay'
                  })
                })
              }
            })
          } else if (res.data.code === 0 && res.data.data.code === '3') {
            this.$notify({
              title: '警告',
              message: '您购买的GDC服务器需硬件和软件配套下单!',
              type: 'warning',
              duration: 2000
            })
            return false
          } else if (res.data.code === 0 && res.data.data.code === '2') {
            this.$notify({
              title: '警告',
              message: '您购买的GDC服务器硬件和软件数量不一致，需要配套下单!',
              type: 'warning',
              duration: 2000
            })
            return false
          }
        })

        /* this.$router.push({
          name:"admin",
          // 这里的params是一个对象，id是属性名，item.id是值(可以从当前组件或者Vue实例上直接取)
          params:{id:item.id}
        }) */
        /* this.$router.push({
          name:"/admin/${item.id}",
        }) */
      } else {
        // this.$alert('请先勾选商品', '提示')
        this.$notify({
          title: '提示',
          message: '请先勾选商品',
          type: 'warning',
          duration: 2000
        })
      }
    }
  },
  created () {
    this.onLoad()
  }
}
</script>

<style lang="scss">
.contentArea {
  width: 100%;
  .no_shop {
    padding: 70px 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    img {
      width: 182px;
      height: 151px;
    }
    .none {
      height: 90px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      margin-left: 40px;
      p {
        font-size: 22px;
        font-weight: 500;
        color: #999999;
        line-height: 60px;
      }
      div {
        width: 94px;
        height: 24px;
        line-height: 24px;
        border: 1px solid #ef922e;
        color: #ef922e;
        font-size: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>
