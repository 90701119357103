import request from '@/router/axios'

/**
 * 查询购物车
 * @returns {AxiosPromise}
 */
export const shouShoppingCart = (customerId) => {
  return request({
    url: '/cineplex/homePageGoods/shouShoppingCart',
    method: 'post',
    params: {
      customerId: customerId
    }
  })
}

/**
 * 添加商品到购物车
 * @returns {AxiosPromise}
 */
export const mergeShoppingCart = (params) => {
  return request({
    url: '/cineplex/homePageGoods/mergeShoppingCart',
    method: 'post',
    params: {
      ...params
    }
  })
}

/**
 * 删除商品到购物车
 * @param params
 * @returns {AxiosPromise}
 */
export const delShoppingCart = (params) => {
  return request({
    url: '/cineplex/homePageGoods/delShoppingCart',
    method: 'post',
    params: {
      goodsId: params
    }
  })
}

/**
 * 修改购物车
 * @param params
 * @returns {AxiosPromise}
 */
export const reduceShoppingCart = (goodsId, num, code) => {
  return request({
    url: '/cineplex/homePageGoods/reduceShoppingCart',
    method: 'post',
    params: {
      goodsId: goodsId,
      num: num,
      code: code
    }
  })
}

/**
 * 修改购物车
 * @param params
 * @returns {AxiosPromise}
 */
export const reduceShoppingCart2 = (goodsCar) => {
  return request({
    url: '/cineplex/homePageGoods/reduceShoppingCart2',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: goodsCar
  })
}

/**
 * 查询登录人信息
 * @param params
 * @returns {AxiosPromise}
 */
export const findInfo = (customerId) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/findInfo',
    method: 'post',
    params: {
      customerId: customerId
    }
  })
}

/**
 * 查询协议范本
 * @param params
 * @returns {AxiosPromise}
 */
export const getElectronicCommerceAgreement = () => {
  return request({
    url: '/cineplex/homePagePersonalCenter/getElectronicCommerceAgreement',
    method: 'post'
  })
}

/**
 * 获取一个新Id
 * @param params
 * @returns {AxiosPromise}
 */
export const findId = () => {
  return request({
    url: '/cineplex/homePagePersonalCenter/findId',
    method: 'post'
  })
}

/**
 * 获取一个新Id
 * @param params
 * @returns {AxiosPromise}
 */
export const clearCart = () => {
  return request({
    url: '/cineplex/homePageGoods/clearCart',
    method: 'post'
  })
}

/**
 * 获取一个猜你喜欢参数
 * @param params
 * @returns {AxiosPromise}
 */
export const goodsCondition = () => {
  return request({
    url: '/cineplex/homePageGoods/goodsCondition',
    method: 'get'
  })
}

/**
 * 获取一个猜你喜欢参数
 * @param params
 * @returns {AxiosPromise}
 */
export const shouCartNum = () => {
  return request({
    url: '/cineplex/homePageGoods/shouCartNum',
    method: 'get'
  })
}

/**
 * 校验需要购买的商品是否月结
 * @param params
 * @returns {AxiosPromise}
 */
export const checkGoodsOnLunarKnot = (data) => {
  return request({
    url: '/cineplex/homePageGoods/checkGoodsOnLunarKnot',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: data
  })
}

/**
 * 获取一个猜你喜欢参数
 * @param params
 * @returns {AxiosPromise}
 */
export const mergeGoodsCart = (goodsId) => {
  return request({
    url: '/cineplex/homePageGoods/mergeGoodsCart',
    method: 'post',
    params: {
      goodsId: goodsId
    }
  })
}

/**
 * 获取一个猜你喜欢参数
 * @param params
 * @returns {AxiosPromise}
 */
export const saveStayUser = (stayUser) => {
  return request({
    url: '/cineplex/homePage/saveStayUser',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: stayUser
  })
}

export const getDictVal = (stayUser) => {
  return request({
    url: '/cineplex/homePageGoods/getDictVal',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: stayUser
  })
}

export const checkIsLunarKnot = (customerId) => {
  return request({
    url: '/cineplex/homePage/checkIsLunarKnot',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    params: {
      customerId: customerId
    }
  })
}
