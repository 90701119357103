<template>
  <div class="orderList">
    <div class="title">
      <div class="left">
        公司信息
      </div>
    </div>
    <hr class="fenge"/>
    <div class="list">
      <!--<p>所属公司名称：{{ companyMes.customerName }}</p>
      <p>操作员1：{{ companyMes.operatorName }}</p>
      <p>操作员2：{{ companyMes.operatorName }}</p>
      <p>操作员3：{{ companyMes.operatorName }}</p>-->
      <div class="qiye fontColor">企业名称： {{ customerCompany.businessName }}</div>
      <div class="yingname fontColor">影院名称： {{ customerCompany.customerName }}</div>
      <div class="yingaddress fontColor">影院地址： {{ customerCompany.address }}</div>
      <div class="yingcode fontColor">影院编码： {{ customerCompany.customerCode }}</div>
      <div class="yuanxian fontColor">所属院线： {{ customerCompany.theatreChainName }}</div>
    </div>
    <div class="two">
    <div class="title">
      <div class="left">
        联系信息
      </div>
    </div>
    <hr class="fenge"/>
    <div class="list">
      <!--<p>所属公司名称：{{ companyMes.customerName }}</p>
      <p>操作员1：{{ companyMes.operatorName }}</p>
      <p>操作员2：{{ companyMes.operatorName }}</p>
      <p>操作员3：{{ companyMes.operatorName }}</p>-->
      <div class="person1 fontColor">第一联系人： {{ customerCompany.firstContact }}</div>
      <div class="tel1 fontColor">联系手机： {{ customerCompany.firstContactTelphone }}</div>
      <div class="mail1 fontColor">联系邮箱： {{ customerCompany.firstContactEmail }}</div>
      <div class="person2 fontColor">第二联系人： {{ customerCompany.secondContact }}</div>
      <div class="tel2 fontColor">联系手机： {{ customerCompany.secondContactTelphone }}</div>
      <div class="mail2 fontColor">联系邮箱： {{ customerCompany.secondContactEmail }}</div>
    </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {}
  },
  props: {
    customerCompany: {
      type: Object,
      required: true
    }
  },
  methods: {
    change () {

    }
  }
}
</script>

<style lang="scss" scoped>
.xuan {
  float: right;
  margin-top: -5px;
}
.fenge {
  margin-top: 20px;
}
.yingaddress {
  display: block;
  float: left;
  width: 50%;
}
.yuanxian {
  display: block;
  float: left;
  width: 100%;
}
.yingcode {
  display: block;
  float: left;
  width: 50%;
}
.yingname {
  display: block;
  float: left;
  width: 50%;
}
.qiye {
  display: block;
  float: left;
  width: 50%;
}
.fontColor {
  color: #8c939d;
  font-size: 15px;
}
.person1 {
  margin-top: -20px;
  float: left;
  margin-left: 18.32%;
  width: 50%;
}
.tel1 {
  float: left;
  margin-left: 20%;
  width: 50%;
}
.mail1 {
  float: left;
  margin-left: 20%;
  width: 50%;
}
.person2 {
  float: left;
  margin-left: 18.32%;
  width: 50%;
}
.tel2 {
  float: left;
  margin-left: 20%;
  width: 50%;
}
.mail2 {
  float: left;
  margin-left: 20%;
  width: 50%;
}
.two {
  margin-top: 200px;
}
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 38px 40px;
  height: 593px;
  box-sizing: border-box;
  .title {
    color: #333333;
    font-size: 24px;
  }
  .list {
    margin-top: 35px;
    color: #333333;
    font-size: 14px;
    line-height: 40px;
  }

}
</style>
