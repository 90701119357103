import request from '@/router/axios'
// 验证账号是否存在
export const accountIsExist = (params) => {
  return request({
    url: '/cineplex/homePageOther/accountIsExist',
    method: 'get',
    params: {
      userCode: params
    }
  })
}

// 验证客户档案是否存在
export const customerIsExist = (params) => {
  return request({
    url: '/cineplex/homePageOther/customerIsExist',
    method: 'get',
    params: {
      customerName: params.customerName
    }
  })
}

// 校验短信
export const checkSendMessage = (params) => {
  return request({
    url: '/cineplex/homePageOther/checkSendMessage',
    method: 'get',
    params: {
      phone: params.phone,
      voId: params.voId,
      code: params.code
    }
  })
}

// 发送短信
export const sendMessage = (params) => {
  return request({
    url: '/cineplex/homePageOther/sendMessage',
    method: 'get',
    params: {
      phone: params.phone,
      voId: params.voId
    }
  })
}

// 获取验证码唯一id
export const getVoId = () => {
  return request({
    url: '/cineplex/homePageOther/getVoId',
    method: 'get'
  })
}

// 获取所有客户列表
export const findCustomerBatchList = (params) => {
  return request({
    url: '/cineplex/homePageOther/findCustomerBatchList',
    method: 'get',
    params: {
      start: params.start,
      limit: params.limit,
      isTheatreChain: '1'
    }
  })
}

// 账户注册
export const registerAccount = (params) => {
  return request({
    url: '/cineplex/homePageOther/registerAccount',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: params
  })
}

// 发送邀请码
export const sendInvitationCode = (params) => {
  return request({
    url: '/cineplex/homePageOther/sendInvitationCode',
    method: 'get',
    params: {
      phone: params.phone,
      voId: params.voId,
      mobilecode: params.mobilecode,
      compname: params.compname
    }
  })
}

// 检验邀请码
export const checkInvitationCode = (params) => {
  return request({
    url: '/cineplex/homePageOther/checkInvitationCode',
    method: 'get',
    params: {
      invitationCode: params.invitationCode,
      phone: params.phone,
      voId: params.voId
    }
  })
}

// 检验邀请码
export const tycCinemaInfo = (params) => {
  return request({
    url: '/cineplex/homePageOther/tycCinemaInfo',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: params
  })
}

// 发送短信
export const sendLoginMessage = (params) => {
  return request({
    url: '/cineplex/homePageOther/sendLoginMessage',
    method: 'get',
    params: {
      phone: params.phone,
      sendPhone: params.sendPhone,
      corporateName: params.compname
    }
  })
}
