<template>
  <div></div>
</template>
<script>
import { casLoginUrl, selfUrl } from '@/config/env'
import { getCustomerInfo } from '@/api/core'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['tagWel'])
  },
  created () {
    const token = this.$route.query.token
    var tmp
    if (token) {
      tmp = this.getQueryString('forwardUrl')
      getCustomerInfo(token).then(res => {
        const data = res.data.data
        this.$store.commit('SET_TOKEN', data.accessToken)
        this.$store.commit('SET_USER_INFO', data)
        this.$store.commit('SET_CUSTOM_INFO_S', data)
        if (this.$store.state.user.customInfos.length > 0) {
          this.$store.commit('SET_CURRENT_CUSTOM', this.$store.state.user.customInfos[0])
        }
        if (tmp) {
          window.location.href = decodeURIComponent(decodeURIComponent(tmp))
        } else {
          window.location.href = selfUrl
        }
      }, () => {
        this.$router.push({ path: '/403' })
      })
    } else {
      tmp = this.$route.query.forwardUrl
      if (tmp) {
        if (casLoginUrl.indexOf('?') !== -1) {
          window.location.href = casLoginUrl + '&forwardService=' + encodeURIComponent(encodeURIComponent(tmp))
        } else {
          window.location.href = casLoginUrl + '?forwardService=' + encodeURIComponent(encodeURIComponent(tmp))
        }
      } else {
        window.location.href = casLoginUrl
      }
    }
  },
  methods: {
    getQueryString (param) {
      var currentUrl = window.location.href
      var arr = currentUrl.split('?')
      if (arr.length > 1) {
        arr = arr[1].split('&') // 分割参数
        for (var i = 0; i < arr.length; i++) {
          var tem = arr[i].split('=') // 分割参数名和参数内容
          if (tem[0] === param) {
            return tem[1]
          }
        }
        return null
      } else {
        return null
      }
    }
  }
}
</script>
