<template>
  <div class="proInfo">
      <div class="img" @click="goDetal">
          <img :src=this.mainGraph alt=""><!--@/assets/product1.png-->
      </div>
      <div class="text">
          <span class="large overflow_two" :title="this.name">{{this.name}}</span><!--DP4K-36BLP- 62-->
          <span>{{this.commoditySubTitle}}</span>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    mainGraph: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    commoditySubTitle: {
      type: String,
      required: true
    },
    id: {
      type: String
    }
  },
  methods: {
    goDetal () {
      var blan = this.$router.resolve({ // 调转到商品搜索页面
        path: '/detail',
        query: {
          id: this.id,
          commodityName: this.name
        }
      })
      window.open(blan.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.proInfo {
    display: flex;
    align-items: center;
    .img {
        width: 60px;
        height: 60px;
        margin-right: 20px;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
  .img:hover {
    cursor: pointer;
  }
    .text {
        color: #999999;
        font-size: 14px;
      width: 200px;
        .large {
            color: #111111;
            padding-right: 5px;
        }
      span{
        width: 80%;
        margin-left: 10%;
        line-height: 20px;
      }
    }
}
</style>
