<template>
  <div class="special-card-item-son">
      <div class="special-card-top" style="padding:5px 0;">
          <img v-lazy="item.mainGraph" alt=""> <!-- :src="mainGraph" -->
      </div>
      <div class="special-card-center display_flex justify_content" :title="item.commodityName">
          <span class=" overflow1" style="color: #333;margin:5px;">{{item.commodityName}}</span>
      </div>
      <div class="special-card-bottom">
          <div class="half left">
              <span>￥{{(item.salePrice).toFixed(2)}}</span>
          </div>
          <div class="half delete">
              <span>￥{{(item.referencePrice).toFixed(2)}}</span>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
    /* name: {
      type: String,
      default: ''
    },
    referencePrice: {
      type: Number,
      default: 0.00
    },
    salePrice: {
      type: Number,
      default: 0.00
    },
    mainGraph: {
      type: String,
      default: ''
    } */
  }
}
</script>

<style lang="scss">
.overflow1{
  width:140px;
  height:15px;
  line-height:15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.special-card-item-son {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #EFEFEF;
    position: relative;
    .special-card-top {
        img {
            display: block;
            width: 66px;
            height: 66px;
        }
    }
    .special-card-center {
        font-size: 12px;
        color: #111111
    }
    .special-card-bottom {
        width: 80%;
        // height: 27px;
        display: flex;
        align-items: center;
        position: relative;
        justify-content:center;
        .half {
            &.left {
                color: #F30012;
                font-size: 14px;
                margin-right: 9px;
            }
            &.delete {
                font-size:12px;
                color: #999999;
                text-decoration:line-through;
            }
        }
    }
}
</style>
