import request from '@/router/axios'

export const myTotalZGB = (params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/myTotalZGB',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const myObtainZGB = (pageNo, pageSize, params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/myObtainZGB',
    method: 'get',
    params: {
      ...params,
      pageNo,
      pageSize
    }
  })
}

export const myConsumptionZGB = (pageNo, pageSize, params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/myConsumptionZGB',
    method: 'get',
    params: {
      ...params,
      pageNo,
      pageSize
    }
  })
}

export const myoneMonthExpireZGB = (pageNo, pageSize, params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/myoneMonthExpireZGB',
    method: 'get',
    params: {
      ...params,
      pageNo,
      pageSize
    }
  })
}
