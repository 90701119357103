<template>
  <div class="orderList">
    <div class="title">
      <div class="left">
        <span style="color: red;" v-if="isSave === true">注意，您尚未签订电子商务合作协议</span>
      </div>
    </div>
    <div class="content" v-if="isSave === true">
      <div>
        尊敬的客户，您的第一笔订单需要和我方签订电子商务合作协议。
      </div>
      <div class="xuan">
        您可以选择以下方式进行签订：
      </div>
      <div class="xuan indent">
        1、<a @click="vimAgreement()" href="#" class="href1">下载电子商务合作协议</a>，请仔细阅读并填写甲方信息及盖章后，并回寄。
      </div>
      <div class="addressG">收件地址：北京市西城区新德街20号东配楼三层中影光峰</div>
      <div class="addressG">收件联系人：犀牛Mall商务</div>
      <div class="addressG">联系电话：158 0149 2494</div>
      <div class="xuan indent">
        2、联系 <a @click="contactCustomer()" href="javascript:void(0);" class="href">在线客服</a> &nbsp; 进行商务合作协议的签订；
      </div>
      <div class="xuan indent">
        3、麻烦留下你的 <a @click="showModal4()" class="href">姓名和联系方式</a> &nbsp; 客服会在24小时内与您联系。
      </div>
      <div class="xuan">
        请您保持电话畅通，以便我们能联系您，为您处理签订协议事宜。
      </div>
    </div>
    <div class="content" v-if="isSave === false">您已签订电子商务合作协议</div>
    <el-dialog title="协议用户信息" :visible.sync="visible4" width="510px">
      <template>
        <div class="full-width">
          <template>
            <div class="get-addrrss" style="height: 210px">
              <div class="form-div">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
                  <el-form-item label="姓名:" width="60px" prop="fullName">
                    <div class="item-div">
                      <el-input v-model="ruleForm.fullName" maxlength="50"></el-input>
                    </div>
                  </el-form-item>
                  <el-form-item label="手机号:" prop="phone">
                    <div class="item-div">
                      <el-input v-model="ruleForm.phone" type="tel" maxlength="11"></el-input>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
              <div class="modal-btn2 active mg-auto" @click="addAddress()">
                <span>提交</span>
              </div>
            </div>
          </template>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { findInfo, getElectronicCommerceAgreement, saveStayUser } from '@/views/myCar/components/goodsCar'
import { mapGetters } from 'vuex'
// import sha1 from 'js-sha1'
// import { guid } from '@/util/utils'

export default {
  computed: {
    ...mapGetters(['cartList', 'currentCustom', 'userInfo'])
  },
  data () {
    return {
      visible4: false,
      agreementUrl: '',
      isSave: false,
      ruleForm: {
        fullName: '',
        phone: '',
        yesNo: '02'
      },
      customer_info: {},
      rules: {
        fullName: [
          { required: true, message: '请填写姓名', trigger: 'change' }
        ],
        phone: [
          { required: true, pattern: /^1[0-9]{10}$/, message: '格式有误', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    addAddress () { // 添加
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          saveStayUser(this.ruleForm).then(res => {
            this.cancelModal4()
          })
        }
      })
      // this.AddressList.push(addressFrom)
    },
    showModal4 () {
      this.handleDialogClose()
      this.visible4 = true
    },
    cancelModal4 () {
      this.visible4 = false
    },
    handleDialogClose () {
      this.ruleForm = {
        fullName: '',
        phone: '',
        yesNo: '02'
      }
      // this.$refs.ruleForm.resetFields()
      this.cancelModal4()
    },
    // 联系客服
    contactCustomer () {
      /* this.$notify({
        title: '提示',
        message: '当前客服未在线! 请拨打客服热线010-62366288',
        type: 'warning',
        duration: 2000
      }) */
      this.kefu()
    },
    kefu () {
      // var url = 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626'
      // var url = 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626&signature=true&randomn=' + guid()
      // this.$store.commit('SET_IFRAME_SRC', url)
      var mpurl = 'https://work.weixin.qq.com/kfid/kfc51fdfa81129546b4'
      window.open(mpurl, '_blank')
      // this.customer_info.nonce = this.randomn(6)
      // this.customer_info.timestamp = new Date().getTime()
      // var name = this.userInfo.operatorName
      // if (name) {
      //   this.customer_info.web_token = name
      //   var signStr = `nonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&e9402bfd15f27fba780d906ce451e3c4`
      //   this.customer_info.signature = sha1(signStr).toUpperCase()
      //   url = url + `&nonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&signature=${this.customer_info.signature}&c_name=${encodeURIComponent(name)}&c_desc=kk`
      // }
      // this.openOne(url, 'detail')
    },
    // openOne (url, name) {
    //   var win = window.open('', name)
    //   try {
    //     if (win.location.href === 'about:blank') {
    //       //  窗口不存在
    //       win = window.open(url, name)
    //     } else {
    //       win.close()
    //       //  窗口以已经存在了
    //       //  win.focus()
    //       window.open(url, name)
    //     }
    //   } catch (e) {
    //     //  win.focus()
    //     win.close()
    //     window.open(url, name)
    //   }
    // },
    randomn (n) {
      if (n > 21) return null
      return parseInt((Math.random() + 1) * Math.pow(10, n - 1))
    },
    vimAgreement () {
      if (this.agreementUrl === null || this.agreementUrl.length <= 0) {
        this.$notify({
          title: '提示',
          message: '协议未找到,请联系客服咨询详情!',
          type: 'warning',
          duration: 2000
        })
      } else {
        window.open(this.agreementUrl)
      }
    },
    onLoad () {
      // 查询是否签约
      var customerId = this.currentCustom.customerId
      if (customerId !== null && customerId !== undefined) {
        findInfo(customerId).then(res => {
          var cust = res.data.data
          if (cust.signingStatus !== null && cust.signingStatus === '02') {
            this.isSave = true
            getElectronicCommerceAgreement().then(res => {
              this.agreementUrl = res.data.data
            })
          }
        })
      }
      // 查询范本
    }
  },
  mounted () {
    this.onLoad()
  }
}
</script>

<style lang="scss" scoped>
// 模态框
.mg-auto {
  margin: 30px auto;
}
.xuan {
  margin-top: 20px;
  &.indent {
    text-indent: 2em;
  }
}
.href1 {
  color: #0066cc;
  //margin-left: 30px;
  text-decoration: none;
}
.href1:hover {
  color: #ef922e;
}
.href {
  color: #0066cc;
  text-decoration: none;
}
.href:hover {
  color: #ef922e;
}
.content {
  width: 100%;
  margin-top: 20px;
  background: #F7F3D8;
  // margin-left: 40px;
}
.item-div {
  width: 342px;
  position: relative;
}
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 38px 40px;
  height: 593px;
  .title {
    color: #333333;
    font-size: 24px;
  }
  .list-form {
    margin-top: 35px;
    color: #333333;
    font-size: 14px;
  }
  .form-div {
    margin-top: 30px;
    margin-left: -10px;
  }
  .button-ope {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .btn {
      width: 123px;
      height: 40px;
      border: 1px solid #e1e1e1;
      border-radius: 2px;
      line-height: 40px;
      text-align: center;
      color: #666666;
      font-size: 14px;
      &.active {
        background-color: #ef922e;
        color: white;
        margin-right: 15px;
      }
    }
  }
}
.addressG {
  margin-left: 48px;
  margin-top: 20px;
}
</style>
