<template>
  <div class="full-width">
      <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.full-width {
    border: 1px solid #E1E1E1;
}
</style>
