<template>
  <div class="addressCard">
    <div class="name">
      <div class="text">
        <span>{{ addressMes.consignee }}</span>
      </div>
      <div class="default" v-if="addressMes.isDefault=='01'">
        <span>默认</span>
      </div>
      <div class="default" style="margin-left: 10px" v-if="addressMes.isFromK3 == '01'">
        <span>金蝶</span>
      </div>
    </div>
    <div class="h2">
      <span>收货人：</span>
      <span>{{ addressMes.consignee }}</span>
    </div>
    <div class="h2">
      <span>所在地区：</span>
      <span>{{ addressMes.area }}</span>
    </div>
    <div class="h2">
      <span>地址：</span>
      <span>{{ addressMes.address }}</span>
    </div>
    <div class="h2">
      <span>手机号：</span>
      <span>{{ addressMes.tel }}</span>
    </div>
    <div class="h2">
      <span>固定电话：</span>
      <span>{{ addressMes.fixedTel }}</span>
    </div>
    <div>
      <div class="h2 operate">
        <span class="sp1" v-if="addressMes.isDefault == '02'" style="margin-right: 15px" @click="setDefaults(index, addressMes.id)">设为默认</span>
        <span class="sp1" v-if="addressMes.isFromK3 == '02'" style="margin-right: 15px" @click="showModal3">编辑</span>
        <span class="sp1" v-if="addressMes.isFromK3 == '02'" @click="showModal2">删除</span>
      </div>
      <el-dialog title="编辑地址信息" @close="closeSelf" :visible.sync="visible3">
        <div class="full-width">
          <addressView :ruleForm="addressList" :edit="true" @closeModal3="closeModal3"/>
        </div>
      </el-dialog>
      <el-dialog title="删除地址" :visible.sync="visible2">
        <deleteAddress></deleteAddress>
      </el-dialog>
    </div>
    </div>
</template>

<script>
import addressView from '@/views/personCenter/sendAdress/components/addressView'
import { doUpdateOperatorAddress } from '@/api/personCenter/addressInfo/addressL'
import deleteAddress from '@/views/personCenter/sendAdress/components/deleteAddress'
export default {
  props: {
    addressMes: {
      type: Object,
      required: true
    },
    addressList: {
      type: Object,
      default: function () {
        return {}
      }
    },
    index: {
      type: Number
    }
  },
  components: {
    addressView,
    deleteAddress
  },
  methods: {
    showModal3 () {
      this.visible3 = true
    },
    closeModal3 () {
      this.visible3 = false
    },
    showModal2 () {
      this.addressId = this.addressMes.id
      this.visible2 = true
    },
    closeModal2 () {
      this.visible2 = false
    },
    closeSelf () {
      this.ruleForm = {}
    },
    delAddress () {
      this.$parent.del(this.index)
      this.closeModal2()
    },
    removeAddress () {
      this.delAddress()
    },
    setDefaults (index, id) {
      this.$parent.setDefault(index)
      doUpdateOperatorAddress(id).then(res => {

      })
    }
  },
  data () {
    return {
      visible3: false,
      visible2: false,
      addressId: ''
    }
  }

}
</script>

<style lang="scss" scoped>
.addressCard {
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  padding: 20px;
  margin-top: 20px;
  .name {
    display: flex;
    align-items: center;
    .text {
      color: #333333;
      font-size: 16px;
      margin-right: 10px;
    }
  }
  .default {
    width: 34px;
    height: 16px;
    background: #ef922e;
    border-radius: 2px;
    font-size: 12px;
    color: #ffffff;
    padding: 0 1px;
    line-height: 16px;
    text-align: center;
  }
  .h2 {
    color: #666666;
    font-size: 14px;
    margin-top: 15px;
    &.operate {
        text-align: right;
        color: #ef922e;
    }
  }
  .sp1:hover{
    cursor: pointer;
    color: #409EFF;
  }
}
</style>
