<template>
  <div class="table-car">
    <el-table
      ref="multipleTable"
      header-row-class-name="white-header"
      :data="tableData"
      @selection-change="asd"
      style="width:100%"
    >
      <el-table-column type="selection" :selectable="checkSelectable" label="全选" min-width="180">
      </el-table-column>
      <el-table-column prop="commodityName" label="商品名称" min-width="300">
        <template slot-scope="scope">
          <pro-info :id="scope.row.goodsId" :mainGraph="scope.row.mainGraph" :name="scope.row.commodityName" :commoditySubTitle="scope.row.commoditySubTitle"></pro-info>
        </template>
      </el-table-column>
      <el-table-column prop="salePrice" label="单价" min-width="180"> </el-table-column>
      <el-table-column prop="number" label="数量" min-width="180">
        <template slot-scope="scope" >
            <el-input-number v-model="scope.row.number" v-if="scope.row.goodsInfoStatus != '02'" @change="handleChange(scope.$index)" :max="99999" :min="1"></el-input-number>
            <span style="color: #C2C6CD" v-if="scope.row.goodsInfoStatus == '02'">商品已下架</span>
        </template>
      </el-table-column>
      <el-table-column prop="Subtotal" label="小计" min-width="180">
          <template slot-scope="scope" v-if="scope.row.goodsInfoStatus != '02'">
              <span style="color: #ef922e">{{scope.row.Subtotal}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="operate" label="操作" min-width="180">
          <template slot-scope="scope">
              <a style="color: #333333" @click="showModal5(scope.$index)" >删除</a> <!--@click="del(scope.$index)"-->
            <div class="modal-page">
              <el-dialog title="删除商品" :visible.sync="visible5"  :close-on-click-modal="false" :modal-append-to-body='false' :append-to-body='true'>
                <template>
                  <div class="delete">
                    <div class="div-1">
                      <img src="@/assets/delete.png" alt="">
                    </div>
                    <div class="div-2">
                      <span>删除商品</span>
                    </div>
                    <div class="div-3">
                      <span>您确定要删除此商品吗？</span>
                    </div>
                    <div class="div-4" @click="del">
                      <div class="modal-btn2 active">
                        <span>删除商品</span>
                      </div>
                    </div>
                  </div>
                </template>
              </el-dialog>
            </div>
          </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import proInfo from '@/components/proInfo'
export default {
  props: {
    tableData: {
      type: Array,
      required: true
    }
  },
  components: {
    proInfo
  },
  data () {
    return {
      visible5: false,
      deleteGodds: null
    }
  },
  mounted () {
  },
  methods: {
    showModal5 (i) {
      this.deleteGodds = i
      this.visible5 = true
    },
    cancelModal5 () {
      this.visible5 = false
    },
    checkSelectable (row) {
      let flag = true
      if (row.goodsInfoStatus === '02') {
        flag = false
      }
      return flag
    },
    asd () {
      // eslint-disable-next-line no-unused-vars
      var tableList = this.$refs.multipleTable.selection
      if (tableList.length > 0) {
        this.$parent.asd(tableList)
      } else {
        this.$parent.asd(tableList)
      }
    },
    handleChange (b) {
      /* var copyn = this.tableData[b].copyNum
      var num = this.tableData[b].number
      // eslint-disable-next-line no-unused-expressions
      if (copyn < num) { // +
        this.$parent.reduceShoppingCart(b, 0)
      } else if (copyn > num) { // -
        this.$parent.reduceShoppingCart(b, 1)
      } */
      // this.tableData[b].copyNum = num
      var n = this.tableData[b].number
      if (n === undefined) {
        // this.tableData[b].number = 1
        this.$nextTick(() => {
          this.$set(this.tableData[b], 'number', 1)
          this.$parent.reduceShoppingCart(b)
          this.asd()
        })
        return false
        //  this.$forceUpdate()
        // this.tableData[b].number = 1
      } else if (n.toString().includes('.')) {
        // this.$alert('只能输入整数', '警告')
        this.tableData[b].number = n.toString().substring(0, n.toString().indexOf('.'))
      }
      this.$parent.reduceShoppingCart(b)
      this.asd()
    },
    del () {
      this.$parent.del(this.deleteGodds)
      this.cancelModal5()
    }
  }
}
</script>
<style lang="scss" scoped>
.table-car {
  background-color: white;
  .el-table .el-table__header{
    width: none;
    width: 100% !important;
}
 .el-table .el-table__body{
    width: none;
    width: 100% !important;
}
}
.modal-page {
  display: flex;
  justify-content: center;
  .content {
    width: 1226px;
    background-color: white;
    padding: 30px;
    display: flex;
    .btn {
      width: 123px;
      height: 40px;
      border: 1px solid #e1e1e1;
      border-radius: 2px;
      line-height: 40px;
      text-align: center;
      color: #666666;
      margin-right: 40px;
      font-size: 14px;
      &.active {
        background-color: #ef922e;
        color: white;
        margin-right: 15px;
      }
    }
  }
}
.delete {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #333333;
  .div-1 {
    width: 70px;
    height: 70px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .div-2 {
    font-size: 24px;
    margin-top: 22px;
  }
  .div-3 {
    font-size: 14px;
    margin-top: 18px;
  }
  .div-4 {
    margin-top: 30px;
    margin-bottom: 55px;
  }
}
</style>
