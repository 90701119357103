import request from '@/router/axios'

export const ajaxList = (params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/findInvoice',
    method: 'POST',
    params: {
      ...params
    }
  })
}

export const findInvoiceDetail = (id) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/findInvoiceDetail',
    method: 'GET',
    params: {
      id
    }
  })
}

export const remove = (id) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/doDeleteInvoice',
    method: 'post',
    params: {
      id
    }
  })
}

export const add = (row) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/doInsertInvoice',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/doUpdateInvoice',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: row
  })
}

export const getCustomerCategoryById = (id) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/getCustomerCategoryById',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    params: {
      id
    }
  })
}
