<template>
  <div class="content-wrap">
      <view class="fail_box"><img src="/assets/404_icon.png" alt="" /></view>
      <div id="left_wrap_content" class="left" v-if="$route.path === '/home' && leftImgObj.advertPostionVo && leftImgObj.advertList">
          <a href="javascript:void(0);" @click="openUrl(leftImgObj.advertPostionVo.link, '0101', '导航栏左')">
            <img :src="leftImgObj.advertPostionVo.img" @mouseover="bannerShow=true" @mouseleave="bannerShow=false">
          </a>
          <div class="banner" style="display: inline-block" :class="{'brand_active': bannerShow}" @mouseover="bannerShow=true" @mouseleave="bannerShow=false">
            <a href="javascript:void(0);" class="fs_act_banner_lk J_fs_act_banner_lk" @click="openUrl(leftImgObj.advertPostionVo.link, '0102', '悬浮banner')">
              <img :src="leftImgObj.advertList[0].img">
            </a>
            <span class="fs_act_banner_close iconfont" @click="bannerShow=false">X</span>
          </div>
      </div>
      <div id="content-wrap-inner" class="content-inner">
          <slot></slot>
      </div>
  </div>
</template>

<script>
import { hits } from '@/api/hits'
export default {
  props: {
    leftImgObj: {
      type: Object
    }
  },
  data () {
    return {
      bannerShow: false
    }
  },
  created () {
  },
  mounted () {
    this.setLeftDivStyle()
    window.onresize = function () {
      console.log('onresize')
      var nextEleLeftVal = document.getElementById('content-wrap-inner').offsetLeft
      var leftEle = document.getElementById('left_wrap_content')
      if (leftEle) {
        leftEle.style.left = (nextEleLeftVal - 267) + 'px'
      }
    }
  },
  methods: {
    openUrl (url, busId, busTitle) {
      hits({
        type: '02',
        clickPostion: '01',
        busId: busId,
        busTitle: busTitle,
        sourceSection: '01'
      })
      window.open(url, '_blank')
    },
    setLeftDivStyle () {
      var tmp = setInterval(function () {
        var nextEleLeftVal = document.getElementById('content-wrap-inner').offsetLeft
        var leftEle = document.getElementById('left_wrap_content')
        if (leftEle) {
          leftEle.style.left = (nextEleLeftVal - 267) + 'px'
          clearInterval(tmp)
        }
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
  .fail_box {
    width: 1200px;
    background: red;
    height: 500px;
    z-index: 999;
  }
.content-wrap {
    width: 100%;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    position: relative;
    .left {
      position: absolute;
      left: 71px;
      width: 267px;
      height: 460px;
      .banner {
        overflow: hidden;
        position: absolute;
        z-index: 30;
        left: 100%;
        width: 0;
        height: 460px;
        -webkit-transition: all .3s ease;
        transition: all .3s ease
      }
      .brand_active{
        width: 1041px
      }
      .fs_act_banner_close {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-weight: 700;
        font-size: 14px;
        color: #fff;
        background: #ff4326;
        opacity: .3;
        filter: alpha(opacity=30);
        text-align: center;
        cursor: pointer
      }
      img{
        width: 100%;
        height: 100%;
      }
    }
    .content-inner {
        width: 1226px;
    }
}
</style>
