<template>
  <div class="orderList">
    <div class="title">
      <span>发票申请</span>
    </div>
    <div class="form-div" style="padding: 0;">
<!--   判断条件   v-if="(customerCategory === '0' || customerCategory === '1') && customerCategory != null"-->
<!--      :rules="rules"-->
      <el-form
        :model="ruleForm"
        :disabled="editShow"
        :show-message="!editShow"
        ref="ruleForm"
        label-width="128px">
        <div class="sperate">
          <span>开票信息</span>
          <span>....................................................................................................................................................................................................................................</span>
        </div>
        <br/>
        <br/>
        <el-form-item label="抬头类型:" prop="invoiceTitleType">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceTitleType" disabled placeholder="抬头类型">
              <el-option label="企业" value="02"/>
            </el-select>
          </div>
        </el-form-item>
<!--        <el-form-item label="发票内容:" prop="invoiceContent">-->
<!--          <div class="invoice-content item-div" style="cursor: pointer">-->
<!--            <div class="btn" :class="{ active: active }" @click="switchActive('商品明细', true)">-->
<!--              <span>商品明细</span>-->
<!--            </div>-->
<!--            <div class="btn" style="margin-left: 15px" :class="{ active: !active }" @click="switchActive('商品类别', false)">-->
<!--              <span>商品类别</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-form-item>-->
        <el-form-item label="是否为一般纳税人:" prop="isPayTaxes">
          <el-select v-model="ruleForm.isPayTaxes" disabled placeholder="是否为一般纳税人">
            <el-option label="否" value="1"/>
            <el-option label="是" value="0"/>
          </el-select>
        </el-form-item>
        <el-form-item label="所需发票种类:" prop="invoiceType">
          <div class="item-div" v-if="this.invoiceTypeTemp === '04'">
            <el-select v-model="ruleForm.invoiceType" disabled placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
              <el-option label="增值税专用发票" value="04"/>
            </el-select>
          </div>
          <div class="item-div" v-if="this.invoiceTypeTemp === '03'">
            <el-select v-model="ruleForm.invoiceType" disabled placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
              <el-option label="纸质普票" value="03"/>
            </el-select>
          </div>
          <div class="item-div" v-if="this.invoiceTypeTemp === '01'">
            <el-select v-model="ruleForm.invoiceType" disabled placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="开票名称:" prop="invoiceTitle">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceTitle" placeholder="请选择开票名称" @change="changeSelect">
              <el-option v-for="(v,index) in selectList" :key="v.id" :label="v.invoiceTitle" :value="index"/>
            </el-select>
            <!--            <el-input v-model="ruleForm.invoiceTitle"/>-->
          </div>
        </el-form-item>
        <el-form-item label="纳税人识别号:" prop="taxpayerIdNumber">
          <div class="item-div">
            <el-input v-model="ruleForm.taxpayerIdNumber" disabled/>
          </div>
        </el-form-item>
<!--        <div class="sperate">-->
<!--          <span>资质信息</span>-->
<!--          <span>....................................................................................................................................................................................................................................</span>-->
<!--        </div>-->
<!--        <br/>-->
<!--        <br/>-->
        <el-form-item label="开户行名称:" prop="bankOfDeposit">
          <div class="item-div">
            <el-input v-model="ruleForm.bankOfDeposit" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="开户行账号:" prop="accountNumber">
          <div class="item-div">
            <el-input v-model="ruleForm.accountNumber" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="开票地址:" prop="registeredAddress">
          <div class="item-div">
            <el-input v-model="ruleForm.registeredAddress" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="电话:" prop="registeredTel">
          <div class="item-div">
            <el-input v-model="ruleForm.registeredTel" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="邮箱:" prop="email" >
          <div class="item-div">
            <el-input v-model="ruleForm.email" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="邮寄省:" prop="invoiceProvince">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceProvince" disabled placeholder="请选择" @change="provinceChanged">
              <el-option
                :key="''"
                :label="'全部'"
                :value="''">
              </el-option>
              <el-option
                v-for="item in provinces"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="邮寄市:" prop="invoiceCity">
          <div class="item-div">
            <el-select disabled v-model="ruleForm.invoiceCity"
                       :loading="loadingCity"
                       placeholder="请选择">
              <el-option
                :key="''"
                :label="'全部'"
                :value="''">
              </el-option>
              <el-option
                v-for="item in cities"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="邮寄地址:" prop="toAddress">
          <div class="item-div">
            <el-input v-model="ruleForm.toAddress" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="联系人:" prop="invoiceLinkMan">
          <div class="item-div">
            <el-input v-model="ruleForm.invoiceLinkMan" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="联系人手机:" prop="telephone">
          <div class="item-div">
            <el-input v-model="ruleForm.telephone" disabled/>
          </div>
        </el-form-item>
<!--        <div class="title  mg-top-50">-->
<!--          <span>发票明细</span>-->
<!--        </div>-->
        <div class="sperate">
          <span>开票明细</span>
          <span>....................................................................................................................................................................................................................................</span>
        </div>
        <br/>
        <br/>
        <div class="mg-top-20" v-for="(v,index) in invoiceList" :key="index">
          <simpleTableMoreInvoice :bean="v" :edit="editColumn" :showCheckBox="showCheckBox"/>
        </div>
        <br/>
        <br/>
        <el-form-item label="开票金额:" prop="invoiceAmt">
          <div class="item-div">
            <el-input v-model="ruleForm.invoiceAmt" disabled oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1').replace(/^0{1,}/g,'')"/>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="form-div" style="padding: 0;" v-if="customerCategory === '2' && customerCategory != null">
      <!--      :rules="rules"-->
      <el-form
        :model="ruleForm"
        :disabled="editShow"
        :show-message="!editShow"
        ref="ruleForm"
        label-width="128px">
        <div class="sperate">
          <span>开票信息</span>
          <span>....................................................................................................................................................................................................................................</span>
        </div>
        <br/>
        <br/>
        <el-form-item label="抬头类型:" prop="invoiceTitleType">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceTitleType" placeholder="抬头类型">
              <el-option label="个人" value="01"/>
              <el-option label="企业" value="02"/>
            </el-select>
          </div>
        </el-form-item>
        <!--        <el-form-item label="发票内容:" prop="invoiceContent">-->
        <!--          <div class="invoice-content item-div" style="cursor: pointer">-->
        <!--            <div class="btn" :class="{ active: active }" @click="switchActive('商品明细', true)">-->
        <!--              <span>商品明细</span>-->
        <!--            </div>-->
        <!--            <div class="btn" style="margin-left: 15px" :class="{ active: !active }" @click="switchActive('商品类别', false)">-->
        <!--              <span>商品类别</span>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </el-form-item>-->
        <el-form-item label="是否为一般纳税人:" prop="isPayTaxes" v-if="1 === 0">
          <el-select v-model="ruleForm.isPayTaxes" disabled placeholder="是否为一般纳税人">
            <el-option label="否" value="1"/>
            <el-option label="是" value="0"/>
          </el-select>
        </el-form-item>
        <el-form-item label="所需发票种类:" prop="invoiceType">
          <div class="item-div" v-if="this.invoiceTypeTemp === '04'">
            <el-select v-model="ruleForm.invoiceType" disabled placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
              <!--              <el-option label="增值税专用发票" value="04"/>-->
            </el-select>
          </div>
          <div class="item-div" v-if="this.invoiceTypeTemp === '03'">
            <el-select v-model="ruleForm.invoiceType" disabled placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
              <el-option label="纸质普票" value="03"/>
            </el-select>
          </div>
          <div class="item-div" v-if="this.invoiceTypeTemp === '01'">
            <el-select v-model="ruleForm.invoiceType" disabled placeholder="所需发票种类">
              <el-option label="电子普票" value="01"/>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="开票名称:" prop="invoiceTitle">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceTitle" placeholder="请选择开票名称" @change="changeSelect">
              <el-option v-for="(v,index) in selectList" :key="v.id" :label="v.invoiceTitle" :value="index"/>
            </el-select>
            <!--            <el-input v-model="ruleForm.invoiceTitle"/>-->
          </div>
        </el-form-item>
        <el-form-item label="纳税人识别号:" prop="taxpayerIdNumber" v-if="1 === 0">
          <div class="item-div">
            <el-input v-model="ruleForm.taxpayerIdNumber" disabled/>
          </div>
        </el-form-item>
        <!--        <div class="sperate">-->
        <!--          <span>资质信息</span>-->
        <!--          <span>....................................................................................................................................................................................................................................</span>-->
        <!--        </div>-->
        <!--        <br/>-->
        <!--        <br/>-->
        <el-form-item label="开户行名称:" prop="bankOfDeposit">
          <div class="item-div">
            <el-input v-model="ruleForm.bankOfDeposit" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="开户行账号:" prop="accountNumber">
          <div class="item-div">
            <el-input v-model="ruleForm.accountNumber" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="开票地址:" prop="registeredAddress">
          <div class="item-div">
            <el-input v-model="ruleForm.registeredAddress" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="电话:" prop="registeredTel">
          <div class="item-div">
            <el-input v-model="ruleForm.registeredTel" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="邮箱:" prop="email" >
          <div class="item-div">
            <el-input v-model="ruleForm.email" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="邮寄省:" prop="invoiceProvince">
          <div class="item-div">
            <el-select v-model="ruleForm.invoiceProvince" disabled placeholder="请选择" @change="provinceChanged">
              <el-option
                :key="''"
                :label="'全部'"
                :value="''">
              </el-option>
              <el-option
                v-for="item in provinces"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="邮寄市:" prop="invoiceCity">
          <div class="item-div">
            <el-select disabled v-model="ruleForm.invoiceCity"
                       :loading="loadingCity"
                       placeholder="请选择">
              <el-option
                :key="''"
                :label="'全部'"
                :value="''">
              </el-option>
              <el-option
                v-for="item in cities"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="邮寄地址:" prop="toAddress">
          <div class="item-div">
            <el-input v-model="ruleForm.toAddress" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="联系人:" prop="invoiceLinkMan">
          <div class="item-div">
            <el-input v-model="ruleForm.invoiceLinkMan" disabled/>
          </div>
        </el-form-item>
        <el-form-item label="联系人手机:" prop="telephone">
          <div class="item-div">
            <el-input v-model="ruleForm.telephone" disabled/>
          </div>
        </el-form-item>
        <!--        <div class="title  mg-top-50">-->
        <!--          <span>发票明细</span>-->
        <!--        </div>-->
        <div class="sperate">
          <span>开票明细</span>
          <span>....................................................................................................................................................................................................................................</span>
        </div>
        <br/>
        <br/>
        <div class="mg-top-20" v-for="(v,index) in invoiceList" :key="index">
          <simpleTableMoreInvoice :bean="v" :edit="editColumn" :showCheckBox="showCheckBox"/>
        </div>
        <br/>
        <br/>
        <el-form-item label="开票金额:" prop="invoiceAmt">
          <div class="item-div">
            <el-input v-model="ruleForm.invoiceAmt" disabled oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1').replace(/^0{1,}/g,'')"/>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="button-ope" style="cursor: pointer;margin-left: 15%">
        <div class="btn active" v-if="!editShow" @click="invoiceSubmit()">
          <span ><a>提交</a></span>
        </div>
        <div class="btn" @click="$router.back()">
          <span ><a>返回</a></span>
        </div>
    </div>
  </div>
</template>

<script>
import simpleTableMoreInvoice from '@/components/simpleTableMoreInvoice'
import { invoiceDetail, invoiceSubmit, getCustomerCategoryById } from '@/api/personCenter/invoiceoperate'
import { ajaxList } from '@/api/personCenter/customerInvoice'
// import provice from '@/api/json/provice.json'
import provice from '@/api/json/city.json'
import { mapGetters } from 'vuex'
export default {
  components: {
    simpleTableMoreInvoice
  },
  computed: {
    ...mapGetters(['currentStat', 'currentCustom', 'userInfo'])
  },
  methods: {
    // 获取客户类别
    getCustomerCategoryById () {
      getCustomerCategoryById(this.userInfo.customerId).then(res => {
        // console.log(res, 'getCustomerCategoryById')
        this.customerCategory = res.data.data
      }).catch(e => {
        console.log(e)
      })
    },
    provinceChanged (value) {
      if (value !== '') {
        this.loadingCity = true
        for (var item of this.provinces) {
          if (item.code === value) {
            this.cities = item.cities
            this.loadingCity = false
            break
          }
        }
      } else {
        this.cities = []
      }
    },
    switchActive (text, active) {
      if (this.editShow) {
        return false
      }
      this.ruleForm.invoiceContent = text
      this.active = active
    },
    getDetail (id) {
      this.ruleForm = {}
      this.invoiceList = []
      invoiceDetail({ id: id }).then((res) => {
        this.invoiceList = res.data.data.detailVos
        this.ruleForm = res.data.data
        this.invoiceTypeTemp = this.ruleForm.invoiceType
        this.active = (this.ruleForm.invoiceContent === '商品明细')
        this.provinceChanged(this.ruleForm.invoiceProvince)
        delete this.ruleForm.detailVos
      })
    },
    invoiceSubmit () {
      // 开票名称
      if (this.ruleForm.invoiceType && this.ruleForm.invoiceType === '01') {
        if (this.ruleForm.invoiceTitle == null || this.ruleForm.invoiceTitle === '') {
          this.$notify({
            title: '提示',
            message: '如需申请发票，请和客服联系400-870-9700',
            type: 'warning',
            duration: 2000
          })
          return
        }
      } else {
        if (this.ruleForm.invoiceTitle == null || this.ruleForm.invoiceTitle === '' || this.ruleForm.accountNumber == null || this.ruleForm.accountNumber === '') {
          this.$notify({
            title: '提示',
            message: '发票信息不全,请和客服联系补全发票信息400-870-9700',
            type: 'warning',
            duration: 2000
          })
          return
        }
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const temp = this.ruleForm.invoiceTitle
          this.ruleForm.invoiceTitle = this.selectList[this.ruleForm.invoiceTitle].invoiceTitle
          this.ruleForm.orderGoodInfo = this.invoiceList
          this.ruleForm.customerId = this.currentCustom.customerId
          this.ruleForm.customerName = this.currentCustom.customerName
          invoiceSubmit(this.ruleForm).then(() => {
            this.ruleForm = {}
            this.$notify({
              title: '操作',
              message: '申请成功',
              type: 'success',
              duration: 2000
            })
            this.$router.back()
          }, (error) => {
            this.ruleForm.invoiceTitle = temp
            this.$notify({
              title: '提示',
              message: error,
              type: 'error',
              duration: 3000
            })
          })
        } else {
          return false
        }
      })
    },
    async ajaxListInvoice () {
      ajaxList({ notDesensitization: '01', customerId: this.currentCustom.customerId }).then((res) => {
        this.selectList = res.data.data
        this.changeSelect('flag')
      })
    },
    changeSelectModify () {
      for (let i = 0; i < this.selectList.length; i++) {
        if (this.ruleForm.invoiceTitle === this.selectList[i].invoiceTitle) {
          this.ruleForm.invoiceType = this.selectList[i].invoiceType
          this.invoiceTypeTemp = this.ruleForm.invoiceType
          this.ruleForm.isPayTaxes = this.selectList[i].isPayTaxes
          this.ruleForm.invoiceContent = this.selectList[i].invoiceContent
          this.active = (this.ruleForm.invoiceContent === '商品明细')
          this.ruleForm.invoiceTitleType = this.selectList[i].invoiceTitleType
          this.ruleForm.taxpayerIdNumber = this.selectList[i].taxpayerIdNumber
          this.ruleForm.registeredAddress = this.selectList[i].registeredAddress
          this.ruleForm.registeredTel = this.selectList[i].registeredTel
          this.ruleForm.bankOfDeposit = this.selectList[i].bankOfDeposit
          this.ruleForm.accountNumber = this.selectList[i].accountNumber
          this.ruleForm.email = this.selectList[i].email
          this.ruleForm.toAddress = this.selectList[i].toAddress
          this.ruleForm.invoiceLinkMan = this.selectList[i].invoiceLinkMan
          this.ruleForm.telephone = this.selectList[i].telephone
          this.ruleForm.invoiceProvince = this.selectList[i].invoiceProvince
          this.provinceChanged(this.ruleForm.invoiceProvince)
          this.ruleForm.invoiceCity = this.selectList[i].invoiceCity
        }
      }
    },
    changeSelect (i) {
      if (this.selectList.length <= 0) {
        return false
      }
      var tmp = this.ruleForm.invoiceTitle
      if (i) {
        tmp = 0
      }
      this.ruleForm.invoiceTitle = tmp
      this.ruleForm.invoiceType = this.selectList[tmp].invoiceType
      this.invoiceTypeTemp = this.ruleForm.invoiceType
      this.ruleForm.isPayTaxes = this.selectList[tmp].isPayTaxes
      this.ruleForm.invoiceContent = this.selectList[tmp].invoiceContent
      this.active = (this.ruleForm.invoiceContent === '商品明细')
      this.ruleForm.invoiceTitleType = this.selectList[tmp].invoiceTitleType
      this.ruleForm.taxpayerIdNumber = this.selectList[tmp].taxpayerIdNumber
      this.ruleForm.registeredAddress = this.selectList[tmp].registeredAddress
      this.ruleForm.registeredTel = this.selectList[tmp].registeredTel
      this.ruleForm.bankOfDeposit = this.selectList[tmp].bankOfDeposit
      this.ruleForm.accountNumber = this.selectList[tmp].accountNumber
      this.ruleForm.email = this.selectList[tmp].email
      this.ruleForm.toAddress = this.selectList[tmp].toAddress
      this.ruleForm.invoiceLinkMan = this.selectList[tmp].invoiceLinkMan
      this.ruleForm.telephone = this.selectList[tmp].telephone
      this.ruleForm.invoiceProvince = this.selectList[tmp].invoiceProvince
      this.provinceChanged(this.ruleForm.invoiceProvince)
      this.ruleForm.invoiceCity = this.selectList[tmp].invoiceCity
    }
  },
  mounted () {
    this.getCustomerCategoryById()
    this.orderInvoiceInformationId = this.$route.params.id
    if (this.$route.params.editShow) {
      this.editShow = this.$route.params.editShow
    }
    if (this.$route.params.moneyTemp) {
      this.ruleForm.invoiceAmt = this.$route.params.moneyTemp
    }
    const temp = this.$route.params.invoiceListTemp
    if (temp && temp.length > 0) {
      this.invoiceList = temp
    }
  },
  created () {
    this.provinces = provice.provinces
    this.ajaxListInvoice()
  },
  watch: {
    orderInvoiceInformationId: function (value) {
      if (value) {
        this.getDetail(value)
      }
    }
  },
  data () {
    return {
      active: true,
      // 客户类别
      customerCategory: '',
      ruleForm: {},
      invoiceTypeTemp: '',
      selectList: [],
      editShow: false,
      orderInvoiceInformationId: '',
      editColumn: false,
      showCheckBox: false,
      invoiceList: [],
      provinces: [],
      cities: [],
      loadingCity: false,
      rules: {
        invoiceType: [
          { required: true, message: '请选择发票类型', trigger: 'change' }
        ],
        invoiceTitleType: [
          { required: true, message: '请选择抬头类型', trigger: 'change' }
        ],
        taxpayerIdNumber: [
          { required: true, message: '请输入发票税号', trigger: 'change' }
        ],
        registeredTel: [
          { required: false, pattern: /^1[0-9]{10}$/, message: '格式不正确', trigger: 'change' }
        ],
        accountNumber: [
          { required: false, pattern: /^[1-9][0-9]{8,30}$/, message: '格式不正确,最少8位,最多30位', trigger: 'change' }
        ],
        invoiceTitle: [
          { required: true, message: '请选择单位名称', trigger: 'change' }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 110px 40px;
  .title {
    color: #333333;
    font-size: 24px;
  }
  .sperate {
    color: #999999;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 30px;
  }
  .form-div {
    margin-top: 30px;
    margin-left: -10px;
  }
  .button-ope {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .btn {
      width: 123px;
    height: 40px;
    border: 1px solid #E1E1E1;
    border-radius: 2px;
    line-height: 40px;
    text-align: center;
    color: #666666;
    font-size: 14px;
      &.active {
        background-color: #ef922e;
        color: white;
        margin-right: 15px;
      }
    }
  }
}
.item-div {
  width: 342px;
  position: relative;
  padding:0 !important;
}
.invoice-content {
  display: flex;
  align-items: center;
  .btn {
    width: 166px;
    height: 40px;
    border: 1px solid #E1E1E1;
    border-radius: 2px;
    line-height: 40px;
    text-align: center;
    color: #333333;
    font-size: 14px;
    &.active {
      border-color: #ef922e;
      color: #ef922e;
    }
  }
}
</style>
