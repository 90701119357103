<template>
  <div class="search-wrap">
    <!-- 搜索层 -->
    <search-part @search="search" />
    <div class="bread-crumb">
      <div class="content">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index" :to="item.url">
            <span class="item" v-if="!item.split" :class="{'active': item.active}">{{item.label}}</span>
            <span class="item crumb-select-item" v-else :class="{'active': item.active, 'border': border[item.label]}" @mouseleave="mouseLeave(item.label)" @mouseover="mouseOver(item.label)">
              <b>{{item.label}}: </b>
              <em :title="item.value">{{item.value.length>6?(item.value.substring(0,6)+' ...'):item.value}}</em>
            </span>
            <span style="cursor: pointer; color: red" @click="splitFunc(index,item.key)" v-if="item.split">
              X
            </span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--    <bread-crumbs/>-->
    <!-- <searchPanel ref="searchPanel" :goodsSearchParam="goodsSearchParam" @search="search" /> -->
    <productList :goodsSearchParam="goodsSearchParam" :list="list" @search="search" :sourceSection="sourceSection" />
  </div>
</template>

<script>
import searchPart from '@/components/searchPart'
// import breadCrumbs from '@/components/breadCrumbs'
// import searchPanel from './components/searchPanel'
import productList from './components/productList'
import { search } from '@/api/search'
import { mapGetters } from 'vuex'
export default {
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.path !== '/search' && (from.path !== '/' || to.query.flag === '01')) {
        const breadCrumbs = [{ label: '首页', url: '/home' }, { label: '搜索', url: '/search' }]
        if (to.query.extName) {
          breadCrumbs.push({ label: to.query.extName, url: '' })
        }
        vm.$store.commit('SET_BREADCRUMB', breadCrumbs)
      }
    })
  },
  computed: {
    ...mapGetters(['breadcrumbList', 'searchKeyword', 'searchGoodsId', 'currentCustom'])
  },
  components: {
    searchPart,
    // searchPanel,
    productList
    // breadCrumbs
  },
  data () {
    return {
      goodsSearchParam: {
        pageSize: 20,
        pageNo: 1
      },
      list: [],
      border: {},
      sourceSection: '07'
    }
  },
  methods: {
    mouseOver (label) {
      this.$set(this.border, label, true)
    },
    mouseLeave (label) {
      this.$set(this.border, label, false)
    },
    search (keyword) {
      debugger
      if (keyword) {
        this.goodsSearchParam.keyword = keyword
      }
      if (this.goodsSearchParam.keyword) {
        document.title = `犀牛MALL-${this.goodsSearchParam.keyword}-搜索`
      }
      this.goodsSearchParam.customerId = this.currentCustom.customerId
      search(this.goodsSearchParam).then((res) => {
        this.list = res.data.data
        this.goodsSearchParam.total = res.data.count
        this.goodsSearchParam.isTokenizer = ''
      })
    },
    splitFunc (index, key) {
      this.breadcrumbList.splice(index, 1)
      this.$store.commit('SET_BREADCRUMB', this.breadcrumbList)
      this.$refs.searchPanel.removeTypeFunc(key)
    }
  },
  created () {
    debugger
    if (this.$route.query.keyword) {
      this.goodsSearchParam.keyword = this.$route.query.keyword
    } else {
      this.goodsSearchParam.keyword = this.searchKeyword
    }
    this.goodsSearchParam.searchGoodsId = this.searchGoodsId
    if (this.$route.query.commodityClass) {
      this.goodsSearchParam.commodityClass = this.$route.query.commodityClass
      this.sourceSection = '03'
    }
    if (this.$route.query.productLabel) {
      this.goodsSearchParam.productLabel = this.$route.query.productLabel
      this.goodsSearchParam.productLabelName = this.$route.query.extName
      if (this.$route.query.extName && this.$route.query.extName === '合同商品') {
        this.goodsSearchParam.lunarKnotId = this.$route.query.lunarKnotId
      }
      this.sourceSection = '02'
    }
    if (this.$route.query.orderBy) {
      this.goodsSearchParam.orderBy = this.$route.query.orderBy
    }
    if (this.$route.query.direction) {
      this.goodsSearchParam.direction = this.$route.query.direction
    }
    if (this.$route.query.startPrice) {
      this.goodsSearchParam.startPrice = this.$route.query.startPrice
    }
    if (this.$route.query.endPrice) {
      this.goodsSearchParam.endPrice = this.$route.query.endPrice
    }
    if (this.$route.query.isTokenizer) {
      this.goodsSearchParam.isTokenizer = this.$route.query.isTokenizer
    }
    this.search()
  },
  destroyed () {
    this.$store.commit('SET_SEARCH_KEYWORD', '')
    this.$store.commit('SET_SEARCH_GOODS_ID', '')
  }
}
</script>

<style lang="scss" scoped>
.border {
  border: 1px solid #c22200;
  border-radius: 4px;
}
.crumb-select-item {
  font-size: 14px;
  vertical-align: top;
  padding: 0 4px 0 4px;
  cursor: pointer;
  background: #f3f3f3;
  b {
    font-weight: 400;
    color: #333;
  }
  em {
    color: #ef922e;
    font-style: normal;
  }
}
.bread-crumb {
  width: 100%;
  height: 42px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    width: 1226px;
    .item {
      color: #666666;
      &.active {
        color: #666666;
      }
    }
  }
}
</style>
