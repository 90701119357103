<template>
  <div class="onlySearchPart">
      <div class="content">
          <div class="left">
            <div class="img" @click="goHome">
                <img src="@/assets/logo1.png" alt="">
            </div>
            <div class="title-page">
                <slot name="title">
                    <span>结算页</span>
                </slot>
            </div>
          </div>
          <div class="slot-div">
              <slot></slot>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  methods: {
    goHome () {
      this.$router.push({ // 调转到商品搜索页面
        path: '/home'
        // query: {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.onlySearchPart {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ef922e;
    background-color: white;
    .content {
        width: 1226px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            display: flex;
            align-items: center;
            .img {
                width: 92px;
                height: 95px;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
          .img:hover{
            cursor: pointer;
          }
            .title-page {
                color: #333333;
                font-size: 24px;
                padding-left: 60px;
            }
        }
    }
}
</style>
