import request from '@/router/axios'
// 订单列表
export const orderAjaxList = (params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/myOrders',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: params
  })
}
// 订单详情
export const orderDetail = (params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/orderDetail',
    method: 'post',
    params: {
      id: params
    }
  })
}
// 我的未收货订单数量
export const myWaitForReceivingCount = () => {
  return request({
    url: '/cineplex/homePagePersonalCenter/myOrderStatusCount',
    method: 'post',
    params: {
      orderStatus: ''
    }
  })
}
// 物流路由信息
export const getLogisticsRoutingVos = (params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/getLogisticsRoutingVos',
    method: 'post',
    params: {
      orderNo: params
    }
  })
}

// 物流路由信息
export const getLogisticsList = (params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/getLogisticsList',
    method: 'post',
    params: {
      logisticsOrderNo: params
    }
  })
}
// 订单取消
export const revokeOrder = (params) => {
  return request({
    url: '/cineplex/homePageGoods/revokeOrder',
    method: 'get',
    params: {
      orderNo: params
    }
  })
}
// 订单取消
export const receivingStock = (params) => {
  return request({
    url: '/cineplex/homePageGoods/receivingStock',
    method: 'get',
    params: {
      stockInvoiceId: params
    }
  })
}
// 订单积分二维码
export const getOrderQrCode = (params) => {
  return request({
    url: '/cineplex/homePageGoods/getOrderQrCode',
    method: 'get',
    params: {
      orderNo: params
    }
  })
}
