<template>
  <div class="shipList">
      <shipCard :title="title1" :list="receivingList"></shipCard>
      <wlShipCard :title="title2" :list="logisticsNoList"></wlShipCard>
      <shipCard :title="title3" :list="payInfoList" :noSperate="true"></shipCard>
  </div>
</template>

<script>
import shipCard from './shipCard'
import wlShipCard from './wlShipCard'
export default {
  props: {
    receivingList: {
      type: Array
    },
    logisticsNoList: {
      type: Array
    },
    payInfoList: {
      type: Array
    }
  },
  components: {
    shipCard,
    wlShipCard
  },
  data () {
    return {
      title1: '收货人信息',
      title2: '物流单号',
      title3: '付款信息',
      list3: ['付款方式： 公司转账', '付款状态：部分付款', '付款时间： 2020-11-19 17:31:51']
    }
  }
}
</script>

<style lang="scss" scoped>
.shipList {
    margin-top: 20px;
    padding: 30px 0 30px 0;
    width: 100%;
    box-sizing: border-box;
    background: white;
    display: flex;
}
</style>
