import request from '@/router/axios'

/**
 * 获取商品可使用优惠券
 * @returns {AxiosPromise}
 */
export const getcouponDetails = (par) => {
  return request({
    url: '/cineplex/homePageGoods/getcouponDetails',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: par
  })
}

/**
 * 获取商品可使用代金券
 * @param par
 * @returns {AxiosPromise}
 */
export const getCashCouponDetails = (par) => {
  return request({
    url: '/cineplex/homePageGoods/getCashCouponDetails',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: par
  })
}

/**
 * 获取商品清单
 * @returns {AxiosPromise}
 */
export const getGoodsDetal = (params) => {
  return request({
    url: '/cineplex/homePageGoods/getGoodsDetal',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: params
  })
}

/**
 * 提交订单
 * @returns {AxiosPromise}
 */
export const submitOrder = (params) => {
  return request({
    url: '/cineplex/homePageGoods/submitOrder',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: params
  })
}

/**
 * 校验需要购买的商品是否月结
 * @param params
 * @returns {AxiosPromise}
 */
export const checkGoodsOnLunarKnot = (data) => {
  return request({
    url: '/cineplex/homePageGoods/checkGoodsOnLunarKnot',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: data
  })
}
