<template>
  <div class="content-wrap display_column">
    <div class="header display_column">
      <div class="img_box"><img src="../../assets/logo.png" alt="" /></div>
    </div>
    <div class="content-inner display_column">
      <img src="../../assets/500_icon.png" alt="" />
      <p class="info">抱歉！系统发生错误，建议检查后再试</p>
      <p class="btn" @click="$router.go(-1)">返回上一步</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.content-wrap {
  background: #f5f5f5;
  padding-bottom: 200px;
  width: 100%;
  margin-top: 14px;
  .header {
    width: 100%;
    height: 100px;
    background: #fff;
    .img_box {
      width: 1226px;
      height: 100px;
      display: flex;
      img {
        width: 135px;
        height: 60px;
      }
    }
  }
  .content-inner {
    width: 1226px;
    .info {
      padding: 50px 0;
    }
    .btn {
      width: 170px;
      height: 42px;
      line-height: 42px;
      background: #fff;
      border: 1px solid #ef922e;
      border-radius: 21px;
      color: #ef922e;
    }
  }
}
</style>
