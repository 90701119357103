<template>
  <!--<div class="orderList">
    <div class="title">
      <span>消息通知</span>
    </div>
    <div>
      <div>{{ notifyInfoList.msgTitle }}</div>&lt;!&ndash;标题&ndash;&gt;
      <div>{{ notifyInfoList.msgContent }}</div>&lt;!&ndash;内容&ndash;&gt;
      <div>{{ notifyInfoList.sendTime }}</div>&lt;!&ndash;时间&ndash;&gt;
    </div>
  </div>-->
  <div class="orderList" v-if="msgDetail === '02'">
    <div class="title">
      <div class="left">
        <span style="display: inline-block;width: 90%">消息通知</span>
        <div class="bottom" @click="returnInfo">返回</div>
      </div>
      <div class="div-1">
        <div class="left">
          <div class="msgTitle">{{ msgInfo.msgTitle }}</div><!--标题-->
          <div class="msgContent">{{ msgInfo.msgContent }}</div><!--内容-->
          <div class="msgTime">{{ msgInfo.sendTime }}</div><!--msgInfo.sendTime时间-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { queryMsgList } from '@/api/personCenter/notify/notify'
export default {
  props: {
    msgInfo: {
      type: Object
    },
    msgDetail: {
      type: String
    },
    idx: {
      type: String,
      default: '01'
    }
  },
  data () {
    return {
    }
  },
  methods: {
    returnInfo () {
      this.$emit('upUsemd', '01')
      switch (this.idx) {
        case '01': // '01'全部消息 '02'未读消息 '03'已读消息
          this.$parent.notifyStatusList('')
          break
        case '02':
          this.$parent.notifyStatusList('03')
          break
        case '03':
          this.$parent.notifyStatusList('02')
          break
        default:
          this.$parent.notifyStatusList('')
          break
      }
    }
    /* onLoad (params) {
      queryMsgList(params).then(res => {
        this.notifyInfoList = res.data.data.data
        this.totalNum = res.data.data.totalNum
      })
    } */
  }
  /* mounted () {
    var params = {
      userId: this.currentCustom.customerId,
      readStatus: ''
    }
    this.onLoad(params)
  } */
}
</script>

<style scoped>
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 110px 40px;
}
.title {
  color: #333333;
  font-size: 24px;
}
.div-1 {
  /*display: flex;*/
  justify-content: space-between;
  /*align-items: center;*/
  margin-top: 40px;
  /*text-align: center;*/
}
.bottom {
  float: right;
  color: #ef922e;
  font-size: 15px;
}
.bottom:hover {
  cursor: pointer;
}
.msgTitle {
  text-align: center;
  font-size: 20px;
}
.msgContent {
  font-size: 17px;
  margin-top: 30px;
}
.msgTime {
  font-size: 15px;
  float: right;
  margin-top: 20px;
}
</style>
