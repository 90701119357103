<template>
  <div class="simpleTable" :class="color">
    <div class="son-1">
      <div class="son-1-1">
        <div class="son-1-1-1" :class="{'fix-left': showCheckBox}">
          <div class="checbox" v-if="showCheckBox">
            <el-checkbox v-model="check" @change="checkboxChange" />
          </div>
          <div class="item">
            <img :src="bean.specificationDrawing" alt="" />
          </div>
        </div>
        <div class="son-1-1-2" style="cursor: pointer">
          <span>{{bean.goodsName}}</span>
          <span class="grey">{{bean.specificationName}}</span>
        </div>
      </div>
      <div class="son-1-2">
        <span>X{{bean.num}}</span>
      </div>
    </div>
    <div class="son-2">
      <span>金额：¥{{bean.subtotal}}</span>
    </div>
    <div class="son-3" v-if="false">
      <div class="text">
        <span>{{bean.billStatus}}</span>
      </div>
      <div class="btn" style="cursor: pointer">
        <span>再次购买</span>
      </div>
    </div>
    <div class="son-3" v-if="showCheckBox">
      <div class="text gray">
        <span>未开票</span>
      </div>
      <div class="text-2">
        <span>¥{{bean.notTranslateMoney}}</span>
      </div>
    </div>
    <div class="son-3">
      <div class="text gray">
        <span>本次开票</span>
      </div>
      <div class="text-2">
        <div class="text-input" v-if="edit">
          <el-input disabled v-model="bean.currentInvoiceAmount" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1').replace(/^0{1,}/g,'')" />
        </div>
        <span v-else>¥{{bean.currentInvoiceAmount}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    yIndex: Number,
    color: String,
    showCheckBox: Boolean,
    edit: Boolean,
    checkFlag: Boolean,
    bean: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    checkFlag: function (value) {
      if (value) {
        this.meFlag = true
      } else {
        this.meFlag = false
      }
    }
  },
  computed: {
    check: {
      get () {
        return this.checkFlag || this.meFlag
      },
      set (flag) {
        this.meFlag = flag
      }
    }
  },
  methods: {
    checkboxChange (flag) {
      this.meFlag = flag
      this.$emit('switch', this.index, this.yIndex, flag)
    }
  },
  data () {
    return {
      meFlag: false
    }
  }
}
</script>

<style lang="scss" scoped>
.simpleTable {
  height: 100px;
  display: flex;
  align-items: stretch;
  border: 1px solid #e1e1e1;
  /*border-top: none;*/
  &.orange {
    border-color: #ff5b22;
  }
  color: #333333;
  font-size: 14px;
  .son-1 {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    .son-1-1 {
      display: flex;
      align-items: center;
      .son-1-1-1 {
        display: flex;
        align-items: center;
        &.fix-left {
          margin-left: -10px;
        }
        .checbox {
          margin-right: 10px;
        }
        .item {
          width: 60px;
          height: 60px;
          margin-right: 15px;
          border: 1px solid #e1e1e1;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
      .son-1-1-2 {
        width: 218px;
        white-space: pre-wrap;
        .grey {
          color: #999999;
          font-size: 12px;
          padding-left: 10px;
        }
      }
    }
  }
  .son-2 {
    width: 143px;
    border-left: 1px solid #e1e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .son-3 {
    width: 143px;
    border-left: 1px solid #e1e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    .text {
      &.gray {
        color: #999999;
      }
    }
    .text-2 {
      margin-top: 10px;
    }
    .btn {
      width: 104px;
      height: 30px;
      border: 1px solid #e1e1e1;
      border-radius: 2px;
      margin-top: 10px;
      line-height: 30px;
    }
  }
}
</style>
