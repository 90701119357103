<template>
  <div class="shipCard">
      <div class="ship-item">
          <div class="h1">
              <span>{{title}}</span>
          </div>
          <div class="h2" v-for="(item, index) in list" :key="index">
              <a style="color: #333333" @click="showModal3(item)" >{{item}}</a>
              <div class="modal-page">
                <el-dialog title="物流跟踪" :visible.sync="visible3"  :close-on-click-modal="false" :modal-append-to-body='false' :append-to-body='true'>
                  <template>
                    <div class="wlgz">
                      <div class="text">
                        <!--<p>您的订单预计2020-11-20送达您手中</p>-->
                      </div>
                      <div class="timeline-wrap">
                        <timeline>
                          <timeline-item
                            v-for="(items, ind) in logisticsRoutingVos"
                            :key="ind"
                            :timestamp="items.routingNodeOccurrenceTime"
                            :timestamp-position="items.timestampPosition"
                            :color="items.color"
                            :size='20'
                            :icon="items.icon"
                            :image="require('@/assets/ship1.png')"
                          >
                            <div slot="content">
                              <timelineItemTemp :content="items.routingNodeDesc" :title="items.routingNodeAddress" />
                            </div>
                          </timeline-item>
                        </timeline>
                      </div>
                    </div>
                  </template>
                </el-dialog>
              </div>

          </div>
      </div>
      <div class="sperate" v-if="!noSperate"></div>
  </div>
</template>

<script>
import timeline from '@/components/timeline/timeline'
import timelineItem from '@/components/timeline/timelineItem'
import timelineItemTemp from '@/components/timeline/timelineItemTemp'
import { getLogisticsRoutingVos } from '@/api/order/orderInfo/index'
export default {
  components: {
    timeline,
    timelineItem,
    timelineItemTemp
  },
  data: function () {
    return {
      visible3: false,
      logisticsRoutingVos: [{
        size: '20',
        timestampPosition: 'left',
        routingNodeOccurrenceTime: '',
        image: require('@/assets/ship1.png'),
        routingNodeDesc: '',
        routingNodeAddress: ''
      }
      ]
    }
  },
  props: ['title', 'list', 'noSperate'],
  methods: {
    // 查询物流信息
    showModal3 (logisticsNo) {
      getLogisticsRoutingVos(logisticsNo).then(res => {
        this.logisticsRoutingVos = res.data.data
      })
      this.visible3 = true
    }
  }
}
</script>

<style lang="scss" scoped>
.shipCard {
    position: relative;
    width: 388px;
    padding-left: 50px;
    box-sizing: border-box;
    .ship-item {
        text-align: left;
        color: #333333;
        font-size: 14px;
        .h1 {
            font-size: 16px;
            margin-bottom: 18px;
        }
        .h2 {
            margin-top: 16px;
        }
    }
    .sperate {
        position: absolute;
        width: 1px;
        height: 100%;
        background: linear-gradient(#ffffff, #E1E1E1, #ffffff);
        right: 0;
        top: 0;
    }

}
.wlgz {
  padding: 30px 50px;
  text-align: left;
  .text {
    color: #333333;
    font-size: 14px;
  }
}
.timeline-wrap {
  margin-top: 30px;
}
</style>
