<template>
  <div class="tableStatus" :class="color">
      <slot>
          <div class="text">
            <span>{{ orderTime }}</span>
            <span>{{ orderName }}：{{ orderNo }}</span>
            <span style="color: #ef922e;text-decoration: underline;" @click="oderDetail(id)" v-if="orderName=='订单号'" class="orderDetail_bnt">订单详情</span>
            <span style="color: #ef922e;text-decoration: underline;" @click="showModal3(logisticsOrderNo)" v-if="orderName=='发货单号'" class="orderDetail_bnt">物流追踪</span>
            <span>收货人：{{ receivingName }}</span>
            <span v-if="orderName=='订单号'"> 订单状态：{{ orderStatus }}</span>
            <span v-if="orderName=='发货单号'"> 收货状态：<span v-if="orderStatus === '01'">已收货</span><span v-if="orderStatus === '02'">未收货</span></span>
            <!--<span style="color: #ef922e;padding-left: 7px;padding-right: 15px;text-decoration: underline;">付款信息</span>-->
            <span v-if="orderName!='发货单号'">{{ amount }}¥{{ orderTotalSum }}</span>
            <span style="color: rgb(34 61 255);" class="orderDetail_bnt" v-if="orderName=='发货单号' && orderStatus === '02'" @click="receivingStock(id)">确认收货</span>
            <!--<span style="color: #ef922e;" v-if="orderTotalSum>0">¥{{ orderTotalSum }}</span>-->
            <span style="color: rgb(34 61 255);" class="orderDetail_bnt" v-if="orderStatus == '待付款' && sourceSystem !== 'BO'" @click="cancelOrder(orderNo)">取消订单</span>
            <span style="color: #FFC107;" class="orderDetail_bnt" v-if="orderStatus == '已完成' && isIntegral && isIntegral === '01'" @click="getIntegral(orderNo)">
<!--              <div class="box" v-if="clickFlag">二维码  <div class="close" @click="clickFlag=false">X</div></div> -->
<el-popover
  placement="top"
  width="100"
  height="auto"
  v-model="visiblex">
  <div style="text-align: right; margin: 0" v-if="clickFlag">
    <img style="width:100%;height: auto;" v-if="isShow" :src="integralUrl"/><!--isShow ./123456.jpg -->
  </div>
    <span v-if="isShow === false">正在生成二维码,请稍后...</span>
  <el-button style="border:none;background:none; color:rgb(255, 193, 7);font-size: 12px !important;" slot="reference" >扫码积分</el-button>
</el-popover>

              </span>
            <span v-else-if="orderStatus == '已完成' && isIntegral && isIntegral === '02'" style="border:none;background:none; color:rgb(255, 193, 7);font-size: 12px !important;" slot="reference">已积分</span>
            <div class="modal-page">
              <el-dialog title="物流跟踪" :visible.sync="visible3"  :close-on-click-modal="false" :modal-append-to-body='false' :append-to-body='true'>
                <template>
                  <div class="wlgz">
                    <div class="text">
                     <!-- <p>您的订单预计2020-11-20送达您手中</p>-->
                    </div>
                    <div class="timeline-wrap">
                      <timeline>
                        <timeline-item
                          v-for="logistics in logisticsRoutingVos"
                          :key="logistics.id"
                          :timestamp="logistics.routingNodeOccurrenceTime"
                          :timestamp-position="logistics.timestampPosition"
                          :color="logistics.color"
                          :size='20'
                          :icon="logistics.icon"
                          :image="require('@/assets/ship1.png')"
                        >
                          <div slot="content">
                            <timelineItemTemp :content="logistics.routingNodeDesc" :title="logistics.routingNodeAddress" />
                          </div>
                        </timeline-item>
                      </timeline>
                    </div>
                  </div>
                </template>
              </el-dialog>
            </div>
        </div>
        <div class="right">
            <img src="@/assets/msg.png" v-if="flag!=1" alt="联系客服" class="orderDetail_bnt" @click="kefu()">
        </div>
        <el-dialog title="取消成功" style="text-align:center;font-size: 22px;" :visible.sync="dialogVisible" width="500px">
          <div class="a1">
            <span><span style="color: #ef922e">提示:</span>若您已汇款，请及时联系客服</span>&nbsp;<a style="color:blue;text-decoration: none;" href="javascript:kefuNative();" target="_blank">客服连接</a>
          </div>
        </el-dialog>
      </slot>
  </div>
</template>

<script>
import timeline from '@/components/timeline/timeline'
import timelineItem from '@/components/timeline/timelineItem'
import timelineItemTemp from '@/components/timeline/timelineItemTemp'
import { getLogisticsRoutingVos, revokeOrder, receivingStock, getOrderQrCode } from '@/api/order/orderInfo/index'
// import sha1 from 'js-sha1'
import { mapGetters } from 'vuex'
import { guid } from '@/util/utils'
export default {
  computed: {
    ...mapGetters(['userInfo'])
  },
  components: {
    timeline,
    timelineItem,
    timelineItemTemp
  },
  props: {
    orderTime: {
      type: String
    },
    orderNo: {
      type: String
    },
    orderName: {
      type: String
    },
    isIntegral: {
      type: String
    },
    searchParams: {
      type: Object
    },
    flag: {
      type: Number
    },
    logisticsOrderNo: {
      type: String
    },
    sourceSystem: {
      type: String
    },
    amount: {
      type: String
    },
    receivingName: {
      type: String
    },
    orderStatus: {
      type: String
    },
    orderTotalSum: {
      type: Number,
      default: 0.00
    },
    color: {
      type: String
    },
    id: {
      type: String
    }
  },
  data: function () {
    return {
      visible3: false,
      dialogVisible: false,
      orderId: '',
      integralUrl: '',
      logisticsRoutingVos: [{
        size: '20',
        timestampPosition: 'left',
        routingNodeOccurrenceTime: '',
        image: require('@/assets/ship1.png'),
        routingNodeDesc: '',
        routingNodeAddress: ''
      }
      ],
      customer_info: {},
      clickFlag: false,
      isShow: false,
      visiblex: false
    }
  },
  methods: {
    randomn (n) {
      if (n > 21) return null
      return parseInt((Math.random() + 1) * Math.pow(10, n - 1))
    },
    kefu () {
      var url = 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626&product_订单编号=' + encodeURIComponent(this.orderNo) + '&product_title=' + encodeURIComponent('订单信息') + '&product_send=true'
      url += '&signature=true&randomn=' + guid()
      this.$store.commit('SET_IFRAME_SRC', url)
      // this.customer_info.nonce = this.randomn(6)
      // this.customer_info.timestamp = new Date().getTime()
      // var name = this.userInfo.operatorName
      // if (name) {
      //   this.customer_info.web_token = name
      //   var signSnonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&e9402bfd15f27fba780d906ce451e3c4`
      //   this.customer_info.signature = sha1(signStr).toUpperCase()
      //   url = url + `&nonce=${this.customer_info.nonce}&timestamp=${this.customer_info.timestamp}&web_token=${this.customer_info.web_token}&signature=${this.customer_info.signature}&c_name=${encodeURIComponent(name)}&c_desc=kk`
      // }
      // this.openOne(urtr = `l, 'myOrder')
    },
    kefuNative () {
      var url = 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626&signature=true&randomn=' + guid()
      this.$store.commit('SET_IFRAME_SRC', url)
    },
    // openOne (url, name) {
    //   var win = window.open('', name)
    //   try {
    //     if (win.location.href === 'about:blank') {
    //       //  窗口不存在
    //       win = window.open(url, name)
    //     } else {
    //       win.close()
    //       //  窗口以已经存在了
    //       //  win.focus()
    //       window.open(url, name)
    //     }
    //   } catch (e) {
    //     //  win.focus()
    //     win.close()
    //     window.open(url, name)
    //   }
    // },
    // 扫码积分
    getIntegral (orderNo) {
      getOrderQrCode(orderNo).then(res => {
        if (res.data.data != null && res.data.data.length > 0) {
          this.integralUrl = res.data.data
          debugger
          this.clickFlag = true
          this.isShow = true
        } else {
          this.clickFlag = false
          this.isShow = false
        }
      })
    },
    // 订单取消弹窗
    cancelOrder (id) {
      this.$confirm('此操作将取消订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var lod = this.$loading()
        revokeOrder(id).then(res => {
          // location.reload()
          lod.close()
          this.dialogVisible = true
          var pam = this.searchParams
          var obj = pam
          this.$emit('onLoad', obj, obj)
        }, error => {
          console.log(error)
          lod.close()
          var arr = error.toString().split(':')
          this.$notify({
            title: '提示',
            message: arr[1],
            type: 'warning',
            duration: 3000
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 查询物流信息
    showModal3 (logisticsNo) {
      if (logisticsNo === null || logisticsNo === undefined || logisticsNo === '' || logisticsNo === ' ') {
        this.$notify({
          title: '物流提示',
          message: '暂时没有物流信息',
          type: 'warning',
          duration: 3000
        })
      } else {
        getLogisticsRoutingVos(logisticsNo).then(res => {
          this.logisticsRoutingVos = res.data.data
        })
        this.visible3 = true
      }
    },
    receivingStock (id) {
      receivingStock(id).then(res => {
        var pam = this.searchParams
        var obj = pam
        this.$emit('onLoad', obj, obj)
        this.$notify({
          title: '提示',
          message: '成功',
          type: 'warning',
          duration: 3000
        })
      })
    },
    oderDetail (id) {
      var blan = this.$router.resolve({ // 调整到详情界面
        path: '/myOrder',
        query: { id: id }
      })
      window.open(blan.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.a1 {
  height: 50px;
  margin-top: 50px;
  font-size: 16px;
}
/deep/ .el-dialog{
  .el-dialog__header {
    margin-left: 180px;
    font-size: 30px;
  }
}
/*  H 定位 */
.orderDetail_bnt:hover {
  cursor: pointer;
}
.orderDetail_bnt{
  display:inline-block;
  position: relative;
  .box{
    position: absolute;
    left:10px;
    top: -88px;
    width: 80px;
    height: 80px;
    background:#eeeeee;
    .close{
      position: relative;
      z-index:1000;
      left:62px;
      top:-10px;
    }
  }
}
.wlgz {
  padding: 30px 50px;
  text-align: left;
  .text {
    color: #333333;
    font-size: 14px;
  }
}
.timeline-wrap {
  margin-top: 30px;
}
.tableStatus {
  .text > span {
    padding: 0 10px !important;
  }
    height: 40px;
    background: #F5F5F5;
    border: 1px solid #E1E1E1;
    &.orange {
        background: #FDF5EF;
        border: 1px solid #ef922e;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    color: #333333;
    font-size: 12px;
}
</style>
