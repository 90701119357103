<template>
  <div>
   <myCinemaList :isActive="isActive" :goodsInfo="goodsInfo" :customerCinema="customerCinema" :cinemaInfo="cinemaInfo" @getCinemas="onLoad"/>
  </div>
</template>

<script>
import myCinemaList from './components/myCinemaList'
import { getCinemas, findOneGoodsInfoId } from '@/api/personCenter/cinemaInfo/index'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['currentCustom'])
  },
  components: {
    myCinemaList
  },
  data () {
    return {
      breadCrumbs: [{
        label: '首页'
      }, {
        label: '个人中心'
      }],
      customerCinema: {},
      cinemaInfo: [],
      isActive: false,
      goodsInfo: {}
    }
  },
  methods: {
    onLoad () {
      getCinemas(this.currentCustom.customerId).then(res => {
        this.customerCinema = res.data.data
        res.data.data.cinemaInfoVos.forEach(item => {
          if (item.serverSerialNum) {
            item.isType = true
          } else {
            item.isType = false
          }
          this.cinemaInfo.push(item)
        })
        // this.cinemaInfo = res.data.data.cinemaInfoVos
        if (this.customerCinema.specialOfficeCode) {
          this.isActive = true
        } else {
          this.isActive = false
        }
        // console.log(this.cinemaInfo, '影厅信息1')
        // console.log(this.customerCinema, '影院信息2')
      })
      findOneGoodsInfoId().then(res => {
        this.goodsInfo = res.data.data
        // console.log(this.goodsInfo, '商品id')
      })
    }
  },
  created () {
    this.onLoad()
  }
}
</script>

<style lang="scss" scoped>
.myOwnOrder {
    width: 100%;
}
</style>
