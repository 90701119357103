<template>
  <div class="simpleTable" :class="color">
    <div class="son-1">
      <div class="son-1-1">
        <div class="son-1-1-1">
          <img :src="specificationDrawing" alt="">
        </div>
        <div class="son-1-1-2">
          <span class="goodsName">{{ goodsName }}</span>
          <div class="grey overflow_two" style="cursor:pointer;" :title="commoditySubTitle">{{ commoditySubTitle }}</div>
        </div>
      </div>
      <div class="son-1-2">
        <span>X {{ num }}</span>
      </div>
    </div>
    <div class="son-2">
      <span>金额：¥{{ actualAmount }}</span>
    </div>
    <div class="son-3">
      <!--<div class="text">
              <span>待发货</span>
          </div>-->
      <div class="btn">
        <span @click="goodsDetail(goodsId, goodsName)" class="orderStatus_stl">再次购买</span>
      </div>
    </div>
  </div>
</template>

<script>
import { hits } from '@/api/hits'
export default {
  methods: {
    goodsDetail (goodsId, goodsName) {
      hits({
        type: '01',
        goodsId: goodsId,
        goodsName: goodsName,
        sourceSection: '05'
      })
      var blan = this.$router.resolve({ // 调转到商品详情页面
        path: '/detail',
        query: {
          id: goodsId,
          commodityName: goodsName
        }
      })
      window.open(blan.href, '_blank')
    }
  },
  props: {
    goodsName: {
      type: String,
      default: ''
    },
    goodsId: {
      type: String
    },
    commoditySubTitle: {
      type: String,
      default: ''
    },
    specificationDrawing: {
      type: String,
      default: ''
    },
    num: {
      type: Number,
      default: 0.00
    },
    actualAmount: {
      type: Number,
      default: 0.00
    },
    color: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.orderStatus_stl:hover {
  cursor: pointer;
}
.simpleTable {
  height: 100px;
  display: flex;
  align-items: stretch;
  border: 1px solid #e1e1e1;
  border-top: none;
  &.orange {
    border-color: #ef922e;
  }
  color: #333333;
  font-size: 14px;
  .son-1 {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    .son-1-1 {
      display: flex;
      align-items: center;
      .son-1-1-1 {
        width: 60px;
        height: 60px;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .son-1-1-2 {
        width: 218px;
        white-space: pre-wrap;
        margin-left: 10px;
        .goodsName {
          font-size: 12px;
        }
        .grey {
          color: #999999;
          font-size: 12px;
          // padding-left: 10px;
          height: 24px;
          overflow: hidden;
        }
      }
    }
  }
  .son-2 {
    width: 160px;
    border-left: 1px solid #e1e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .son-3 {
    width: 160px;
    border-left: 1px solid #e1e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    .btn {
      width: 104px;
      height: 30px;
      border: 1px solid #e1e1e1;
      border-radius: 2px;
      margin-top: 10px;
      line-height: 30px;
    }
  }
}
</style>
