<template>
  <infoForm/>
</template>

<script>
// import searchPart from '@/components/searchPart'
// import breadCrumbs from '@/components/breadCrumbs'
// import contentWrap from '@/components/contentWrap'
import infoForm from './components/infoForm'
export default {
  components: {
    // searchPart,
    // contentWrap,
    infoForm
    // breadCrumbs
  },
  data () {
    return {
      breadCrumbs: [{
        label: '首页'
      }, {
        label: '个人中心'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.myOwnOrder {
    width: 100%;
}
</style>
