<template>
  <div class="get-addrrss">
    <div class="form-div">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="90px">
        <el-form-item label="收货人:" prop="consignee">
          <div class="item-div">
            <el-input v-model="ruleForm.consignee"/>
          </div>
        </el-form-item>
        <el-form-item label="所在地区:" prop="area">
          <div class="item-div">
            <el-cascader
              ref="cascaderAddr"
              size="large"
              width="300px"
              :options="options"
              v-model="ruleForm.attribute1"
              @change="handleChange"
            >
            </el-cascader>
            <!--            <el-input v-model="ruleForm.area"/>-->
          </div>
        </el-form-item>
        <el-form-item label="详细地址:" prop="address">
          <div class="item-div">
            <el-input v-model="ruleForm.address"/>
          </div>
        </el-form-item>
        <el-form-item label="手机号:" prop="tel">
          <div class="item-div">
            <el-input maxlength="11" v-model="ruleForm.tel"/>
          </div>
        </el-form-item>
        <el-form-item label="固定电话:" prop="fixedTel">
          <div class="item-div">
            <el-input maxlength="13" v-model="ruleForm.fixedTel"/>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="modal-btn2 active mg-auto" @click="addSubmit" style="cursor: pointer">
      <span >保存地址信息</span>
    </div>
  </div>
</template>

<script>
import { doInsertAddress, doUpdateAddress } from '@/api/personCenter/addressInfo/addressL'
import { regionData } from 'element-china-area-data'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['currentCustom'])
  },
  props: {
    edit: Boolean,
    ruleForm: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    handleChange (e) {
      this.ruleForm.area = this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels
      var areaName = this.ruleForm.area
      this.ruleForm.area = ''
      for (var i = 0; i < areaName.length; i++) {
        if (i !== areaName.length - 1) {
          this.ruleForm.area += areaName[i] + '/'
        } else {
          this.ruleForm.area += areaName[i]
        }
      }
      /* areaName.forEach(ele => {
        this.ruleForm.area += ele
      }) */
    },
    addSubmit () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.edit) {
            doUpdateAddress(this.ruleForm).then(() => {
              this.$emit('closeModal4', false)
            })
          } else {
            this.ruleForm.customerId = this.currentCustom.customerId
            this.ruleForm.customerName = this.currentCustom.customerName
            doInsertAddress(this.ruleForm).then(() => {
              this.$emit('closeModal4', true)
            })
          }
        } else {
          return false
        }
      })
    }
  },
  data () {
    return {
      active: true,
      options: regionData,
      rules: {
        consignee: [
          { required: true, message: '请输入收货人姓名', trigger: 'change' }
        ],
        area: [
          { required: true, message: '请输入所在地区', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'change' }
        ],
        // fixedTel: [
        //   { required: true, message: '请输入固定电话', trigger: 'change' }
        // ],
        tel: [
          { required: true, pattern: /^1[0-9]{10}$/, message: '格式不正确', trigger: 'change' }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.get-addrrss {
  padding: 40px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-div {
  margin-left: 80px;
}
.item-div {
  width: 342px;
  position: relative;
  display: flex;
}
.mg-auto {
  margin: 30px auto;
}
.invoice-content {
  display: flex;
  align-items: center;
  .btn {
    width: 166px;
    height: 40px;
    border: 1px solid #E1E1E1;
    border-radius: 2px;
    line-height: 40px;
    text-align: center;
    color: #333333;
    font-size: 14px;
    &.active {
      border-color: #ef922e;
      color: #ef922e;
    }
  }
}
</style>
