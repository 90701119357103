import request from '@/router/axios'

/**
 * 修改密码
 * @returns {AxiosPromise}
 */
export const editPwd = (params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/editPwd',
    method: 'post',
    params: {
      userCode: params.userCode,
      password: params.password,
      newPassword: params.newPassword
    }
  })
}
