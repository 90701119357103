import request from '@/router/axios'
// 商品类别
export const categoryAjaxList = () => {
  return request({
    url: '/cineplex/homePage/categoryTree',
    method: 'post'
  })
}
// 商品推荐
export const goosRecommendedationAjaxList = (params) => {
  return request({
    url: '/cineplex/homePage/goosRecommendedation',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: params
  })
}
// 商品详情
export const goodsInfoDetail = (params) => {
  return request({
    url: '/cineplex/homePage/goodsInfoDetail',
    method: 'post',
    params: {
      id: params
    }
  })
}
// 商品详情下面的tab页签
export const goodsInfoBelowDetail = (params) => {
  return request({
    url: '/cineplex/homePage/goodsInfoBelowDetail',
    method: 'post',
    params: {
      id: params
    }
  })
}
// 商品详情图片
export const goodsInfoDetailPic = (params) => {
  return request({
    url: '/cineplex/homePage/goodsInfoDetailPic',
    method: 'post',
    params: {
      id: params
    }
  })
}
// 加入购物车
export const mergeShoppingCart = (params) => {
  return request({
    url: '/cineplex/homePageGoods/mergeShoppingCart',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: params
  })
}
// 加入购物车
export const getGoodsCom = (params) => {
  return request({
    url: '/cineplex/homePageGoods/getGoodsCom?goodsId=' + params,
    method: 'get'
  })
}
// 首页商品推荐
export const homePageGoosRecommendedation = (params) => {
  return request({
    url: '/cineplex/homePage/homePageGoosRecommendedation',
    method: 'post'
  })
}
// 首页商品标签
export const homePageGoodsLabel = (params) => {
  return request({
    url: '/cineplex/homePage/homePageGoodsLabel',
    method: 'post'
  })
}
// 商品排行榜
export const goosRecommendedationRanking = (params) => {
  return request({
    url: '/cineplex/homePage/goosRecommendedationRanking',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: params
  })
}
// 商品(今日特价)
export const goodsSpecialPrice = () => {
  return request({
    url: '/cineplex/homePage/goodsSpecialPrice',
    method: 'post'
  })
}
// 商品根据标签放不同的模块
export const goodsLabelDiscounts = (params) => {
  return request({
    url: '/cineplex/homePage/goodsLabelDiscounts',
    method: 'post',
    params: {
      goodsLabel: params
    }
  })
}
// 商品详情规格
export const goodsSpecifications = (row, params, customerId) => {
  return request({
    url: '/cineplex/homePage/goodsSpecifications',
    method: 'POST',
    params: {
      customerId: customerId,
      goodsId: row,
      params: params
    }
  })
}
// 商品详情规格
export const goodsSpecificationsToCom = (row, params) => {
  return request({
    url: '/cineplex/homePage/goodsSpecificationsToCom',
    method: 'POST',
    params: {
      goodsId: row,
      params: params
    }
  })
}
// 商品详情规格价格
export const getSpecificationsPrice = (row, params, customerId) => {
  return request({
    url: '/cineplex/homePage/getGoodsPrice',
    method: 'POST',
    params: {
      customerId: customerId,
      goodsId: row,
      params: params
    }
  })
}
// 首页商品(2021-02-04)
export const getGoodsByIds = () => {
  return request({
    url: '/cineplex/homePage/getGoodsByIds',
    method: 'POST'
  })
}
// 五一档,见面礼(2021-02-04)
export const getLabelVos = () => {
  return request({
    url: '/cineplex/homePage/getLabelVos',
    method: 'POST'
  })
}

// 商品优惠券
export const goodsCoupons = (params, customerId) => {
  return request({
    url: '/cineplex/homePage/goodsCoupons',
    method: 'post',
    params: {
      id: params,
      customerId: customerId
    }
  })
}

// 首页改版通知阅读状态
export const getOperator = () => {
  return request({
    url: '/cineplex/homePage/getOperator',
    method: 'get'
  })
}

// 修改首页改版通知阅读状态
export const updateOperator = () => {
  return request({
    url: '/cineplex/homePage/updateOperator',
    method: 'get'
  })
}

// 首页新年通知阅读状态
export const getSpringFestival = () => {
  return request({
    url: '/cineplex/homePage/getSpringFestival',
    method: 'get'
  })
}

// 修改首页新年通知阅读状态
export const updateSpringFestival = () => {
  return request({
    url: '/cineplex/homePage/updateSpringFestival',
    method: 'get'
  })
}
// 首页下面标签商品
export const findLabelShowList = (customerId) => {
  return request({
    url: '/cineplex/homePage/findLabelShowList',
    method: 'get',
    params: {
      customerId: customerId
    }
  })
}
