<template>
  <div class="pay">
    <onlyLogoPart>
      <steps></steps>
    </onlyLogoPart>
    <contentWrap>
      <payContent :AddressList="AddressList"
                  :confirmAdd="confirmAdd"
                  :coupnoList="coupnoList"
                  :voucherList="voucherList"
                  :isMonthlyPayment="isMonthlyPayment"
                  :iscoupon="iscoupon"
                  :isVoucher="isVoucher"
                  :goodsOrder="goodsOrder"
                  :remainingQuantity="myTotalZGB.leftNum"
                   @upUseCoin="upUseCoin" @edit="edit"></payContent>
      <div class="all-moneny">
        <p>
          <span class="span1">应付总额：</span>
          <span class="span2">¥{{orderMain.amountDue}}</span>
        </p>
        <!-- <p class="p2">寄送至：北京市 朝阳区 十八里店乡 张家店东街273号 收货人：张先生 139****111</p> -->
        <p class="p2">寄送至： {{addressPath}}</p>
      </div>
      <div class="bottom-div">
        <div class="text" v-if="false">
          本订单收货确认后，可产生 <span style="color: #ef922e">{{produceZhongGuang}}</span> 个中光币
        </div>
        <div class="btn" @click="sunmitOrder">
          <span>提交订单</span>
        </div>
      </div>
    </contentWrap>
  </div>
</template>

<script>
import onlyLogoPart from '@/components/onlyLogoPart'
import contentWrap from '@/components/contentWrap'
import steps from '@/components/steps'
import payContent from './components/payContent'
import { doInsertAddress, findAddress, doUpdateAddress, doDeleteAddress, doUpdateOperatorAddress } from '@/api/personCenter/addressInfo/addressL'
import { myTotalZGB } from '@/api/personCenter/myzgb/index'
import { getcouponDetails, getCashCouponDetails, getGoodsDetal, submitOrder, checkGoodsOnLunarKnot } from './components/goodsOrder'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['cartList', 'currentCustom'])
  },
  components: {
    onlyLogoPart,
    contentWrap,
    steps,
    payContent
  },
  data () {
    return {
      // 已勾选的优惠券
      iscoupon: {
        num: 999,
        maney: 0.00,
        id: ''
      },
      // 已勾选的代金券
      isVoucher: {
        maney: 0.00
      },
      AddressList: [],
      confirmAdd: 0.00,
      addressPath: '',
      voucherIdx: 999,
      isMonthlyPayment: '',
      produceZhongGuang: 0,
      coupnoList: [],
      goodsOrder: {
        remarker: '', // beizhu
        goodsList: [],
        totalPiece: 0.00,
        totalAmont: 0.00,
        AvailableCoin: 0 // 本单可使用
      },
      zhongGuangRule: {
        minimumProductionAmount: 0, // 产生最低金额
        generationRuleProportion: 0.00, // 产生比例
        minimumConsumptionAmount: 0, // 消费最低金额
        maxProportionOfConsumption: 0.00 // 消费比例
      },
      myTotalZGB: {
        leftNum: 0
      },
      orderMain: {
        goodsList: [], // 商品清单
        orderNo: '',
        addressId: '', // 地址id
        couponId: '', // 优惠券id
        voucherId: [], // 代金券id
        remarker: '', // 备注
        useCoin: 0.00, // 用户使用中光比个数
        AvailableCoin: 0, // 本单可使用中光币个数
        amountDue: 0.00 // 成交总金额
      },
      voucherList: []
      /* AddressList: [
        {
          id: '5745845487',
          customerId: '6633224847',
          customerName: '黑天',
          consignee: '黑大爷',
          tel: '13313515110',
          fixedTel: '5163888',
          area: '黑龙江',
          address: '黑龙江省密山市知一镇归仁村',
          isDefault: '02',
          confirmAdd: false,
          operatId: '123465535',
          isFromK3: '02',
          email: '123@163.com',
          addressAlias: '黑'
        },
        {
          id: '57458454875541',
          customerId: '6633224847',
          customerName: '黑天',
          consignee: '徐缺',
          tel: '13327410614',
          fixedTel: '5163888',
          area: '山西省',
          address: '太原市万柏林区迎泽桥西前北屯路水利设计院405',
          isDefault: '01',
          confirmAdd: true,
          operatId: '123465535',
          isFromK3: '01',
          email: '129@163.com',
          addressAlias: '黑'
        }
      ] */
    }
  },
  methods: {
    add (addressFrom) {
      addressFrom.customerId = this.currentCustom.customerId
      addressFrom.customerName = this.currentCustom.customerName
      doInsertAddress(addressFrom).then(res => {
        addressFrom.id = res.data.data
        this.AddressList.push(addressFrom)
        if (this.AddressList.length === 1) {
          this.analysis(this.AddressList[0], 0)
        }
      })
    },
    edit (addressFrom, i) {
      /* var obj = JSON.parse(JSON.stringify(addressFrom))
      var areaArr = obj.areaBack
      obj.area = ''
      for (var j = 0; j < areaArr.length; j++) {
        obj.area += areaArr[j]
      } */
      doUpdateAddress(addressFrom).then(res => {
        this.AddressList[i].address = addressFrom.address
        this.AddressList[i].tel = addressFrom.tel
        this.AddressList[i].consignee = addressFrom.consignee
        this.AddressList[i].area = addressFrom.area
        this.AddressList[i].fixedTel = addressFrom.fixedTel
        this.AddressList[i].attribute1 = addressFrom.attribute1
      })
    },
    del (i, id) {
      doDeleteAddress(id).then(res => {
        if (this.confirmAdd > i) {
          this.confirmAdd = this.confirmAdd - 1
        } else if (this.confirmAdd === i) {
          this.confirmAdd = 0
        }
        this.AddressList.splice(i, 1)
        this.analysis(this.AddressList[this.confirmAdd], this.confirmAdd)
      })
    },
    setDefult (i, id) {
      doUpdateOperatorAddress(id).then(res => {
        for (var a = 0; a < this.AddressList.length; a++) {
          if (a === i) {
            this.AddressList[a].isDefault = '01'
          } else {
            this.AddressList[a].isDefault = '02'
          }
        }
      })
    },
    analysis (address, i) { // 勾选地址
      if (this.AddressList.length === 0) {
        this.addressPath = ''
        this.orderMain.addressId = ''
      }
      this.confirmAdd = i
      var tels = address.tel.substring(0, 3) + '****' + address.tel.substring(address.tel.length - 4, address.tel.length)
      this.addressPath = address.area + '  ' + address.address + '  收货人：' + address.consignee + '  ' + tels + '   ' + address.fixedTel
      this.orderMain.addressId = address.id
    },
    setConpon (i) { // 勾选优惠劵
      if (this.iscoupon.num === i) {
        this.iscoupon.num = 999
        this.iscoupon.id = ''
        this.iscoupon.maney = 0.00
      } else {
        this.iscoupon.num = i
        this.iscoupon.id = this.coupnoList[i].id
        this.iscoupon.maney = parseFloat(this.coupnoList[i].faceValue).toFixed(2)
      }
      this.orderMain.couponId = this.iscoupon.id
      this.calculation()
    },
    setVoucher (i) { // 勾选代金券
      var lod = this.$loading()
      console.log('代金券索引:', i)
      if (this.voucherList[i].attribute2 === true) {
        // 取消勾选
        // this.isVoucher.maney = parseFloat(parseFloat(this.isVoucher.maney) - parseFloat(this.voucherList[i].faceValue)).toFixed(2)
        this.orderMain.voucherId.splice(this.orderMain.voucherId.indexOf(this.voucherList[i].id), 1)
        this.voucherList[i].attribute2 = false
      } else {
        if (parseFloat(this.orderMain.amountDue) <= 0) {
          this.$notify({
            title: '警告',
            message: '应付总额已为0元,无法继续折扣!',
            type: 'warning',
            duration: 2000
          })
          lod.close()
          return
        }
        // 添加勾选
        // this.isVoucher.maney = parseFloat(parseFloat(this.isVoucher.maney) + parseFloat(this.voucherList[i].faceValue)).toFixed(2)
        this.orderMain.voucherId.push(this.voucherList[i].id)
        this.voucherList[i].attribute2 = true
      }
      // 清空勾选的优惠券
      this.iscoupon.num = 999
      this.iscoupon.id = ''
      this.iscoupon.maney = 0.00
      this.orderMain.couponId = ''
      // 重新获取可用的优惠券
      this.cartList.voucherId = this.orderMain.voucherId
      console.log('变更后 代金券 ids:', this.cartList.voucherId)
      var params = {
        json: this.cartList,
        customerId: this.currentCustom.customerId
      }
      this.voucherIdx = i
      getcouponDetails(params).then(res => {
        lod.close()
        var code = res.data.data.code
        var totalVoucher = res.data.data.totalVoucher
        if (code) {
          this.voucherList[this.voucherIdx].attribute2 = false
          this.orderMain.voucherId.splice(this.voucherList.indexOf(this.voucherList[this.voucherIdx].id), 1)
          // this.isVoucher.maney = parseFloat(parseFloat(this.isVoucher.maney) - parseFloat(this.voucherList[this.voucherIdx].faceValue)).toFixed(2)
          this.$notify({
            title: '警告',
            message: res.data.data.msg,
            type: 'warning',
            duration: 2000
          })
          return
        }
        this.isVoucher.maney = totalVoucher
        // 获取可用的优惠券
        this.coupnoList = res.data.data.coupons
        var ind = []
        for (var i = 0; i < this.coupnoList.length; i++) {
          if (this.coupnoList[i].attribute1 === 'no') {
            ind.push(i)
          } else {
            this.coupnoList[i].faceValue = parseFloat(this.coupnoList[i].faceValue).toFixed(2)
          }
        }
        // debugger
        if (ind.length > 0) {
          for (var j = ind.length - 1; j >= 0; j--) {
            this.coupnoList.splice(ind[j], 1)
          }
        }
        // console.log(this.coupnoList)
        this.calculation(totalVoucher)
      })
    },
    upUseCoin (n) { // 填写中光币
      this.orderMain.useCoin = n
      this.calculation()
    },
    calculation () { // 计算
      // 获取所有选中的代金券金额
      var voucher = 0.00
      /* this.voucherList.forEach(item => {
        if (item.attribute2 === true) {
          voucher = voucher + parseFloat(item.faceValue)
        }
      }) */
      if (this.isVoucher.maney) {
        voucher = this.isVoucher.maney
      }
      this.orderMain.amountDue = parseFloat(this.goodsOrder.totalAmont).toFixed(2)
      this.orderMain.amountDue = (this.orderMain.amountDue - this.iscoupon.maney - voucher).toFixed(2) <= 0 ? 0.00 : (this.orderMain.amountDue - this.iscoupon.maney - voucher).toFixed(2)
      this.orderMain.amountDue = (this.orderMain.amountDue - this.orderMain.useCoin).toFixed(2)
      if (this.zhongGuangRule.minimumProductionAmount <= this.orderMain.amountDue) { // 判断总金额是否大于最低产生金额
        this.produceZhongGuang = Math.floor(this.orderMain.amountDue * this.zhongGuangRule.generationRuleProportion)
      }
    },

    // 21-04-12 校验限制区域 start
    checkAddress () {
      var goodsArr = this.goodsOrder.goodsList
      var address = this.AddressList[this.confirmAdd]
      var addArr = []
      for (var j = 0; j < goodsArr.length; j++) {
        if (goodsArr[j].areaCode) {
          var addCode = JSON.parse(goodsArr[j].areaCode)
          if (addCode.length > 0) {
            addCode.forEach(attr => {
              addArr.push(attr)
            })
          }
        }
      }
      for (var i = 0; i < addArr.length; i++) {
        if (address.attribute1) {
          var newAdd = addArr[i]
          var lodAdd = address.attribute1
          if (newAdd.length === 1) {
            if (newAdd[0] === lodAdd[0]) {
              return true
            }
          } else if (newAdd.length === 2) {
            if (newAdd[1] === lodAdd[1]) {
              return true
            }
          } else if (newAdd.length === 3) {
            if (newAdd[2] === lodAdd[2]) {
              return true
            }
          }
        } else {
          return true
        }
      }
      return false
    },
    // 21-04-12 校验限制区域 end
    sunmitOrder () {
      var lod = this.$loading()
      // 数据校验
      if (this.orderMain.addressId === null || this.orderMain.addressId.length === 0) {
        // this.$alert('请选择收货地址', '提示')
        this.$notify({
          title: '警告',
          message: '请选择收货地址',
          type: 'warning',
          duration: 2000
        })
        lod.close()
        return false
      }
      // 21-04-12 校验限制区域 start
      if (this.checkAddress()) {
        this.$notify({
          title: '警告',
          message: '该地区不支持发货，请您重新选择商品',
          type: 'warning',
          duration: 2000
        })
        lod.close()
        return false
      }
      // 21-04-12 校验限制区域 end
      var customerId = this.currentCustom.customerId
      var customerName = this.currentCustom.customerName
      this.orderMain.customerId = customerId
      this.orderMain.customerName = customerName
      this.orderMain.isMonthlyPayment = this.isMonthlyPayment
      this.orderMain.remarker = this.goodsOrder.remarker
      this.orderMain.sourceSystem = '电商PC'
      if (this.isMonthlyPayment && this.isMonthlyPayment === '1') {
        this.$confirm('请确认商品信息，订单提交后会尽快为您安排发货', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then((val) => {
            this.submit(lod)
          }).catch((val) => {
            lod.close()
          })
      } else {
        this.isAutomaticInvoicing(lod)
      }
    },
    isAutomaticInvoicing (lod) {
      const h = this.$createElement
      this.$confirm('', {
        customClass: 'message-logout',
        message: h('div', null, [
          h('span', { style: 'margin-left:10px;font-size:16px;line-height:30px;font-weight:600;vertical-align:top;' }, '请选择是否现在申请发票?'),
          h('p', { style: 'margin:10px 0 0 12px;' }, '注:发票将在订单付款后开出')
        ]),
        confirmButtonText: '立即申请',
        cancelButtonText: '暂不申请',
        type: 'success'
      })
        .then((val) => {
          this.orderMain.isAutomaticInvoicing = true
          this.submit(lod)
        }).catch((val) => {
          this.submit(lod)
        })
    },
    submit (lod) {
      submitOrder(this.orderMain).then(res => {
        lod.close()
        debugger
        if (res.data.data.start === '0') {
          // this.$alert(res.data.data.msg, '提示')
          this.$notify({
            title: '提示',
            message: res.data.data.msg,
            type: 'warning',
            duration: 3000
          })
          return
        }
        var orderNo = res.data.data
        this.$router.push({ // 调整到详情界面
          path: 'orderSuccess',
          query: orderNo
        })
      }, error => {
        lod.close()
        var arr = error.toString().split(':')
        this.$notify({
          title: '提示',
          message: arr[1],
          type: 'warning',
          duration: 3000
        })
      })
    },
    onload () {
      var lod = this.$loading()
      // 获取页面带过来的参数
      var params = {}
      /* for (var key in this.$route.query) { params.push(this.$route.query[key]) // 写入url中的参数
      } */
      /* params = JSON.stringify(params)
      if (params.includes('[object Object]')) {
        this.$alert('当前页面数据已过期,请返回上一页面重新操作', '提示')
        return
      } */
      if (this.cartList.submitList === null && this.cartList.submitList.length === 0) {
        // this.$alert('当前页面数据已过期,请返回上一页面重新操作', '提示')
        this.$notify({
          title: '警告',
          message: '当前页面数据已过期,请返回上一页面重新操作',
          type: 'warning',
          duration: 3000
        })
        return
      } else {
        // params = JSON.stringify(this.cartList)
        params.json = this.cartList
      }
      debugger
      this.isMonthlyPayment = this.cartList.isMonthlyPayment
      var customerId = this.currentCustom.customerId
      // 查询收货地址
      findAddress(customerId).then(res => {
        this.AddressList = res.data.data
        var a = 0
        for (var i = 0; i < this.AddressList.length; i++) {
          if (this.AddressList[i].isDefault === '01') {
            a++
            this.confirmAdd = i
            this.analysis(this.AddressList[i], i)
          }
          if (this.AddressList[i].attribute1 != null && this.AddressList[i].attribute1.length > 0) {
            this.AddressList[i].attribute1 = JSON.parse(this.AddressList[i].attribute1)
          }
        }
        if (a === 0) {
          this.confirmAdd = 0
          this.analysis(this.AddressList[0], 0)
        }
      })
      // 查询我的中光币
      myTotalZGB({ customerId: customerId }).then(res => {
        this.myTotalZGB = res.data.data
        if (this.goodsOrder.AvailableCoin > this.myTotalZGB.leftNum) {
          this.goodsOrder.AvailableCoin = this.myTotalZGB.leftNum
        }
      })
      // 查询优惠券
      params.customerId = customerId
      getcouponDetails(params).then(res => {
        res.data.data.coupons.forEach((item, i) => {
          if (item.availableProductRange !== '') {
            JSON.parse(item.availableProductRange).forEach((items, j) => {
              if (JSON.parse(item.availableProductRange).length > 1) {
                item.attribute4 += items.commodityName + '|'
              } else {
                item.attribute4 = items.commodityName
              }
            })
          } else if (item.typesOfGoodsAvailable !== '') {
            JSON.parse(item.typesOfGoodsAvailable).forEach((items, j) => {
              if (JSON.parse(item.typesOfGoodsAvailable).length > 1) {
                item.attribute4 += items.treeName + '|'
              } else {
                item.attribute4 = items.treeName
              }
            })
          }
        })
        this.coupnoList = res.data.data.coupons
        // console.log(this.coupnoList)
        var ind = []
        for (var i = 0; i < this.coupnoList.length; i++) {
          if (this.coupnoList[i].attribute1 === 'no') {
            ind.push(i)
          } else {
            this.coupnoList[i].faceValue = parseFloat(this.coupnoList[i].faceValue).toFixed(2)
          }
        }
        // debugger
        if (ind.length > 0) {
          for (var j = ind.length - 1; j >= 0; j--) {
            this.coupnoList.splice(ind[j], 1)
          }
        }
        // console.log(this.coupnoList)
      })
      if (!this.isMonthlyPayment) {
        checkGoodsOnLunarKnot(params).then(res => {
          var isMonthlyPayment = res.data.data.isMonthlyPayment
          if (isMonthlyPayment && isMonthlyPayment === '1') {
            this.isMonthlyPayment = isMonthlyPayment
          } else {
            this.isMonthlyPayment = '0'
          }
        })
      }
      // 查询代金券
      getCashCouponDetails(params).then(res => {
        res.data.data.forEach((item, i) => {
          if (item.availableProductRange !== '') {
            JSON.parse(item.availableProductRange).forEach((items, j) => {
              if (JSON.parse(item.availableProductRange).length > 1) {
                item.attribute5 += items.commodityName + '   '
              } else {
                item.attribute5 = items.commodityName
              }
            })
          } else if (item.typesOfGoodsAvailable !== '') {
            JSON.parse(item.typesOfGoodsAvailable).forEach((items, j) => {
              if (JSON.parse(item.typesOfGoodsAvailable).length > 1) {
                item.attribute5 += items.treeName + '   '
              } else {
                item.attribute5 = items.treeName
              }
            })
          }
        })
        this.voucherList = res.data.data
        // console.log(this.voucherList)
        var ind = []
        for (var i = 0; i < this.voucherList.length; i++) {
          if (this.voucherList[i].attribute1 === 'no') {
            ind.push(i)
          } else {
            this.voucherList[i].faceValue = parseFloat(this.voucherList[i].faceValue).toFixed(2)
          }
        }
        // debugger
        if (ind.length > 0) {
          for (var j = ind.length - 1; j >= 0; j--) {
            this.voucherList.splice(ind[j], 1)
          }
        }
        // console.log(this.voucherList)
      })
      // 查询商品清单
      getGoodsDetal(params).then(res => {
        if (res.data.data.start === 0) {
          // this.$alert(res.data.data.msg, '提示')
          this.$notify({
            title: '提示',
            message: res.data.data.msg,
            type: 'warning',
            duration: 3000
          })
          var orderNo = res.data.data.orderNo
          this.$router.push({ // 调整到详情界面
            path: 'orderSuccess',
            query: {
              orderNo: orderNo
            }
          })
        } else if (res.data.data.start === 1) {
          this.$notify({
            title: '提示',
            message: res.data.data.msg,
            type: 'warning',
            duration: 3000
          })
        } else {
          this.goodsOrder = res.data.data
          for (var i = 0; i < this.goodsOrder.goodsList.length; i++) {
            this.goodsOrder.goodsList[i].Subtotal = parseFloat(this.goodsOrder.goodsList[i].Subtotal).toFixed(2)
            this.goodsOrder.goodsList[i].salePrice = parseFloat(this.goodsOrder.goodsList[i].salePrice).toFixed(2)
          }
          this.orderMain.goodsList = this.cartList.submitList
          this.orderMain.orderNo = this.goodsOrder.orderNo
          this.zhongGuangRule = this.goodsOrder.zhongguangbi
          this.goodsOrder.totalAmont = parseFloat(this.goodsOrder.totalAmont).toFixed(2)
          // 计算中光币
          this.orderMain.amountDue = this.goodsOrder.totalAmont // 实际支付金额
          var total = this.goodsOrder.totalAmont
          if (this.zhongGuangRule.minimumProductionAmount <= this.orderMain.amountDue) { // 判断总金额是否大于最低产生金额
            this.produceZhongGuang = Math.floor(this.orderMain.amountDue * this.zhongGuangRule.generationRuleProportion)
          }
          if (this.zhongGuangRule.minimumConsumptionAmount > total) { // 判断总金额是否大于最低消费金额
            this.goodsOrder.AvailableCoin = 0.00
          } else {
            this.orderMain.AvailableCoin = this.goodsOrder.AvailableCoin
          }
          this.orderMain.cinemaList = this.goodsOrder.cinemaList
        }
      }, error => {
        if (error.code === 1002) {
          this.$notify({
            title: '提示',
            message: error.msg,
            type: 'warning',
            duration: 2000
          })
        }
      })
      lod.close()
    }
  },
  created () {
    this.onload()
  }
}
</script>
<style>
  .message-logout {
    width: 327px;
  }
</style>
<style lang="scss" scoped>
  .all-moneny {
    padding: 25px 30px;

    border-top: 1px solid #E1E1E1;
    border-bottom: 1px solid #E1E1E1;
    text-align: right;
    background-color: #FAFAFA;

    .span1 {
      color: #333333;
      font-size: 16px;
    }

    .span2 {
      color: #ef922e;
      font-size: 26px;
    }

    .p2 {
      color: #666666;
      padding-top: 13px;
      font-size: 14px;
    }
  }

  .bottom-div {
    height: 123px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    padding-right: 30px;

    .text {
      color: #333333;
      font-size: 14px;
    }

    .btn {
      width: 187px;
      height: 42px;
      background: #ef922e;
      border-radius: 2px;
      line-height: 42px;
      text-align: center;
      color: #ffffff;
      margin-left: 20px;
    }
    .btn:hover {
      cursor: pointer;
    }
  }
</style>
