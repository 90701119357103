<template>
  <div class="personList">
    <div class="person-title">
      <span>个人中心</span>
    </div>
    <div class="person-icon" v-for="item in list" :key="item.id" :class="{'active': item.id === currentTag}">
      <div class="icon">
        <img :src="item.activeImgUrl" alt="" v-if="item.id === currentTag">
        <img :src="item.imgUrl" alt="" v-else>
      </div>
      <div class="text" style="position: relative;">
        <span @click="jump(item.id,item.text,item.url)"><a>{{item.text}}</a></span>
        <div v-if="item.id === '6' && queryReadCount > 0" style="position:absolute;right:-17px;top:-16px;" class="top">
          <span style="user-select: none;display:inline-block;padding:1px 2px; background:#ef922e;border-radius:3px;font-size:10px;color: white;"><span v-if="queryReadCount <= 99">{{ queryReadCount }}</span><span v-if="queryReadCount > 99">99+</span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { queryNotReadCount } from '@/api/personCenter/notify/notify'
import { getCustomerCategoryById } from '@/api/personCenter/customerInvoice'
export default {
  data () {
    return {
      id: '1',
      queryCount: 0,
      list: [
        {
          id: '1',
          imgUrl: require('@/assets/wddd.png'),
          activeImgUrl: require('@/assets/wddd1.png'),
          text: '我的订单',
          url: '/myOwnOrder'
        },
        // {
        //   id: '2',
        //   imgUrl: require('@/assets/wdgwc.png'),
        //   activeImgUrl: require('@/assets/wdgwc1.png'),
        //   text: '我的购物车',
        //   url: '/myCar'
        // },
        {
          id: '3',
          imgUrl: require('@/assets/wdyhq.png'),
          activeImgUrl: require('@/assets/wdyhq1.png'),
          text: '我的优惠券',
          url: '/myCoupon'
        },
        // {
        //   id: '4',
        //   imgUrl: require('@/assets/wdzgb.png'),
        //   activeImgUrl: require('@/assets/wdzgb1.png'),
        //   text: '我的中光币',
        //   url: '/myZGB'
        // },
        {
          id: '5',
          imgUrl: require('@/assets/shdz.png'),
          activeImgUrl: require('@/assets/shdz1.png'),
          text: '收货地址',
          url: '/sendAdress'
        },
        {
          id: '6',
          imgUrl: require('@/assets/2.png'),
          activeImgUrl: require('@/assets/1.png'),
          text: '消息通知',
          url: '/notify'
        },
        {
          id: '7',
          imgUrl: require('@/assets/wddd.png'),
          activeImgUrl: require('@/assets/wddd1.png'),
          text: '发票信息',
          url: '/invoiceApply'
        },
        {
          id: '8',
          imgUrl: require('@/assets/gsxx.png'),
          activeImgUrl: require('@/assets/gsxx1.png'),
          text: '公司信息',
          url: '/componeyInfo'
        },
        {
          id: '12',
          imgUrl: require('@/assets/gsxx.png'),
          activeImgUrl: require('@/assets/gsxx1.png'),
          text: '影院信息',
          url: '/cinemaInfo'
        },
        {
          id: '19',
          imgUrl: require('@/assets/xtsz.png'),
          activeImgUrl: require('@/assets/xtsz1.png'),
          text: '设备授权',
          url: '/equipment'
        },
        {
          id: '9',
          imgUrl: require('@/assets/wddd.png'),
          activeImgUrl: require('@/assets/wddd1.png'),
          text: '电子商务合作协议',
          url: '/agreement'
        },
        {
          id: '11',
          imgUrl: require('@/assets/wddd.png'),
          activeImgUrl: require('@/assets/wddd1.png'),
          text: '代付证明',
          url: '/prove'
        },
        {
          id: '10',
          imgUrl: require('@/assets/xtsz.png'),
          activeImgUrl: require('@/assets/xtsz1.png'),
          text: '系统设置',
          url: '/settings'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['currentTag', 'breadcrumbList', 'currentCustom', 'queryReadCount', 'userInfo'])
  },
  watch: {
    '$route.path': function (value) {
      this.castUrl(value)
    }
  },
  created () {
    //  个人导航栏
    getCustomerCategoryById(this.userInfo.customerId).then(res => {
      if (res.data.data && res.data.data === '2') {
        this.list.splice(8, 1)
        this.list.splice(6, 1)
      }
    }).catch(e => {
      // console.log(e)
    })
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      if (this.id === '1') {
        const breadCrumbs = [{ label: '首页', url: '/home' }, { label: '个人中心', url: '/personCenter' }]
        breadCrumbs.push({ label: '我的订单', url: '/myOwnOrder' })
        this.$store.commit('SET_BREADCRUMB', breadCrumbs)
        this.$store.commit('SET_CURRENT_TAG', this.id)
      }
    }
    // } else {
    //   if (this.breadcrumbList.length > 2 && this.breadcrumbList[1].url === '/search') {
    //     const breadCrumbs = [{ label: '首页', url: '/home' }, { label: '个人中心', url: '/personCenter' }]
    //     breadCrumbs.push({ label: '我的订单', url: '/myOwnOrder' })
    //     this.$store.commit('SET_BREADCRUMB', breadCrumbs)
    //     this.$store.commit('SET_CURRENT_TAG', '1')
    //   }
    // }
    this.castUrl(this.$route.path)
    this.onLoad()
  },
  methods: {
    jump (id, text, url) {
      const breadCrumbs = [{ label: '首页', url: '/home' }, { label: '个人中心', url: '/personCenter' }]
      breadCrumbs.push({ label: text })
      this.$store.commit('SET_BREADCRUMB', breadCrumbs)
      this.$store.commit('SET_CURRENT_TAG', id)
      this.id = id
      if (url) {
        this.$router.push({ path: url, params: { id: id } })
      }
    },
    castUrl (value) {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].url === value) {
          if (this.list[i].id === this.currentTag && this.breadcrumbList.length > 0) break
          const breadCrumbs = [{ label: '首页', url: '/home' }, { label: '个人中心', url: '/personCenter' }]
          breadCrumbs.push({ label: this.list[i].text })
          this.$store.commit('SET_BREADCRUMB', breadCrumbs)
          this.$store.commit('SET_CURRENT_TAG', this.list[i].id)
          break
        }
      }
    },
    onLoad () {
      queryNotReadCount(this.currentCustom.customerId).then(res => {
        this.queryCount = res.data.data
        this.$store.commit('SET_QUERY_READ_COUNT', this.queryCount)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  background: #ef922e;
  border-radius: 3px;
  font-size: 10px;
  color: #ffffff;
  padding: 0 1px;
  position: absolute;
  right: -20px;
  top: -12px;
}
.personList {
    padding: 30px;
    width: 230px;
    box-sizing: border-box;
    background-color: white;
    text-align: left;
    .person-title {
      color: #333333;
      font-size: 18px;
    }
    .person-icon {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 14px;
      margin-top: 30px;
      &.active {
        color: #ef922e;
      }
      .text {
        padding-left: 7px;
      }
    }
   .person-icon:hover {
     color: #ef922e;
     cursor: pointer;
   }
}
</style>
