<template>
  <div class="best-goods">
      <div class="labelPicture" @click="clickTotal('13', labelId, labelName)">
        <img :src="labelPicture" alt="">
      </div>
      <!-- <div class="title">
          <span>{{ labelName }}</span>
      </div> -->
      <div class="content"> <!-- .slice(0,10) -->
          <div class="best-goods-item"
            v-for="(item, idx) in labelHomePageList"
            :key="item.id" @click="goodsDetail(item.id, item.commodityName, idx)">
            <a :href="`#/detail?id=${item.id}&commodityName=${item.commodityName}&keys=${labelName}&url=/home`" target="_blank" style="text-decoration: none">
              <best-goods-card :item= "item"/>
            </a>
            <!-- <best-goods-card
               :item= "item"
            ></best-goods-card> -->
          </div>
      </div>
  </div>
</template>

<script>
import bestGoodsCard from '@/components/bestGoodsCard'
import { hits } from '@/api/hits/index'
export default {
  props: {
    labelHomePageList: {
      type: Array
    },
    record: {
      type: Object
    },
    idNo: Number,
    labelId: {
      type: String
    },
    labelName: {
      type: String
    },
    labelPicture: {
      type: String
    }
  },
  data () {
    return {
      goodsId: ''
    }
  },
  methods: {
    goodsDetail (id, commodityName, idx) {
      var that = this
      var idxTmp = this.labelHomePageList.slice(0, 10).length - idx
      var busId
      if (idxTmp > 9) {
        busId = (this.idNo + 13) + '' + idxTmp
      } else {
        busId = (this.idNo + 13) + '0' + idxTmp
      }
      hits({
        type: '02',
        clickPostion: '13',
        busId: busId,
        busTitle: that.labelName + '-产品' + (idx + 1),
        sourceSection: '01'
      })
      hits({
        type: '01',
        goodsId: id,
        goodsName: commodityName,
        sourceSection: '02',
        sourcePosition: ''
      })
      var blan = this.$router.resolve({ // 调转到商品详情页面
        path: '/detail',
        query: {
          id: id,
          keys: this.labelName,
          commodityName: commodityName,
          url: '/home'
        }
      })
      window.open(blan.href, '_blank')
    },
    clickTotal (clickPosition, busId, busTitle) {
      hits({
        type: '02',
        clickPostion: clickPosition,
        busId: busId,
        busTitle: busTitle,
        sourceSection: '01'
      })
    }
  },
  components: {
    bestGoodsCard
  }
}
</script>

<style lang="scss" scoped>
.best-goods:hover {
  cursor: pointer;
}
.best-goods {
    margin-top: 28px;
    padding-left:17px;
    // width: 100%;
    .title {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        text-align: left;
    }
    .content {
        margin-top: 23px;
        width: 100%;
        display: flex;
        flex-wrap:wrap;
        // grid-template-columns: repeat(5, 234px);
        // grid-template-rows: repeat(1, 340px);
        // grid-row-gap: 14px;
        // grid-column-gap: 14px;
        .best-goods-item {
          margin-bottom: 7px;
          margin-right:7px;
            width: 234px;
            height: 380px;
            &:hover {
                box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.19);
            }
        }
    }
    .labelPicture{
      width:100%;
      height:80px;
      margin:30px 0;
      img{
        width:100%;
        height:100%;
      }
    }
}
</style>
