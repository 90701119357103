<template>
  <div class="buy-card-item">
    <div class="buy-card-top" @click="goDetail(bean.id, bean.attribute1)">
      <a><img :src="bean.mainGraph" alt=""></a>
    </div>
    <div class="buy-card-center" :title="bean.commodityName" @click="goDetail(bean.id, bean.attribute1)">
      <a><span class="overflow_two" v-html="bean.commodityName" /></a>
    </div>
    <div class="buy-card-tips" @click="goDetail(bean.id, bean.attribute1)">
      <a><span>{{bean.sellingPoints}}</span></a>
    </div>
    <div class="buy-card-bottom">
      <div class="half left">
        <span>{{(bean.salePrice).toFixed(2)}}</span>
      </div>
      <div class="half delete">
        <span>{{(bean.referencePrice).toFixed(2)}}</span>
      </div>
    </div>
    <div class="buy-card-add" v-if="false">
      <!--          <div class="item left">-->
      <!--              <div class="icon">-->
      <!--                  <img src="@/assets/car.png" alt="">-->
      <!--              </div>-->
      <!--              <div class="text">-->
      <!--                  <span>加入购物车</span>-->
      <!--              </div>-->
      <!--          </div>-->
      <!--          <div class="item right">-->
      <!--              <div>-->
      <!--                  <span>立即购买</span>-->
      <!--              </div>-->
      <!--          </div>-->
    </div>
  </div>
</template>

<script>
import { hits } from '@/api/hits'
export default {
  props: {
    bean: {
      type: Object,
      default: function () {
        return {}
      }
    },
    sourceSection: String
  },
  methods: {
    goDetail (id, commodityName) {
      var that = this
      hits({
        type: '01',
        goodsId: id,
        goodsName: commodityName,
        sourceSection: that.sourceSection
      })
      const { href } = this.$router.resolve({
        path: '/detail?id=' + id + '&keys=搜索&url=/search' + '&commodityName=' + commodityName
      })
      window.open(href, '_blank')
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.buy-card-item {
  width: 235px;
  height: 100%;
  margin: 0px 10px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  &:hover {
    border-color: #e9e9e9;
    box-shadow: 0 0 2px 2px #f8f8f8;
  }
  .buy-card-top {
    padding-top: 30px;
    img {
      // commodityClass 筛选条件查询
      display: block;
      width: 209px;
      // width:139px;
      height: 209px;
    }
  }
  .buy-card-center {
    width:100%;
    height:28px;
    overflow:hidden;
    font-size: 14px;
    color: #111111;
    margin-top: 30px;
    span {
      // width: 80%;
      // margin-left: 10%;
      // line-height: 20px;
    }
  }
  .buy-card-tips {
    width: 100%;
    min-height:30px;
    color: #999999;
    padding: 15px 0;
    position: relative;
    text-align:left;
    left: 5px;
  }
  .buy-card-bottom {
    width: 90%;
    height: 27px;
    display: flex;
    // margin-top: 13px;
    align-items: center;
    justify-content: left;
    position: relative;
    left: -5px;
    .half {
      &.left {
        color: #f30012;
        font-size: 14px;
        margin-right: 9px;
      }
      &.delete {
        font-size: 12px;
        color: #999999;
        text-decoration: line-through;
      }
    }
  }
  .buy-card-add {
    width: 90%;
    margin-top: 20px;
    position: relative;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e1e1e1;
    .item {
      cursor: pointer;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      height: 100%;
      &.left {
        border-right: 1px solid #e1e1e1;
        color: #666666;
        .icon {
          margin-right: 8px;
          height: 17px;
        }
      }
      &.right {
        color: #ef922e;
      }
    }
  }
}
</style>
