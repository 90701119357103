<template>
  <div class="orderList">
    <div class="title">
      <div class="left">
        <span style="display: inline-block;width: 90%">代付款说明</span>
      </div>
      <div class="div-1">
        <div class="left">
          <div class="smallTitle">1、代付款说明：</div>
          <div class="content">
            1）付款账户与《中影光峰激光光源租赁协议》约定付款方及开票名称不一致；<br>
            2）每次代付都需要提供代付款证明。
          </div>
          <div class="smallTitle">2、代付款证明模板：</div>
          <div class="content">
            1）个人垫付：下载 <a @click="personalProve" class="linkHref">代付款证明-个人</a> <br>
            2）其他公司垫付：下载 <a @click="companyProve" class="linkHref">代付款证明-公司</a>
          </div>
          <div class="smallTitle">3、代付款证明流程：</div>
          <div class="content">
            1）请下载待付款证明，并准确填写待付款证明信息，加盖公章或合同章；<br>
            2）原件邮寄至： <br>
            <span class="addressY">收件地址：北京市西城区新德街20号东配楼三层中影光峰</span><br>
            <span class="addressY">犀牛Mall收件联系人：犀牛Mall商务</span><span class="addressY">联系电话：158 0149 2494</span><br>
            <span class="addressY">充值小时收件联系人：客户服务部</span><span class="addressY">联系电话：156 5267 4253</span><br>
            3）中影光峰收到原件后，安排发票开具。
          </div>
          <br>
          <div class="care">注：如有其他问题，请拨打中影光峰客服电话：400 870 9700</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCompanyProve, getPersonalProve } from '@/api/personCenter/prove/prove'
export default {
  data () {
    return {
      personalProveUrl: '',
      companyProveUrl: ''
    }
  },
  methods: {
    personalProve () {
      if (this.personalProveUrl === null || this.personalProveUrl.length <= 0) {
        this.$notify({
          title: '提示',
          message: '个人代付款证明未找到,请联系客服咨询详情!',
          type: 'warning',
          duration: 2000
        })
      } else {
        window.open(this.personalProveUrl)
      }
    },
    companyProve () {
      if (this.companyProveUrl === null || this.companyProveUrl.length <= 0) {
        this.$notify({
          title: '提示',
          message: '公司代付款证明未找到,请联系客服咨询详情!',
          type: 'warning',
          duration: 2000
        })
      } else {
        window.open(this.companyProveUrl)
      }
    },
    onLoad () {
      getPersonalProve().then(res => {
        // console.log(res)
        this.personalProveUrl = res.data.data
      })
      getCompanyProve().then(res => {
        // console.log(res)
        this.companyProveUrl = res.data.data
      })
    }
  },
  mounted () {
    this.onLoad()
  }
}
</script>

<style scoped>
.addressY {
  margin-left: 22px;
}
.div-1 {
  background: #F7F3D8;
}
.smallTitle{
  font-size: 14px;
  margin-top: 30px;
}
.content {
  font-size: 14px;
  margin-top: 10px;
  margin-left: 20px;
  line-height: 30px;
}
.care {
  font-size: 14px;
}
.linkHref {
  color: #0066cc;
}
.linkHref:hover {
  color: #ef922e;
}
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 110px 40px;
}
.title {
  color: #333333;
  font-size: 24px;
}
</style>
