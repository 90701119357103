<template>
  <div class="orderList">
    <div class="title">
      <span>发票信息</span>
      <!--<div class="left">
        <span>发票基础信息</span>
      </div>-->
        <div class="right" @click="showModal4" v-if="false">
          <span><a>+ 新增发票信息</a></span>
        </div>
        <el-dialog title="添加发票信息"  @close="closeSelf" :visible.sync="visible4">
          <div class="full-width">
            <invoiceFrom :ruleForm="ruleForm" :edit="false" @closeModal4="closeModal4"/>
          </div>
        </el-dialog>
    </div>
    <div class="sub-title">
      <span :class="{h1: true, orange: currentStat === '0'}"><a class="span1" @click="jump('0','invoiceApply')">开票申请</a></span>
      <span :class="{h2: true, orange: currentStat === '01'}"><a class="span1" @click="jump('01','invoiceInfo')">申请中</a></span>
      <span :class="{h2: true, orange: currentStat === '04'}"><a class="span1" @click="jump('04','invoiceInfo')">异常申请</a></span>
      <span :class="{h2: true, orange: currentStat === '02'}"><a class="span1" @click="jump('02','invoiceInfo')">已开票</a></span>
      <span :class="{h2: true, orange: currentStat === '03'}"><a class="span1" @click="jump('03','invoiceInfo')">全部发票</a></span>
      <span :class="{h1: true, orange: currentStat === '5'}"><a class="span1" @click="jump('5','customerInvoice')">开票账号管理</a></span>
    </div>
    <div class="list">
      <invoiceView :isDefault="false" v-for="(v,index) in data" :key="index" :bean="v" @ajaxList="ajaxList"/>
    </div>
  </div>
</template>
<script>
import invoiceView from './invoiceView'
import invoiceFrom from './invoiceFrom'
import { ajaxList } from '@/api/personCenter/customerInvoice'
import { mapGetters } from 'vuex'
export default {
  components: {
    invoiceView,
    invoiceFrom
  },
  computed: {
    ...mapGetters(['currentCustom', 'currentStat'])
  },
  data () {
    return {
      visible4: false,
      ruleForm: {},
      data: []
    }
  },
  created () {
    this.ajaxList()
  },
  methods: {
    jump (stat, url) {
      this.$store.commit('SET_CURRENT_STAT', stat)
      this.$router.push({ name: url, params: { type: stat } })
    },
    showModal4 () {
      this.visible4 = true
    },
    closeModal4 (flag) {
      this.visible4 = false
      this.ruleForm = {}
      if (flag) this.ajaxList()
    },
    closeSelf () {
      this.ruleForm = {}
      this.visible4 = false
    },
    ajaxList () {
      ajaxList({ customerId: this.currentCustom.customerId, customerName: this.currentCustom.customerName, notDesensitization: '01' }).then((res) => {
        this.data = res.data.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.full-width {
  border: 1px solid #E1E1E1;
}
/*.orderList {*/
/*  text-align: left;*/
/*  width: 982px;*/
/*  box-sizing: border-box;*/
/*  background-color: white;*/
/*  padding: 38px 40px 38px 40px;*/
/*  .title {*/
/*    color: #333333;*/
/*    font-size: 24px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*    .left {*/
/*      line-height: 42px;*/
/*    }*/
/*    .right {*/
/*      width: 148px;*/
/*      height: 36px;*/
/*      border: 1px solid gainsboro;*/
/*      border-radius: 2px;*/
/*      line-height: 36px;*/
/*      text-align: center;*/
/*      color: #ef922e;*/
/*      font-size: 14px;*/
/*    }*/
/*    .right:hover{*/
/*      cursor: pointer;*/
/*      border: 1px solid #ef922e;*/
/*    }*/
/*  }*/
/*}*/
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 110px 40px;
  .title {
    color: #333333;
    font-size: 24px;
  }
  .sub-title {
    margin-top: 30px;
    span {
      margin-right: 20px;
    }
    .h1 {
      color: #333333;
      font-size: 16px;
    }
    .h2 {
      font-size: 14px;
      color: #333333;
    }
    .orange {
      color: #ef922e;
    }
  }
  .page {
    margin-top: 35px;
    display: flex;
    justify-content: center;
  }
}
</style>
