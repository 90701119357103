<template>
  <div class="product-list">
    <div class="product-content">
      <div class="product-filter">
        <div class="sort">
          <div class="text" :class="{ active: active==='01' }" @click="sortFun('01')">
            <div class="left">
              <span>综合</span>
            </div>
            <div class="right">
              ⬇
            </div>
          </div>
          <div class="sperate"></div>
        </div>
        <div class="sort">
          <div class="text" :class="{ active: active==='02' }" @click="sortFun('02',1)">
            <div class="left">
              <span>价格</span>
            </div>
            <div class="right" v-if="activeT">
              ⬇
            </div>
            <div class="right" v-else>
              ⬆
            </div>
          </div>
          <div class="sperate"></div>
        </div>
        <div class="input">
          <div class="input-item">
            <el-input v-model="goodsSearchParam.startPrice" maxlength="8" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1').replace(/^0{1,}/g,'')" />
          </div>
          <div class="input-sperate">
            <span>-</span>
          </div>
          <div class="input-item">
            <el-input v-model="goodsSearchParam.endPrice" maxlength="8" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1').replace(/^0{1,}/g,'')" />
          </div>
          <div class="f-price-edit .f-price-edit-move" :style="{ display: display, zoom: '1' }">
            <a @click="clearAll" class="item1 J-price-cancle">清空</a>
            <a @click="priceSubmit" class="item2 J-price-confirm">确定</a>
          </div>
        </div>
      </div>
      <div class="product-card">
        <buyCard v-for="item in list" :key="item.id" :bean="item" :sourceSection="sourceSection"/>
      </div>
      <div class="product-page">
        <el-pagination background layout="prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange" :total="goodsSearchParam.total" :page-sizes="[20,25]" :page-size="goodsSearchParam.pageSize" :current-page.sync="goodsSearchParam.pageNo">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import buyCard from '@/components/buyCard'
export default {
  props: {
    goodsSearchParam: {
      type: Object
    },
    list: {
      type: Array
    },
    sourceSection: String
  },
  components: {
    buyCard
  },
  created () {
    if (this.$route.query.orderBy) {
      this.active = this.goodsSearchParam.orderBy
    }
    if (this.$route.query.direction) {
      this.activeT = this.goodsSearchParam.direction
    }
  },
  methods: {
    mouseOver () {
      this.display = 'block'
    },
    mouseLeave () {
      this.display = 'block'
    },
    clearAll () {
      const query = Object.assign({}, this.$route.query)
      this.goodsSearchParam.startPrice = ''
      this.goodsSearchParam.endPrice = ''
      if (Object.prototype.hasOwnProperty.call(query, 'startPrice') || Object.prototype.hasOwnProperty.call(query, 'endPrice')) {
        delete query.startPrice
        delete query.endPrice
        this.$emit('search')
        this.$router.push({ path: 'search', query: query })
      }
    },
    sortFun (stat, flag) {
      if (stat === '01' && this.active === '01') {
        return false
      }
      this.active = stat
      if (flag !== undefined) {
        this.activeT = !this.activeT
      }
      if (stat === '01') {
        this.goodsSearchParam.orderBy = '01'
      } else if (stat === '02') {
        this.goodsSearchParam.orderBy = '02'
        if (this.activeT) {
          this.goodsSearchParam.direction = '02'
        } else {
          this.goodsSearchParam.direction = '01'
        }
      }
      const query = Object.assign({}, this.$route.query)
      query.orderBy = this.goodsSearchParam.orderBy
      query.direction = this.goodsSearchParam.direction
      this.$emit('search')
      this.$router.push({ path: 'search', query: query })
    },
    priceSubmit () {
      if (!this.goodsSearchParam.startPrice && !this.goodsSearchParam.endPrice) {
        return false
      }
      const query = Object.assign({}, this.$route.query)
      if (this.goodsSearchParam.startPrice) {
        query.startPrice = this.goodsSearchParam.startPrice
      }
      if (this.goodsSearchParam.endPrice) {
        query.endPrice = this.goodsSearchParam.endPrice
      }
      this.$emit('search')
      this.$router.push({ path: 'search', query: query })
    },
    handleSizeChange (val) {
      this.goodsSearchParam.pageNo = 1
      this.goodsSearchParam.pageSize = val
      // 获取记录
      this.$emit('search')
    },
    handleCurrentChange (val) {
      this.goodsSearchParam.pageNo = val
      // 获取记录
      this.$emit('search')
    }
  },
  data () {
    return {
      activeT: true,
      active: '01',
      display: 'block'
    }
  }
}
</script>

<style lang="scss" scoped>
.sort {
  cursor: pointer;
}
.item2 {
  bottom: 48px;
  left: 6px;
  width: 36px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #005aa0;
}
.item1 {
  bottom: 48px;
  margin-left: 5px;
  margin-right: 5px;
  left: 6px;
  width: 36px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #005aa0;
}

.product-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .product-content {
    width: 1226px;
    padding-top: 24px;
    .product-filter {
      display: flex;
      align-items: center;
      .sort {
        padding-right: 19px;
        margin-right: 19px;
        //height: 13px;
        position: relative;
        height: 20px;
        .text {
          font-size: 14px;
          color: #333333;
          display: flex;
          align-items: center;
          .right {
            padding-left: 7px;
          }
          &.active {
            background: #ef922e;
            border-radius: 2px;
            padding: 0 1px;
            color: #ffffff;
          }
        }
        .sperate {
          position: absolute;
          top: 4px;
          right: 0;
          width: 1px;
          height: 13px;
          background: #c1c1c1;
        }
      }
      .input {
        display: flex;
        align-items: center;
        .input-item {
          width: 88px;
          height: 26px;
        }
        .input-sperate {
          padding: 0 7px;
        }
      }
    }
    .product-card {
      padding-top: 18px;
      width: 100%;
      display: flex;
      flex-wrap:wrap;
      // grid-template-columns: repeat(5, 234px);
      // /*grid-template-rows: repeat(4, 370px);*/
      // grid-column-gap: 14px;
      // grid-row-gap: 14px;
    }
    .product-page {
      margin-top: 60px;
    }
  }
}
</style>

<style lang="scss">
.product-list {
  .el-input__inner {
    height: 26px;
  }
}
</style>
