<template>
  <div class="orderList">
    <div class="title">
      <div class="left">
        <span>系统设置</span>
      </div>
    </div>
    <div class="form-div">
      <el-form
        :model="ruleForm"
        :rules="rules"
        status-icon
        ref="ruleForm"
        class="demo-ruleForm"
        label-width="90px"
      >
        <el-form-item label="旧密码:" prop="old">
          <div class="item-div">
            <el-input type="password" maxlength="16" v-model="ruleForm.old" autocomplete="off"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="新密码:" prop="new">
          <div class="item-div">
            <el-input type="password" maxlength="16" v-model="ruleForm.new" autocomplete="off"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="确认密码:" prop="confirm">
          <div class="item-div">
            <el-input type="password" maxlength="16" v-model="ruleForm.confirm" autocomplete="off"></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="buttonColor" @click="submitForm('ruleForm')">提交</el-button>
          <el-button class="buttonColor1" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
<!--    <div class="button-ope">-->
<!--      <div class="btn active" @click="submitForm('ruleForm')">-->
<!--        <span>提交</span>-->
<!--      </div>-->
<!--      <div class="btn active" @click="resetForm('ruleForm')">-->
<!--        <span>重置</span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import { editPwd } from '@/api/personCenter/settings/settings'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['currentCustom', 'userInfo'])
  },
  data () {
    var checkOld = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('旧密码不能为空'))
      }
      setTimeout(() => {
        callback()
      }, 1000)
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.confirm !== '') {
          this.$refs.ruleForm.validateField('confirm')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.new) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        new: '',
        old: '',
        confirm: ''
      },
      rules: {
        old: [
          { required: true, validator: checkOld, trigger: 'blur' }
        ],
        new: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        confirm: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm (ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        // debugger
        if (valid) {
          // alert('aaa')
          this.ruleForm.userCode = this.userInfo.loginAccount
          // console.log(this.ruleForm.userCode, 'this.ruleForm.userCode')
          this.ruleForm.password = this.ruleForm.old
          this.ruleForm.newPassword = this.ruleForm.new
          editPwd(this.ruleForm).then(res => {
            this.$notify({
              // title: '警告',
              message: '密码修改成功',
              // type: 'warning',
              duration: 1000
            })
            this.resetForm('ruleForm')
          }, Error => {
            var arr = Error.toString().split(':')
            this.$notify({
              // title: '警告',
              message: arr[1],
              // type: 'warning',
              duration: 1000
            })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm (ruleForm) {
      this.$refs[ruleForm].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.buttonColor {
  background-color: #ef922e;
  color: white;
}
.buttonColor1 {
  background-color: white;
  color: #ef922e;
}
.buttonColor:hover {
  background-color: #0066cc;
}
.item-div {
  width: 342px;
  position: relative;
}
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 38px 40px;
  height: 593px;
  .title {
    color: #333333;
    font-size: 24px;
  }
  .list-form {
    margin-top: 35px;
    color: #333333;
    font-size: 14px;
  }
  .form-div {
    margin-top: 30px;
    margin-left: -10px;
  }
  .button-ope {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .btn {
      width: 123px;
    height: 40px;
    border: 1px solid #E1E1E1;
    border-radius: 2px;
    line-height: 40px;
    text-align: center;
    color: #666666;
    font-size: 14px;
      &.active {
        //background-color: #ef922e;
        color: black;
        margin-right: 15px;
      }
      &.active:hover {
        cursor: pointer;
        color: white;
        background-color: #ef922e;
      }
    }
  }
}
</style>
