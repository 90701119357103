<template>
  <div class="orderList">
    <div class="title">
      <div>
        影院信息
      </div>
    </div>
    <div class="_left">
      影院名称<br>
      <el-input type="text" style="width: 400px;" disabled v-model="customerCinema.customerName" />
    </div>
    <div class="_left" style="margin-left: 170px;">
      省<br>
      <el-input type="text" style="width: 100px;" disabled v-model="customerCinema.proviceName" />
    </div>
    <div class="_left" style="margin-left: -127px;">
      城市<br>
      <el-input type="text" style="width: 200px;" disabled v-model="customerCinema.city" />
    </div>
    <div class="_left1">
      <span style="color: #f64f00">专资办编码*</span><br>
      <el-input type="text" style="width: 272px;" v-if="isActive" :disabled="isActive?true:false"  v-model="customerCinema.specialOfficeCode" />
      <el-input type="text" v-if="!isActive" style="background-color: white;width: 272px;" :disabled="isActive?true:false" v-model="customerCinema.specialOfficeCode" />
<!--   { 'active': isActive, 'error': !isActive }   -->
    </div>
    <div class="_left1" style="margin-left: -60px">
      安装地址<br>
      <el-input type="text" style="width: 500px;" disabled v-model="customerCinema.installationAddress" />
    </div>
    <div class="_left1">
      院线<br>
      <el-input type="text" style="width: 272px;" disabled v-model="customerCinema.theatreChainName" />
    </div>
    <div class="_left1" style="margin-left: -60px">
      投资人<br>
      <el-input type="text" style="width: 272px;" disabled v-model="customerCinema.investorName" />
    </div>
    <div class="title _head">
      <div style="display: flex;flex-direction: row">
        影厅信息
        <el-row>
          <el-button class="buttonColor" @click="flushCinemaContent">刷新</el-button>
          <el-button class="buttonColor" @click="editCinemaContent(customerCinema)">保存</el-button>
<!--          <el-button class="buttonColor" @click="saveCinemaContent(customerCinema)">保存</el-button>-->
        </el-row>
      </div>
      <el-table
        :data="cinemaInfo"
        border
        max-height="600px"
        :cell-style="cellStyle"
        style="width: 100%">
        <el-table-column
          fixed
          prop="hallNum"
          label="厅号">
        </el-table-column>
        <el-table-column
          width="200px"
          prop="cinemaName"
          label="影厅名称">
          <template slot-scope="scope">
            <el-input clearable v-model="scope.row.cinemaName" />
          </template>
        </el-table-column>
        <el-table-column
          width="160px"
          prop="screenSize"
          label="银幕尺寸(宽x高)m">
          <template slot-scope="scope">
            <el-input style="width: 120px" v-model="scope.row.screenSize" placeholder="比如: 12x6"/>
          </template>
        </el-table-column>
        <el-table-column
          width="150px"
          prop="seatsNum"
          label="座位数">
          <template slot-scope="scope">
            <el-input-number style="width: 110px;" controls-position="right" v-model="scope.row.seatsNum" />
          </template>
        </el-table-column>
        <el-table-column
          width="180px"
          prop="projectorBrand"
          label="放映机品牌">
          <template slot-scope="scope">
            <el-input disabled style="width: 140px" v-model="scope.row.projectorBrand" />
          </template>
        </el-table-column>
        <el-table-column
          prop="projectorModel"
          width="180px"
          label="放映机型号">
          <template slot-scope="scope">
            <el-select v-model="scope.row.projectorModel" @change="projectorChange($event, scope.row)" filterable placeholder="请选择">
              <el-option
                v-for="item in projectorList"
                :key="item.id"
                :label="item.label"
                :value="item.label">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="lightSourceModel"
          width="150px"
          label="光源型号">
        </el-table-column>
        <el-table-column
          width="180px"
          prop="serverBrand"
          label="服务器品牌">
          <template slot-scope="scope">
            <el-input disabled style="width: 140px" v-model="scope.row.serverBrand" />
          </template>
        </el-table-column>
        <el-table-column
          width="180px"
          prop="serverModel"
          label="服务器型号">
          <template slot-scope="scope">
            <el-select v-model="scope.row.serverModel" @change="serviceChange($event, scope.row)" filterable placeholder="请选择">
              <el-option
                v-for="item in serviceList"
                :key="item.id"
                :label="item.label"
                :value="item.label" >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          width="180px"
          prop="serverSerialNum"
          label="服务器序列号*" :render-header="showHeader">
          <template slot-scope="scope">
            <el-input style="width: 140px" v-model="scope.row.serverSerialNum" filterable placeholder="请输入"/>
          </template>
        </el-table-column>
        <el-table-column
          width="120px"
          prop="isLaserHall"
          label="激光厅标识">
          <template slot-scope="scope">
             <el-image v-if="scope.row.isLaserHall === '01'" class="img_style" style="width: 95px;" :src="jgtbs" :preview-src-list="[jgtbs]" />
             <!--<img v-if="scope.row.isLaserHall === '01'" style="width:65px"  src="@/assets/jgtbs.png" />-->
            <div v-else>暂无</div>
<!--            <input v-else type="button" value="去认证">-->
          </template>
        </el-table-column>
        <el-table-column
          width="120px"
          prop="isHlh"
          label="高亮版认证厅标识">
          <template slot-scope="scope">
            <el-image v-if="scope.row.isHlh === '01'" style="width: 95px;" :src="gllogo" :preview-src-list="[gllogo]" />
            <!--<img v-if="scope.row.isHlh === '01'" style="width:65px" src="@/assets/gllogo.png" />-->
<!--            <div v-if="scope.row.isHlh == '01'">{{ scope.row.isHlh }}</div>-->
            <input style="background-color: white; border: 1px solid #eee" v-if="scope.row.isHlh === '02' && scope.row.highlightAuthStatus === '01'" type="button" @click="goIdentity(scope.row)" value="去认证">
            <input style="background-color: #A2A2A2; border: 1px solid #eee" v-if="scope.row.isHlh === '02' && (scope.row.highlightAuthStatus === '02' || scope.row.highlightAuthStatus === '03')" type="button" value="认证中">
          </template>
        </el-table-column>
        <el-table-column
          width="120px"
          prop="highlightServiceType"
          label="高亮版服务类型">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.highlightServiceType == '01'">包年</span>
              <span v-else-if="scope.row.highlightServiceType == '02'">次卡(剩余{{ scope.row.residueNum }}次)</span>
              <span v-else-if="scope.row.highlightServiceType == '03'">使用</span>
              <span v-else>无</span>
            </div>
            <!-- <div v-if="scope.row.highlightServiceType == '02'">
              {{ scope.row.highlightServiceType }}(剩余{{ scope.row.residueNum }}次)
            </div>
            <div v-else>{{ scope.row.highlightServiceType }}</div> -->
          </template>
        </el-table-column>
        <el-table-column
          width="120px"
          prop="expirationDate"
          label="期限">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { editCustomAndCinemaInfo, add, findCinemaByType } from '@/api/personCenter/cinemaInfo/index'
export default {
  components: {
  },
  props: {
    customerCinema: {
      type: Object
    },
    cinemaInfo: {
      type: Array
    },
    isActive: {
      type: Boolean
    },
    goodsInfo: {
      type: Object
    }
  },
  data () {
    return {
      type1: '01',
      type2: '02',
      projectorList: [],
      serviceList: [],
      gllogo: require('@/assets/gllogo.png'),
      jgtbs: require('@/assets/jgtbs.png')
    }
  },
  onLoad () {
  },
  mounted () {
    findCinemaByType(this.type1).then(res => {
      // console.log(res.data.data.projectorList, '东西1')
      // this.projectorList = res.data.data.projectorList
      res.data.data.projectorList.forEach(item => {
        var obj = {
          label: item.projectorModel,
          value: item.projectorBrand,
          id: item.id
        }
        this.projectorList.push(obj)
      })
    })
    findCinemaByType(this.type2).then(res => {
      // console.log(res.data.data.serviceList, '东西2')
      // this.serviceList = res.data.data.serviceList
      res.data.data.serviceList.forEach(item => {
        var obj = {
          label: item.projectorModel,
          value: item.projectorBrand,
          id: item.id
        }
        this.serviceList.push(obj)
      })
    })
  },
  methods: {
    // 控制专资办编码框的样式
    // turnStyle () {
    //   if (this.customerCinema.specialOfficeCode) {
    //     this.isActive = true
    //   } else {
    //     this.isActive = false
    //   }
    // },
    showHeader (h, { column }) {
      return h(
        'div',
        [
          h('span', {
            style: 'color:#f64f00;margin-left:5px;'
          }, column.label)
        ]
      )
    },
    // 设置指定行、列、具体单元格颜色
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 1 && columnIndex === 9) {
        return 'color:red'
      } else {
        return ''
      }
    },
    // 渲染服务器品牌
    serviceChange (e, row) {
      debugger
      var objs = {}
      this.serviceList.forEach((item) => {
        if (item.label === e) {
          objs = item
        }
      })
      // console.log(objs)
      row.serverBrand = objs.value
    },
    // 渲染放映机品牌
    projectorChange (e, row) {
      var objs = {}
      this.projectorList.forEach((item) => {
        if (item.label === e) {
          objs = item
        }
      })
      // console.log(objs)
      row.projectorBrand = objs.value
    },
    // 刷新影厅
    flushCinemaContent () {
      this.$emit('getCinemas')
      // 刷新页面
      location.reload()
    },
    // 修改影厅
    editCinemaContent (obj) {
      editCustomAndCinemaInfo(obj).then(res => {
        this.$confirm('确定保存影厅信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '修改成功！',
            type: 'success',
            duration: 2000
          })
          // 刷新页面
          setTimeout(() => {
            location.reload()
          }, 2000)
        }, error => {
          console.log(error)
          var arr = error.toString().split(':')
          this.$notify({
            title: '提示',
            message: arr[1],
            type: 'warning',
            duration: 3000
          })
        })
      })
    },
    // 保存影厅
    saveCinemaContent (row) {
      add(row).then(res => {
        console.log(res)
      })
    },
    // 去认证
    goIdentity (row) {
      if (!row.serverSerialNum) {
        this.$notify({
          title: '提示',
          message: '完善服务器序列号才能进行认证!',
          type: 'warning',
          duration: 3000
        })
        return false
      }
      if (!row.isType) {
        this.$notify({
          title: '提示',
          message: '完善服务器序列号并保存后重试',
          type: 'warning',
          duration: 3000
        })
        return false
      }
      if (this.goodsInfo != null || this.goodsInfo !== '') {
        var blan = this.$router.resolve({ // 调转到商品搜索页面
          path: '/detail',
          query: {
            id: this.goodsInfo.id,
            commodityName: this.goodsInfo.commodityName
          }
        })
        window.open(blan.href, '_blank')
      } else {
        this.$notify({
          title: '提示',
          message: '高亮版认证产品未上架!',
          type: 'warning',
          duration: 3000
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.active {
  background: white;
}
.error {
  background: #CCCCCC;
}
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 38px 40px;
  box-sizing: border-box;
  ._head {
    margin-top: 380px;
  }
  .title {
    color: #333333;
    font-size: 24px;
    line-height: 40px;
    .buttonColor {
      color: black;
      margin-left: 20px;
    }
    .buttonColor:hover {
      background-color: #ef922e;
      color: white;
    }
  }
  ._left{
    display: block;
    float: left;
    font-size: 15px;
    width: 30%;
    margin-top: 70px;
    font-weight: 500;
    line-height: 2;
    input {
      //display: inline-block;
      width: 275px;
      font-size: 15px;
      font-weight: 500;
      background: #CCCCCC;
    }
  }
  ._left1{
    display: block;
    float: left;
    font-size: 15px;
    width: 40%;
    margin-top: 10px;
    font-weight: 500;
    line-height: 2;
    input {
      //display: inline-block;
      width: 275px;
      font-size: 15px;
      font-weight: 500;
      background: #CCCCCC;
    }
  }
}
.myOwnPage {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
</style>
