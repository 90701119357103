<template>
  <addressList/>
</template>

<script>
import addressList from './components/addressList'
export default {
  components: {
    addressList
  },
  data () {
    return {
      breadCrumbs: [{
        label: '首页'
      }, {
        label: '个人中心'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.myOwnOrder {
    width: 100%;
}
</style>
