import request from '@/router/axios'

/**
 * 获取公司信息
 * @returns {AxiosPromise}
 */
export const getCompanyMessage = () => {
  return request({
    url: '/cineplex/homePagePersonalCenter/getCompanyMessage',
    method: 'post'
  })
}

/**
 * 获取客户公司信息
 * @returns {AxiosPromise}
 */
export const findCompanyMessage = (customerId) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/findCompanyMessage',
    method: 'post',
    params: {
      customerId: customerId
    }
  })
}

/**
 * 获取资产信息
 * @returns {AxiosPromise}
 */
export const getAssetslightsource = (customerId) => {
  return request({
    url: '/cineplex/homePageOther/getAssetslightsource',
    method: 'post',
    params: {
      customerId: customerId
    }
  })
}
