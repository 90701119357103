<template>
  <div class="box display_column">
    <div class="header all_width display_flex bg_fff"><img src="../../assets/login_logo.png" alt="" /></div>
    <div class="content display_column">
      <div class="all_width">
        <img class="all_width" src="../../assets/login_bg.jpg" alt="" />
        <div v-if="status == 0" class="login_box bg_fff">
          <div class="com_width">
            <div class="wel">个人注册 - 验证手机号</div>
            <label>
              <input type="text" v-model="form.phone" placeholder="用户名/手机号" />
            </label>
            <label class="display_flex space_between label_view">
              <input type="password" v-model="form.phoneCode" placeholder="验证码" />
              <el-button v-show="show" type="primary" @click="sendcode">获取验证码</el-button>
              <el-button v-show="!show" type="primary">{{count}}秒</el-button>
            </label>
            <div class="btn" @click="verify">下一步</div>
          </div>
<!--         status = 1-->
        </div>
        <div v-if="status == 1" class="login_box bg_fff">
          <div class="com_width">
            <div class="wel">个人注册 - 填写账号信息</div>
            <label>
              <input type="text" v-model="form.userCode" placeholder="用户名" />
            </label>
            <label>
              <input class="margin_top" type="text" v-model="form.code" placeholder="推荐人" />
            </label>
            <label >
              <input class="margin_top" type="password" v-model="form.password" placeholder="设置密码" />
            </label>
            <label >
              <input class="margin_top" type="password" v-model="form.againPassword" placeholder="确认密码" />
            </label>
            <div class="btn" @click="grZc">立即注册</div>
            <div class="btn" @click="status = 0">重新验证手机号</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import { grCode, getVoId, verifyCode, registerAccount, accountIsExist } from '@/api/personRegister/personRegister'
export default {
  data () {
    return {
      qqq: '',
      status: 0,
      show: true,
      count: '',
      timer: null,
      form: {
        //  租户ID
        tenantId: '000000',
        //  用户名
        username: '',
        //  密码
        password: '',
        //  确认密码
        againPassword: '',
        //  账户类型
        type: 'password',
        //  验证码的值
        phoneCode: '',
        //  验证码的索引
        key: '',
        //  预加载白色背景
        image: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
      },
      voId: ''
    }
  },
  mounted () {
    this.onLoad()
  },
  methods: {
    // 获取验证码id
    onLoad () {
      getVoId().then(res => {
        this.voId = res.data.data
      }).catch(e => {
        // console.log(e)
      })
    },
    login () {
      this.$store.dispatch('Login', this.form).then(res => {
        this.$router.push('/home')
      })
    },
    goPerson () {
      this.$router.push('/personRegister')
    },
    getCode () {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    // 获取验证码
    sendcode () {
      var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/
      if (this.form.phone === '') {
        this.$message({
          message: '手机号不能为空',
          type: 'warning'
        })
      } else if (!reg.test(this.form.phone)) {
        this.$message({
          message: '手机号格式不正确',
          type: 'warning'
        })
      } else {
        this.getCode()
        // this.timer()
        const par = {}
        par.phone = this.form.phone
        par.voId = this.voId
        grCode(par).then(res => {
        }).catch(e => {
          // console.log(e)
        })
      }
    },
    // 检验验证码
    verify () {
      debugger
      const par = {}
      par.phone = this.form.phone
      par.code = this.form.phoneCode
      par.voId = this.voId
      if (this.form.phoneCode && this.form.phone) {
        var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/
        if (this.form.phone === '') {
          this.$message({
            message: '手机号不能为空',
            type: 'warning'
          })
        } else if (!reg.test(this.form.phone)) {
          this.$message({
            message: '手机号格式不正确',
            type: 'warning'
          })
          return
        }
        if (this.form.userCode === '' && this.form.password === '' && this.qqq === this.form.phoneCode) {
          // eslint-disable-next-line no-undef
          this.$message({
            title: '注册友情提示',
            message: '请输入用户名和密码',
            type: 'warning'
          }).then(() => {
          })
        }
        //  判断手机号是否重复
        accountIsExist(this.form.phone).then(res => {
          if (res.data.data && res.data.data.code === '-1') {
            this.$message({
              message: '该账号已存在,请换个账号重试！',
              type: 'warning'
            })
            // return false
            // 如果不重复进行下一步
          } else if (res.data.code === 0) {
            verifyCode(par).then(res => {
              if (res.data.data && res.data.data === true) {
                this.status = 1
              } else {
                this.$message({
                  title: '注册友情提示',
                  message: '验证码输入有误',
                  type: 'warning'
                }).then(() => {
                })
              }
              // res.data.data
            }).catch(e => {
              // console.log(e)
            })
          }
        }).catch(e => {
          // console.log(e)
        })
        // verifyCode(par).then(res => {
        //   this.status = 1
        //   // res.data.data
        // }).catch(e => {
        //   // console.log(e)
        // })
      } else if (this.form.phone === '' || this.form.phoneCode === '') {
        this.$message({
          title: '注册友情提示',
          message: '手机号和验证码不能为空',
          type: 'warning'
        }).then(() => {
        })
      }
    },
    // 注册
    grZc () {
      debugger
      if (this.form.userCode === '') {
        this.$message({
          message: '请输入用户名',
          type: 'warning'
        })
      } else if (this.form.customerName === '') {
        this.form.customerName = 'MALL'
      } else if (this.form.password === '') {
        this.$message({
          message: '请输入密码',
          type: 'warning'
        })
      } else if (this.form.againPassword === '') {
        this.$message({
          message: '重复密码不能为空！',
          type: 'warning'
        })
      } else if (this.form.password.length < 6) {
        this.$message({
          message: '密码不能少于6位！',
          type: 'warning'
        })
      } else if (this.form.password.length > 16) {
        this.$message({
          message: '密码最长不能超过16位！',
          type: 'warning'
        })
      } else if (this.form.password !== this.form.againPassword) {
        this.$message({
          message: '两次密码不一致',
          type: 'warning'
        })
      } else if (this.form.userCode && this.form.password) {
        const par = {
          userCode: this.form.userCode,
          password: this.form.password,
          phone: this.form.phone,
          properties: '0',
          code: 'MALL',
          systemSource: 'MALL',
          customerInfo: {
            customerName: this.form.userCode,
            systemSource: 'MALL'
          }
        }
        //  判断账号是否重复
        accountIsExist(this.form.userCode).then(res => {
          if (res.data.data && res.data.data.code === '-1') {
            this.$message({
              message: '该用户名已存在,请换个用户名重试！',
              type: 'warning'
            })
            return false
            //  如果不重复则注册
          } else if (res.data.code === 0) {
            registerAccount(par).then(res => {
              this.$alert('恭喜您,注册成功', {
                confirmButtonText: '去登录',
                callback: action => {
                  this.$router.push('/home')
                }
              })
            }).catch(e => {
              // console.log(e)
            })
          }
        }).catch(e => {
          // console.log(e)
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .box {
    .margin_top{
      margin-top: 10px;
    }
    .header {
      height: 100px;
      justify-content: flex-start;
      img {
        width: 188px;
        height: 43px;
      }
    }
    .content {
      width: 100%;
      background: #f83c46;
      .all_width {
        position: relative;
        img {
          height: 558px;
        }
        .login_box {
          border-radius: 6px;
          width: 405px;
          background: #ffffff;
          position: absolute;
          z-index: 999;
          top: 49px;
          box-sizing: border-box;
          right: 0;
          padding: 40px 20px;
          .label_view{
            input{
              width: 60%;
              margin-top: 30px;
            }
            .el-button{
              height: 46px;
              margin-top: 30px;
            }
          }
          .wel {
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            text-align: left;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
            margin-bottom: 30px;
          }
          input {
            box-sizing: border-box;
            width: 100%;
            display: block;
            height: 48px;
            background: #ffffff;
            border: 1px solid #e5e5e5;
            border-radius: 4px;
            padding-left: 20px;
          }
          .btn {
            width: 100%;
            height: 48px;
            line-height: 48px;
            text-align: center;
            background: #ef922e;
            border-radius: 4px;
            color: #fff;
            margin-top: 20px;
            cursor: pointer;
          }
          .bot_div {
            margin-top: 30px;
            color: #333;
            font-size: 15px;
            span{
              width: 14px;
              height: 14px;
              border: 1px solid #ef922e;
              border-radius: 3px;
              // flex:1;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
</style>
