<template>
  <div class="myOwnOrder">
    <!-- 搜索层 -->
    <search-part/>
    <bread-crumbs :crumbs="breadCrumbs"/>
    <contentWrap>
      <div class="myOwnPage">
        <personList id="1"/>
        <router-view />
      </div>
    </contentWrap>
  </div>
</template>

<script>
import searchPart from '@/components/searchPart'
import breadCrumbs from '@/components/breadCrumbs'
import contentWrap from '@/components/contentWrap'
import personList from '@/components/personList'
export default {
  components: {
    searchPart,
    contentWrap,
    breadCrumbs,
    personList
  },
  data () {
    return {
      breadCrumbs: [{
        label: '首页'
      }, {
        label: '个人中心'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.myOwnOrder {
    width: 100%;
}
.myOwnPage {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
</style>
