import request from '@/router/axios'

/**
 * 获取订单详情
 * @returns {AxiosPromise}
 */
export const getOrderDetal = (orderNo) => {
  return request({
    url: '/cineplex/homePageGoods/getOrderDetal',
    method: 'post',
    params: {
      orderNo: orderNo
    }
  })
}
