<template>
  <div class="special-floor">
      <div class="left card">
          <div class="title-with-sub" v-if="specialRecommend.advertPostionVo">
              <div class="left">
                <span>{{ specialRecommend.advertPostionVo? specialRecommend.advertPostionVo.text : '' }}</span>
              </div>
          </div>
          <div class="card-content" v-if="specialRecommend.goodsList && specialRecommend.goodsList.length > 0">
              <div class="special-card-wrapper" @click="goodsDetail(specialRecommend.goodsList[0].id, specialRecommend.goodsList[0].commodityName, 1, '08', 0)">
                  <default-special-card
                    :item="specialRecommend.goodsList[0]"
                    background-color="#F5F5F5"/>
              </div>
              <div class="special-card-small">
                <div style="width:184px;height:135px;"
                     @click="goodsDetail(item.id, item.commodityName, 1, '08', idx+1)"
                     v-for="(item, idx) in specialRecommend.goodsList.slice(1)" :key="item.id">
                  <small-special-card :item="item"/>
                </div>
              </div>
          </div>
      </div>
      <div class="right card">
          <div class="title-with-sub" v-if="rankingRecommend.advertPostionVo">
              <div class="left">
                <span>{{ rankingRecommend.advertPostionVo? rankingRecommend.advertPostionVo.text : '' }}</span>
              </div>
          </div>
          <div class="card-content addbg" v-if="rankingRecommend.goodsList && rankingRecommend.goodsList.length > 0">
            <div class="special-card-wrapper"
                 v-for="(item,index) in rankingRecommend.goodsList" :key="item.id"
                 @click="goodsDetail(item.id, item.commodityName, 2, '09', 2 - index)">
              <default-special-card :item="item"/>
              <div class="top-label">
                <img v-if="index === 0" src="@/assets/top1.png" alt="">
                <img v-if="index === 1" src="@/assets/top2.png" alt="">
                <img v-if="index === 2" src="@/assets/top3.png" alt="">
              </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import defaultSpecialCard from '@/components/specialCard/default'
import smallSpecialCard from '@/components/specialCard/small'
import { hits } from '@/api/hits'
export default {
  methods: {
    goodsDetail (id, commodityName, label, clickPostion, idx) {
      var busId
      var busTitle
      var idxTmp
      if (clickPostion === '08') {
        idxTmp = this.specialRecommend.goodsList.length - idx
        busId = '080' + (idxTmp)
        busTitle = '本周推荐' + (idx + 1)
      } else if (clickPostion === '09') {
        idxTmp = this.rankingRecommend.goodsList.length - idx
        busId = '090' + (idxTmp)
        busTitle = '排行榜' + (3 - idx)
        // busTitle = '排行榜' + (idx + 1)
      }
      hits({
        type: '02',
        clickPostion: clickPostion,
        busTitle: busTitle,
        busId: busId,
        sourcePosition: clickPostion,
        sourceSection: '01'
      })
      hits({
        type: '01',
        goodsId: id,
        goodsName: commodityName,
        sourcePosition: clickPostion,
        sourceSection: '01'
      })
      if (label === 1) {
        label = '本周推荐'
      } else {
        label = '排行榜'
      }
      var blan = this.$router.resolve({ // 调转到商品详情页面
        path: '/detail',
        query: {
          id: id,
          commodityName: commodityName,
          keys: label,
          url: '/home'
        }
      })
      window.open(blan.href, '_blank')
    }
  },
  components: {
    defaultSpecialCard,
    smallSpecialCard
  },
  props: {
    rankingRecommend: {
      type: Object
    },
    specialRecommend: {
      type: Object
    }
  },
  data () {
    return {
      status: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.special-floor:hover {
  cursor: pointer;
}
.special-floor {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 360px;
    .card {
        width: 603px;
        height: 100%;
        background-color: #fff;
        padding: 23px 20px 20px 20px;
        position: relative;
         box-sizing: border-box;
        .title-with-sub {
            display: flex;
            align-items: flex-end;
            .left {
              color: #000000;
              font-size: 24px;
              font-weight: 600;
              text-align: left;
            }
            .right {
              margin-left: 20px;
              display: flex;
              align-items: flex-end;
              .switch-item {
                width: 103px;
                height: 29px;
                background: #F6F6F6;
                border-radius: 15px;
                margin-right: 6px;
                text-align: center;
                line-height: 29px;
                &.active {
                  background-color: #ef922e;
                  font-size: 14px;
                  font-weight: 400;
                  color: #FFFFFF;
                }
              }
            }

        }
		.addbg{
			> :first-child{
				background: #EBF7F4;
			}
			> :nth-child(2){
				background:#F1F5F9;
			}
			> :last-child{
				background: #F9F8F1;
			}
		}
        .card-content {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            height: 285px;
            width: 100%;
            .special-card-wrapper {
                width: 187px;
                height: 280px;
                position: relative;
                .special-card-center{
                  margin-top: 0;
                }
                .card-cheapest-label {
                  position: absolute;
                  top: 21px;
                  left: 0;
                  width: 106px;
                  img {
                    display: block;
                    width: 100%;
                  }
                }
                .top-label {
                  position: absolute;
                  top: 0;
                  left: 20px;
                  width: 33px;
                  height: 38px;
                  img {
                    display: block;
                    width: 100%;
                  }
                }
            }
            .special-card-small {
                display:flex;
                flex-wrap:wrap;
                flex:1;
                justify-content: space-between;
                display: grid;
                position:relative;
                left:20px;
                grid-template-columns: 184px 184px;
                grid-template-rows: 133px 121px;
                grid-row-gap: 11px;
                grid-column-gap: 11px;
                // div{
                //   -webkit-width:184px;
                //   -webkit-height:122px;
                // }
            }
        }
    }
}
</style>
