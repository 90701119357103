<template>
  <div class="order-header">
    <div class="left">
      <div class="left-item">
        <div class="number">
          <span style="font-weight: bolder">订单号：</span><span style="font-weight: bolder" id="orderNo">{{ orderDetails.orderNo }}</span> <textarea id="input"></textarea><img class="copyOrderNo" src="@/assets/copy.png" @click="copyText"/><!--<button class="copyOrderNo" @click="copyText">复制</button>-->
        </div>
        <div class="status">
          <span>{{ orderDetails.orderStatusName }}</span>
        </div>
        <div class="print" @click="goOrderDetail">
          <div>
            <img src="@/assets/print.png" alt="">
          </div>
          <div>
            <span>打印订单</span>
          </div>
        </div>
         <div class="view-detail">
        <span>{{ orderDetails.orderTime }}</span>
      </div>
      </div>
      <div class="sperate"></div>
    </div>
    <div class="right_div right">
      <div class="title">
        <span v-if="orderDetails.orderStatus == '05'">订单已经完成，感谢您在中影光峰商城购物</span>
       <!-- <span v-if="orderDetails.orderStatus != '05'&&orderDetails.orderStatus != '01'">下单完成，等待完成</span>-->
      </div>

      <div class="cont display_flex space_between">
        <dl>
          <dt v-if="orderDetails.payStatus=='01' || orderDetails.payStatus=='04'"><img src="../../../../assets/status_one_n.png" alt=""/></dt>
          <dt v-if="orderDetails.payStatus=='02'"><img src="../../../../assets/2、部分付款.png" alt=""/></dt>
          <dt v-if="orderDetails.payStatus=='03'"><img src="../../../../assets/3、已付款.png" alt=""/></dt>
          <dd v-if="orderDetails.payStatus=='01'">付款(未付款)</dd>
          <dd v-if="orderDetails.payStatus=='02'">付款(部分付款)</dd>
          <dd v-if="orderDetails.payStatus=='03'">付款(已付款)</dd>
          <dd v-if="orderDetails.payStatus=='04'">付款(已取消)</dd>
        </dl>
        <img class="line" src="../../../../assets/line.png" alt="" />
        <dl>
          <dt v-if="orderDetails.orderStatus=='02'|| orderDetails.orderStatus=='01'||orderDetails.orderStatus=='06'"><img src="../../../../assets/5、未发货.png" alt=""/></dt>
          <dt v-if="orderDetails.orderStatus=='03'"><img src="../../../../assets/6、部分发货.png" alt=""/></dt>
          <dt v-if="orderDetails.orderStatus=='04'||orderDetails.orderStatus=='05'"><img src="../../../../assets/8、发货已完成.png" alt=""/></dt>
          <dd v-if="orderDetails.orderStatus=='02'|| orderDetails.orderStatus=='01'||orderDetails.orderStatus=='06'">发货(未发货)</dd>
          <dd v-if="orderDetails.orderStatus=='03'">发货(部分发货)</dd>
          <dd v-if="orderDetails.orderStatus=='04'||orderDetails.orderStatus=='05'">发货(发货完成)</dd>
        </dl>
        <img class="line" src="../../../../assets/line.png" alt="" />
        <dl>
          <dt v-if="(orderDetails.invoiceStatus==''||orderDetails.invoiceStatus=='01')&&orderDetails.payStatus=='04'"><img src="../../../../assets/weikaipiao.png" alt="去开发票" title="去开发票"/></dt>
          <dt v-if="(orderDetails.invoiceStatus==''||orderDetails.invoiceStatus=='01') && orderDetails.payStatus!='04'" @click="goOpenInvoce()" class="orderDetail_bnt"><img src="../../../../assets/weikaipiao.png" alt="去开发票" title="去开发票"/></dt>
          <dt v-if="orderDetails.invoiceStatus=='02'" @click="goOpenInvoce()" class="orderDetail_bnt"><img src="../../../../assets/10、部分开票.png" alt="去开发票" title="去开发票"/></dt>
          <dt v-if="orderDetails.invoiceStatus=='03'"><img src="../../../../assets/11、已完成.png" alt=""/></dt>
          <dt v-if="orderDetails.invoiceStatus=='04'"><img src="../../../../assets/11、已完成.png" alt=""/></dt>
          <dd v-if="(orderDetails.invoiceStatus==''||orderDetails.invoiceStatus=='01')&& orderDetails.payStatus!='04'">去开票(未申请)</dd>
          <dd v-if="(orderDetails.invoiceStatus==''||orderDetails.invoiceStatus=='01')&& orderDetails.payStatus=='04'">(未申请)</dd>
          <dd v-if="orderDetails.invoiceStatus=='02'">去开票(部分申请)</dd>
          <dd v-if="orderDetails.invoiceStatus=='03'">全部申请(全部申请)</dd>
          <dd v-if="orderDetails.invoiceStatus=='04'">不开具</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderDetails: {
      type: Object
    }
  },
  methods: {
    goOrderDetail () {
      this.$router.push({ // 调整到详情界面
        path: 'orderPrint',
        query: {
          orderNo: this.orderDetails.orderNo
        }
      })
    },
    copyText () {
      var text = document.getElementById('orderNo').innerText
      var input = document.getElementById('input')
      input.value = text // 修改文本框的内容
      input.select() // 选中文本
      document.execCommand('copy') // 执行浏览器复制命令
      this.$notify({
        // title: '警告',
        message: '复制成功',
        // type: 'warning',
        duration: 500
      })
    },
    goOpenInvoce () {
      this.$router.push({ // 调整到详情界面
        path: '/invoiceApply',
        query: {
          orderNo: this.orderDetails.orderNo
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.orderDetail_bnt:hover {
  cursor: pointer;
}
#input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
}
.copyOrderNo:hover {
  cursor: pointer;
}
.order-header {
  width: 100%;
  background-color: #ffffff;
  height: 200px;
  position: relative;
  display: flex;
  .left {
    width: 289px;
    position: relative;
    .left-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .number {
        color: #999999;
        font-size: 14px;
        margin-top: 17px;
      }
      .status {
        color: #7abd53;
        font-size: 34px;
        margin-top: 26px;
      }
      .view-detail {
        width: 130px;
        height: 30px;
        border: 0px solid #e1e1e1;
        border-radius: 2px;
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        margin-top: 18px;
        color: #333333;
      }
      .print {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #666666;
      }
      .print:hover {
        cursor: pointer;
      }
    }
    .sperate {
      position: absolute;
      width: 1px;
      height: 200px;
      background: linear-gradient(#ffffff, #e1e1e1, #ffffff);
      right: 0;
      top: 0;
    }
  }
  .right {
    width: 936px;
    height: 100%;
    padding-left: 55px;
    box-sizing: border-box;
    .title {
      font-size: 14px;
      color: #999999;
      margin-top: 19px;
      text-align: left;
    }
    .cont {
      width: 90%;
      padding: 26px 0;
      dl{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      dt {
        img {
          height: 60px;
          width: 60px;
        }
        margin-bottom: 14px;
      }
      .line {
        width: 155px;
        height: 5px;
        margin-bottom: 24px;
      }
    }
    .progress {
      margin-top: 48px;
      display: flex;
      height: 22px;
      background: #f5f5f5;
      width: 750px;
      border-radius: 11px;
      .progress-item {
        width: 150px;
        text-align: center;
        line-height: 22px;
        font-size: 12px;
        color: #333333;
        &.active {
          color: white;
          background: #84c44f;
          border-radius: 11px;
        }
      }
    }
    .time {
      width: 91px;
      font-size: 12px;
      color: #333333;
      margin-top: 18px;
      margin-left: 30px;
    }
  }
}
</style>
