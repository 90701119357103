import request from '@/router/axios'

/**
 * 个人代付款证明
 * @param params
 * @returns {AxiosPromise}
 */
export const getPersonalProve = () => {
  return request({
    url: '/cineplex/homePagePersonalCenter/getPersonalProve',
    method: 'post'
  })
}

/**
 * 公司代付款证明
 * @param params
 * @returns {AxiosPromise}
 */
export const getCompanyProve = () => {
  return request({
    url: '/cineplex/homePagePersonalCenter/getCompanyProve',
    method: 'post'
  })
}
