<template>
  <div class="my-order">
      <onlySearchPart></onlySearchPart>
      <bread-crumbs :crumbs="breadCrumbs"></bread-crumbs>
      <contentWrap>
        <orderHeader :orderDetails="orderDetails"></orderHeader>
        <orderList
          :orderGoodsVoList="orderGoodsVoList"
          :cinemaInfoList="cinemaInfoList"
        ></orderList>
        <shipList
          :receivingList="receivingList"
          :logisticsNoList="logisticsNoList"
          :payInfoList="payInfoList"
        ></shipList>
        <moneyInfo
          :goodsNum="orderDetails.goodsNum"
          :goodsTranslateNum="orderDetails.goodsTranslateNum"
          :totalSum="orderDetails.orderTotalSum"
          :goodsCouponAmount="orderDetails.goodsCouponAmount"
          :voucherMoney="orderDetails.voucherMoney"
          :goodsZGBAmount="orderDetails.goodsZGBAmount"
        ></moneyInfo>
        <resultInfo
          :orderTotalSum="orderDetails.orderTotalSum"
          :amountPaid="orderDetails.amountPaid"
          :outstandingAmount="orderDetails.outstandingAmount"
          :goodsTranslateMoney="orderDetails.goodsTranslateMoney"
        ></resultInfo>
      </contentWrap>
  </div>
</template>

<script>
import onlySearchPart from '@/components/onlySearchPart'
import breadCrumbs from '@/components/breadCrumbs'
import contentWrap from '@/components/contentWrap'
import orderHeader from './components/orderHeader'
import orderList from './components/orderList'
import shipList from './components/shipList'
import moneyInfo from './components/moneyInfo'
import resultInfo from './components/resultInfo'
import { orderDetail } from '@/api/order/orderInfo/index'
export default {
  data () {
    return {
      pstatus: '待付款',
      orderDetails: {
        invoiceStatus: '01',
        orderStatus: '02',
        payStatus: '01'
      },
      receivingList: [],
      logisticsNoList: [],
      payInfoList: [],
      orderGoodsVoList: [],
      cinemaInfoList: [],
      breadCrumbs: [{
        label: '首页'
      }, {
        label: '个人中心'
      }, {
        label: '订单中心'
      }, {
        label: '订单1000001232133435'
      }]
    }
  },
  components: {
    onlySearchPart,
    contentWrap,
    orderList,
    resultInfo,
    moneyInfo,
    shipList,
    orderHeader,
    breadCrumbs
  },
  mounted () {
    this.onLoad()
  },
  methods: {
    onLoad () { // 初始化加载推荐,商品详情,商品图片
      orderDetail(this.$route.query.id).then(res => {
        this.orderDetails = res.data.data
        this.receivingList.push('收 货 人： ' + res.data.data.receivingName)
        this.receivingList.push('地 址： ' + res.data.data.receivingAddr)
        this.receivingList.push('手机号码： ' + res.data.data.receivingPhone)
        var receivingFixedTel = ''
        if (res.data.data.receivingFixedTel !== undefined && res.data.data.receivingFixedTel !== '') {
          receivingFixedTel = res.data.data.receivingFixedTel
        }
        this.receivingList.push('固定电话： ' + receivingFixedTel)
        this.logisticsNoList = res.data.data.logisticsNo
        this.orderGoodsVoList = res.data.data.goodsVoList
        this.cinemaInfoList = res.data.data.cinemaInfoList
        this.payInfoList.push('付款方式： 公司转账')
        // 01=待付款,02=部分付款,03=已付款
        if (res.data.data.payStatus === '02') {
          this.pstatus = '部分付款'
        }
        if (res.data.data.payStatus === '03') {
          this.pstatus = '已付款'
        }
        if (res.data.data.payStatus === '04') {
          this.pstatus = '已取消'
        }
        this.payInfoList.push('付款状态： ' + this.pstatus)
        this.payInfoList.push('付款时间： ' + res.data.data.collectionTime)
      })
    }
  }
}
</script>

<style>
.my-order {
  width: 100%;
}
</style>
