<template>
  <infoForm/>
<!--  <div class="myOwnOrder">-->
<!--    &lt;!&ndash; 搜索层 &ndash;&gt;-->
<!--    <search-part/>-->
<!--    <bread-crumbs :crumbs="breadCrumbs"/>-->
<!--    <contentWrap>-->
<!--      <router-view />-->
<!--&lt;!&ndash;      <myOwnPage/>&ndash;&gt;-->
<!--    </contentWrap>-->
<!--  </div>-->
</template>

<script>
// import searchPart from '@/components/searchPart'
// import breadCrumbs from '@/components/breadCrumbs'
// import contentWrap from '@/components/contentWrap'
import infoForm from './components/infoForm'
export default {
  components: {
    // searchPart,
    // contentWrap,
    infoForm
    // breadCrumbs
  },
  data () {
    return {
      breadCrumbs: [{
        label: '首页'
      }, {
        label: '个人中心'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.myOwnOrder {
    width: 100%;
}
</style>
