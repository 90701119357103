<template>
  <div class="operateArea mg-top-20">
      <div class="left">
          <a style="margin-right: 10px;color: #ef922e" @click="goHome">继续购物</a>
          <a style="margin-right: 10px;color: #ef922e" @click="clearCart">清空购物车</a>
      </div>
      <div class="right">
        <div class="price">
          <span>共 <span class="orange" style="font-size: 24px;">{{this.num}}</span> 件商品，已选择 <span class="orange" style="font-size: 24px;">{{this.number}}</span> 件</span>
        </div>
          <div class="price">
              <!--<span style="margin-right: 15px;">优惠券：-600 元</span>-->
              <span>合计：<span class="orange" style="font-size: 24px;">{{this.totalAmont}}</span> 元</span>
          </div>
          <div class="btn" @click="jiesuan">
              <span>去结算</span>
          </div>
      </div>
  </div>
</template>

<script>
import { clearCart } from '@/views/myCar/components/goodsCar.js'
export default {
  props: {
    num: {
      type: Number,
      required: true,
      default: 0
    },
    totalAmont: {
      type: NaN,
      required: true,
      default: 0
    },
    number: {
      type: Number,
      required: true,
      default: 0
    }
  },
  methods: {
    jiesuan () {
      this.$parent.submitJieSuan()
    },
    goHome () {
      this.$router.push({ // 调整到详情界面
        path: '/home'
        // query: {}
      })
    },
    clearCart () {
      clearCart().then(res => {
        this.$parent.onLoad()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.operateArea {
    display: flex;
    color: #333333;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: white;
    .left {
        padding-left: 20px;
    }
    .right {
        display: flex;
        align-items: center;
        .price {
            margin-right: 15px;
        }
        .btn {
            width: 223px;
            height: 60px;
            background: #ef922e;
            line-height: 60px;
            text-align: center;
            color: white;

        }
      .btn:hover {
        cursor: pointer;
      }
    }
}
.orange {
            color: #ef922e;
        }
</style>
