<template>
  <div class="myCar">
      <onlyLogoPart>
          <span slot=title>我的购物车</span>
        <div v-show="pictureList.length" v-for="(item, index) in pictureList" :key="index" style="width: 930px;height: 100px;">
          <img style="width: 930px;height: 100px;" :src="item.homePageImg" />
        </div>
          <userPart></userPart>
      </onlyLogoPart>
        <contentWrap>
            <contentArea></contentArea>
        </contentWrap>
  </div>
</template>

<script>
import onlyLogoPart from '@/components/onlyLogoPart'
import userPart from './components/user'
import contentArea from './components/contentArea'
import contentWrap from '@/components/contentWrap'
import { showShopAdvert } from '@/api/personCenter/addressInfo/advertL'
export default {
  data () {
    return {
      pictureList: []
    }
  },
  mounted () {
    this.onLoad()
  },
  methods: {
    onLoad () {
      showShopAdvert().then(res => {
        this.pictureList = res.data.data
      })
    }
  },
  components: {
    onlyLogoPart,
    contentWrap,
    contentArea,
    userPart
  }
}
</script>

<style lang="scss" scoped>
.myCar {

}
</style>
