<template>
  <div class="orderSuccess">
    <contentWrap>
        <printContent></printContent>
      </contentWrap>
  </div>
</template>

<script>
import contentWrap from '@/components/contentWrap'
import printContent from './components/printContent'
export default {
  components: {
    contentWrap,
    printContent
  }
}
</script>

<style>

</style>
