<template>
  <div class="index-wrap">
    <!-- 搜索层 -->
    <search-part :labelVosList="labelVosList"/>
    <!-- 内容区 -->
    <content-wrap :leftImgObj="leftImgObj">
      <!-- 轮播层 3、4-->
      <swipper-floor :categoryList="categoryList" :carouselList="carouselList" :centerIntervalTmp="centerIntervalTmp" :rightCarouselList="rightCarouselList" :rightIntervalTmp="rightIntervalTmp"/>
      <!-- 特价层 8、9-->
      <special-floor :specialRecommend="weekVos" :rankingRecommend="rankingVos"/>
      <!-- 移动区 5-->
      <move-bar-floor :moveBarObj="moveBarObj"/>
      <!-- 中部广告 10-->
      <div v-if="middleAdvertObj && middleAdvertObj.advertPostionVo" style="width: 1226px;height: 296px;margin: 20px 0px;">
        <!--<div style="height: 40px;font-size: 24px;font-weight: 600;color: black;">
          {{middleAdvertObj.advertPostionVo.text}}
        </div>-->
        <el-image @click="sendClick('10', middleAdvertObj.advertPostionVo.img)" class="img_style" style="width: 1209px;height: 256px;padding-left: 17px;" :src="middleAdvertObj.advertPostionVo.img" :preview-src-list="previewList" />
      </div>
      <!-- 热搜榜层 11 -->
      <cheap-floor :hotObj="hotObj" v-if="hotObj"/>
      <!-- 精品促销层 -->
      <div v-for="(labelVosItem,idx) in labelVos" :key="labelVosItem.id">
        <best-goods-floor
          :idNo="idx"
          :labelId="labelVosItem.id"
          :labelName="labelVosItem.labelName"
          :labelHomePageList="labelVosItem.infoVos"
          :labelPicture="labelVosItem.labelPicture"
          v-if="labelVosItem.infoVos.length>0 && labelVosItem.labelName !== '合同商品'"
        ></best-goods-floor>
      </div>
    </content-wrap>
    <div v-if="isShow">
      <el-dialog
      style="margin-top:15vh;"
      title="中影光峰自助客户服务网站改版通知"
      :visible.sync="centerDialogVisible"
      :show-close="false"
      width="986px"
      class="centerDialogVisible"
      :center="true">
     <div>
       <span style="font-size: 25px;font-weight: 600;color: #2C2C2C;line-height: 30px;">尊敬的客户您好：</span>
       <p>
         为提升服务质量，自即日起，中影光峰自助客户服务网站首页已完成全新改版升级，激光小时充值入口已统一迁移至犀牛MALL平台首页右上方（<span style="color: #f6180b;">激光充值</span>）。
       </p>
       <p>
         给您带来的不便，敬请谅解！
       </p>
      <p class="cla">
        如有疑问，请您拨打客服电话 &nbsp;400-870-9700
      </p>
     </div>
      <span slot="footer" class="dialog-footer">
        <el-button style="width: 105px;background: red;color: #FFF;background-color: red;border-color: red;font-size: 15px;font-weight: 700;" type="primary" @click="updateOperator"
          >我已阅读</el-button
        >
      </span>
    </el-dialog>
    </div>
    <!--<div v-if="isSpringFestivalShow">
      <el-dialog
      style="margin-top:10vh;"
      title="国庆假期物流通知"
      :visible.sync="centerDialogVisible"
      :show-close="false"
      width="955px"
      class="SpringFestivalVisible"
      :center="true" >
     <div>
       <span style="font-size: 25px;font-weight: 600;color: #2C2C2C;line-height: 30px;">尊敬的客户：</span>
       <p>
         您好，国庆假期即将来临，犀牛商城商品将于2024年9月28日起陆续停止订单发货，2024年10月8日起恢复正常发货。在此期间，您可以正常下单，我们工作人员将在节后及时处理订单，尽快安排发货。如您有任何问题，请拨打客服电话4008709700进行咨询。
       </p>
       <p>
         请您根据需求，提前订货。如给您带来不便，敬请谅解，多谢支持！
       </p>
      <p class="cla">
        祝您国庆快乐，票房大卖！
      </p>
       <dev>
         <p class="cla" style="float:left; margin-left: 77%;">
           <span style="float:right">中影光峰</span><br/>
           <span style="float:right">2024年9月23日</span>
         </p>
       </dev>
     </div>
      <span slot="footer" class="dialog-footer">
        <el-button style="width: 185px;background: red;margin-top: 3%;color: #FFF;background-color: red;border-color: red;font-size: 20px;font-weight: 700;" type="primary" @click="updateSpringFestival"
          >我已阅读</el-button
        >
      </span>
    </el-dialog>
    </div>-->
    <!-- <div v-if="isSpringFestivalShow">
      <el-dialog
        style="margin-top:5vh;"
        title="关于我司全面推行数字化电子发票的重要通知"
        :visible.sync="centerDialogVisible"
        :show-close="false"
        width="955px"
        class="SpringFestivalVisible"
        :center="true" >
        <div>
          <span style="font-size: 25px;font-weight: 600;color: #2C2C2C;line-height: 30px;margin-left: 37px;">尊敬的客户：</span>
          <p>您好！</p>
          <p>
            感谢您一直以来对我们公司的支持与信任！为了积极响应国家税制改革号召，进一步提升服务效率，优化业务流程，我们现决定全面启用数字电子发票系统。在此，我们特此通知您关于未来开具发票的相关变更事项：
          </p>
          <p>
            一、2024年5月15日我公司将停止提供传统的纸质发票和传统的电子普票，转而采用全国统一的数字电子发票。这意味着您在享受我们服务的同时，将通过电子化的方式获取发票。
          </p>
          <p>
            二、数字电子发票具有与纸质发票同等的法律效力，且更便于保存和查询。您在完成交易后，我们将通过电子邮件或客户自助服务系统将电子发票发送给您，您只需下载、打印或直接在线查阅即可。
          </p>
          <p>
            三、为确保您的权益，激光充值客户请您尽快在<b><span style="color: #e54712;">客户自助服务系统</span></b>的<b><span style="color: #e54712;">个人中心&#45;&#45;开票信息&#45;&#45;邮箱</span></b>中维护您的收票邮箱，非激光充值客户请您及时联系我们维护邮箱，以便我们能及时为您开具并送达电子发票。
          </p>
          <p>
            四、数字电子发票具有低碳环保、便于管理等诸多优点，希望能得到您的理解和支持。如有任何疑问或在使用过程中遇到问题，请拨打400 870 9700联系客服人员，我们将竭诚为您提供帮助。
          </p>
          <p>
            再次感谢您对本公司工作的配合与支持，期待我们在绿色、高效的数字化道路上携手共进！
          </p>
          <p class="cla">
            顺祝商祺！
          </p>
          <dev>
            <p class="cla" style="float:left; margin-left: 58%;">
              <span style="float:right">中影光峰激光影院技术(北京)有限公司</span><br/>
              <span style="float:right">2024-04-15</span>
            </p>
          </dev>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button style="width: 185px;background: red;margin-top: 3%;color: #FFF;background-color: red;border-color: red;font-size: 20px;font-weight: 700;" type="primary" @click="updateSpringFestival"
        >我已阅读</el-button
        >
      </span>
      </el-dialog>
    </div> -->
     <div v-if="isSpringFestivalShow">
      <el-dialog
        style="margin-top:5vh;"
        title=""
        :visible.sync="centerDialogVisible"
        :show-close="false"
        width="888px"
        class="SpringFestivalVisible"
        :center="true" >
        <div>
          <img src="https://xiniumall.oss-cn-beijing.aliyuncs.com/cineplex/file/321f2974-eb48-4003-b806-07c0e247dfdb.jpg" style="" alt="" />
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button style="width: 185px;background: red;margin-top: 3%;color: #FFF;background-color: red;border-color: red;font-size: 20px;font-weight: 700;" type="primary" @click="updateSpringFestival"
        >我已阅读</el-button
        >
      </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import searchPart from '@/components/searchPart'
import contentWrap from '@/components/contentWrap'
import swipperFloor from './components/swipperFloor'
import specialFloor from './components/specialFloor'
import cheapFloor from './components/cheapFloor'
import moveBarFloor from './components/moveBarFloor'
import bestGoodsFloor from './components/bestGoodsFloor'
import { findAdvertAndGoods } from '@/api/home'
import { hits } from '@/api/hits'
import { categoryAjaxList, homePageGoodsLabel, getOperator, updateOperator, findLabelShowList, getSpringFestival, updateSpringFestival } from '@/api/goods/goodsInfo/index' // 查询春节提醒是否已查看 getSpringFestival,
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['currentCustom'])
  },
  components: {
    searchPart,
    swipperFloor,
    specialFloor,
    bestGoodsFloor,
    cheapFloor,
    contentWrap,
    moveBarFloor
  },
  data () {
    return {
      centerDialogVisible: true,
      isShow: false,
      isSpringFestivalShow: true,
      categoryList: [], // 类别
      labelVos: [], // 标签
      labelVosList: [], // 标签列表
      labelHomePageList: [],
      homePageGoodsMap: {},
      advertTotalData: {},
      leftImgObj: {}, // 左侧广告
      carouselList: {}, // 大轮播图
      centerIntervalTmp: '0', // 大轮播时间间隔
      rightCarouselList: {}, // 小轮播图,
      rightIntervalTmp: '0', // 轮播时间间隔
      weekVos: {}, // 本周推荐
      rankingVos: {}, // 排行榜
      moveBarObj: {}, // 移动区
      middleAdvertObj: {}, // 中部广告
      hotObj: {}, // 热搜榜
      previewList: [] // 预览图
    }
  },
  mounted () {
    this.onLoad()
  },
  methods: {
    onLoad () {
      var customerId = this.currentCustom.customerId
      findAdvertAndGoods({ type: '01', customerId: customerId }).then(res => {
        this.advertTotalData = res.data.data
        this.leftImgObj = this.advertTotalData['01']
        this.carouselList = this.advertTotalData['03']
        if (this.advertTotalData['03'].advertPostionVo.attribute2) {
          this.centerIntervalTmp = parseFloat(this.advertTotalData['03'].advertPostionVo.attribute2) * 1000
        } else {
          this.centerIntervalTmp = 3000
        }
        var arr = this.advertTotalData['04']
        if (arr.advertPostionVo.attribute2) {
          this.rightIntervalTmp = parseFloat(arr.advertPostionVo.attribute2) * 1000
        } else {
          this.rightIntervalTmp = 3000
        }
        this.rightCarouselList = {
          rightList1: [],
          rightList2: [],
          rightList3: []
        }
        // 处理变化
        for (let i = arr.advertList.length - 1; i >= 0; i -= 3) {
          try {
            this.rightCarouselList.rightList1.push(arr.advertList[i]) //  上
            this.rightCarouselList.rightList2.push(arr.advertList[i - 1]) //  中
            this.rightCarouselList.rightList3.push(arr.advertList[i - 2]) //  下
          } catch (e) {
          }
        }
        this.weekVos = this.advertTotalData['08']
        this.rankingVos = this.advertTotalData['09']
        this.moveBarObj = this.advertTotalData['05']
        this.middleAdvertObj = this.advertTotalData['10']
        if (this.middleAdvertObj && this.middleAdvertObj.advertList && this.middleAdvertObj.advertList.length > 0) {
          this.previewList = [this.middleAdvertObj.advertList[0].img]
        }
        this.hotObj = this.advertTotalData['11']
      })
      getOperator().then(res => {
        if (res.data.data === '01') {
          this.isShow = true
        }
      })
      // 春节发货提醒 2022-02-08 取消显示
      getSpringFestival().then(res => {
        if (res.data.data === '01') {
          // this.isSpringFestivalShow = true
          console.log(res.data)
        }
      })
      categoryAjaxList().then(res => {
        this.categoryList = res.data.data
      })
      // 抢优惠下面遍历标签推荐
      // 首页上面标签
      homePageGoodsLabel().then(res => {
        this.labelVosList = res.data.data
      })
      // 首页下面标签商品
      findLabelShowList(customerId).then(res => {
        this.labelVos = res.data.data
      })
    },
    updateOperator () {
      updateOperator().then(res => {
        this.isShow = false
      })
    },
    updateSpringFestival () {
      updateSpringFestival().then(res => {
        this.isSpringFestivalShow = false
      })
    },
    sendClick (clickPostion) {
      hits({
        type: '02',
        clickPostion: clickPostion,
        busId: clickPostion,
        busTitle: '中部banner',
        sourceSection: '01'
      })
    }
  }
}
</script>

<style lang="scss">
.img_style:hover {
  cursor: pointer;
}
.centerDialogVisible.el-dialog__wrapper .el-dialog,.el-dialog__wrapper .el-dialog--center{
  /*width: 40% !important;*/
  height:460px;
  .el-dialog__header{
    text-align: center !important;
  }
  .el-dialog__header span{
    font-size: 27px;
    font-weight: 600;
    color: red;
  }
  .el-dialog__body div{
    padding: 20px 30px;
     font-weight: 600;
     h5{
       margin-bottom: 10px;
     }
     p{
       line-height: 40px;
       font-size: 19px;
       text-indent:2em;
       padding-top:30px
     }
     .cla{
       font-size: 19px;
        line-height: 40px;
       padding-top:30px
     }
  }
}

.SpringFestivalVisible.el-dialog__wrapper .el-dialog,.el-dialog__wrapper .el-dialog--center{
  /*width: 40% !important;*/
  height:1197px;
  background: #fff0;
  /*height:648px;*/
  .el-dialog__header{
    text-align: center !important;
  }
  .el-dialog__header span{
    font-size: 27px;
    font-weight: 600;
    color: red;
  }
  .el-dialog__body div{
    padding: 20px 30px;
    font-weight: 600;
    h5{
      margin-bottom: 10px;
    }
    p{
      line-height: 40px;
      font-size: 19px;
      text-indent:2em;
      padding-top:30px;
      font-weight: 500
    }
    .cla{
      font-size: 19px;
      line-height: 40px;
      padding-top:30px
    }
  }
}
</style>
