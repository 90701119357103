<template>
  <div class="bread-crumb bread1">
    <div class="content bread1">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="bread1" v-for="(item, index) in breadcrumbList" :key="index" :to="item.url">
          <span class="span1" :class="['item',{ 'active' : item.active},{ 'goodsItem': item.label.length > 40}]">{{ item.label }}</span>
          <span class="span1" style="cursor: pointer" @click="splitFunc(index)" v-if="item.split">❌</span>
        </el-breadcrumb-item>
        <div class="changes display_flex bread1" v-if="customerCategory !== '2'">
          <p class="title display_flex bread1" style="width:90px;">
            <img src="../../assets/company_icon.png" alt="" />
            当前公司：
          </p>
          <el-dropdown trigger="click" @command="operateFunc">
            <span class="el-dropdown-link bread1" style="cursor: pointer">
              {{currentCustom.customerName}}<i class="el-icon-arrow-down el-icon--right"></i>
              <!--              <p class="btn" style="display: inline-block"><img src="../../assets/tabs.png" alt="" />切换</p>-->
            </span>
            <el-dropdown-menu class="span1" slot="dropdown" >
              <el-dropdown-item class="span1" v-for="(v, index) in customInfos" :command="index" :disabled="disabledFlag && index === 0" :key="v.customerId">{{v.customerName}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!--          <p></p>-->

        </div>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCustomerCategoryById } from '@/api/personCenter/customerInvoice'
export default {
  mounted () {
    this.getCustomerCategoryById()
  },
  data () {
    return {
      // 客户类别
      customerCategory: '',
      disabledFlag: false
    }
  },
  watch: {
    // '$route.path': function (value) {
    //   if (value === '/myZGB' || value === '/invoiceInfo' || value === '/invoiceOperate' || value === '/invoiceApply' || value === '/customerInvoice') {
    //     this.disabledFlag = true
    //     if (this.currentCustom.customerName === '所有管理企业') {
    //       this.$store.commit('SET_CURRENT_CUSTOM', this.customInfos[1])
    //     }
    //   } else {
    //     this.disabledFlag = false
    //   }
    // }
  },
  computed: {
    ...mapGetters(['breadcrumbList', 'userInfo', 'customInfos', 'currentCustom'])
  },
  methods: {
    // 获取客户类别
    getCustomerCategoryById () {
      getCustomerCategoryById(this.userInfo.customerId).then(res => {
        this.customerCategory = res.data.data
      }).catch(e => {
        // console.log(e)
      })
    },
    splitFunc (index) {
      this.breadcrumbList.splice(index, 1)
      this.$store.commit('SET_BREADCRUMB', this.breadcrumbList)
    },
    operateFunc (command) {
      this.$store.commit('SET_CURRENT_CUSTOM', this.customInfos[command])
      this.$router.go(0)
    }
  }
}
</script>
<style lang="scss">
  body .span1.el-dropdown-menu {
    /*overflow: hidden;*/
    overflow-y: scroll;
    max-height: 300px;
  }
</style>
<style lang="scss" scoped>
.bread1:hover {
  cursor: pointer;
  color: #ef922e;
}
.span1:hover {
  cursor: pointer;
  color: #ef922e;
}

.bread-crumb {
  width: 100%;
  height: 42px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    width: 1226px;
    position: relative;
    .changes {
      float:right;
      max-width:365px;
      // position: absolute;
      // right: 0;
      // top: 50%;
      // transform: translate(0%, -50%);
      .title {
        color: #666666;
        img {
          width: 14px;
          height: 14px;
          padding: 0 3px;
        }
      }
      .btn {
        cursor: pointer;
        width: 53px;
        height: 20px;
        line-height: 20px;
        background: #f4f4f4;
        border: 1px solid #ef922e;
        border-radius: 3px;
        font-size: 12px;
        font-weight: 400;
        color: #ef922e;
        margin-left: 20px;
        img {
          width: 10px;
          height: 10px;
          padding: 0 3px;
        }
      }
    }
    .item {
      color: #666666;
      &.active {
        color: #666666;
      }
      .active:hover {
        color: #ef922e;
      }
      &.goodsItem {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 500px;
        display: inline-block;
      }
    }
  }
}
</style>
