<template>
  <div class="payContent">
    <div class="h1">
      <span>填写并核对订单信息</span>

      <div class="changes display_flex" v-if="!goodsOrder.cinemaList && customerCategory !== '2'"><!-- changes display_flex  -->
        <p class="title display_flex">
          <img src="../../../assets/company_icon.png" alt="" />
          当前公司：
        </p>
        <el-dropdown trigger="click"  @command="operateFunc">
            <span class="el-dropdown-link" style="cursor: pointer">
              {{currentCustom.customerName}}<i class="el-icon-arrow-down el-icon--right"></i>
              <!--              <p class="btn" style="display: inline-block"><img src="../../assets/tabs.png" alt="" />切换</p>-->
            </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(v, index) in customInfos" :command="index" :disabled="disabledFlag && index === 0" :key="v.customerId">{{v.customerName}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!--          <p></p>-->

      </div>
    </div>

    <div class="package">
      <div class="top">
        <div class="left">
          <span>收货人信息：</span>
        </div>
        <div class="right xiaoshou" @click="showModal4('新增收货地址')">
          <span>+新增收货地址</span>
        </div>
      </div>
      <div class="modal-page">
        <el-dialog :title="this.AddTatle" :before-close="handleDialogClose" :visible.sync="visible4" width="750px">
          <template>
            <div class="full-width">
              <template>
                <div class="get-addrrss">
                  <div class="form-div">
                    <el-form
                      :model="ruleForm"
                      :rules="rules"
                      ref="ruleForm"
                      label-width="90px"
                    >
                      <el-form-item label="收货人:" prop="consignee">
                        <div class="item-div">
                          <el-input v-model="ruleForm.consignee" maxlength="50"></el-input>
                        </div>
                      </el-form-item>
                      <el-form-item label="所在地区:" prop="area">
                        <div class="item-div">
                          <!--<el-select v-model="ruleForm.area" placeholder="请选择所在地区">
                            <el-option label="所在地区1" value="所在地区1"></el-option>
                            <el-option label="所在地区2" value="所在地区2"></el-option>
                          </el-select>-->
                          <el-cascader
                            ref="cascaderAddr"
                            size="large"
                            width="300px"
                            :options="options"
                            v-model="ruleForm.attribute1"
                            @change="handleChange"
                          >
                          </el-cascader>
                        </div>
                      </el-form-item>
                      <el-form-item label="详细地址:" prop="address">
                        <div class="item-div">
                          <el-input v-model="ruleForm.address" maxlength="250"></el-input>
                        </div>
                      </el-form-item>
                      <el-form-item label="手机号:" prop="tel">
                        <div class="item-div">
                          <el-input v-model="ruleForm.tel" type="tel" maxlength="11"></el-input>
                        </div>
                      </el-form-item>
                      <el-form-item label="固定电话:" prop="fixedTel">
                        <div class="item-div">
                          <el-input maxlength="13" v-model="ruleForm.fixedTel"></el-input>
                        </div>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="modal-btn2 active mg-auto" v-if="!ruleForm.isup" @click="addAddress">
                    <span>保存收货地址</span>
                  </div>
                  <div class="modal-btn2 active mg-auto" v-if="ruleForm.isup" @click="editAddress">
                    <span>修改收货地址</span>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </el-dialog>
        <el-dialog title="删除地址" width="40%" :visible.sync="visible5"  :close-on-click-modal="false" :modal-append-to-body='false' :append-to-body='true'>
          <template>
            <div class="delete">
              <div class="div-1">
                <img src="@/assets/delete.png" alt="">
              </div>
              <div class="div-2">
                <span>删除地址</span>
              </div>
              <div class="div-3">
                <span>您确定要删除此地址吗？</span>
              </div>
              <div class="div-4" @click="del">
                <div class="modal-btn2 active">
                  <span>删除</span>
                </div>
              </div>
            </div>
          </template>
        </el-dialog>
      </div>
      <div v-for="(addr, index) in AddressList" :key="index">
      <div class="address-list mg-tp-20" v-if="max === 999 || confirmAdd == index">
        <div class="list-item">
          <div class="left">
            <div @click="confirmAddress(index)" :class="[{'btn active': (confirmAdd == index )},{'btn': (confirmAdd != index )}]">
              <span>{{addr.consignee}}</span>
              <img src="@/assets/check.png" v-if="confirmAdd == index" alt="" />
            </div>
            <div class="address" :class="[{'active': (confirmAdd == index )},{'': (confirmAdd != index )}]">
              <span>{{addr.fixedTel}}</span>
              <span>{{addr.tel}}</span>
              <span>{{addr.area}}</span>
              <span>{{addr.address}}</span>
            </div>
            <div style="margin:0 10px" class="default" v-if="addr.isDefault == '01'">
              <span>默认</span>
            </div>
            <div style="margin:0 10px" class="default" v-if="addr.isFromK3 == '01'">
              <span>金蝶</span>
            </div>
          </div>
          <div v-if="confirmAdd == index" class="right" :class="[{'active': (confirmAdd == index )},{'': (confirmAdd != index )}]">
            <span class="xiaoshou" v-if="addr.isDefault == '02'" @click="setDefault(index, addr.id)">设为默认地址</span>
            <span class="xiaoshou" v-if="addr.isFromK3 == '02'" @click="upShowDefault(index)">编辑</span>
            <span class="xiaoshou" v-if="addr.isFromK3 == '02'" @click="delAddress(index, addr.id)">删除</span>
          </div>
        </div>
      </div>
       <!-- <div class="address-list mg-tp-10">
        <div class="list-item">
          <div class="left">
            <div class="btn active">
              <span>张先生</span>
              <img src="@/assets/check.png" alt="" />
            </div>
            <div class="address active">
              <span>张先生</span>
              <span>北京市 </span>
              <span>海淀区</span>
              <span>农大南路</span>
              <span>树村西街</span>
              <span>甲6</span>
            </div>
            <div class="default">
              <span>默认</span>
            </div>
          </div>
          <div class="right active">
            <span>设为默认地址</span>
            <span>编辑</span>
            <span>删除</span>
          </div>
        </div>
      </div>
      <div class="address-list mg-tp-10">
        <div class="list-item">
          <div class="left">
            <div class="btn">
              <span>张先生</span>
            </div>
            <div class="address active">
              <span>张先生</span>
              <span>北京市 </span>
              <span>海淀区</span>
              <span>农大南路</span>
              <span>树村西街</span>
              <span>甲6</span>
            </div>
          </div>
          <div class="right active">
            <span>设为默认地址</span>
            <span>编辑</span>
            <span>删除</span>
          </div>
        </div>
      </div> -->
      </div>
      <div class="toggle-address" @click="dropAddress">
        <div class="xiaoshou">
          <span v-if="max==999">收起地址</span>
          <span v-if="max!=999">展开地址</span>
        </div>
        <div class="right xiaoshou">
          <img src="@/assets/sq.png" v-if="max==999" alt="" />
          <img src="@/assets/xx.png" v-if="max!=999" alt="" />
        </div>
      </div>
      <div class="sperate"></div>
      <div class="dilivery-h1">
        <span class="type">配送方式：</span>
<!--        <span class="weight">总重量：</span>-->
<!--        <span class="number">14.09KG</span>-->
      </div>
      <div class="check-type">
        <div class="left btn active">
          <span>第三方物流</span>
          <img src="@/assets/check.png" alt="" />
        </div>
        <!-- <div class="right btn">
          <span>上门自体</span>
        </div> -->
      </div>
      <div class="sperate"></div>
      <div class="dilivery-h1">
        <span class="type">支付方式：</span>
      </div>
      <div class="check-type">
        <div class="left btn active">
          <span>公司转账</span>
          <img src="@/assets/check.png" alt="" />
        </div>
      </div>
      <div class="sperate" v-if="isMonthlyPayment === '1'"></div>
      <div class="dilivery-h1" v-if="isMonthlyPayment === '1'">
        <span class="type">月结订单：</span>
      </div>
      <div class="check-type" v-if="isMonthlyPayment === '1'">
        <div class="left btn active">
          <span>此订单为月结订单</span>
          <img src="@/assets/check.png" alt="" />
        </div>
      </div>
    </div>
    <div class="package">
      <div class="top">
        <div class="left">
          <span class="overflow_two">送货清单：</span>
        </div>
        <div class="right xiaoshou">
          <div style="margin-right: 10px">
            <img src="@/assets/jgsm.png" alt="" />
          </div>
          <!-- <div><span style="padding-right: 10px">价格说明</span></div>-->
          <div @click="onGoodsCar"><span>返回购物车修改</span></div>
        </div>
      </div>
      <div v-for="(goods, index) in goodsOrder.goodsList" :key="goods.goodsId"> <!-- 遍历商品清单 -->
        <div class="prd-list mg-tp-20">
          <div class="img xiaoshou" @click="goDatil(goods.goodsId, goods.commodityName)" style="width: 10%">
            <img :src="goods.mainGraph" alt="" />
          </div>
          <div class="text1" style="width: 30%;margin-left: 50px" :title="goods.commodityName">
            <span class="overflow_two">{{goods.commodityName}}</span>
          </div>
          <div class="text2" style="width: 30%;margin-left: 100px;line-height: 16px;">
            <span>{{goods.commoditySubTitle}}</span>
          </div>
          <div class="price" style="width: 6%">
            <span>¥{{goods.salePrice}}</span>
          </div>
          <div class="num" style="width: 6%">
            <span>x{{goods.number}}</span>
          </div>
          <div class="price1">
            <span>¥{{goods.Subtotal}}</span>
          </div>
        </div>
        <div class="sperate mg-top-20" v-if="index != goodsOrder.goodsList.length-1"></div>
       <!-- <div class="prd-list mg-tp-20" v-for="item in goodsOrder.cinemaList" :key="item.id">
          <span>{{item.hallNum}},{{item.cinemaName}}</span>
        </div> -->
    </div>
    </div>
    <!-- 影厅信息 start -->
    <div class="package" v-if="goodsOrder.cinemaList">
      <div class="top">
        <div class="left">
          <span class="overflow_two">影厅信息：</span>
        </div>
        <div class="right xiaoshou">
          <!--<div style="margin-right: 10px">
            <img src="@/assets/jgsm.png" alt="" />
          </div>-->
          <!-- <div><span style="padding-right: 10px">价格说明</span></div>-->
          <!--<div @click="onGoodsCar"><span>返回购物车修改</span></div>-->
        </div>
      </div>
      <div v-for="(item, idx) in goodsOrder.cinemaList" :key="item.id"> <!-- 遍历影厅信息 -->
        <div class="prd-list mg-tp-20">
          <!--<div class="img xiaoshou" style="width: 10%">
            <span class="overflow_two">厅号:</span>
          </div>-->
          <div class="price1" style="width: 9%">
            <span>厅号:</span>
          </div>
          <div class="text2" style="width: 20%;margin-left: 10px" >
            <span class="overflow_two">{{item.hallNum}}</span>
          </div>
          <div class="price1" style="width: 20%;margin-left: 50px;line-height: 16px;">
            <span>影厅名称:</span>
          </div>
          <div class="text2" style="width: 30%;margin-left: 10px;line-height: 16px;">
            <span>{{item.cinemaName}}</span>
          </div>
          <!--<div class="num" style="width: 6%">
            <span>x{{goods.number}}</span>
          </div>
          <div class="price1">
            <span>¥{{goods.Subtotal}}</span>
          </div>-->
        </div>
        <div class="sperate mg-top-20" v-if="idx != goodsOrder.cinemaList.length-1"></div>
      </div>
    </div>
    <!-- 影厅信息 end -->
     <!-- <div class="package">
      <div class="top-start">
        <div class="left">
          <span>发票信息：</span>
        </div>
        <div class="right">
          <span>开企业抬头发票须填写纳税人识别号，以免影响报销</span>
        </div>
      </div>
      <div class="radio-check">
        <el-radio v-model="radio" label="1">
          <span style="padding-right: 15px">电子普通发票</span>
          <span style="padding-right: 15px">个人</span>
          <span style="padding-right: 15px">商品明细</span>
          <span style="padding-right: 15px; color: #ef922e">修改</span>
        </el-radio>
        <el-radio v-model="radio" label="2">
          <span>分批或延后的发票请到发票中心处理</span>
        </el-radio>
      </div>
     </div> -->
    <div class="package" v-if="(coupnoList != null && coupnoList.length > 0) || (voucherList != null && voucherList.length > 0)">
      <div class="top">
        <div class="left">
          <span v-if="coupnoList != null && coupnoList.length > 0" class="spans" :class="{ juan: fullMinus === true || voucherList.length <= 0 }" @click="changeCoupon(1)">使用满减券</span>
          <span v-if="voucherList != null && voucherList.length > 0" class="spans" :class="{ juan: allowance === true || coupnoList.length <= 0 }" @click="changeCoupon(2)" style="margin-left: 50px;">使用代金券</span>
        </div>
      </div>
      <div class="coupon-list" v-if="fullMinus === true || !voucherList || voucherList.length <= 0">
          <!-- <coupon :faceValue="item.faceValue" :overNYuanUse="item.overNYuanUse"></coupon> -->
          <div class="coupon-item xiaoshou" v-for="(item, index) in coupnoList" :key="item.id" v-show="item.attribute1 !== 'no'" >
            <div :class="[{'card' : iscoupon.num != index},{'card active': iscoupon.num == index}]" @click="onCoupon(index)">
              <template>
                <div class="coupon-img" :class="{'disabled': item.attribute1 === 'no'}">
                  <span class="coupon-img-title">￥ {{item.faceValue}}</span>
                  <span>满{{item.overNYuanUse}}元可用</span>
                </div>
              </template>
              <div class="coupon-tips mg-top-20">
                <p style="cursor: pointer" v-if="item.typesOfGoodsAvailable !== {} && item.typesOfGoodsAvailable" :title="item.attribute4">限品类：
                  <!--                    {{item.typesOfGoodsAvailable.length>12?(item.typesOfGoodsAvailable.substring(0,12)+'  ...'):item.typesOfGoodsAvailable}}-->
                  {{item.attribute4.substring(0,12)+'  ...'}}
                </p>
                <p style="cursor: pointer" v-if="item.availableProductRange !== {} && item.availableProductRange" :title="item.attribute4">限商品：
                  <!--                    {{item.availableProductRange.length>12?(item.availableProductRange.substring(0,12)+'  ...'):item.availableProductRange}}-->
                  {{item.attribute4.substring(0,12)+'  ...'}}
                </p>
              </div>
              <div class="text">
                <span>有效期至：{{item.specifyValidityPeriod}}</span>
              </div>
              <img src="@/assets/check.png" v-show="iscoupon.num === index" alt="" />
            </div>
            <div class="tips">
              <span v-if="item.attribute1 === 'no'">所结算商品中没有符合条件的商品</span>
            </div>
          </div>
      </div>
      <!-- dong -->
      <div class="coupon-list" v-if="allowance === true">
          <!-- <coupon :faceValue="item.faceValue" :overNYuanUse="item.overNYuanUse"></coupon> -->
          <div class="coupon-item xiaoshou" v-for="(item, index) in voucherList" :key="item.id" v-show="item.attribute1 !== 'no'" >
            <div :class="[{'card' : item.attribute2 != true},{'card active': item.attribute2 == true}]" @click="onVoucher(index)">
              <template>
                <div class="coupon-img" :class="{'disabled': item.attribute1 === 'no'}">
                  <span class="coupon-img-title">￥ {{item.faceValue}}</span>
<!--                  <span>满{{item.overNYuanUse}}元可用</span>-->
                </div>
              </template>
              <div class="coupon-tips mg-top-20">
                <p style="cursor: pointer" v-if="item.typesOfGoodsAvailable !== {} && item.typesOfGoodsAvailable" :title="item.attribute5">限品类：
                  <!--                    {{item.typesOfGoodsAvailable.length>12?(item.typesOfGoodsAvailable.substring(0,12)+'  ...'):item.typesOfGoodsAvailable}}-->
                  {{item.attribute5.substring(0,12)+'  ...'}}
                </p>
                <p style="cursor: pointer" v-if="item.availableProductRange !== {} && item.availableProductRange" :title="item.attribute5">限商品：
                  <!--                    {{item.availableProductRange.length>12?(item.availableProductRange.substring(0,12)+'  ...'):item.availableProductRange}}-->
                  {{item.attribute5.substring(0,12)+'  ...'}}
                </p>
              </div>
              <div class="text">
                <span>有效期至：{{item.specifyValidityPeriod}}</span>
              </div>
              <img src="@/assets/check.png" v-show="item.attribute2 == true" alt="" />
            </div>
            <div class="tips">
              <span v-if="item.attribute1 === 'no'">所结算商品中没有符合条件的商品</span>
            </div>
          </div>
      </div>
    </div>
    <!--                          -->
    <div class="package">
      <div class="top">
        <div class="left">
          <span class="overflow_two">订单备注：</span>
        </div>
      </div>
      <div class="right">
        <!-- <div><span style="padding-right: 10px">价格说明</span></div>-->
<!--        <div><input type="textarea" v-model="goodsOrder.remarker"/></div>-->
        <el-input
          style="padding-top: 10px; font-size:12px;"
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="goodsOrder.remarker">
        </el-input>

      </div>
    </div>
    <!--                          -->
    <div class="all-cpupon" v-if="coupnoList != null && coupnoList.length > 0">
        <span>满减券抵用：</span>
        <span style="color:#F30012">¥{{iscoupon.maney}}</span>
    </div>
    <!-- dong -->
    <div class="all-cpupon" v-if="voucherList != null && voucherList.length > 0">
        <span>代金券抵用：</span>
        <span style="color:#F30012">¥{{isVoucher.maney}}</span>
    </div>
    <div class="div-zgb">
        <div class="left" v-if="remainingQuantity > 0 && goodsOrder.AvailableCoin > 0">
            <div class="text">
                <span>中光币：</span>
                <span style="color:#F30012;">{{remainingQuantity}}</span>
                <span>，本单可使用</span>
                <span style="color:#F30012;">{{goodsOrder.AvailableCoin}}</span>
            </div>
            <div class="checkbox" v-if="goodsOrder.AvailableCoin > 0">
                <el-checkbox @change="isCheck"></el-checkbox>
            </div>
            <div class="sy" v-if="goodsOrder.AvailableCoin > 0">
                <span>使用</span>
            </div>
            <div class="input" v-if="checkType">
                <el-input v-model="useCoinC" type="Number" min="0" :max="goodsOrder.AvailableCoin" @change="setUseCoin" />
            </div>
        </div>
      <div class="left" v-if="remainingQuantity <= 0 || goodsOrder.AvailableCoin <= 0">
      </div>
        <div class="right">
            <p><span style="color: #ef922e">{{goodsOrder.totalPiece}}</span>件商品，总商品金额：¥{{goodsOrder.totalAmont}}</p>
            <p >满减券抵扣：-¥{{iscoupon.maney}}</p>
            <!-- dong -->
            <p >代金券抵扣：-¥{{isVoucher.maney}}</p>
            <p v-if="goodsOrder.AvailableCoin > 0">中光币抵扣： -¥{{useCoinC}}</p>
            <p>运费： ¥0</p>
        </div>
    </div>
  </div>
</template>

<script>
import { regionData } from 'element-china-area-data'
import { mapGetters } from 'vuex'
import { getCustomerCategoryById } from '@/api/personCenter/customerInvoice'
export default {
  mounted () {
    this.getCustomerCategoryById()
  },
  computed: {
    ...mapGetters(['breadcrumbList', 'userInfo', 'customInfos', 'currentCustom'])
  },
  props: {
    AddressList: {
      type: Array
    },
    confirmAdd: {
      type: Number,
      default: 0
    },
    coupnoList: {
      type: Array
    },
    // dong
    voucherList: {
      type: Array
    },
    iscoupon: {
      type: Object
    },
    isVoucher: {
      type: Object
    },
    goodsOrder: {
      type: Object
    },
    remainingQuantity: {
      type: Number
    },
    isMonthlyPayment: {
      type: String
    }
  },
  data () {
    return {
      disabledFlag: false,
      useCoinC: 0.00,
      radio: '1',
      input: '0',
      max: 999,
      delind: '',
      checkType: false,
      delId: '',
      addressFrom: {},
      visible4: false,
      visible5: false,
      options: regionData,
      // 客户类别
      customerCategory: '',
      AddTatle: '新增收货地址',
      selectedOptions: [],
      ruleForm: {
        address: '',
        tel: '',
        consignee: '',
        fixedTel: '',
        area: '',
        isup: false,
        areaBack: ''
      },
      rules: {
        address: [
          { required: true, message: '请填详细地址', trigger: 'change' }
        ],
        /* fixedTel: [
          { required: false, message: '请填固定电话', trigger: 'change' }
        ], */
        tel: [
          { required: true, pattern: /^1[0-9]{10}$/, message: '格式有误', trigger: 'change' }
        ],
        consignee: [
          { required: true, message: '请填收货人', trigger: 'change' }
        ],
        areaBack: [
          { required: true, message: '请选择区域', trigger: 'change' }
        ]
      },
      // dong
      fullMinus: false,
      allowance: true
    }
  },
  methods: {
    // 获取客户类别
    getCustomerCategoryById () {
      getCustomerCategoryById(this.userInfo.customerId).then(res => {
        this.customerCategory = res.data.data
      }).catch(e => {
        // console.log(e)
      })
    },
    // 改变tabs <!-- dong -->
    changeCoupon (index) {
      if (index === 1) {
        this.allowance = false
        this.fullMinus = true
      } else {
        this.allowance = true
        this.fullMinus = false
      }
    },
    //
    operateFunc (command) {
      this.$store.commit('SET_CURRENT_CUSTOM', this.customInfos[command])
      this.$router.go(0)
    },
    handleChange (e) {
      this.ruleForm.areaBack = this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels
    },
    dropAddress () { // 收起与放开
      if (this.max === 1) {
        this.max = 999
      } else {
        this.max = 1
      }
    },
    addAddress () { // 添加地址
      this.addressFrom = this.ruleForm
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.addressFrom.isDefault = '02'
          this.addressFrom.isFromK3 = '02'
          this.addressFrom.area = ''
          var areaArr = this.addressFrom.areaBack
          this.addressFrom.area = ''
          for (var i = 0; i < areaArr.length; i++) {
            if (i !== areaArr.length - 1) {
              this.addressFrom.area += areaArr[i] + '/'
            } else {
              this.addressFrom.area += areaArr[i]
            }
          }
          /* areaArr.forEach(ele => {
            this.addressFrom.area += ele
          }) */
          this.$parent.$parent.add(this.addressFrom)
          this.initAddFrom()
          this.cancelModal4()
        }
      })
      // this.AddressList.push(addressFrom)
    },
    editAddress () { // 修改收货地址
      this.addressFrom = this.ruleForm
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.addressFrom.isFromK3 = '02'
          // this.$parent.$parent.edit(addressFrom, addressFrom.index)
          if (this.addressFrom.areaBack != null && this.addressFrom.areaBack.length > 0) {
            this.addressFrom.area = ''
            var areaArr = this.addressFrom.areaBack
            for (var i = 0; i < areaArr.length; i++) {
              if (i !== areaArr.length - 1) {
                this.addressFrom.area += areaArr[i] + '/'
              } else {
                this.addressFrom.area += areaArr[i]
              }
            }
            /* areaArr.forEach(ele => {
              this.addressFrom.area += ele
            }) */
          }
          var obj = JSON.parse(JSON.stringify(this.addressFrom))
          this.$emit('edit', obj, obj.index)
          // this.AddressList[addressFrom.index] = addressFrom
          this.initAddFrom()
          this.cancelModal4()
          this.$refs.ruleForm.resetFields()
          // this.$parent.$parent.analysis(this.AddressList[this.confirmAdd], this.confirmAdd)
        }
      })
    },
    showModal5 () {
      this.visible5 = true
    },
    cancelModal5 () {
      this.visible5 = false
    },
    delAddress (i, id) {
      this.delind = i
      this.delId = id
      this.showModal5()
    },
    del () {
      this.$parent.$parent.del(this.delind, this.delId)
      this.cancelModal5()
    },
    handleDialogClose () {
      this.initAddFrom()
      this.$refs.ruleForm.resetFields()
      this.cancelModal4()
    },
    initAddFrom () {
      this.ruleForm = {
        address: '',
        tel: '',
        consignee: '',
        fixedTel: '',
        area: '',
        isup: false,
        attribute1: [],
        areaBack: ''
      }
    },
    showModal4 (AddTatle) {
      this.AddTatle = AddTatle
      this.visible4 = true
    },
    cancelModal4 () {
      this.visible4 = false
    },
    upShowDefault (i) {
      this.ruleForm = {
        address: this.AddressList[i].address,
        tel: this.AddressList[i].tel,
        consignee: this.AddressList[i].consignee,
        fixedTel: this.AddressList[i].fixedTel,
        area: this.AddressList[i].area,
        areaBack: this.AddressList[i].areaBack,
        isDefault: this.AddressList[i].isDefault,
        isFromK3: this.AddressList[i].isFromK3,
        attribute1: this.AddressList[i].attribute1,
        id: this.AddressList[i].id,
        isup: true,
        index: i
      }
      this.showModal4('修改收货地址')
    },
    confirmAddress (i) { // 勾选地址
      this.$parent.$parent.analysis(this.AddressList[i], i)
    },
    setDefault (i, id) { // 设置默认
      this.$parent.$parent.setDefult(i, id)
    },
    onCoupon (i) { // 勾选优惠券
      if (this.coupnoList[i].attribute1 === 'no') {
        return
      }
      this.$parent.$parent.setConpon(i)
    },
    onVoucher (i) { // 勾选代金券
      if (this.voucherList[i].attribute1 === 'no') {
        return
      }
      this.$parent.$parent.setVoucher(i)
    },
    isCheck (event) {
      this.checkType = event
      if (!this.checkType) {
        this.useCoinC = 0.00
        this.$emit('upUseCoin', this.useCoinC)
        // this.$parent.$parent.upUseCoin(this.useCoinC)
      }
    },
    setUseCoin () {
      if (this.useCoinC > this.goodsOrder.AvailableCoin) {
        // this.$alert('本单最大使用' + this.goodsOrder.AvailableCoin, '警告')
        this.$notify({
          title: '警告',
          message: '本单最大使用' + this.goodsOrder.AvailableCoin,
          type: 'warning',
          duration: 2000
        })
        this.useCoinC = 0.00
      }
      if (this.useCoinC.toString().includes('.')) {
        // this.$alert('只能输入整数', '警告')
        this.$notify({
          title: '警告',
          message: '只能输入整数',
          type: 'warning',
          duration: 2000
        })
        this.useCoinC = 0.00
      }
      this.$emit('upUseCoin', this.useCoinC)
      // this.$parent.$parent.upUseCoin(this.useCoinC)
    },
    goDatil (id, commodityName) {
      var blan = this.$router.resolve({ // 调转到商品搜索页面
        path: '/detail',
        query: {
          id: id,
          commodityName: commodityName
        }
      })
      window.open(blan.href, '_blank')
    },
    onGoodsCar () {
      this.$router.push({ // 调整到详情界面
        path: '/myCar'
        // query: {id: row.id}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .overflow_two{
    line-height: 19px;
  }
  .display_flex {
    display: inline-block;
    /*align-items: flex-end;*/
    right:0;
  }
  .changes {
    position: absolute;
    /*right: 0;*/
    .title {
      color: #666666;
      img {
        width: 14px;
        height: 14px;
        padding: 0 3px;
      }
    }
    .btn {
      cursor: pointer;
      width: 53px;
      height: 20px;
      line-height: 20px;
      background: #f4f4f4;
      border: 1px solid #ef922e;
      border-radius: 3px;
      font-size: 12px;
      font-weight: 400;
      color: #ef922e;
      margin-left: 20px;
      img {
        width: 10px;
        height: 10px;
        padding: 0 3px;
      }
    }
  }
  .xiaoshou {

  }
  .xiaoshou:hover {
    cursor: pointer;
  }
  .get-addrrss {
    padding: 40px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .form-div {
    margin-left: 80px;
  }
  .item-div {
    width: 342px;
    position: relative;
    display: flex;
  }
  .mg-auto {
    margin: 30px auto;
  }
  // ---------------上面是添加的模态框
.delete {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #333333;
  .div-1 {
    width: 70px;
    height: 70px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .div-2 {
    font-size: 24px;
    margin-top: 22px;
  }
  .div-3 {
    font-size: 14px;
    margin-top: 18px;
  }
  .div-4 {
    margin-top: 30px;
    margin-bottom: 55px;
  }
}
  // ---------------上面是删除模态框
.coupon-img {
  height: 69px;
  width: 137px;
  background: url("../../../assets/coupon.png");
  &.disabled {
    background: url("../../../assets/coupon2.png");
    background-size: 137px 69px;
  }
  background-size: 137px 69px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  color: #f5f5f5;
  .coupon-img-title {
    font-size: 18px;
  }
}
  // ---------------上面是优惠券
.btn {
  width: 166px;
  height: 40px;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  color: #333333;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  position: relative;
  &.active {
    border-color: #ef922e;
    color: #ef922e;
    img {
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
.payContent {
  .sperate {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
    margin-top: 30px;
    &.dotted {
      height: 0;
      border-bottom: 1px dotted #c1c1c1;
    }
    &.mg-top-20 {
      margin-top: 20px;
    }
  }
  margin-top: 20px;
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  background: white;
  text-align: left;
  .mg-tp-10 {
    margin-top: 10px;
  }
  .mg-tp-20 {
    margin-top: 20px;
  }
  .h1 {
    color: #333333;
    font-size: 14px;
    font-weight: 700;
    position:relative
  }
  .package {
    padding: 30px;
    margin-top: 20px;
    border: 1px solid #e1e1e1;
    .top-start {
      display: flex;
      align-items: center;
      .left {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        span{
          width: 60%;
          margin-left: 10%;
          line-height: 20px;
        }
      }
      .right {
        width: 390px;
        height: 39px;
        background: url("../../../assets/fpxlsm.png");
        background-size: 390px 39px;
        line-height: 41px;
        color: #333333;
        font-size: 14px;
        span {
          padding-left: 40px;
        }
      }
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
      }
      .right {
        color: #ef922e;
        display: flex;
        align-items: center;
      }
    }
    .address-list {
      padding-left: 20px;
      .list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          height: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          .btn{
            background: #fff !important;
          }
          .address {
            &.active {
              background-color: #fff4f0;
            }
            margin-left: 10px;
            color: #333333;
            font-size: 14px;
            padding-left: 10px;
            span {
              padding-right: 20px;
            }
          }
          .default {
            width: 34px;
            height: 16px;
            background: #ef922e;
            border-radius: 2px;
            font-size: 12px;
            color: #ffffff;
            padding: 0 1px;
            line-height: 16px;
            text-align: center;
          }
        }
        .right {
          color: #ef922e;
          font-size: 14px;
          text-align: right;
          height: 40px;
          line-height: 40px;
          flex: 1;
          span {
            padding-right: 15px;
          }
          &.active {
            background-color: #fff4f0;
          }
        }
      }
    }
    .toggle-address {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 14px;
      margin-left: 20px;
      margin-top: 20px;
      .right {
        margin-left: 10px;
      }
    }
    .dilivery-h1 {
      color: #333333;
      font-size: 14px;
      margin-top: 30px;
      .type {
        font-size: 16px;
        font-weight: bold;
        padding-left: 10px;
      }
      .number {
        font-weight: bold;
        padding-left: 10px;
      }
    }
    .check-type {
      margin-left: 20px;
      margin-top: 20px;
      display: flex;
      .right {
        margin-left: 10px;
      }
    }
    .prd-list {
      display: flex;
      align-items: center;
      padding-left: 20px;
      .img {
        width: 60px;
        height: 60px;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .text1 {
        color: #111111;
        font-size: 14px;
        padding-right: 10px;
        margin-left: 20px;
      }
      .text2 {
        color: #999999;
        font-size: 12px;
        padding-right: 10px;
      }
      .price {
        color: #333333;
        font-size: 14px;
        margin-left: 110px;
      }
      .num {
        color: #333333;
        font-size: 14px;
        margin-left: 118px;
      }
      .price1 {
        color: #333333;
        font-size: 14px;
        margin-left: 120px;
      }
    }
  }
  .radio-check {
    padding-left: 20px;
    margin-top: 27px;
  }
  .coupon-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 20px;
    margin-top: 22px;
    display: grid;
    grid-template-columns: 250px 250px 250px 250px;
    grid-template-rows: 205px 205px;
    grid-column-gap: 20px;
    .coupon-item {
      .card {
        width: 250px;
        height: 164px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e1e1e1;
        &.active {
          border-color: #ef922e;
          position: relative;
          img {
            display: block;
            width: 15px;
            height: 15px;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
        flex-direction: column;
        .text {
          color: #999999;
          font-size: 14px;
          margin-top: 15px;
        }
      }
      .tips {
        height: 41px;
        color: #999999;
        font-size: 12px;
        line-height: 41px;
        text-align: center;
      }
    }
  }
  .all-cpupon {
        padding-left: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid #E1E1E1;
        border-top: none;
        background-color: #F5F5F5;
        line-height: 30px;
        color: #333333;
        font-size: 14px;
  }
}
.div-zgb {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
    .left {
        display: flex;
        align-items: center;
        .text {
            margin-right: 20px;
        }
        .checkbox {
            margin-right: 10px;
        }
        .sy {
            margin-right: 10px;
        }
        .input {
            width: 104px;
            height: 30px;
        }
    }
    .right {
        color: #333333;
        font-size: 14px;
        line-height:24px;
    }
}

</style>

<style lang="scss">
.payContent {
    .el-input__inner {
        height: 30px;
        line-height: 30px;
    }
}
.coupon-tips {
  color: #999999;
  font-size: 12px;
}
//<!-- dong -->
.juan {
  color: #ef922e
}
.spans:hover {
  cursor: pointer;
  color: #ef922e;
}
</style>
