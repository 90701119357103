import request from '@/router/axios'

/**
 * 新增收货地址
 * @returns {AxiosPromise}
 */
export const doInsertAddress = (params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/doInsertAddress',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: params
  })
}

/**
 * 删除收货地址
 * @returns {AxiosPromise}
 */
export const doDeleteAddress = (addressId) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/doDeleteAddress',
    method: 'post',
    params: {
      id: addressId
    }
  })
}

/**
 * 修改收货地址
 * @returns {AxiosPromise}
 */
export const doUpdateAddress = (addressId) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/doUpdateAddress',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: addressId
  })
}

/**
 * 修改客户操作员收货地址
 * @returns {AxiosPromise}
 */
export const doUpdateOperatorAddress = (addressId) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/doUpdateOperatorAddress',
    method: 'post',
    params: {
      addressId: addressId
    }
  })
}

/**
 * 获取收货地址
 * @returns {AxiosPromise}
 */
export const findAddress = (params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/findAddress',
    method: 'post',
    params: {
      customerId: params
    }
  })
}
