<template>
  <div class="best-goods">
      <div class="title">
          <span>猜你喜欢</span>
      </div>
      <div class="content" v-if="goosRecommendedation.length > 0">
          <div class="best-goods-item"
            v-for="item in goosRecommendedation"
            :key="item.id">
            <best-goods-card
              :item="item"
              @upGoodsCar="$emit('upGoodsCar')"
            ></best-goods-card>
            <!-- :name="item.commodityName"
                :originPrice="item.salePrice"
                :tips="item.commoditySubTitle"
                :discountPrice=0
                :mainGraph="item.mainGraph" -->
          </div>
      </div>
  </div>
</template>

<script>
import bestGoodsCard from '@/components/bestGoodsCardCopy'
import { goosRecommendedationAjaxList } from '@/api/goods/goodsInfo/index'
import { goodsCondition } from './goodsCar'
export default {
  components: {
    bestGoodsCard
  },
  data () {
    return {
      goosRecommendedation: []
    }
  },
  methods: {
    onload () {
      goodsCondition().then(res => {
        var pam = res.data.data
        goosRecommendedationAjaxList(pam).then(res => {
          this.goosRecommendedation = res.data.data
        })
      })
    }
  },
  created () {
    this.onload()
  }
}
</script>

<style lang="scss" scoped>
.best-goods {
    margin-top: 28px;
    width: 100%;
    .title {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        text-align: left;
    }
    .content {
        margin-top: 23px;
        width: 100%;
        display:flex;
         justify-content: space-between;
          // flex-wrap: wrap;
          //   flex-direction: row;
        flex-wrap:wrap;
        display: grid;
        grid-template-columns: repeat(5, 234px);
        // grid-template-rows: repeat(3, 340px);
        grid-row-gap: 14px;
        grid-column-gap: 14px;
        .best-goods-item {
          margin-bottom:10px;
            width: 234px;
            height: 400px;
            &:hover {
                box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.19);
            }
        }
      .best-goods-item:hover {
        cursor: pointer;
      }
    }
}
</style>
