<template>
  <div class="orderList">
    <div class="title">
      <div class="left">
        操作说明
      </div>
    </div>
    <hr class="fenge"/>
    <div class="list">
      <div class="qiye fontColor"><a @click="downFile('https://xiniumall.oss-cn-beijing.aliyuncs.com/cineplex/file/3d4bbfca-10ba-4740-8789-d603a31307ad.pdf')" class="a">GDC授权文件操作说明</a></div><!-- https://xiniumall.oss-cn-beijing.aliyuncs.com/cineplex/file/3d4bbfca-10ba-4740-8789-d603a31307ad.pdf -->
      <!--<div class="yingaddress fontColor"><a class="a">LED或C6授权文件操作说明</a></div>-->
      <!--<div class="qiye fontColor">企业名称： {{ customerCompany.businessName }}</div>
      <div class="yingname fontColor">影院名称： {{ customerCompany.customerName }}</div>
      <div class="yingaddress fontColor">影院地址： {{ customerCompany.address }}</div>
      <div class="yingcode fontColor">影院编码： {{ customerCompany.customerCode }}</div>
      <div class="yuanxian fontColor">所属院线： {{ customerCompany.theatreChainName }}</div>-->
    </div>
    <div class="two">
    <div class="title">
      <div class="left">
        服务器列表
      </div>
    </div>
    <hr class="fenge"/>
    <div class="list">
      <el-table
        :data="customerCompany"
        border
        max-height="600px"><!-- style="width: 100%" -->
        <el-table-column
          fixed
          prop="index"
          width="50px"
          label="序号">
          <template slot-scope="scope">
            <span>{{scope.$index + 1}}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="80px"
          prop="productModel"
          label="产品型号">
        </el-table-column>
        <el-table-column
          width="80px"
          prop="cinemaNo"
          label="厅号">
        </el-table-column>
        <el-table-column
          width="80px"
          prop="assetsSn"
          label="产品SN">
        </el-table-column>
        <el-table-column
          width="230px"
          prop="assetsFileName"
          label="授权文件">
        </el-table-column>
        <el-table-column
          prop="assetsEndDate"
          width="180px"
          label="授权有效期">
          <template slot-scope="scope">
            <span v-if="scope.row.assetsStartDate && scope.row.assetsEndDate">{{dateFamit(scope.row.assetsStartDate)}}至{{dateFamit(scope.row.assetsEndDate)}}</span>
            <span v-else-if="scope.row.assetsEndDate">至:{{dateFamit(scope.row.assetsEndDate)}}</span>
            <span v-else>{{dateFamit(scope.row.assetsEndDate)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="attribute1"
          width="80px"
          label="授权状态">
        </el-table-column>
        <el-table-column
          width="120px"
          prop="expirationDate"
          fixed="right"
          label="操作">
          <template slot-scope="scope">
            <el-button @click="downloadFile(scope.row)" :disabled="scope.row.assetsStatus !== '01' && scope.row.assetsStatus !== '02'">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    </div>
  </div>
</template>

<script>
import { baseUrl } from '@/config/env'
export default {
  data () {
    return {}
  },
  props: {
    customerCompany: {
      type: Array,
      required: true
    }
  },
  methods: {
    downloadFile (row) {
      console.log(row)
      debugger
      if (row.assetsFile === null || row.assetsFile.length <= 0) {
        this.$notify({
          title: '提示',
          message: '授权文件不存在,请联系管理员!',
          type: 'warning',
          duration: 2000
        })
      } else {
        // window.open(row.assetsFile)
        // this.downloadUrl(row.assetsFile)
        var mpurl = `${baseUrl}/cineplex/homePageOther/exportDownload?id=${row.id}&token=${this.$store.getters.token}&ticket=${this.$store.getters.userInfo.ticket}`
        window.open(mpurl, '_blank')
        setTimeout(res => {
          this.$emit('onLoad')
        }, 3000)
      }
    },
    dateFamit (date) {
      if (date) {
        return date.substring(0, 10)
      } else {
        return ''
      }
    },
    downFile (fileUrl) {
      window.open(fileUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.xuan {
  float: right;
  margin-top: -5px;
}
.fenge {
  margin-top: 20px;
}
.yingaddress {
  display: block;
  float: left;
  width: 50%;
}
.yuanxian {
  display: block;
  float: left;
  width: 100%;
}
.yingcode {
  display: block;
  float: left;
  width: 50%;
}
.yingname {
  display: block;
  float: left;
  width: 50%;
}
.qiye {
  display: block;
  float: left;
  width: 50%;
}
.fontColor {
  color: #8c939d;
  font-size: 15px;
}
.person1 {
  margin-top: -20px;
  float: left;
  margin-left: 18.32%;
  width: 50%;
}
.tel1 {
  float: left;
  margin-left: 20%;
  width: 50%;
}
.mail1 {
  float: left;
  margin-left: 20%;
  width: 50%;
}
.person2 {
  float: left;
  margin-left: 18.32%;
  width: 50%;
}
.tel2 {
  float: left;
  margin-left: 20%;
  width: 50%;
}
.mail2 {
  float: left;
  margin-left: 20%;
  width: 50%;
}
.two {
  margin-top: 105px;
}
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 38px 40px;
  height: 593px;
  box-sizing: border-box;
  .title {
    color: #333333;
    font-size: 24px;
  }
  .list {
    margin-top: 35px;
    color: #333333;
    font-size: 14px;
    line-height: 40px;
  }
  .a {
    text-decoration:underline;
  }
  .a:hover {
    cursor: pointer;
    color: #ef922e;
  }
}
</style>
